import React, { useEffect, useState } from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {UseMyRoadmapModule} from '../../../profile/components/my-road-step/store'
import useAuth from '../../../../api/main'
import {TopicsEbookDrawer} from '../../../../../_metronic/partials'
import { UseModeCourseModule } from '../../../courses/components/front-page/store/main'

type Props = {
  logoCourse: string
  colorCourse: string
  lessonName: string
  mode: 'STUDENT' | 'ADMIN' | 'TEACHER' | 'PUBLIC'
}

const HeaderBook: React.FC<Props> = ({logoCourse, colorCourse, lessonName, mode}) => {
  const navigate = useNavigate()
  const {settings} = UseMyRoadmapModule()
  const {modeCourseChecked} = UseModeCourseModule();
  const {typeUser} = useAuth()
  const [isMobile,setIsMobile] = useState<boolean>(window.innerWidth <= 990); //768 son los pixeles que usa Bootstrap para determinar que una pantalla es md o menos
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 990);
      // if(window.innerWidth <= 990){
      //   setStatePage(1)
      // }
    };
    modeCourseChecked();
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className='d-flex'>
      <div
        style={
          isMobile
          ? {
            // display: 'flex',
            zIndex: 2,
            backgroundColor: colorCourse,
            borderRadius: '8px',
            width: '100vw',
            padding: '5px',
          }
          : {
            position: 'fixed',
            top: 65,
            left: 50,
            display: 'grid',
            gridTemplateRows: 'auto 1fr auto',
            zIndex: 2,
            backgroundColor: colorCourse,
            borderRadius: '8px',
            padding: '5px',
          }
        }
      >
        <div className='d-flex justify-content-between align-items-center'>
          {!isMobile && (
            <div
              onClick={() => {
                if (typeUser === 'STUDENT') {
                  navigate(`/courses/${settings?.currentCourse}`)
                } else {
                  navigate(`/learning-path`)
                }
              }}
              className='me-5 cursor-pointer'
            >
              <img
                src={toAbsoluteUrl('/media/svg/michigan-icons/header/blue/back_blue.svg')}
                alt=''
                style={{width: '40px'}}
              />
            </div>
          )}
          <img
            src={logoCourse}
            alt=''
            style={{height: `${isMobile ? '30px' : '40px'}`, width: 'auto'}}
          />
          <div className='d-flex me-5' style={{ width: '2px', height: '100%', backgroundColor: 'white' }} />
          {/* <div> */}
            {isMobile ? (
              // <div className='d-flex justify-end'>
                <TopicsEbookDrawer colorCourse='' mode={mode} />
              // </div>
            ) : (
              <span className='poppins-light fs-2' style={{color: 'white'}}>
                {lessonName}
              </span>
            )}
          {/* </div> */}
        </div>
      </div>
    </div>
  )
}

export {HeaderBook}
