import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik, } from 'formik'
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {User} from '../../interfaces'
import InputMask from 'react-input-mask';
import { UseChangeCredential } from '../../services'
import { UseUserModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { UseCountryAll } from '../../../country/services'
import { Country } from '../../../country/interfaces'
import { showSimpleAlert } from '../../../../../commonHooks/alert'

export type FormFields = {
  id?: string
  email: string
  password?:string
}
const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const entitySchema = Yup.object().shape({
  email: Yup.string(),
  password: Yup.string()
})


type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'|'CREDENTIALS';
}
const ChangeCredentialsForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {itemSelected, mode,type, resetData,setModalCredentials} = UseUserModule();
  const [data, setData] = useState<User>()
  const [countries, setCountries] = React.useState<Country[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
   useFormik<FormFields>({
      initialValues: {
        email:  itemSelected?.email??'',
        password: ''
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
      
        if(itemSelected){
          try{
            setLoading(true);
              await UseChangeCredential({
                user:itemSelected.id,
                password:formData.password,
                email:formData.email
              });
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['user'] });
              setModalCredentials(false)
            }catch(e){
              setLoading(false);
            }
          }
      },
    })

 
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Email</label>
            <input
              type='email'
              className='form-control form-control-lg form-control-solid'
              placeholder='dummy@gmail.com'
              name={'email'}
              value={values.email}
              onChange={handleChange}

            />
            {errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.email}</span>
                </div>
              </div>
            )}
          </div>

         <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Password </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'password'}
              value={values.password}
              onChange={handleChange}
            />
            {errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        {<div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>}
      </form>
    </div>
  )
}

export {ChangeCredentialsForm}
