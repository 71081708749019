import useAuth from "../api/main";
import { showSimpleAlert } from "../commonHooks/alert";

export const catchServerError = (response: any) => {
  const { token, logout } = useAuth.getState();
  if (response.status === 401) {
    logout()
  }
  if (response.status === 400) {
    //console.log(e.response)
    if (Array.isArray(response.data.message)) {
      response.data.message.map((x) => {
        showSimpleAlert({ message: x, icon: 'error' })
      })
    } else {
      showSimpleAlert({ message: response.data.message, icon: 'error' })
    }
    throw 'Error'
  }
  showSimpleAlert({ message: 'Error!', icon: 'error' })
  throw 'Error'
};