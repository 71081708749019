import React from 'react';
import { Dropdown } from 'react-bootstrap';

interface SummaryByDayProps {
  summaryByDay: {
    MONDAY: number;
    TUESDAY: number;
    WEDNESDAY: number;
    THURSDAY: number;
    FRIDAY: number;
    SATURDAY: number;
  };
}

const SummaryByDayDropdown: React.FC<SummaryByDayProps> = ({ summaryByDay }) => {
  return (
    <Dropdown className='btn-sm'>
      <Dropdown.Toggle variant="primary" id="summary-by-day-dropdown">
        Summary by Day
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.entries(summaryByDay).map(([day, count]) => (
          <Dropdown.Item key={day}>
            {day}: {count}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SummaryByDayDropdown;