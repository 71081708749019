import React from 'react';
import moment from 'moment';
import { Booking } from '../../../admin/appointment/interfaces';
import useAuth from '../../../../api/main';
import CardCourse from '../../../../components/common/expresive/card-course';
import BookingWindows from '../../../booking-window';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useThemeMode } from '../../../../../_metronic/partials';
import { useTranslation } from 'react-i18next';
import { WelcomeHeaderModule } from '../../../../components/common/widgets/statistics/WelcomeHeaderModule';
import './styles-student.css'
import { AdvertisementsCard } from '../Advertisements';
import { LinkComponent } from '../LinkComponent';
import { UseMyRoadmapModule } from '../../../profile/components/my-road-step/store';
import { UseEvaluationCourseModule } from '../../../courses/screen/store/main';
import { useQueryClient } from '@tanstack/react-query';
import { useTrailClassStudentState } from './hooks/useTrailClassRepo';
import UseTranslateTimeZone from '../../../../hooks/useTranslateTimeZone';
import { FloatingButton } from '../next-lesssons/float-button';

type Props = {

}
const StudentDashboardMain: React.FC<Props> = ({ }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  
  const { mode } = useThemeMode()
  const [windowMode, setWindowMode] = React.useState<'NORMAL' | 'WELCOME' | 'VERIFICATION' | 'PLACEMENT_TEST'>('NORMAL');
  const { settings } = UseMyRoadmapModule();
  let { profile, notifications, fullName } = useAuth();
  const {setCurrentTask} = UseEvaluationCourseModule();
  
  const [loading, setLoading] = React.useState<boolean>(true)
  const [bookingNext, setBookingNext] = React.useState<Booking>();

  const {data, isLoading} = useTrailClassStudentState();

  const setterWindowsMode = () => {
    if (settings?.verification == 'PENDING' || settings?.verification == 'IN_PROCESS') {
      setWindowMode('VERIFICATION')
      return
    }
    if (settings?.welcomeDay == 'PENDING' || settings?.welcomeDay == 'IN_PROCESS') {
      setWindowMode('WELCOME')
      return
    }
    if (settings?.placementTest == 'PENDING' || settings?.placementTest == 'IN_PROCESS') {
      setWindowMode('PLACEMENT_TEST')
      return
    }
    setWindowMode('NORMAL')
  }
  React.useEffect(() => {
    setterWindowsMode();
  }, [settings]);

  // Se movio para start booking windows porque solo se hace al principio, no todas las veces 
  // para saber cuantas clases por semana tiene el estudiante
  // const findStarterList = async () => {
  //   let starterList = await UseFindPaginatedTrailClass({
  //     before: moment(day)/*.subtract(7,'d')*/.startOf('week').format('YYYY-MM-DD').toString(),
  //     after: moment(day)/*.add(12,'d')*/.endOf('week').format('YYYY-MM-DD').toString()
  //   });
  //   const numberClassWorkshops = starterList?.result.filter((x) => x.isSuspense === false && x?.activityType === 'WORKSHOP')
  //   const numberClassLessons = starterList?.result.filter((x) => x.isSuspense === false && x?.activityType === 'LESSON')
    
  //   setLessonHoursThisWeek(numberClassLessons.length)
  //   setWorkshopHoursThisWeek(numberClassWorkshops.length);

  //   setMyAgenda(starterList?.result)
  //   let list: any[] = [];
  //   if (starterList?.result && starterList?.result?.length > 0) {
  //     starterList.map((x) => {
  //       list.push(moment(x.start))
  //     })
  //   }
  //   setAvailableList(list);
  // };

  React.useEffect(() => {
    if (settings?.banner) {
      setLoading(false)
    }
  }, [settings])

  React.useEffect(() => {
    if (!isLoading && data && data.length > 0) {
      const interval = setInterval(() => {
        const currentDateTime = moment();
        // Se transforma la fecha por la zona horario del usuario
        data.sort((a, b) => {
          let endDiff = moment(
            UseTranslateTimeZone(a.time.end)
          ).diff(
            UseTranslateTimeZone(b.time.end)
          );
          if (endDiff !== 0) return endDiff;
          return moment(
            UseTranslateTimeZone(a.time.start)
          ).diff(moment(
            UseTranslateTimeZone(b.time.start)
          ));
        });
        const foundClass = data?.filter((a) => a.isSuspense === false).find(
          (x) => currentDateTime.isBefore(UseTranslateTimeZone(x.time.end))
        );
        
        if (foundClass) {
          setBookingNext(foundClass);
        } else {
          setBookingNext(undefined);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
    
  }, [data, isLoading]);

  React.useEffect(() => {
    queryClient.invalidateQueries({queryKey: ['trail-active-dashboard']});
  }, []);

  React.useEffect(() => {
    if (windowMode !== 'NORMAL' && windowMode !== 'VERIFICATION' && settings?.taskId) {
      setCurrentTask(settings?.taskId);
    }
  }, [windowMode, settings]);

  const nameParts = profile?.firstName?.split(' ') ?? [''];
  const formattedFirstName = nameParts[0].charAt(0).toUpperCase() + nameParts[0].slice(1).toLowerCase();
  const formattedLastName = nameParts.slice(1).join(' ').toLowerCase();

  return (
    <div className="card p-5 student-dashboard-container" >

      
      <div className='row'>
        <div className='col-12 '>
          <WelcomeHeaderModule
            title={`${profile?.firstName && profile?.lastName ? `${formattedFirstName} ${formattedLastName}` : fullName}`}
            leftIcon={'/media/svg/michigan-icons/header/blue/lobby_blue.svg'}
            svgIcon={`/media/svg/michigan-icons/notifications/white/${notifications.length > 0 ? 'notifications2_white' : 'one'}.svg`}
            background={mode != 'dark' ? '#2d30e1' : '#1e1e2d'}
            colorTitle={mode === 'dark' ? '#2d30e1' : '#2d30e1'}
            routeBack='/notifications'
          />
        </div>
        <div className='col-md-6 col-lg-6 mb-4'>
          <div className="row">
            <div className='col-lg-8 col-md-8 col-sm-12'>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <a href="/me-booking" className={`card ${bookingNext && 'breathing-blue-effect'} text-decoration-none mb-5`} style={{ backgroundColor: mode === 'light' ? '#1cebcc' : '#14141a', borderRadius: 50 }}>
                  <div className='card-body pt-3 pb-3 row'>
                    <div className='col-8 d-flex flex-column justify-content-center' style={{ color: mode === 'light' ? '#2d30e1' : '#1cebcc' }}>
                      <span className='poppins-bold fs-5'>{bookingNext ? 'Ongoing Bookings' : `You don't have`}</span>
                      <span className='champ-bold fs-1'>
                        {bookingNext ? UseTranslateTimeZone(bookingNext?.start).format('ddd, MMM DD') :
                          `classes scheduled`}
                      </span>
                      <span className='poppins-light fs-6'>{bookingNext ? UseTranslateTimeZone(bookingNext?.start).format('YYYY / HH:mm') : ''}</span>
                    </div>
                    <div className='col-4 d-flex justify-content-center align-items-center'>
                      <img src={toAbsoluteUrl(`/media/svg/michigan-icons/header/blue/my_booking_eye.svg`)} alt="" style={{ width: '150px' }} className='p-2' />
                    </div>
                  </div>
                </a>
                {/* <CardHeaderInformation
                      className='card text-white mb-4'
                      svgIcon='cheque'
                      color={mode==='light'?'#1cebcc':'#14141a'}
                      iconColor='#2d30e1'
                      title={bookingNext?.title ?? undefined}
                      titleColor={mode==='light'?'#2d30e1':'#1cebcc'}
                      descriptionColor='#2d30e1'
                      description={bookingNext ? moment(bookingNext?.start).format('ddd, MMM DD'):undefined}
                      hour={bookingNext ? moment(bookingNext?.start).format('HH:mm'):undefined}
                      link={`lobby-class/${bookingNext?.id}`}
                    /> */}
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 h-100">
                <BookingWindows />
              </div>
            </div>
            <div className='row col-lg-4 col-md-4 col-sm-12 me-0 pe-0'>
              <LinkComponent
                url='/my-courses'
                colorTitle='#2d30e1'
                title='Road Map'
                background='#1cebcc'
                className='col-lg-12 col-md-12 col-sm-6 container-link-component-item py-2'
                icon='/media/svg/michigan-icons/header/blue/roadmap_blue.svg'
              />
              <LinkComponent
                url='/faq'
                colorTitle='#2d30e1'
                title='Faqs'
                background='#1cebcc'
                className='col-lg-12 col-md-12 col-sm-6 container-link-component-item py-2'
                icon='/media/svg/michigan-icons/header/blue/faqs_blue.svg'
              />
            </div>
          </div>
        </div>
        <div className=" row col-md-6 col-lg-6 col-sm-12 mb-4" >
          <div className='col-md-6 col-lg-6 col-sm-12 col-xs-12 py-2 ps-0' style={{ objectFit: 'contain' }}>
            {loading ?
              <div className="d-flex justify-content-center align-items-center"
                style={
                  mode != 'dark' ? {
                    height: 350,
                    borderRadius: '50px',
                    background: 'white',
                    padding: 3
                  } : {
                    height: '100%',
                    borderRadius: '50px',
                    background: '#1e1e2d',
                    padding: 3,
                    border: `1px solid`,
                    borderColor: '#2d30e1'
                  }
                }>
                <div className="spinner-grow text-red" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
              : <>
                {windowMode == 'NORMAL' &&
                  <CardCourse
                    className={settings?.numberTasksPending && settings.numberTasksPending > 0 ? 'breathing-blue-effect' : undefined}
                    title='Imagen del curso'
                    imageUrl={toAbsoluteUrl(`${settings?.banner}`)}
                    url={`/courses/${settings?.currentCourse}`}
                  />
                }
                {windowMode == 'WELCOME' &&
                  <CardCourse
                    className='breathing-blue-effect'
                    title='Welcome Day'
                    imageUrl={toAbsoluteUrl('/media/svg/michigan-icons/welcome_day.png')}
                    url={(settings?.taskId && settings?.evaluationId) ? `/evaluation/screen/course/${settings.evaluationId}` : '#'}
                  />
                }
                {windowMode == 'PLACEMENT_TEST' &&
                  <CardCourse
                    className='breathing-blue-effect'
                    title='Placement Test'
                    imageUrl={toAbsoluteUrl('/media/svg/michigan-icons/placement_test.png')}
                    url={(settings?.taskId && settings?.evaluationId) ? `/evaluation/screen/course/${settings.evaluationId}` : '#'}
                  />
                }
                {windowMode == 'VERIFICATION' &&
                  <CardCourse
                    className='breathing-blue-effect'
                    title='Verificacion'
                    imageUrl='https://media.istockphoto.com/id/531013983/vector/delivery-verification-banner-design.jpg?s=612x612&w=is&k=20&c=9J6WCOjjGVlQmHHPu0mD6ElFEL7RaqclyfFn3MdxnDA='
                    url={'#'}
                  />
                }
              </>
            }
          </div>
          <div className='col-md-6 col-lg-6 col-sm-12 col-xs-12 py-2' style={{ objectFit: 'contain' }} >
            <AdvertisementsCard />
          </div>
        </div>
      </div>
    </div>
  )
};

export { StudentDashboardMain }