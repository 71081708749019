import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { UseBookContainerModule } from '../../store/main';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';

interface AudioPlayerProps {
  audioUrl: string;
  color: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioUrl, color}) => {
  const { setOffDraggable } = UseBookContainerModule();
  const waveformRef = useRef<WaveSurfer | null>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [playbackRate, setPlaybackRate] = useState(1);
  const speedOptions = [0.5, 1, 1.5, 2];

  const handleSpeedChange = () => {
    const currentSpeedIndex = speedOptions.indexOf(playbackRate);
    const nextSpeedIndex = (currentSpeedIndex + 1) % speedOptions.length;
    const newSpeed = speedOptions[nextSpeedIndex];
  
    setPlaybackRate(newSpeed);
    if (waveformRef.current) {
      waveformRef.current.setPlaybackRate(newSpeed);
    }
  };

  useEffect(() => {
    if (waveformRef.current === null) {
      setIsLoading(true)

      waveformRef.current = WaveSurfer.create({
        container: '#waveform',
        waveColor: 'transparent',
        progressColor: 'lightblue',
        barWidth: 0,
        height: 2,
        cursorWidth: 0,
      });
      waveformRef.current.load(audioUrl);
      
      waveformRef.current.on('ready', () => {
        waveformRef.current?.setVolume(0.5);
        waveformRef.current?.setPlaybackRate(playbackRate); // Ajusta la velocidad de reproducción
        setDuration(waveformRef.current?.getDuration() || 0);
        setIsLoading(false)
      });
      
      waveformRef.current.on('audioprocess', () => {
        const currentTime = waveformRef.current?.getCurrentTime() || 0;
        const duration = waveformRef.current?.getDuration() || 0;
        setCurrentTime(currentTime);
        setProgress(currentTime / duration);
      });
    }

    return () => {
      if (waveformRef.current !== null) {
        waveformRef.current.destroy();
        waveformRef.current = null;
      }
    };
  }, [audioUrl]);

  const togglePlay = () => {
    if (waveformRef.current !== null) {
      if (isPlaying) {
        setOffDraggable(false);
        waveformRef.current.pause();
      } else {
        setOffDraggable(true);
        waveformRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleProgressClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (waveformRef.current && progressRef.current) {
      const rect = progressRef.current.getBoundingClientRect();
      const clickX = e.clientX - rect.left;
      const newProgress = clickX / rect.width;
      waveformRef.current.seekTo(newProgress);
      setProgress(newProgress);
      setCurrentTime(newProgress * duration);
    }
  };

  const handleCircleDragStart = () => {
    setOffDraggable(true);
    document.addEventListener('mousemove', handleCircleDrag);
    document.addEventListener('mouseup', handleCircleDragEnd);
  };

  const handleCircleDrag = (e: MouseEvent) => {
    if (progressRef.current) {
      const rect = progressRef.current.getBoundingClientRect();
      const dragX = e.clientX - rect.left;
      const newProgress = Math.min(Math.max(dragX / rect.width, 0), 1);
      if (waveformRef.current) {
        waveformRef.current.seekTo(newProgress);
      }
      setProgress(newProgress);
      setCurrentTime(newProgress * duration);
    }
  };

  const handleCircleDragEnd = () => {
    setOffDraggable(false);
    document.removeEventListener('mousemove', handleCircleDrag);
    document.removeEventListener('mouseup', handleCircleDragEnd);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center',}}>
      {isLoading ? 
      <span className='indicator-progress d-flex justify-content-center  align-items-center ms-5'>
        <span className='spinner-border spinner-border-lg align-middle' style={{color: color}}> </span>
      </span>  
      : isPlaying ?
        <img alt="pause" className='cursor-pointer ' onClick={togglePlay} src={toAbsoluteUrl('/media/svg/michigan-icons/tools/blue/pause_timer_blue.svg')} style={{ width: '30px' }} /> :
        <img alt="play" className='cursor-pointer ' onClick={togglePlay} src={toAbsoluteUrl('/media/svg/michigan-icons/tools/blue/play_timer_blue.svg')} style={{ width: '30px' }} />
      }
      <span className='popping-light ms-1' style={{ color: color }}>{formatTime(currentTime)}</span>
      <div
        id="waveform"
        ref={progressRef}
        style={{ width: '170px', height: '4px', backgroundColor: 'lightblue', position: 'relative', cursor: 'pointer',marginLeft:'5px' }}
        onClick={handleProgressClick}
        onMouseDown={() => setOffDraggable(true)}
        onMouseUp={() => setOffDraggable(false)}
      >
        <div style={{ width: `${progress * 100}%`, height: '100%', backgroundColor: 'blue', position: 'absolute', top: '0', left: '0' }} />
        <div
          style={{
            position: 'absolute',
            top: '-3px',
            left: `calc(${progress * 100}% - 6px)`,
            width: '12px',
            height: '12px',
            backgroundColor: 'blue',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          draggable={false}
          onMouseDown={handleCircleDragStart}
        />
      </div>
      <span className=' ms-2' style={{ color: color }}>{formatTime(duration)}</span>
      <button 
        className='ms-1 text-certer poping-light p-0 btn btn-outline-light'
        style={{borderRadius: '50%',width:'28px',height:'28px',border:'none',textAlign:'center',backgroundColor:'#C6F711'}}
        onClick={handleSpeedChange}>
        <span className='champ-bold fs-8 text-center ml-2' style={{color:'blue',}}>x{playbackRate}</span>
      </button>
    </div>
  );
};

export default AudioPlayer;