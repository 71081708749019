import React, { useEffect, useState } from 'react';
import { BookPage } from '../../../admin/book/modules/book-page/interfaces';
import MultiplePages from './multiplePages';
import useScrollAndZoom from '../../../../hooks/useScrollAndZoom';
import { UseBookContainerModule } from '../../store/main';
import { UseModeCourseModule } from '../../../courses/components/front-page/store/main';

type Props = {
  pagesBook: BookPage[];
  numberRenderPage: 1 | 2;
  numberPage: number;
};

const RenderBook: React.FC<Props> = ({ pagesBook, numberRenderPage, numberPage }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const containerSmallRef = React.useRef<HTMLDivElement>(null);
  const activeContainerRef = React.useRef<HTMLDivElement | null>(null);

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 990);
  const [renderPageQuantity, setRenderPageQuantity] = useState<1 | 2>(2);
  const { toolsFullscreen } = UseBookContainerModule();

  const {modeCourseChecked} = UseModeCourseModule();

  const { 
    handleScroll, 
    handleMouseDown, 
    handleMouseMove, 
    handleMouseUp, 
    handleMouseEnter, 
    handleMouseLeave, 
    zoom, 
    isDragging 
  } = useScrollAndZoom(containerRef, activeContainerRef);

  const {
    handleScroll: handleScrollMovil,
    handleMouseDown: handleMouseDownMovil,
    handleMouseMove: handleMouseMoveMovil,
    handleMouseUp: handleMouseUpMovil,
    handleMouseEnter: handleMouseEnterMovil,
    handleMouseLeave: handleMouseLeaveMovil,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    zoom: zoomMovil,
    isDragging: isDraggingMovil
  } = useScrollAndZoom(containerSmallRef, activeContainerRef);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 990);
    };
    modeCourseChecked();
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const disableRightClickOnImages = () => {
      const images = document.querySelectorAll('img');
      images.forEach(img => {
        img.addEventListener('contextmenu', (event) => {
          event.preventDefault();
        });
      });
    };

    const observer = new MutationObserver(disableRightClickOnImages);
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    disableRightClickOnImages(); // Aplicar en la carga inicial

    return () => observer.disconnect(); // Desconectar el observador al desmontar
  }, []);

  useEffect(() => {
    setRenderPageQuantity(isMobile ? 1 : numberRenderPage);
  }, [isMobile, numberRenderPage]);

  if (renderPageQuantity === 1 && pagesBook.length > 0 && numberPage) {
    const currentPage = pagesBook[numberPage - 1];
    if (!currentPage) {
      return (
        <div
          className='d-flex justify-content-center'
          style={{
            position: 'fixed',
            top: 120,
            left: 160,
            right: 296,
            bottom: 20,
            backgroundColor: '#3e94ff',
            borderRadius: '50px'
          }}
        >
          <p className='champ-light text-white mt-8 fs-2'>PAGINA NO DISPONIBLE</p>
        </div>
      );
    }
    return (
      <>
        <div
          id='regular-container-page'
          ref={containerRef}
          className='d-none d-lg-flex justify-content-center'
          style={
            toolsFullscreen
              ? {
                  position: 'fixed',
                  top: 65,
                  left: 0,
                  right: 135,
                  bottom: 0,
                  backgroundColor: '#FFFFFF',
                  overflow: 'hidden',
                  maxWidth: '100%',
                  maxHeight: '100%',
                }
              : {
                  position: 'fixed',
                  top: 120,
                  left: 160,
                  right: 296,
                  bottom: 20,
                  backgroundColor: '#FFFFFF',
                  borderRadius: '50px',
                  overflow: 'auto',
                }
          }
          onWheel={(e) => handleScroll(e as unknown as WheelEvent)}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <img
            key={currentPage.id}
            src={currentPage.file.location}
            alt=''
            style={
              toolsFullscreen
                ? {
                    transform: `scale(${zoom / 100})`,
                    transformOrigin: 'top left',
                    cursor: isDragging ? 'grabbing' : 'grab',
                    height: '100%',
                    width: 'auto',
                    // maxWidth: '100%',
                    maxHeight: '100%',
                  }
                : {
                    transform: `scale(${zoom / 100})`,
                    transformOrigin: 'top left',
                    cursor: isDragging ? 'grabbing' : 'grab',
                    width: 'fit-content',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    borderTopLeftRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomRightRadius: '50px',
                  }
            }
            draggable
            onDragStart={(event) => event.preventDefault()}
          />
        </div>
        <div
          id='small-container-page'
          ref={containerSmallRef}
          className='d-flex d-lg-none justify-content-center img-thumbnail'
          style={
            toolsFullscreen
              ? {
                  position: 'fixed',
                  top: 60,
                  left: 0,
                  right: 0,
                  bottom: 65,
                  backgroundColor: '#FFFFFF',
                  overflow: 'hidden',
                  maxWidth: '100%',
                  maxHeight: '100%',
                }
              : {
                  position: 'fixed',
                  top: 105,
                  left: 10,
                  right: 10,
                  bottom: 65,
                  backgroundColor: '#FFFFFF',
                  borderRadius: '5px',
                  overflow: 'auto',
                }
          }
          onWheel={(e) => handleScrollMovil(e as unknown as WheelEvent)}
          onMouseDown={handleMouseDownMovil}
          onMouseMove={handleMouseMoveMovil}
          onMouseUp={handleMouseUpMovil}
          onMouseEnter={handleMouseEnterMovil}
          onMouseLeave={handleMouseLeaveMovil}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <img
            key={currentPage.id}
            src={currentPage.file.location}
            alt=''
            style={
              toolsFullscreen
                ? {
                    transform: `scale(${zoomMovil / 100})`,
                    transformOrigin: 'top left',
                    cursor: isDraggingMovil ? 'grabbing' : 'grab',
                    height: '100%',
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }
                : {
                    transform: `scale(${zoomMovil / 100})`,
                    transformOrigin: 'top left',
                    cursor: isDraggingMovil ? 'grabbing' : 'grab',
                    width: 'fit-content',
                  }
            }
            draggable
            onDragStart={(event) => event.preventDefault()}
          />
        </div>
      </>
    );
  }

  return <MultiplePages pagesBook={pagesBook} />;
};

export { RenderBook };
