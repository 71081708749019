import { DataLessons } from '../interfaces/index'
import { UseLessonsModule } from '../store/main'
import useAuth from '../../../../../../api/main'
import { catchServerError } from '../../../../../../hooks/useCatchError'
import { api } from '../../../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../../../commonHooks/alert'

const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export const useLessonsAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/lessons/simple?type_category=all`,
    {
      headers: {
        Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}
export const useLessonsAllPaginate = async (): Promise<any> => {
  try {
    // await authChecked()
    const { setPaginator, limit, currentPage, where, isActive } = await UseLessonsModule.getState()

    const response: any = await api.get(
      `/lesson/paginated?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const LessonsAdd = async (data: DataLessons): Promise<any> => {
  try {
    const response: any = await api.post(
      '/lesson',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const LessonsUpdate = async (data: DataLessons): Promise<any> => {
  try {
    const { itemSelected } = UseLessonsModule.getState()
    const response: any = await api.patch(
      `/lesson/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const LessonsDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/lesson/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {

    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseLessonByChapter = async (term: string, limitNumber?:number): Promise<any> => {
  try {
    const { setPaginator, limit, currentPage, where, isActive } = await UseLessonsModule.getState()
    const response: any = await api.get(`/lesson/paginated-by-chapter?isActive=${isActive ? 1 : 0}&limit=${limitNumber ? limitNumber : limit}&page=${currentPage}&where=${where}&root=${term}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    setPaginator(response.data);
    return response.data;
  } catch (err) {
    //@ts-ignore
    catchServerError(e.response)
  }
}


export const UseAllLessonByChapter = async (term: string): Promise<any> => {
  try {
    const response: any = await api.get(`/lesson/by-chapter/${term}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data;
  } catch (err) {
    //@ts-ignore
    catchServerError(e.response)
  }
}

export const UseLessonOne = async (id:string): Promise<any> => {
  try {
    const response = await api.get(`/lesson/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}

export const LessonAddGlossaryTips = async (data: any) => {
  try {
    const { itemSelected } = await UseLessonsModule.getState()
    const response = await api.patch(`/lesson/tips-glossary/${itemSelected?.id}`, 
    {
      ...data
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    showSimpleAlert({ message: 'Ok!', icon: 'success' });
    return response.data;
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const LessonsUpdateEvaluation = async (idLesson: string, idEvaluation: string): Promise<any> => {
  try {
    const response: any = await api.patch(
      `/lesson/evaluation/${idLesson}`,
      {
        evaluation: idEvaluation,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}