import React, { useState } from 'react';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import HeaderCard from '../ui/HeaderCard';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { UseModuleExerciseEleven } from './main/main';
import './ExerciseEleven.css'
import { auto } from '@popperjs/core';
type Props = {
    instruction: string
}

const ExerciseEleven: React.FC<Props> = () => {
    const { Arrayquestion, instruction, checked, setChecked, setInputValuesMo } = UseModuleExerciseEleven();
    const [validationResults, setValidationResults] = useState<(string | JSX.Element | null)[]>(Arrayquestion.row.map(() => null)); // Estado inicializado con un arreglo de valores nulos
    const [tableEnabled, setTableEnabled] = useState(true);
    const [selectedCellId, setSelectedCellId] = useState<any[]>([]);
    const correctImage = <FontAwesomeIcon icon={faCheck} style={{ color: "#34f000", width: '15px', height: '15px' }} className='animate__bounce animate__animated ' />;
    const incorrectImage = <FontAwesomeIcon icon={faTimes} style={{ color: "#ff0000", width: '15px', height: '15px' }} className='animate__bounce animate__animated' />;
    
    const handleReset = () => {
        setTableEnabled(true);
        setSelectedCellId([]);
    }

    const handleCellClick = (rowIndex: number, columnIndex: number, idWord: string) => {
    const correctValue = Arrayquestion.row.find(row => row.idWord === idWord)?.value;
    // Verificar si el valor seleccionado es correcto
    if (correctValue && correctValue === Arrayquestion.column[columnIndex].value) {
        // console.log('Correcto');
    } else {
        const correctAnswer = Arrayquestion.column.find(col => col.value === correctValue)?.title;
        console.error(`Error. La respuesta correcta es: ${correctAnswer}`);
    }

    setSelectedCellId(prev => {
        const indexToUpdate = prev.findIndex(cell => cell.columnIndex === columnIndex);
        if (indexToUpdate !== -1) {
          return prev.map((cell, index) =>
            index === indexToUpdate ? { ...cell, rowIndex, idWord } : cell
          );
        } else {
          return [...prev, { rowIndex, columnIndex, idWord }];
        }
      });
};

const handleCheck = () => {
    const unselectedCellsExist = Arrayquestion.column.some((x, i) => 
        !selectedCellId.some(cell => cell.columnIndex === i)
    );
    
    if (unselectedCellsExist) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: '¡Por favor selecciona una celda en cada columna antes de verificar!',
        });
    } else {
        // Calcular el número de celdas correctas
        const correctCellsCount = selectedCellId.reduce((count, cell) => {
            // Obtener el valor correcto para esta fila
            const correctValue = Arrayquestion.row.find(row => row.idWord === cell.idWord)?.value;
            // Verificar si el valor seleccionado es correcto
            const isCorrect = correctValue === Arrayquestion.column[cell.columnIndex].value; 
            // Incrementar el contador si la celda es correcta
            return isCorrect ? count + 1 : count;
        }, 0);
        
        const totalCellsCount = selectedCellId.length; // Calcular el porcentaje de celdas correctas
        const accuracyPercentage = totalCellsCount > 0 ? (correctCellsCount / totalCellsCount) * 100 : 0;
        Swal.fire({
            title: `Porcentaje de aciertos: ${accuracyPercentage.toFixed(1)}%`,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#3f8bff' ,
            customClass: {
                title: 'poppins-light'
              },
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `
            },
            hideClass: {
              popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `
            }
          });
          setTableEnabled(false);
    }
    
    const newValidationResults = Arrayquestion.row.map(({ idWord, value }) => {
        const correctColumn = Arrayquestion.column.find(column => column.value === value);
        if (correctColumn) {
            return idWord === correctColumn.value ? correctImage : incorrectImage;
        } else {
            return incorrectImage;
        }
    });
    
    setValidationResults(newValidationResults);
    const updatedSelectedCells = selectedCellId.map(cell => { // Actualizar el estado de las celdas seleccionadas con el resultado de la validación
        const { rowIndex, columnIndex, idWord } = cell;
        const correctValue = Arrayquestion.row.find(row => row.idWord === idWord)?.value;
        const isCorrect = correctValue && correctValue === Arrayquestion.column[columnIndex].value;
        return {
            ...cell,
            isValid: isCorrect
        };
    });
    
    setSelectedCellId(updatedSelectedCells);
};
    React.useEffect(() => {
        console.log('selectede', selectedCellId)
    }, [selectedCellId])

    const divStyle = {
        backgroundColor: '#3f8bff',
        width: '525px',
        height: 'auto',
        borderRadius: '30px',
        borderColor: 'rgb(255, 255, 255)',
        borderStyle: 'solid',
        padding: '27px 20px 38px 20px'
    };

    return (
        <>
            <div className="container-fluid" style={{ display: 'flex' }}>
                <div style={divStyle}>
                    <HeaderCard/>
                    <p className=" text-white  mb-0 poppins-light fs-8 p-2">{instruction}</p>
                    <div className="table-responsive rounded-4 border border-2 border-white overflow-auto">
                        <table className={`table-bordered border-white p-0 ${tableEnabled ? '' : 'disabled-table'}`} style={{ width: 'auto' }}>
                            <colgroup>
                            <col style={{ width: '70px' }} /> 
                            </colgroup>
                            <thead>
                            <tr className="poppins-bold fs-9">
                                <th></th>
                                {Arrayquestion.column.map(({ title }, index) => (
                                <th
                                    style={{ background: '#D0FF00', width: '80px' }}
                                    className="text-primary text-center bg-light p-"
                                    key={title}
                                >
                                    {title}
                                </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody className="text-center text-white poppins-light fs-9">
                            {Arrayquestion.row.map(({ idWord, title }, rowIndex) => (
                                <tr className='td-size' key={idWord}>
                                <td className='text-primary poppins-bold p-1' style={{ background: '#D0FF00', width: '80px' }}>{title}</td> 
                                {Arrayquestion.column.map(({ title: columnTitle, value: columnValue }, columnIndex) => (
                                    <td
                                    key={columnValue}
                                    id={`${idWord}-${columnValue}`} // ID único para cada celda
                                    onClick={() => handleCellClick(rowIndex, columnIndex, idWord)}
                                    className={selectedCellId.some(cell => cell.rowIndex === rowIndex && cell.columnIndex === columnIndex) ? (selectedCellId.find(cell => cell.rowIndex === rowIndex && cell.columnIndex === columnIndex)?.isValid ? 'bg-success' : 'bg-danger') : 'bg-primary'}
                                    >
                                    </td>
                                ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                </div>
                <div className="position-relative" onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCheck();
                                                            setTableEnabled(false);
                                                        }}>
                                                            
                    <div className="position-absolute bottom-0 end-0" style={{ marginRight: '20px', marginBottom: '12px' }}>
                        <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="Next" />
                    </div>
                </div>
                <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} style={{ width: '40px', height: '40px' }}></img>
            </div>
        </>
    );
};
export { ExerciseEleven };