// Importa las dependencias necesarias
import React from 'react';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import { UseModeCourseModule } from '../../../../modules/courses/components/front-page/store/main';
import { UseMyRoadmapModule } from '../../../../modules/profile/components/my-road-step/store';

// Define la interfaz para las propiedades del componente
interface CardProps {
  imageUrl: string;
  title: string;
  className?: string;
  url?: string;
}

// Componente de tarjeta
const CardCourse: React.FC<CardProps> = ({ imageUrl, title, className, url }) => {
  const navigate = useNavigate();
  const {setModeCourse} = UseModeCourseModule();
  const { isLoading } = UseMyRoadmapModule()
  const handleClick = () => {
    if (url) {
      navigate(url);
      setModeCourse(true);
    }
    return;
  };
  return (
    <>
      {isLoading ? 
        <div className="d-flex justify-content-center align-items-center mb-1 h-100">
          <span className="spinner-grow spinner-grow-lg mx-1"></span>
        </div> 
        :
        <Card style={{}} 
          onClick={handleClick} 
          className={`${typeof url !== 'undefined' && 'cursor-pointer'}`}
        >
          <Card.Img
            style={{borderRadius:30}}
            variant="top" height={'375'} src={imageUrl} className={`card-img-top ${className}`} alt={`Imagen de ${title}`} 
          />
        </Card>
      }
    </>
  );
};

export default CardCourse;