import React from 'react';
import { TodayDashboard } from './elements/today';
import { ClassListTeacherDashboard } from './class-list';
import { UseAppointmentModule } from '../../../admin/appointment/store/main';
import { DayPickerSelector } from '../../../../components/common/DayPicker';
import moment from 'moment';
import useAuth from '../../../../api/main';
type Props = {

}
const TeacherDashboardMain: React.FC<Props> = ({ }: Props) => {
    const {
        after,
        before,
        setDate,
        setCurrentDate,
    } = UseAppointmentModule();
    let { profile } = useAuth();
    return (<>
        <div className="card">
            <div className="card-body">
                <div className='row'>
                    <div className='col-12 '>
                        <a href={'#'} className={`card text-white  mb-xl-8`} style={{ background: '#2d30e1', borderRadius: 30 }}>
                            <div className='card-body'>
                                <div className={`champ-light fw-semibold text-white`} style={{ fontSize: 40 }}>
                                    Welcome back,
                                </div>
                                <div className={`champ-bold fw-semibold text-white`} style={{ fontSize: 25 }}>
                                {` ${profile?.firstName ?? ''} ${profile?.lastName ?? ''}`}
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className='col-3 d-none d-sm-none d-xs-none d-md-none d-lg-block'>
                        <TodayDashboard />
                        <DayPickerSelector day={moment(before).toDate()} 
                            changeDate={(e) => {
                                setDate({
                                    after: e,
                                    before: e
                                })
                                setCurrentDate(moment(e).toDate());
                            }}
                        />
                    </div>
                    <div className='col-6 d-xl-none d-lg-none my-3 '>
                        <div className="input-group pl-4 pr-4">
                            <label className="input-group-text champ-light">Date</label>
                            <input className='form-select'
                                value={moment(before).format('YYYY-MM-DD')}
                                onChange={(e) =>{
                                    setDate({
                                        after: moment(e.target.value).toDate(),
                                        before: moment(e.target.value).toDate()
                                    })
                                    setCurrentDate(moment(e.target.value).toDate())
                                }}
                                type="date"
                            />
                        </div>
                    </div>
                    <div className="col ">
                        <ClassListTeacherDashboard typeUser='TEACHER' />
                    </div>
                </div>
            </div>
        </div>
    </>)
};

export { TeacherDashboardMain }