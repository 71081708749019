import React from 'react';
import './index.css';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { TemplateModifiedCourse, UseModeCourseModule } from './store/main';
import { LearningPathType } from '../../../abroad/learning-path/interfaces';
import { TopicsDrawer, TopicsEbookDrawer } from '../../../../../_metronic/partials';
import { logoAppSelector } from '../../../../hooks/useAppSelectorAssetsMode';
import { CourtesyTopicsDrawer } from '../../../../../_metronic/partials/layout/topics-drawer/CourtesyTopicsDrawer';

type Props = {
    description: string,
    logoCourse: string;
    bannerPrincipal: string;
    colorCourse: string;
    typeCourse: LearningPathType;
    mode: "ADMIN" | "STUDENT" | "COURTESY";
    courseId: string;
};

const FrontPageCourses: React.FC<Props> = ({ courseId, mode, description, logoCourse, bannerPrincipal, colorCourse, typeCourse }) => {
    const { modeCourse, modeFrontPage, setModeCourse, modeCourseChecked } = UseModeCourseModule();
    const [showTopics, setShowTopics] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (showTopics) {
            const buttonElement = document.querySelector('.button-topics-ee') as HTMLElement | null;
            if (buttonElement) {
                buttonElement.style.zIndex = '0'
            }
        }
        modeCourseChecked();
    }, [showTopics]);

    React.useEffect(() => {
        if (modeCourse || modeFrontPage) {
            TemplateModifiedCourse(colorCourse);
        }
    }, [modeCourse, modeFrontPage]);

    return (<>
        <div className='d-none d-lg-flex' style={{ overflowX: 'hidden' }}>
            <div className=''>
                <div className='d-flex row'>
                    <div className='col-6'>
                        <img
                            src={toAbsoluteUrl(logoAppSelector('LIGHT'))} alt=""
                            className='logo-ee-michigan'
                        />
                    </div>
                    <div className='col-6 text-end button-topics-ee'>
                        {(mode !== 'COURTESY') ? typeCourse === LearningPathType.ABROAD ?
                            <TopicsEbookDrawer
                                mode={mode}
                                courseId={courseId}
                                colorCourse={colorCourse}
                            />
                            :
                            <TopicsDrawer
                                mode={mode}
                                courseId={courseId}
                                colorCourse={colorCourse}
                            />
                            :
                            <CourtesyTopicsDrawer
                                courseId={courseId}
                                colorCourse={colorCourse}
                            />
                        }
                        {/* <button type='button'
                            className='btn bg-white cursor-pointer champ-light py-2 p-2'
                            style={{borderRadius: '30px', color: '#2d30e1'}}
                            // id='kt_glossary_course_toggle'
                            // id='kt_tips_course_toggle'
                            id={idDrawer}
                            onClick={() => setShowTopics(true)}
                        >
                            <img className='me-3 ms-4' style={{width:'10px'}} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/left_arrow.svg`)} alt='' />
                            <span className='pt-1 me-4'>Topics</span>
                        </button> */}
                    </div>
                </div>


                <div className='row'>
                    <div className='col-6'>
                        <div className='d-flex flex-column'>
                            <div className='d-flex justify-content-start'>
                                <a href="/dashboard" onClick={() => setModeCourse(false)}>
                                    <img src={toAbsoluteUrl('/media/svg/michigan-icons/header/white/back_white.svg')}
                                        alt=""
                                        className='back-ee'
                                    />
                                </a>
                            </div>
                            <div className=''>
                                <img src={logoCourse}
                                    alt=""
                                    className='logo-course-ee'
                                />
                            </div>
                            <span className='text-white description-course-ee'>
                                {description}
                            </span>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <img src={bannerPrincipal}
                                alt=""
                                className='img-fluid banner-course-ee'
                            //className='banner-course-ee'
                            />
                        </div>
                    </div>
                </div>

                {/* <div className='d-flex'>
                    <div className='d-flex flex-column'>
                        <div className='d-flex justify-content-start'>
                            <a href="/dashboard" onClick={()=>setModeCourse(false)}>
                                <img src={toAbsoluteUrl('/media/svg/michigan-icons/header/white/back_white.svg')}
                                    alt=""
                                    className='back-ee'
                                />
                            </a>
                        </div>
                        <div className=''>
                            <img src={logoCourse} 
                                alt=""
                                className='logo-course-ee'
                            />
                        </div>
                        <span className='text-white description-course-ee'>
                            {description}
                        </span>
                    </div>
                    <div>
                        <img src={bannerPrincipal} 
                            alt=""
                            className='banner-course-ee'
                        />
                    </div>
                </div> */}
            </div>
        </div>

        {/* Movil */}
        <div className='d-lg-none d-flex flex-column'>
            <div className='d-flex justify-content-between align-items-center mt-5 mx-5'>
                <img
                    src={toAbsoluteUrl(logoAppSelector('LIGHT'))} alt=""
                    style={{ width: '60px' }}
                />
                {(mode !== 'COURTESY') ? typeCourse === LearningPathType.ABROAD ?
                    <TopicsEbookDrawer
                        mode={mode}
                        courseId={courseId}
                        colorCourse={colorCourse}
                    />
                    :
                    <TopicsDrawer
                        mode={mode}
                        courseId={courseId}
                        colorCourse={colorCourse}
                    />
                    :
                    <CourtesyTopicsDrawer
                        courseId={courseId}
                        colorCourse={colorCourse}
                    />}
                {/* <button type='button'
                    className='btn bg-white p-2 champ-light d-flex justify-content-center align-items-center'
                    style={{borderRadius: '30px', color: '#2d30e1'}}
                    // id='kt_glossary_course_toggle'
                    // id='kt_tips_course_toggle'
                    id={idDrawer}
                    onClick={() => setShowTopics(true)}
                >
                    <img className='me-3 ms-4' style={{width:'10px'}} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/left_arrow.svg`)} alt='' />
                    <span className='pt-1 me-4'>Topics</span>
                </button> */}
            </div>
            <div className='d-flex flex-column'>
                <div className='d-flex justify-content-center align-items-center'>
                    <img src={logoCourse}
                        alt=""
                        className='logo-course-ee'
                    // style={{width: '350px'}}
                    />
                </div>
                <div className='d-flex justify-content-center align-items-center mx-5'>
                    <span className='text-white fs-5 text-center description-course-ee'>
                        {description}
                    </span>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <img src={bannerPrincipal}
                        alt=""
                        // style={{width: '400px'}}
                        className='banner-course-ee'
                    />
                </div>
            </div>
        </div>
    </>)
};

export { FrontPageCourses }