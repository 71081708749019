import React from "react";
import { CardHeaderModule } from "../../../../../../components/common/widgets/statistics/cardHeaderModule";
import { useThemeMode } from "../../../../../../../_metronic/partials";
//import './index.css';
import { useTranslation } from "react-i18next";
import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import { UseMyRoadmapModule } from "../../../../../profile/components/my-road-step/store";
import { UseTrailStrikeMe } from "../../../../../admin/trail-suspense/services";
import { UseBookingModule } from "../../../../../me-booking/store";
import { Item } from "../../../../../admin/transfer-class/components/itemComponent";

type Props = {};

const StrikesList: React.FC<Props> = () => {
  const {mode} = useThemeMode();
  const {t} = useTranslation();
  const {settings} = UseMyRoadmapModule();
  const {level,setStrikes,strikes} = UseBookingModule();

  const [highlightedItem, setHighlightedItem] = React.useState<any | null>(null);
  const [moreInfoActive, setMoreInfoActive] = React.useState<boolean>(true);

  const findTrailStrikes = async () => {
    try{
      let response = await UseTrailStrikeMe();
      setStrikes(response);
    }catch(e){
      console.log(e)
    }
  };

  React.useEffect(() => {
      findTrailStrikes();
  }, [level]);

  return(<>
    <CardHeaderModule
      title={t('STRIKES')} 
      leftIcon={`/media/svg/michigan-icons/header/${mode==='light'?'white/back_white':'dark-modules/back_red'}.svg`} 
      svgIcon={`/media/svg/michigan-icons/header/${mode==='light'?'white/strike_white':'dark-modules/strike_red'}.svg`} 
      background={'#ff336b'}
      colorTitle={'#FFFFFF'}
      routeBack={'/me-resume'}
    />


  <div className={`d-flex mt-8  ${moreInfoActive?'row':''}`}>
    <div className={`strike-container ${moreInfoActive?'col-lg-7 col-md-7 col-ms-12':'w-100'}`}>
      {strikes?.results && strikes.results.length>0?
      <>
        {strikes && strikes.results.map((x, i) => (
        <div
          key={i}
          className={`mb-1 ${strikes && strikes?.total >= i+1 && 'cursor-pointer'}`}
          style={{
            border: mode === 'light' ? '1px solid #ff336b' : '1px solid #FFFFFF',
            transition: 'background-color 0.3s, color 0.3s',
            backgroundColor: highlightedItem !== i ? strikes ? strikes?.total >= i+1 ? '#ff336b':'':'' : '',
            height: '154px',
            color:mode==='light'? highlightedItem !== i ? strikes && strikes?.total >= i+1 ? '#FFFFFF':'#ff336b':'#ff336b' : '#FFFFFF',
            position: 'relative',
            overflow: 'hidden'
          }}
          onClick={() => {
            console.log(strikes);
            
            if (strikes && strikes?.total >= i+1) {
              setHighlightedItem(i)
            };
            if (highlightedItem === i) {
              setHighlightedItem(null);
            };
          }}
        >


          {highlightedItem === i ? 
          <div className="p-12 d-flex align-items-center" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <span className="poppins-light text-center" style={{ fontSize: 15 }}>
                {strikes?.results[i]?.description.split(".").map(text=>
                <p className="mb-1 px-1">
                  <div key={text}>
                    {text.split(".")}
                    <br />
                  </div>
                </p>)}
            </span>
          </div> :
          <div className="p-5">
            <div className="text-start pt-2">
              <span className="fs-2 champ-light">
                #{i+1}
              </span>
          </div>
            <div className="text-end pb-2">
              <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/${mode==='light'?strikes && strikes?.total >= i+1 ? 'equis_white': 'equis_red' :'equis_white'}.svg`)} alt='' style={{width:'80px'}} />
            </div>
          </div>}


        </div> 
      ))}
      </>
      :
      <div
        className="mb-1"
        style={{
          border: mode === 'light' ? '1px solid #ff336b' : '1px solid #FFFFFF',
          height: '154px',
          color: mode === 'light' ? '#ff336b' : '#FFFFFF',
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <span className="poppins-light text-center" style={{fontSize:15}}>
          No tienes strikes
        </span>
      </div>

      }
    </div>
    {moreInfoActive &&
      <div className={`${moreInfoActive?'col-lg-5 col-md-5 col-sm-12':''} d-flex flex-column`} style={{color:mode==='light'?'#ff336b':'#FFFFFF'}}>
          <span className="mb-0 champ-light text-end" style={{fontSize:25}}>
            {t('QUESTION_ONE')} <span className="champ-bold">{'Strike'}</span>?
          </span>
          <span className="mb-2 poppins-light fs-6 text-end">
            {t('ANSWER_ONE', { days: 2 })} 
          </span>
          <span className="mb-0 text-end champ-light" style={{fontSize:25}}>
            {t('QUESTION_TWO')}
          </span>
          <span className="mb-2 poppins-light fs-6 text-end">
            {t('ANSWER_TWO', { days: 2})}
          </span>
      </div>
    }
  </div>
  <div className="text-end">
    <span className="fs-4 cursor-pointer poppins-light text-decoration-underline" 
          style={{color:mode==='light'?'#ff336b':'#FFFFFF'}}
          onClick={() => setMoreInfoActive(!moreInfoActive)}
    >
      {moreInfoActive ? t('LESS_INFO'): t('MORE_INFO')}
    </span>
  </div>
  <style>
  {`
    /* Tamaño de texto para dispositivos pequeños */
    .strike-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(calc(50% - 15px), 1fr));
        gap: 25px;
    }
    
    .strike-container > div {
        border-radius: 30px;
    }
    
    /* Para dispositivos medianos o más grandes */
    @media (min-width: 768px) {
        .strike-container {
            grid-template-columns: repeat(auto-fill, minmax(calc(25% - 15px), 1fr));
        }
        .strike-container > div {
            border-radius: 30px;
        }
    }
    
    /* Para dispositivos grandes o más grandes */
    @media (min-width: 992px) {
        .strike-container {
            grid-template-columns: repeat(auto-fill, minmax(calc(${moreInfoActive?'30%':'18%'} - 15px), 1fr));
        }
        .strike-container > div {
            border-radius: 50px;
        }
    }
    
    /* Para dispositivos extra grandes o más grandes */
    @media (min-width: 1200px) {
        .strike-container {
            grid-template-columns: repeat(auto-fill, minmax(calc(${moreInfoActive?'30%':'18%'} - 15px), 1fr));
        }
        .strike-container > div {
            border-radius: 50px;
        }
    }`}

  </style>
  </>)
};

export {StrikesList};