import React from 'react';
import { UseExerciseTimerModule } from "../store/main";
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';

type Props = {
  isChecked: boolean;
  onNext: () => any;
  onSubmit: () => void;
  exerciseId: string | undefined;
}

const ButtonSaveExercise: React.FC<Props> = ({ isChecked, onNext, onSubmit, exerciseId }) => {
  const { timeLeft } = UseExerciseTimerModule();
  const {isLoadingSubmit} = UseBookStudentModule.getState();
  const currentTimer = timeLeft.find(element => element.exerciseId === exerciseId);
  const exerciseBeforeTime = (currentTimer?.time ?? 0) > 0
  const handleClick = () => {
      isChecked ? onSubmit() : onNext();
  };

  return (
    <div className='mt-0' style={{ cursor: `${exerciseBeforeTime ? 'not-allowed' : 'pointer'}`, marginRight: `${exerciseBeforeTime ? '25px' : '0'}` }}>
      <button
        type='button'
        disabled={exerciseBeforeTime || isLoadingSubmit}
        title={exerciseBeforeTime ? "Vea todo el contenido" : "Save"}
        className="btn champ-bold py-1"
        style={{ fontSize: 14, backgroundColor: '#D0FF00', color: `${exerciseBeforeTime ? "#c1b7b7" : "#3f8bff"}`, cursor: `${exerciseBeforeTime ? 'not-allowed' : 'pointer'}`, borderRadius: '20px' }}
        onClick={handleClick}
      >
        <div className='d-flex align-items-center fs-5'>
          {isLoadingSubmit ? (
          <div className="text-center">
            <span className="spinner-grow spinner-grow-sm mt-1" role="status"/>
            <span className="spinner-grow spinner-grow-sm mt-1" role="status"/>
            <span className="spinner-grow spinner-grow-sm mt-1" role="status"/>
          </div>
          ) : currentTimer ? (
            currentTimer.time !== 0 ? (
              <span style={{ whiteSpace: 'nowrap' }}>
                {`Wait ${currentTimer.time}s`}
              </span>
            ) : (
              <span>
                {isChecked ? <>save</> : <>next</>}
              </span>
            )
          ) : (
            <span>
              {isChecked ? <>save</> : <>next</>}
            </span>
          )}
          <i className="bi bi-chevron-right" style={{ color: `${exerciseBeforeTime || isLoadingSubmit ? "#c1b7b7" : "#3f8bff"}`, marginLeft: '5px' }} />
        </div>
      </button>
    </div>
  );
};

export { ButtonSaveExercise };
