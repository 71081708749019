import {Route, Routes} from 'react-router-dom'
import { SurveySectionResponseDetails } from '../components/details'
import { SurveySectionResponseTableList } from '../components/list'


const SurveySectionResponsePage = () => (
  <Routes>
      <Route path='/details' element={<SurveySectionResponseDetails />} />
      <Route path='/' element={<SurveySectionResponseTableList />} />
  </Routes>
)

export {SurveySectionResponsePage}
