import React, {useEffect} from 'react'
import './index.css'
import {useThemeMode} from '../../../../../../_metronic/partials'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Answer, QuestionAudioTest, QuestionAndAnswer} from '../interfaces'
import useSound from 'use-sound'
import {UseEvaluationCourseModule} from '../../../screen/store/main'
import {VoiceRecorderInput} from '../../../../../components/common/voice-recorder'
import ContentAudioRecord from '../components/audio/index'
import {UseAudioTestModule} from '../store'
type Props = {
  question: QuestionAudioTest
  modeQuiz: 'RESUME' | 'ANSWER' | 'THROW_ERROR' | 'TITLE'
  handleSendAnswer: (x: Answer) => void
  totalQuestions: number
}

const AudioTestItemRight: React.FC<Props> = ({
  question,
  modeQuiz,
  handleSendAnswer,
  totalQuestions,
}: Props) => {
  const {mode} = useThemeMode()
  const {instructions} = UseAudioTestModule()
  const {evaluationLesson} = UseEvaluationCourseModule()
  const [urlAudio, setUrlAudio] = React.useState<string | undefined>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const handleSend = () => {
    if (urlAudio) {
      handleSendAnswer({
        questionPercentage: question.percentage,
        audio: urlAudio,
        question: question.order,
        name: question.title,
      })
      setUrlAudio(undefined)
    }
  }
  useEffect(() => {
    setUrlAudio(undefined)
  }, [question])

  const title = question.title.split('*')

  return (
    <>
      <div
        className=' card-basic-vocabulary-completion'
        style={
          mode !== 'dark'
            ? {
                height: 420,

                background: 'white',
                position: 'relative',
              }
            : {
                height: 420,

                background: '#1e1e2d',
                border: `1px solid`,
                borderColor: '#3e94ff',
                position: 'relative',
              }
        }
      >
        <h2
          className='champ-light title-basic-vocabulary-completion text-end'
          style={{
            color: evaluationLesson?.colorPrimary ?? '#3e94ff',
          }}
        >
          #{question.order}/{totalQuestions}
        </h2>

        <h3
          className='poppins-light fs-6 subtitle-basic-vocabulary-completion'
          style={{
            color: evaluationLesson?.colorPrimary ?? '#3e94ff',
          }}
        >
          <strong>Instructions: </strong>
          {instructions}
        </h3>
        <div>
          <h2
            className='champ-light fs-3 text mb-5D d-flex flex-column'
            style={{
              maxHeight: '75px',
              color: evaluationLesson?.colorPrimary ?? '#3e94ff',
              overflow: 'auto',
            }}
          >
            {title.map((t) => (
              <span className='mb-1'>{t}</span>
            ))}
          </h2>
        </div>
        {urlAudio && <ContentAudioRecord urlAudio={urlAudio} />}
        {!urlAudio && (
          <VoiceRecorderInput
            maxTime={question.time ?? 1}
            onChangeFile={(e) => setUrlAudio(e.url)}
            onChangeLoading={(e) => setIsLoading(e)}
            idInput='audioVerification'
          />
        )}

        {urlAudio && (
          <>
            <button
              type='button'
              className='arrow-left-basic-vocabulary-completion cursor-pointer border border-0 bg-transparent'
              style={{position: 'absolute'}}
              onClick={() => setUrlAudio(undefined)}
            >
              <span className='text-primary d-flex align-content-center fs-2'>
                <i className='bi bi-chevron-left text-primary me-2 fs-2 mt-1' />
                <p> Back</p>
              </span>
            </button>
          </>
        )}

        {modeQuiz === 'ANSWER' && urlAudio && (
          <>
            <button
              type='button'
              className='arrow-right-basic-vocabulary-completion cursor-pointer border border-0 bg-transparent'
              style={{position: 'absolute', paddingRight:'50px'}}
              onClick={() => handleSend()}
            >
            <span className='text-primary d-flex align-content-center fs-2'>
              <p> Next</p>
              <i className='bi bi-chevron-right text-primary me-2 fs-2 mt-1' />
            </span>
            </button>
          </>
        )}
      </div>
    </>
  )
}

export {AudioTestItemRight}
