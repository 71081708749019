import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Evaluation, EvaluationItem } from '../../../admin/evaluation/interfaces';
import { UseBasicVocabularyCompletionModule } from '../../exercises/basic-vocabulary-completion/store';
import { UseBasicVocabularyModule } from '../../exercises/basic-vocabulary/store';
import { ExercisesType } from '../../../admin/exercises/interfaces';
import { Lessons } from '../../../abroad/learning-path/modules/lessons/interfaces';
import { UseParagraphDragDropModule } from '../../exercises/paragraph-drag-drop/store';
import { UseTrueFalseModule } from '../../exercises/true-false/store';
import { UseHistoryWritingModule } from '../../exercises/history-wrinting/store';
import { UseModeCourseModule } from '../../components/front-page/store/main';
import { UseExerciseTimerModule } from '../../exercises/common/store/main';
import { IsExerciseTimeUp } from '../../exercises/utils';
import { UseAudioTestModule } from '../../exercises/audio-test/store';

interface lessonsIdType {
    id: string;
    order: number;
}

interface dragDropFeedback {
    sentences: string[]
    responses: string[]
    percentage: number
    isPercentageMin: boolean
}

export type FeedbackEvaluation = {
    percentage: number,
    exercise_title: string,
    responses:  FeedbackResponse[],
    isDragDropExercise? : dragDropFeedback
}

export interface FeedbackResponse {
    question: string,
    value: string,
    isCorrect: boolean,
    feedback: string
}

interface EvaluationCourseModules {
    evaluationLesson?: Evaluation;
    lessonCourse?: Lessons;
    currentTaskId?: string;
    currentLessonId?: string;
    lessonsId: lessonsIdType[] | null;
    questionsEvaluation:EvaluationItem | null;
    stepEvaluation:number;
    evaluationResponses: FeedbackEvaluation[];
    showEndEvaluation: boolean;
    isLoading : boolean;
    setEvaluationCourse:(value: Evaluation)=>void;
    setLessonCourse:(value: Lessons)=>void;
    resetEvaluationTest:()=>void;
    setStepEvaluation:(value:number)=>void;
    setCurrentTask:(value: string)=>void;
    setCurrentLessonId:(value: string)=>void;
    setLessonsId:(value: lessonsIdType[])=>void;
    setEvaluationResponses :(value: FeedbackEvaluation) => void;
    setShowEndEvaluation:(value: boolean) => void;
    resetEvaluationResponses:()=>void;
    setIsLoading:(value: boolean)=>void;
}

export const UseEvaluationCourseModule = create(persist<EvaluationCourseModules>((set, get) => ({
    showEndEvaluation: false,
    stepEvaluation: 0,
    questionsEvaluation: null,
    lessonsId: null,
    evaluationResponses: [],
    isLoading: false,
    setEvaluationCourse: (value) => {
        const evaluationLength = get().evaluationLesson?.evaluation_item?.length ?? 0
        let isFinishedEvaluation = (get().stepEvaluation+1) < evaluationLength
        let isToResetResponse = value?.id !== get().evaluationLesson?.id
        let currentQuestion: EvaluationItem | null = null
        if(isToResetResponse){
            isFinishedEvaluation = false
        }
        
        if (value && value?.evaluation_item) {
            let current: EvaluationItem | undefined = value.evaluation_item.sort((a, b) => a.order - b.order)[get().stepEvaluation];
            if (!current) {
                set({ stepEvaluation: 0 });
                current = value.evaluation_item.sort((a, b) => a.order - b.order)[0];
            }
            currentQuestion = {
                ...current,
                exercise: {
                    ...current.exercise,
                    questions: current.exercise?.questions?.sort((a, b) => a.order - b.order).map((x) => ({
                        ...x,
                        answers: x?.answer?.map((q) => JSON.parse(q)),
                    })),
                    questions_completion: current.exercise?.questions_completion?.sort((a, b) => a.order - b.order).map((x) => ({
                        ...x,
                        answers: x?.answer?.map((q) => JSON.parse(q)),
                    })),
                    drag_drop_questions: current.exercise?.drag_drop_questions?.sort((a, b) => a.order - b.order).map((x) => ({
                        ...x,
                        words: x?.words?.map((q) => JSON.parse(q)),
                    })),
                    true_or_false: current.exercise?.true_or_false?.sort((a, b) => a.order - b.order).map((x) => ({
                        ...x,
                        answer: x?.answer?.map((q) => JSON.parse(q)),
                    })),
                    history_writing: current.exercise?.history_writing?.sort((a, b) => a.order - b.order).map((x) => ({
                        ...x,
                        questions: x?.questions?.map((q) => JSON.parse(q)),
                    })),
                    audio_test: current.exercise?.audio_test?.sort((a, b) => a.order - b.order).map((x) => ({
                        ...x,
                        answers: x?.answers?.map((q) => JSON.parse(q)),
                    }))
                    // movie_wring: current.exercise?.movie_wring?.sort((a, b) => a.order - b.order).map((x) => ({
                    //     ...x
                    // }))
                }
            }
        }
        if (currentQuestion) {
            SetQuestionsInQuiz(currentQuestion);
        }
        set({
            evaluationLesson: value,
            questionsEvaluation: currentQuestion,
            showEndEvaluation: isFinishedEvaluation,
            evaluationResponses: isToResetResponse? [] : get().evaluationResponses
        })
    },
    setLessonCourse: (value) => {
        set({ lessonCourse: value })
    },
    resetEvaluationTest: () => {
        set({ evaluationLesson: undefined, stepEvaluation: 0, questionsEvaluation: null })
    },
    setStepEvaluation: (value) => {
        set((state) => {
            let currentQuestion: EvaluationItem | null = null
            let isFinishedEvaluation = (value+1) >( get().evaluationLesson?.evaluation_item?.length ??0)
            if (state.evaluationLesson && state.evaluationLesson?.evaluation_item) {
                let current = state.evaluationLesson.evaluation_item.sort((a, b) => a.order - b.order)[value]
                currentQuestion = {
                    ...current,
                    exercise: {
                        ...current.exercise,
                        questions: current.exercise?.questions?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            answers: x?.answer?.map((q) => JSON.parse(q)),
                        })),
                        questions_completion: current.exercise?.questions_completion?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            answers: x?.answer?.map((q) => JSON.parse(q)),
                        })),
                        drag_drop_questions: current.exercise?.drag_drop_questions?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            words: x?.words?.map((q) => JSON.parse(q)),
                        })),
                        true_or_false: current.exercise?.true_or_false?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            answer: x?.answer?.map((q) => JSON.parse(q)),
                        })),
                        history_writing: current.exercise?.history_writing?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            questions: x?.questions?.map((q) => JSON.parse(q)),
                        })),
                        audio_test: current.exercise?.audio_test?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            answers: x?.answers?.map((q) => JSON.parse(q)),
                        }))
                        // movie_wring: current.exercise?.movie_wring?.sort((a, b) => a.order - b.order).map((x) => ({
                        //     ...x
                        // }))
                    }
                }
            }
            if (currentQuestion) {
                SetQuestionsInQuiz(currentQuestion);
            };
            return {
                stepEvaluation: value,
                questionsEvaluation: currentQuestion,
                showEndEvaluation: isFinishedEvaluation
            }
        })
    },
    setCurrentTask: (value) => {
        set({ currentTaskId: value });
    },
    setCurrentLessonId: (value) => {
        set({ currentLessonId: value });
    },
    setLessonsId: (value) => {
        set({ lessonsId: value });
    },
    setShowEndEvaluation: (value) => {
        // if (value === false) {
        //     set({ evaluationResponses: [] })
        // }
        set({ showEndEvaluation: value })
    },
    setEvaluationResponses: (newResponse) => {
        set((state) => ({
            evaluationResponses: [...state.evaluationResponses, newResponse]
        }));
    },
    resetEvaluationResponses: ()=> {
        set({evaluationResponses: []})
    },
    setIsLoading:(value)=>{
        set({isLoading: value})
    }
}),
    {
        name: 'content-course'
    }
));

export const SetQuestionsInQuiz = (currentQuestion: EvaluationItem) => {
    const { currentFile, setCurrentFile } = UseModeCourseModule.getState(); //setTimer
    const { setExerciseBeforeTime, setTimeLeft } = UseExerciseTimerModule.getState()
    const { setQuestionBasicCompletion } = UseBasicVocabularyCompletionModule.getState();
    const { setQuestionsBasic } = UseBasicVocabularyModule.getState();
    const { setQuestionsParagraphDrag } = UseParagraphDragDropModule.getState();
    const { setQuestionsTrueFalse } = UseTrueFalseModule.getState();
    const { setQuestionsHistoryFull } = UseHistoryWritingModule.getState();
    const { setQuestionAudioTest } = UseAudioTestModule.getState();
    const type: ExercisesType = currentQuestion?.exercise.type as ExercisesType;
    if (currentFile?.id !== currentQuestion?.exercise?.fileContent?.id) {
        setCurrentFile(currentQuestion?.exercise?.fileContent);
    }

    //poner a correr el timer desde que entra al ejercicio, depende de cada componente hacer que 
    if (currentQuestion.id) {
        IsExerciseTimeUp(
            currentQuestion.id,
            currentQuestion.timer,
            (e) => {
                setExerciseBeforeTime(e)
            },
            (e) => {
                setTimeLeft(e)
            }
        )
    }
    switch (type) {
        case ExercisesType.DRAG_DROP:
            setQuestionsParagraphDrag(
                currentQuestion.exercise.id,
                currentQuestion.exercise.drag_drop_questions[0].words,
                currentQuestion.exercise.drag_drop_questions[0].sentences,
                currentQuestion.exercise.name,
                currentQuestion.exercise.description
            );
            break;
        case ExercisesType.LISTENING:
            break;
        case ExercisesType.MULTIPLE_SELECTION:
            break;
        case ExercisesType.SIMPLE_SELECTION:
            setQuestionsBasic(currentQuestion.exercise.questions);
            break;
        case ExercisesType.SIMPLE_SELECTION_COMPLETION:
            setQuestionBasicCompletion(currentQuestion.exercise.questions_completion);
            break;
        case ExercisesType.SPEAKING:
            break;
        case ExercisesType.HISTORY_WRITING:
            setQuestionsHistoryFull(
                currentQuestion.exercise.id,
                currentQuestion.exercise.description,
                currentQuestion.exercise.history_writing);
            break;
        case ExercisesType.TRUE_FALSE:
            setQuestionsTrueFalse(
                currentQuestion.exercise.id,
                currentQuestion.exercise.description,
                currentQuestion.exercise.true_or_false[0].answer);
            break;
        case ExercisesType.AUDIO_TEST:
            setQuestionAudioTest(
                currentQuestion.exercise.audio_test[0].answers,
                currentQuestion.exercise.name,
                currentQuestion.exercise.description,
            );
            break;
    }
}