import React, { useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, useFormik } from 'formik'
import { SurveySectionResponse, TypeSurveySectionAnswer } from '../../interfaces'

import { SurveySectionResponseAdd, SurveySectionResponseUpdate } from '../../services'
import { UseSurveySectionResponseModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { UseMyRoadmapModule } from '../../../../../../profile/components/my-road-step/store'
import { QuestionTypeSurveySection, SurveySection } from '../../../../interfaces'
import { GetAllSurveySection } from '../../../../services'
import { showSimpleAlert } from '../../../../../../../commonHooks/alert'
import { useThemeMode } from '../../../../../../../../_metronic/partials'
import { UpdateStarsValueStudent } from '../../../../../../abroad/trail/services'
import { useNavigate } from 'react-router-dom'
import { UseMeReportModule } from '../../../../../../me-report/store/main'
import moment from 'moment'

export type SurveySectionResponseFormFields = {
  isActive: boolean
  appointment: string;
  answers: TypeSurveySectionAnswer[];
  stars: number;
}

const entitySchema = Yup.object().shape({
  appointment: Yup.string().min(1).required('Field is required'),
})

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
}
const SurveySectionResponseForm: React.FC<Props> = (props: Props) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate();
  const { mode: theme } = useThemeMode()
  const { settings } = UseMyRoadmapModule();
  const { setBefore } = UseMeReportModule();
  const { trailClassId, itemSelected, mode, resetData, appointmentId } = UseSurveySectionResponseModule();
  const [answerSurveySection, setAnswerSurveySection] = useState<SurveySection[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false)
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<SurveySectionResponseFormFields>({
      initialValues: {
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        appointment: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.appointment.id ?? appointmentId : appointmentId,
        answers: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.answers.map(x => JSON.parse(x)) ?? [] : [],
        stars: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.stars ?? 4 : 4
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (props.mode === 'ADD') {
          try {
            setLoading(true);
            await SurveySectionResponseAdd({
              ...formData,
              student_setting: settings?.id ?? ''
            })
            if (trailClassId) {
              await UpdateStarsValueStudent(trailClassId, formData.stars);
            }
            setBefore(moment().startOf('month').toDate());
            resetData();
            setLoading(false);
            queryClient.invalidateQueries({ queryKey: ['survey-section-response'] });

          } catch (e) {
            setLoading(false);
          }

        }
        else {
          try {
            setLoading(true);
            await SurveySectionResponseUpdate({
              ...formData,
              student_setting: settings?.id ?? ''
            })
            if (trailClassId) {
              await UpdateStarsValueStudent(trailClassId, formData.stars);
            }
            setBefore(moment().startOf('month').toDate());
            resetData();
            setLoading(false);
            queryClient.invalidateQueries({ queryKey: ['survey-section-response'] });

          } catch (e) {
            setLoading(false);
          }
        }
      },
    });

  const fetchSurveySection = React.useCallback(async () => {
    const response = await GetAllSurveySection();
    if (!response) return;
    setAnswerSurveySection(response);
    setFieldValue('answers', response.map((x) => ({
      question: x.question,
      order: x.order,
      answer: x.type === QuestionTypeSurveySection.TRUE_FALSE ? 'Si' : ''
    })));
  }, []);

  React.useEffect(() => {
    if (mode === 'ADD') {
      fetchSurveySection();
      return;
    }
  }, [mode]);

  const handleChangeAnswer = (value: string, orderQuestion: number) => {
    const data = values.answers.find((x) => x.order === orderQuestion);
    if (!data) return showSimpleAlert({ message: 'Pregunta no encontrada', icon: 'error' });

    const updateValues = values.answers.map((x) => {
      return data.order === x.order ? { ...x, answer: value } : x
    });

    setFieldValue('answers', updateValues);
  };

  const handleRatingChange = (value: number) => {
    setFieldValue('stars', values.stars === value ? 0 : value);
  }

  const getAnswerStudent = (orderQuestion: number) => {
    const response = values.answers.find((x) => x.order === orderQuestion);
    if (!response) return '';

    return response.answer;
  };

  const renderQuestion = (index: number, question: string, order: number, type: QuestionTypeSurveySection) => {
    const value = getAnswerStudent(order);
    return (
      <div className='mb-10 col-12' key={index}>
        <label className='form-label mb-3 poppins-bold'>{question}</label>
        {type === QuestionTypeSurveySection.TEXT ?
          <textarea
            className='form-control form-control-lg form-control-solid'
            placeholder=''
            disabled={mode !== 'ADD'}
            name={`${order}`}
            value={value}
            onChange={(e) => handleChangeAnswer(e.target.value, order)}
          />
          : <div className='d-flex'>
            {['Si', 'No'].map((option) => (
              <button
                type='button'
                disabled={mode !== 'ADD'}
                onClick={() => handleChangeAnswer(option, order)}
                className={`${value === option ? 'bg-primary text-white' : 'bg-secondary text-dark'} btn ms-2 bg-white cursor-pointer p-2 py-1 champ-light d-flex justify-content-center align-items-center`}
                style={{
                  borderRadius: '20px',
                  color: '#2d30e1',
                }}
              >
                {value === option ? (
                  <i className="fa-solid fa-check text-white me-2"></i>
                ) : (
                  <i className="bi bi-dash-square-fill me-2"></i>
                )}
                <span className='pt-1 me-4'>{option}</span>
              </button>
            ))}
          </div>}
      </div>
    )
  };

  return (
    <div id='kt_account_profile_details' className='collapse show poppins-light'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div> */}

          {mode==='ADD'? answerSurveySection && answerSurveySection.map((x, i) => {
            return renderQuestion(i, x.question, x.order, x.type);
          }) : values.answers.map((x, i) => {
            const type = (x.answer !== 'Si' && x.answer !== 'No' && x.answer !== '') ? 
              QuestionTypeSurveySection.TEXT : QuestionTypeSurveySection.TRUE_FALSE
            return renderQuestion(i, x.question, x.order, type);
          })}

          <div className='col-12 d-flex'>
            <label className='form-label mb-3 poppins-bold me-2'>Califica tu clase: </label>
            {[1, 2, 3, 4, 5].map((value) => (
              <label key={value} className={`me-1 cursor-pointer`}>
                <input
                  type='radio'
                  name='star-rating'
                  value={value}
                  checked={values.stars === value}
                  onChange={() => handleRatingChange(value)}
                  style={{ display: 'none' }} // Ocultar el radio button
                  disabled={mode !== 'ADD'}
                />
                <i
                  className={`fa-${values.stars >= value ? 'solid' : 'regular'
                    } fa-star d-inline-block`}
                  style={{ fontSize: 15, color: theme === 'light' ? '#2d30e1' : 'white' }}
                ></i>
              </label>
            ))}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || mode !== 'ADD'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { SurveySectionResponseForm }
