import React from 'react';
import { BookPage } from '../../../admin/book/modules/book-page/interfaces';
import useScrollAndZoom from '../../../../hooks/useScrollAndZoom';
import useImageLoader from '../../../../hooks/useImageLoader';
import { UseBookContainerModule } from '../../store/main';

type Props = {
  pagesBook: BookPage[];
};

const MultiplePages: React.FC<Props> = ({ pagesBook }) => {
  const containerLeftRef = React.useRef<HTMLDivElement>(null);
  const containerRightRef = React.useRef<HTMLDivElement>(null);
  const activeContainerRef = React.useRef<HTMLDivElement | null>(null);

  const leftImages = pagesBook.map((page) => page.file.location);
  const { loaded, images } = useImageLoader(leftImages);
  const { toolsFullscreen } = UseBookContainerModule();

  const {
    handleScroll: handleLeftScroll,
    handleMouseDown: handleLeftMouseDown,
    handleMouseMove: handleLeftMouseMove,
    handleMouseUp: handleLeftMouseUp,
    handleMouseEnter: handleLeftMouseEnter,
    handleMouseLeave: handleLeftMouseLeave,
    handleTouchStart: handleLeftTouchStart,
    handleTouchMove: handleLeftTouchMove,
    handleTouchEnd: handleLeftTouchEnd,
    zoom: zoomLeft,
    isDragging: isDraggingLeft,
  } = useScrollAndZoom(containerLeftRef, activeContainerRef);

  const {
    handleScroll: handleRightScroll,
    handleMouseDown: handleRightMouseDown,
    handleMouseMove: handleRightMouseMove,
    handleMouseUp: handleRightMouseUp,
    handleMouseEnter: handleRightMouseEnter,
    handleMouseLeave: handleRightMouseLeave,
    handleTouchStart: handleRightTouchStart,
    handleTouchMove: handleRightTouchMove,
    handleTouchEnd: handleRightTouchEnd,
    zoom: zoomRight,
    isDragging: isDraggingRight,
  } = useScrollAndZoom(containerRightRef, activeContainerRef);

  if (!loaded) {
    return (
      <div className="d-none d-lg-flex justify-content-center wrap" id="book-container" style={loadingContainerStyle}>
        <span className="indicator-progress">
          <span className="spinner-border spinner-border-lg align-middle" />
        </span>
      </div>
    );
  }

  const containerStyle = toolsFullscreen
    ? fullScreenContainerStyle
    : defaultContainerStyle;

  return (
    <div className="d-none d-lg-flex justify-content-center wrap" id="book-container" style={containerStyle}>
      <div
        id= "left container page"
        ref={containerLeftRef}
        className="page-container d-flex justify-content-end"
        style={getPageContainerStyle('left')}
        onWheel={(e) => handleLeftScroll(e as unknown as WheelEvent)}
        onMouseDown={handleLeftMouseDown}
        onMouseMove={handleLeftMouseMove}
        onMouseUp={handleLeftMouseUp}
        onMouseEnter={handleLeftMouseEnter}
        onMouseLeave={handleLeftMouseLeave}
        onTouchStart={handleLeftTouchStart}
        onTouchMove={handleLeftTouchMove}
        onTouchEnd={handleLeftTouchEnd}
      >
        {images.length > 0 && (
          <img
            loading="lazy"
            key={images[0].src}
            src={images[0].src}
            alt=""
            draggable
            onDragStart={(e) => e.preventDefault()}
            style={getImageStyle(zoomLeft, isDraggingLeft)}
          />
        )}
      </div>

      <div
        id= "right container page"
        ref={containerRightRef}
        className="page-container d-flex justify-content-start"
        style={getPageContainerStyle('right')}
        onWheel={(e) => handleRightScroll(e as unknown as WheelEvent)}
        onMouseDown={handleRightMouseDown}
        onMouseMove={handleRightMouseMove}
        onMouseUp={handleRightMouseUp}
        onMouseEnter={handleRightMouseEnter}
        onMouseLeave={handleRightMouseLeave}
        onTouchStart={handleRightTouchStart}
        onTouchMove={handleRightTouchMove}
        onTouchEnd={handleRightTouchEnd}
      >
        {images.length > 1 && (
          <img
            loading="lazy"
            key={images[1].src}
            src={images[1].src}
            alt=""
            draggable
            onDragStart={(e) => e.preventDefault()}
            style={getImageStyle(zoomRight, isDraggingRight)}
          />
        )}
      </div>
    </div>
  );
};

export default MultiplePages;

const loadingContainerStyle: React.CSSProperties = {
  position: 'fixed',
  top: 120,
  left: 160,
  right: 296,
  bottom: 20,
  backgroundColor: '#ffffff',
  borderRadius: '50px',
  display: 'flex',
};

const fullScreenContainerStyle: React.CSSProperties = {
  position: 'fixed',
  top: 65,
  left: 0,
  right: 135,
  bottom: 0,
  backgroundColor: '#FFFFFF',
  overflow: 'hidden',
  maxWidth: '100%',
  maxHeight: '100%',
};

const defaultContainerStyle: React.CSSProperties = {
  position: 'fixed',
  top: 120,
  left: 160,
  right: 296,
  bottom: 20,
  backgroundColor: '#ffffff',
  borderRadius: '50px',
  display: 'flex',
};

const getPageContainerStyle = (side: 'left' | 'right'): React.CSSProperties => ({
  flex: '1 1 50%',
  maxWidth: '50%',
  maxHeight: '100%',
  overflow: 'auto',
  position: 'relative',
  backgroundColor: '#ffffff',
  borderTopRightRadius: side === 'left' ? 0 : '50px',
  borderBottomRightRadius: side === 'left' ? 0 : '50px',
  borderTopLeftRadius: side === 'right' ? 0 : '50px',
  borderBottomLeftRadius: side === 'right' ? 0 : '50px',
});

const getImageStyle = (zoom: number, isDragging: boolean): React.CSSProperties => ({
  width: 'auto',
  height: '100%',
  transform: `scale(${zoom / 100})`,
  transformOrigin: 'top left',
  cursor: isDragging ? 'grabbing' : 'grab',
});
