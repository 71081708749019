import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { FindOneEvaluation } from '../../services';
import { UseEvaluationCourseModule } from '../../../../courses/screen/store/main';
import { UseModeCourseModule } from '../../../../courses/components/front-page/store/main';
import ContainerFather from '../../../../courses/components/container';
import useAuth from '../../../../../api/main';
import { FindOneEvaluationByTask } from '../../../../courses/screen/services';
import { UseMyRoadmapModule } from '../../../../profile/components/my-road-step/store';
import { SettingPlacementTestStatus } from '../../../../abroad/road-map-step/interfaces';

type Props = {};

const ScreenEvaluationCourse: React.FC<Props> = () => {
    const { id } = useParams();
    const {
        lessonCourse,
        evaluationLesson,
        currentTaskId,
        currentLessonId,
        setEvaluationCourse,
        setLessonCourse,
        setCurrentTask,
    } = UseEvaluationCourseModule();
    const { modeCourseChecked } = UseModeCourseModule();
    const { typeUser } = useAuth();
    const { settings } = UseMyRoadmapModule()
    const navigate = useNavigate();
    const findEvaluation = async () => {
        try {
            if (id) {
                if ((typeUser === 'STUDENT' || typeUser === 'COURTESY')) {
                    // if (!currentLessonId) return navigate('/');
                    if (!settings) return navigate('/');

                    const task = await FindOneEvaluationByTask(id, settings.id, 
                        (settings.welcomeDay == 'PENDING' || 
                            settings.welcomeDay == 'IN_PROCESS' ||
                            settings.placementTest == 'PENDING' || 
                            settings.placementTest == 'IN_PROCESS'
                        ) ? 'evaluation' : 'lesson');
                    if (!task) return navigate('/');

                    if (task.lesson) {
                        setLessonCourse(task.lesson);
                        //   setTask(task);
                        setCurrentTask(task.id);
                        setEvaluationCourse(task.lesson?.evaluation)
                    } else if (task.placement_test) {
                        setCurrentTask(task.id);
                        setEvaluationCourse(task.placement_test?.evaluation)
                    } else {
                        return navigate('/')
                    }
                } else {
                    const response = await FindOneEvaluation(id);
                    if (response.lesson.length > 0) {
                        setLessonCourse(response?.lesson[0])
                    }
                    delete response.lesson;
                    setEvaluationCourse(response)
                }
            }
        } catch (e) {
            showSimpleAlert({ message: 'Error al encontrar Evaluation', icon: 'error' });
            console.error(e);
        }
    };

    React.useEffect(() => {
        findEvaluation();
        modeCourseChecked();
        // resetEvaluationTest();
    }, [id]);

    return (<>
        {evaluationLesson &&
            <ContainerFather
                colorPrimary={evaluationLesson.colorPrimary}
                colorSecondary={evaluationLesson.colorSecondary}
                logoCourse={lessonCourse?.chapter?.path?.logo?.location ?? ''}
                nameTopic={evaluationLesson.name ?? 'Michigan Master'}
                typeEvaluation={evaluationLesson.type}
                typeCourse={lessonCourse?.chapter.path.type}
            />
        }
    </>)
};

export { ScreenEvaluationCourse };