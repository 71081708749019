import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useFormik} from 'formik'
import {SequenceType, TraditionalLevelEnum} from '../../../../abroad/road-map-step/interfaces'
import {useCallback, useEffect, useState} from 'react'
import {UseMyRoadmapModule} from '../../../../profile/components/my-road-step/store'
import * as Yup from 'yup'
import {RoadMapStep} from '../../../../admin/student-actions/promotion-history/components/forms'
import {showSimpleAlert} from '../../../../../commonHooks/alert'
import {RoadMapStepByRoadMap} from '../../../../abroad/road-map-step/services'
import {UseAllLessonByChapter} from '../../../../abroad/learning-path/modules/lessons/services'
import {Lessons} from '../../../../abroad/learning-path/modules/lessons/interfaces'
import {useQueryClient} from 'react-query'
import {UpgradeStudentManual} from '../../../../admin/user/services/student/UpgradeStudentService'
import moment from 'moment'
import {PromotionHistoryType} from '../../../../admin/student-actions/promotion-history/interfaces'
import {UseManualUpgradeStudent} from '../../../../admin/student-actions/promotion-history/services'
import Swal from 'sweetalert2'
import {useTranslation} from 'react-i18next'

export type FormFields = {
  level: TraditionalLevelEnum
  newCurrentStep: string
}

const entitySchema = Yup.object().shape({
  newCurrentStep: Yup.string().required('Debes elegir una lección'),
})

type Props = {
  show: boolean
  setShow: (v: boolean) => void
  reload: boolean
  setReload: (v: boolean) => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const UpgradeMySelfModal = ({show, setShow}: Props) => {
  const {settingChecked} = UseMyRoadmapModule()
  const [loading, setLoading] = useState<boolean>(false)
  const [roadMapStep, setRoadMapStep] = useState<RoadMapStep[]>([])
  const [selectedChapter, setSelectedChapter] = useState<RoadMapStep>()
  const [selectedLessons, setSelectedLessons] = useState<string[]>([])
  const [lessons, setLessons] = useState<Lessons[]>()
  const [groupedLessons, setGroupedLessons] = useState<Record<string, Lessons[]>>({})
  const [selectedUnit, setSelectedUnit] = useState<string | null>(null)
  const {settings} = UseMyRoadmapModule()
  // const queryClient = useQueryClient()

  const determineUpgradeType = (
    currentLevel: TraditionalLevelEnum,
    level: TraditionalLevelEnum
  ): PromotionHistoryType => {
    // Convertimos los valores del enum a un array ordenado
    const levels = Object.values(TraditionalLevelEnum)
    // Obtenemos los índices del currentLevel y del level en el array
    const currentIndex = levels.indexOf(currentLevel)
    const newLevelIndex = levels.indexOf(level)
    // Comparamos los índices para determinar si es una actualización o una degradación
    if (newLevelIndex > currentIndex) {
      return PromotionHistoryType.UPGRADE
    } else {
      return PromotionHistoryType.DOWNGRADE
    }
  }
  const {t} = useTranslation()
  const {values, setFieldValue, handleSubmit, validateField} =
    useFormik<FormFields>({
      initialValues: {
        level: settings ? (settings.level as TraditionalLevelEnum) : TraditionalLevelEnum.PRE_A1_1,
        newCurrentStep: '',
      },
      onSubmit: async (formData) => {
        Swal.fire({
          title: `<strong class=''>¿Esta seguro de cambiar su nivel actual?</strong>`,
          html: `
            <div>
              <span>
                ${
                  lessons?.find(
                    (lesson) => lesson.id === selectedLessons[selectedLessons.length - 1]
                  )
                    ? `<p>Quedarás en el nivel ${t(formData.level)} - ${
                        lessons.find(
                          (lesson) => lesson.id === selectedLessons[selectedLessons.length - 1]
                        )?.name
                      }</p>`
                    : `<p>Quedarás al comienzo del nivel ${t(formData.level)}</p>`
                }
              </span><br/>
              <span class='fs-3 text-secondary-emphasis warning-text-emphasis'> Solo puede usar estos cambios 3 veces.</span><br/><br/>
              <p class='fs-5 text-decoration-underline text-danger'>Perderá su progreso en el nivel actual<p>
            <div/>
          `,
          showCancelButton: true,
          confirmButtonText:
            "<span class='poppins-light fs-4'>Estoy seguro, quiero cambiar mi nivel</span>",
          cancelButtonText: "<span class='poppins-light fs-4'>No estoy seguro</span>",
          width: 600,
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-success',
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            await submitPromotion(formData)
          } else {
            showSimpleAlert({message: 'ajuste cancelado', icon: 'info'})
            setSelectedUnit(null)
            setSelectedChapter(undefined)
            setSelectedLessons([])
            setShow(false)
          }
        })
      },
    })

  const submitPromotion = async (formData: FormFields) => {
    if (settings) {
      try {
        setLoading(true)
        await UpgradeStudentManual({
          studentSetting: settings?.id,
          newCurrentStep: formData.newCurrentStep,
          promotionType: determineUpgradeType(
            settings.level as TraditionalLevelEnum,
            formData.level
          ),
          date: moment().format('YYYY-MM-DD'),
          hasLessons: selectedLessons.length > 0,
          description: `${
            settings.level == formData.level
              ? 'AJUSTE'
              : determineUpgradeType(settings.level as TraditionalLevelEnum, formData.level)
          } desde ${settings.level} hasta ${formData.level} por el mismo estudiante`,
          additionalHours: 0,
        })
        // queryClient.invalidateQueries({ queryKey: ['user', 'promotion-history'] })
        if (selectedLessons?.length > 0) {
          await UseManualUpgradeStudent({
            studentSetting: settings?.id,
            newCurrentStep: formData.newCurrentStep,
            approvedLessons: selectedLessons,
            date: moment().format('YYYY-MM-DD'),
            additionalHours: 0,
          })
          await settingChecked()
          // queryClient.invalidateQueries({queryKey: ['promotion-history']})
        }
      } finally {
        setLoading(false)
        setShow(false)
      }
    }
  }

  const handleSetChapter = (stepId: string) => {
    const step = roadMapStep.find((x) => x.id === stepId)
    setSelectedLessons([])
    setSelectedUnit(null)
    if (step) {
      setSelectedChapter(step)
      setTimeout(() => {
        setFieldValue('level', step.level)
        setFieldValue('newCurrentStep', step.id)
        validateField('level')
      }, 0)
      getLessonsData(step)
      return
    }
    setFieldValue('level', TraditionalLevelEnum.PRE_A1_1)
    return
  }
  const handleUnitChange = (unit: string) => {
    setSelectedUnit(unit)

    if (lessons) {
      const newSelectedLessons: string[] = []
      let stopAdding = false

      Object.keys(groupedLessons)
        .sort((a, b) => {
          const unitA = parseInt(a.match(/\d+/)?.[0] || '0', 10)
          const unitB = parseInt(b.match(/\d+/)?.[0] || '0', 10)
          return unitA - unitB
        })
        .forEach((currentUnit) => {
          if (currentUnit === unit) {
            stopAdding = true
          }

          if (!stopAdding) {
            groupedLessons[currentUnit]
              .sort((a, b) => a.order - b.order)
              .forEach((lesson) => {
                newSelectedLessons.push(lesson.id)
              })
          }
        })

      setSelectedLessons(newSelectedLessons)
    }
  }

  const handleLessonChange = useCallback(
    (lessonId: string, isStandardSequence: boolean) => {
      if (lessons) {
        setSelectedLessons((prevSelected) => {
          let newSelected: string[] = []

          if (isStandardSequence) {
            let lessonFound = false

            Object.keys(groupedLessons)
              .sort((a, b) => {
                const unitA = parseInt(a.match(/\d+/)?.[0] || '0', 10)
                const unitB = parseInt(b.match(/\d+/)?.[0] || '0', 10)
                return unitA - unitB
              })
              .forEach((unit) => {
                if (!lessonFound) {
                  groupedLessons[unit]
                    .sort((a, b) => a.order - b.order)
                    .forEach((lesson) => {
                      if (lesson.id === lessonId) {
                        lessonFound = true
                        const selectedIndex = prevSelected.findIndex((id) => id === lessonId)
                        if (selectedIndex !== -1) {
                          // Remove the selected lesson and all subsequent lessons
                          newSelected = prevSelected.slice(0, selectedIndex)
                        } else {
                          newSelected.push(lesson.id)
                        }
                      } else if (!lessonFound) {
                        newSelected.push(lesson.id)
                      }
                    })
                }
              })
          } else {
            newSelected = prevSelected.includes(lessonId)
              ? prevSelected.filter((id) => id !== lessonId)
              : [...prevSelected, lessonId]
          }
          return newSelected
        })
      }
    },
    [lessons, groupedLessons]
  )

  useEffect(() => {
    console.log(selectedLessons)
  }, [selectedLessons])

  useEffect(() => {
    if (settings?.roadMap.id) {
      const fetchRoadMapStep = async () => {
        try {
          let response = await RoadMapStepByRoadMap(settings?.roadMap.id)
          setRoadMapStep(response)
        } catch (e) {
          showSimpleAlert({message: 'Error al encontrar Road Map Step!', icon: 'error'})
          throw 'Error'
        }
      }
      fetchRoadMapStep()
    }
  }, [settings])

  const getLessonsData = async (step: RoadMapStep) => {
    if (step && step.id) {
      try {
        const lessons = await UseAllLessonByChapter(step.chapter.id)
        setLessons(lessons)
        if (step.sequence === SequenceType.STANDARD) {
          // Agrupamos las lecciones por unidad usando el campo `name`
          const groupedLessons = lessons.reduce((acc: Record<string, Lessons[]>, lesson) => {
            // Extraemos la unidad y el número de la lección del nombre
            const match = lesson.name.trim().match(/^Unit (\d+) - Lesson (\d+)/)
            if (match) {
              const unit = `Unit ${match[1]}` // Ej. "Unit 1"
              if (!acc[unit]) {
                acc[unit] = []
              }
              acc[unit].push(lesson)
            }
            return acc
          }, {})
          setGroupedLessons(groupedLessons)
        }
      } catch (e) {
        showSimpleAlert({message: `${e}`, icon: 'error'})
      }
    }
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered modal-lg modal-fullscreen-md-down'
      show={show}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Cambiar mi nivel </h2>
        <div
          className='btn btn-sm btn-icon btn-active-color-primary'
          onClick={() => setShow(!show)}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={handleSubmit} noValidate className='form'>
          <div className='card-body border-top px-9 row'>
            <h1 className='form-label fs-3'>Elije el nuevo nivel.</h1>
            <div className='d-flex flex-wrap justify-content-between'>
              {roadMapStep &&
                roadMapStep
                  .sort((a, b) => a.order - b.order)
                  .map((level, i) => {
                    return (
                      <a
                        href='#'
                        key={i}
                        className={`col-12 mb-3 ${
                          selectedChapter?.id === level.id
                            ? 'bg-primary text-white'
                            : 'bg-secondary text-dark'
                        }`}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '32%',
                          justifyContent: 'flex-start',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                        onClick={() => {
                          handleSetChapter(level.id ?? '')
                        }}
                      >
                        {selectedChapter?.id === level.id ? (
                          <i className='fa-solid fa-check text-white me-2' />
                        ) : (
                          <i className='bi bi-dash-square-fill me-2' />
                        )}
                        {level.name}
                      </a>
                    )
                  })}
            </div>
            {selectedChapter?.sequence === SequenceType.STANDARD ? (
              <>
                <h2 className='form-label fs-3'>Seleccione la unidad</h2>
                <div className='d-flex flex-wrap justify-content-between'>
                  {Object.keys(groupedLessons)
                    .sort((a, b) => {
                      const unitA = parseInt(a.match(/\d+/)?.[0] || '0', 10)
                      const unitB = parseInt(b.match(/\d+/)?.[0] || '0', 10)
                      return unitA - unitB
                    })
                    .map((unit) => (
                      <a
                        href='#'
                        key={unit}
                        className={`col-12 mb-3 ${
                          selectedUnit === unit ? 'bg-primary text-white' : 'bg-secondary text-dark'
                        }`}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '32%',
                          justifyContent: 'flex-start',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                        onClick={() => {
                          setSelectedLessons([])
                          handleUnitChange(unit)
                        }}
                      >
                        {selectedUnit === unit ? (
                          <i className='fa-solid fa-check text-white me-2'></i>
                        ) : (
                          <i className='bi bi-dash-square-fill me-2'></i>
                        )}
                        {unit}
                      </a>
                    ))}
                </div>
                {selectedUnit && (
                  <>
                    <h2 className='form-label fs-3'>Seleccione las lecciones que ya aprobó</h2>
                    <div className='d-flex flex-wrap justify-content-between'>
                      {groupedLessons[selectedUnit]
                        .sort((a, b) => a.order - b.order)
                        .map((lesson, i) => (
                          <a
                            href='#'
                            key={i}
                            className={`col-12 mb-3 ${
                              selectedLessons.includes(lesson.id)
                                ? 'bg-primary text-white'
                                : 'bg-secondary text-dark'
                            }`}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '32%',
                              justifyContent: 'flex-start',
                              padding: '5px',
                              borderRadius: '5px',
                            }}
                            onClick={() => {
                              handleLessonChange(
                                lesson.id ?? '',
                                selectedChapter?.sequence === SequenceType.STANDARD
                              )
                            }}
                          >
                            {selectedLessons.includes(lesson.id) ? (
                              <i className='fa-solid fa-check text-white me-2'></i>
                            ) : (
                              <i className='bi bi-dash-square-fill me-2'></i>
                            )}
                            {lesson.name}
                          </a>
                        ))}
                    </div>
                  </>
                )}
              </>
            ) : (
              selectedChapter && (
                <>
                  <h2 className='form-label fs-3'>Seleccione las lecciones que ya aprobó</h2>
                  <div className='d-flex flex-wrap justify-content-between'>
                    {lessons &&
                      lessons
                        .sort((a, b) => a.order - b.order)
                        .map((lesson, i) => {
                          return (
                            <a
                              href='#'
                              key={i}
                              className={`col-12 mb-3 ${
                                selectedLessons.includes(lesson.id)
                                  ? 'bg-primary text-white'
                                  : 'bg-secondary text-dark'
                              }`}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '32%',
                                justifyContent: 'flex-start',
                                padding: '5px',
                                borderRadius: '5px',
                              }}
                              onClick={() => {
                                handleLessonChange(
                                  lesson.id ?? '',
                                  selectedChapter?.sequence === SequenceType.STANDARD
                                )
                              }}
                            >
                              {selectedLessons.includes(lesson.id) ? (
                                <i className='fa-solid fa-check text-white me-2'></i>
                              ) : (
                                <i className='bi bi-dash-square-fill me-2'></i>
                              )}
                              {lesson.name}
                            </a>
                          )
                        })}
                  </div>
                </>
              )
            )}
          </div>
          <div className='card-footer d-flex justify-content-end align-content-end py-6 px-9'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={loading || values.newCurrentStep === ''}
            >
              {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <span>Cambiar Nivel</span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {UpgradeMySelfModal}
