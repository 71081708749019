import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {QuestionTypeSurveySection, SurveySection} from '../../interfaces'

import { SurveySectionAdd, SurveySectionUpdate } from '../../services'
import { UseSurveySectionModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

export type SurveySectionFormFields = {
  question: string
  type: QuestionTypeSurveySection;
  isActive: boolean
  order: number
}

const entitySchema = Yup.object().shape({
  question: Yup.string().required('First name is required'),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const SurveySectionForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient();
  const {t} = useTranslation();
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseSurveySectionModule();
  const [data, setData] = useState<SurveySection>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<SurveySectionFormFields>({
      initialValues: {
        question:(mode==='EDIT' || mode==='VIEW')? itemSelected?.question??'': '',
        isActive:(mode==='EDIT' || mode==='VIEW')? itemSelected?.isActive??true:true,
        type:(mode==='EDIT' || mode==='VIEW')? itemSelected?.type??QuestionTypeSurveySection.TRUE_FALSE:QuestionTypeSurveySection.TRUE_FALSE,
        order:(mode==='EDIT' || mode==='VIEW')? itemSelected?.order??1:1
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await SurveySectionAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['survey-section'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  SurveySectionUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['survey-section'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Tipo</label>
            <select
              disabled={mode === 'VIEW'}
              name='type'
              value={values.type}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(QuestionTypeSurveySection).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {touched.type && errors.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.type}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Orden</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'order'}
              value={values.order}
              onChange={handleChange}
            />
            {touched.order && errors.order && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.order}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Pregunta</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'question'}
              value={values.question}
              onChange={handleChange}
            />
            {touched.question && errors.question && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.question}</span>
                </div>
              </div>
            )}
          </div>


        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {SurveySectionForm}
