import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {useParams} from 'react-router-dom'
import {AssistanceStudentTrailClass} from '../../../abroad/trail/services'
import {showSimpleAlert} from '../../../../commonHooks/alert'
import {useThemeMode} from '../../../../../_metronic/partials'
import UseTranslateToBogotaTimeZone from '../../../../hooks/useBogotaTranslateTz'
import UseTranslateTimeZone from '../../../../hooks/useTranslateTimeZone'

interface CountdownTimerProps {
  studentAssistant: boolean
  enabledButton: boolean
  targetDate: string // Asumiendo que la fecha se proporciona como una cadena (puedes usar el formato que desees)
  showButtonThreshold: number // Número de minutos antes de mostrar el botón para ir a la clase
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  targetDate,
  showButtonThreshold,
  enabledButton,
  studentAssistant,
}) => {
  const {id} = useParams()
  const {mode} = useThemeMode()

  const [timeRemaining, setTimeRemaining] = useState<string>('')
  const [showButton, setShowButton] = useState<boolean>(false)
  const startTime = UseTranslateTimeZone(targetDate).format('h:mm A')
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment(UseTranslateToBogotaTimeZone().yearMonthDayHour);
      const futureDate = moment(targetDate)      
      const duration = moment.duration(futureDate.diff(now))
      
      const hours = Math.floor(duration.asHours())
      const minutes = duration.minutes()
      const seconds = duration.seconds()
      
      // Formatea las horas, minutos y segundos para asegurar dos dígitos
      const formattedHours = hours.toString().padStart(2, '0')
      const formattedMinutes = minutes.toString().padStart(2, '0')
      const formattedSeconds = seconds.toString().padStart(2, '0')
      
      setTimeRemaining(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`)
      
      if (duration.asMilliseconds() <= -60 * 60 * 1000) {
        setShowButton(false)
        setTimeRemaining('00:00:00')
        clearInterval(interval)
      } else if (duration.asMinutes() <= showButtonThreshold) {
        setTimeRemaining('00:00:00')
        setShowButton(true) // Muestra el botón cuando se alcanza el umbral de minutos
      } else {
        setShowButton(false)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [targetDate, showButtonThreshold])
  
  const handleAssistanceStudent = async () => {
    try {
      if (id) {
        const responseAssistance = await AssistanceStudentTrailClass(id)
        if (responseAssistance.url) {
          window.location.href = responseAssistance.url
        }
      }
    } catch (e) {
      showSimpleAlert({message: 'Error al actualizar estudiante!', icon: 'error'})
      throw 'Error'
    }
  }
  
  return (
    <div
      className='row py-5 px-2'
      style={
        mode === 'dark'
          ? {
              color: 'white',
              border: `1px solid`,
              background: '#151521',
              borderBlockColor: '#1becd5',
              borderRadius: 80,
            }
          : {color: '#2d30e1', background: '#1becd5', borderRadius: 80}
      }
    >
      <div className='col-md-6 d-flex flex-column justify-content-center text-center'>
        <p className='champ-light display-6'>{timeRemaining !== '00:00:00'? timeRemaining: `La clase comenzó a las ${startTime}` }</p>
        <p
          className='poppins-light display-6'
          style={mode === 'light' ? {color: '#2d30e1'} : {color: '#1becd5'}}
        >
          {UseTranslateTimeZone(targetDate).format('MMMM DD/YY')}
        </p>
      </div>
      <div className='col-md-6 d-flex justify-content-center align-items-center'>
        {(showButton || studentAssistant === true) ? (
          <button
            onClick={(e) => {
              handleAssistanceStudent();
            }}
            type='button'
            className='btn btn-lg'
            style={{
              cursor: 'pointer',
              color: mode === 'dark' ? '#7577dd' : '#1becd5',
              borderRadius: 50,
              border: mode === 'dark' ? '2px solid #7577dd' : '2px solid #2d30e1',
              background: mode === 'dark' ? 'none' : '#2d30e1',
            }}
          >
            <span className='display-6 px-5'>
              Ingresar
            </span>
          </button>
        ) : (
          <button
            type='button'
            disabled
            className='btn btn-lg'
            style={{
              cursor: 'not-allowed',
              color: mode === 'dark' ? '#22b0a1' : '#2b2dad',
              borderRadius: 50,
              border: mode === 'dark' ? '2px solid #22b0a1' : '2px solid #2b2dad',
              background: mode === 'dark' ? 'none' : '#22b0a1',
            }}
          >
            <span className='display-6 px-5'>
              Ingresar
            </span>
          </button>
        )}
      </div>
    </div>
  )
}

export default CountdownTimer
