
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { UseQualifyPlacementModule } from '../../store/main'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import { StudentSetting } from '../../../../../history-writing-response/interfaces'
import FeedbackResponses from '../../../../../../courses/components/finalStats/feedbackResponses'

type Props = {
    show: boolean,
    student?: StudentSetting,
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ViewAnswersModal = ({show, student}: Props) => {
  const { setModalQualify} = UseQualifyPlacementModule();
  const handleCloseModal =() => {
    setModalQualify(false)
  }
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered modal-lg modal-fullscreen-lg-down'
      show={show}
     // onHide={handleClose}
      //   onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>{`Ver respuestas ${student? `de ${student.user.fullName}` :"" }`}</h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={()=>setModalQualify(false)}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div
        className='d-flex flex-column  justify-content-between'
        style={{
          height: '420px',
          position: 'relative',
          padding: '50px',
          overflowY: 'auto',
        }}
      >
        <FeedbackResponses/>
      </div>
      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button type='button' onClick={handleCloseModal} className='btn btn-primary me-5' >
          Regresar
        </button>      
      </div>
    </Modal>, 
    modalsRoot
  )
}

export {ViewAnswersModal}
