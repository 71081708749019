import React from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { useThemeMode } from '../../../../../../_metronic/partials';


type Props = {
    dataExcel: Array<any>;
    filename: string;
    sheetName: string;
    theadName: Array<string>;
    type?: string;
    backgroundColor?: string;
    titleColor?: string;
    // moreSheet: boolean;
}
const AllCodeExcel: React.FC<Props> = ({dataExcel, filename, sheetName, theadName, type, backgroundColor, titleColor}: Props) => {
    const {mode} = useThemeMode();

    const copyData =  JSON.parse(JSON.stringify(dataExcel));
  
    const modified = !type ? copyData.map(item => {
        item.url = item.url
        item.code    =  item.code
        item.days    = item.days
        item.courtesy    = item.courtesy
        item.status = item.status
        item.dni =  item.dni
        return item;
    }) : copyData.map(item => ({ 
          url:item.url,
          code: item.code,
          days: item.days,
          courtesy: item.courtesy,
          status: item.status,
          dni: item.dni,
    }));

    const dataArray = modified.map(obj => Object.values(obj));
    const exportToExcel = () => {
        const sheetsData: Array<{
          name: string;
          data: Array<Array<any>>;
        }> = [
          {
            name: sheetName,
            data: [[...theadName], 
            ...dataArray],
          }
        ];
        const wb = XLSX.utils.book_new();
        sheetsData.forEach((sheet) => {
          const ws = XLSX.utils.aoa_to_sheet(sheet.data);
          XLSX.utils.book_append_sheet(wb, ws, sheet.name);
        });
    
        XLSX.writeFile(wb, `${filename}.xlsx`);
      };
      

    return (

        <div className=''>
          <button onClick={exportToExcel} 
              className={`btn card w-100 d-flex justify-content-center align-items-center`} 
              style={{backgroundColor: backgroundColor, borderRadius: '30px', color: titleColor, border:mode==='dark'?`1px solid ${backgroundColor}`:''}}
          >
            <span className='d-flex justify-content-center align-items-center champ-light'>
              Excel
            </span>
          </button>
        </div>

    )
}

export default AllCodeExcel;