import useAuth from "../../../api/main";
import { showSimpleAlert } from "../../../commonHooks/alert";
import { catchServerError } from "../../../hooks/useCatchError";
import { api } from "../../auth/services/auth.services";
import { UseNotificationModule } from "../store/main";

const { token, logout, authChecked } = useAuth.getState()

export const useNotificationAll = async (): Promise<any> => {
    try {
      const response = await api.get(`/notification`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data
    } catch (e) {
      //@ts-ignore
      catchServerError(e.response)
    }
}

export const useNotificationPaginated = async (): Promise<any> => {
  try {
    // await authChecked();
    const {isActive, currentPage, limit, setPaginator} = UseNotificationModule.getState();
    const response: any = await api.get(
      `/notification/me-paginated?isActive=${isActive?1:0}&limit=${limit}&page=${currentPage}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data);
    return response.data;
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const NotificationDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/notification/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {

    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}