import {useState, useEffect} from 'react'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {ExercisesType}from '../../../../exercises/interfaces'
import {showSimpleAlert} from '../../../../../../commonHooks/alert'
import {UseQualifyPlacementModule} from '../../placement-test/store/main'
import {HistoryWritingItem} from '../../../../../courses/exercises/history-wrinting/components/history-item'
import {HistoryWritingResponseTeacherIE} from '../../../../../courses/exercises/history-wrinting/interfaces'
import {AddTeacherHistoryWritingResponse} from '../../../../../courses/exercises/history-wrinting/services'
import useAuth from '../../../../../../api/main'

type Props = {
  setShow: (v: boolean) => void
  exercise: {type: ExercisesType; id: string}
  feedback: any
}

const QualifyHistoryWritingModal = ({exercise, setShow, feedback}: Props) => {
  const [percentage, setPercentage] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [grades, setGrades] = useState<HistoryWritingResponseTeacherIE[]>()
  const { reload, setReload} = UseQualifyPlacementModule()
  const { typeUser } = useAuth();

  useEffect(() => {
    setPercentage(feedback.percentage)
    let gradesArray: HistoryWritingResponseTeacherIE[] = []
    feedback.answers.map((x: any) => {
      const response = JSON.parse(x)
      const gradesObject: HistoryWritingResponseTeacherIE = {
        questions: response.questions,
        response: response.response,
        percentage: response.percentage ?? 0,
        teacherObservation: response.teacherObservation ?? ''
      }
      gradesArray.push(gradesObject)
    })
    setGrades(gradesArray)
  }, [feedback])

  useEffect(() => {    
    if (!grades || grades.length === 0) return
    let totalWeight = 0
    grades.forEach((grade) => {
      totalWeight += (grade.percentage)/grades.length
    })
    const newPercentage = totalWeight
    setPercentage(newPercentage)
  }, [grades])

  const handleSubmit = async () => {
    setIsLoading(true)
    if(!grades)return
    try {
      await AddTeacherHistoryWritingResponse(exercise.id, {
        response_teacher: grades,          
        percentage,
      })
      setShow(false)
      setReload(!reload)
    } catch {
      showSimpleAlert({message: 'Error al calificar la tarea', icon: 'error'})
    }
    setIsLoading(false)
  }

  const handlePercentageChange = (key: number, percentage: number) =>{
    setGrades((prevGrades) =>{
      if (!prevGrades) return prevGrades;
      const updatedGrades = [...prevGrades];
      updatedGrades[key] = {
        ...updatedGrades[key],
        percentage: (percentage <0 ? 0 : percentage > 100 ? 100 : percentage),
      };
      return updatedGrades;}
    )
  }

  const handleCommentsChange = (key: number, comment: string) =>{
    setGrades((prevGrades) =>{
      if (!prevGrades) return prevGrades;
      const updatedGrades = [...prevGrades];
      updatedGrades[key] = {
        ...updatedGrades[key],
        teacherObservation: comment,
      };
      return updatedGrades;}
    )
  }

  return (
    <>
      <div className='modal-body'>
        <div>
          <button
            onClick={() => setShow(false)}
            type='button'
            className='btn btn-icon btn-light btn-sm border-0 me-1'
          >
            <KTIcon iconName='arrow-left' className='fs-2 text-primary' />
          </button>
        </div>
        {(grades && grades.length>0) ?
        <>
          <div>
            <div className='my-5 w-100'>
              <div className='row justify-content-between'>
                {grades &&
                  grades.map((grade, index) => (
                    <>
                      {grade.questions.map((question, i) => (
                        <div className='bg-dark-subtle fs-5 w-75 m-auto'>
                          <HistoryWritingItem key={`${index}-${i}`} questions={question} />
                        </div>
                      ))}
                      <div className='row w-100 mt-5'>
                        <>
                          <label className='form-label poppins-bold fs-5'>Description: </label>
                          <textarea
                            disabled
                            value={grade.response}
                            className='w-100 h-80 mx-5 px-5 pt-3 border-1 border-opacity-25 rounded poppins-light'
                            placeholder='No hay respuesta...'
                          />
                        </>
                      </div>
                      <div className='row w-100 align-items-center justify-content-between'>
                        <label className='form-label poppins-bold fs-5'>Comments: </label>
                        <div className='mb-3 d-flex col-9 align-items-center'>
                          <textarea
                            id={`comments-input-${index}`}                              
                            disabled={typeUser === 'STUDENT'}
                            value={grade.teacherObservation}
                            className='ms-2 my-3 ps-5 w-100 align-content-center border-2 border-opacity-25 rounded poppins-light'
                            onChange={(e) => handleCommentsChange(index, e.target.value)}
                            placeholder='Leave some comments...'
                          />
                        </div>
                        <div className='mb-3 d-flex col-3 align-items-center justify-content-end'>
                          <div className='d-flex align-items-center'>
                            <label className='form-label poppins-bold fs-5 m-auto'>Score: </label>
                            <input
                              id={`score-input-${index}`}
                              disabled={typeUser === 'STUDENT'}
                              min={0}
                              max={100}
                              type='number'
                              value={grade.percentage}
                              onChange={(e)=>handlePercentageChange(index, parseInt(e.target.value))}
                              className='poppins-light px-0 ms-2 text-end border-1 border-opacity-25 rounded ml-auto'
                              style={{width: '50px'}}
                            />
                            <p className='poppins-bold fs-5 m-auto' style={{color: '#00b6f0'}}>
                              {`/${(100/grades.length).toFixed(1)}%`}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='px-5'>
                        <hr className='flex-grow'></hr>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center'>
              <label className='form-label poppins-bold fs-5 m-auto'> Total score: </label>
              <input
                disabled
                id={`score-input-total}`}
                min={0}
                max={100}
                type='number'
                value={percentage}
                className='poppins-light px-0 ms-2 text-end border-1 border-opacity-25 rounded ml-auto'
                style={{width: '50px'}}
              />
              %
            </div>
            {typeUser !== 'STUDENT' &&
              <button
                type='button'
                onClick={() => handleSubmit()}
                className='btn btn-primary py-2 d-flex justify-content-end'
              >
                {!isLoading && 'Submit'}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            }
          </div>  
        </>
        :
        <p className='row fs-2 w-100 my-5 mx-1 text-danger' >Error al cargar la respuesta</p>
      }
      </div>
    </>
  )
}

export {QualifyHistoryWritingModal}
