import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ResetStepByExercises} from '../exercises/utils'
import {OneTaskByFindOneTask} from '../exercises/basic-vocabulary/services'
import {UseEvaluationCourseModule} from '../screen/store/main'
import {UseModeCourseModule} from '../components/front-page/store/main'
import useIsImportantEvaluation from '../hooks/useIsImportantEvaluation'
import {UseEvaluationTestModule} from '../../admin/evaluation/modules/test/store/main'
import useAuth from '../../../api/main'

type Props = {
  onAction: () => Promise<void>
}

const ButtonNextFullScreen: React.FC<Props> = ({onAction}) => {
  const {typeUser} = useAuth()
  const {
    stepEvaluation,
    evaluationLesson,
    lessonCourse,
    currentTaskId,
    isLoading, 
    setIsLoading,
    setStepEvaluation,
    setShowEndEvaluation,
  } = UseEvaluationCourseModule()

  const {stepEvaluationTest, evaluationTest, setStepEvaluationTest} = UseEvaluationTestModule()

  const {modeCourse, modeTest, isPercentageMin} = UseModeCourseModule()

  const isImportant = useIsImportantEvaluation()

  return (
    <div className='text-end mt-auto'>
      <button
        type='button'
        className='cursor-pointer border border-0 bg-transparent'
        style={{ minWidth: 'fit-content'}}
        onClick={async (e) => {
          e.preventDefault();
          setIsLoading(true);
          
          if (!isPercentageMin && !isImportant) return;

          if (modeTest && evaluationTest && evaluationTest.evaluation_item) {
            if (evaluationTest?.evaluation_item?.length > stepEvaluationTest + 1) {
              setStepEvaluationTest(stepEvaluationTest + 1);
              ResetStepByExercises();
            } else {
              alert('acabo');
            }
          }

          if (modeCourse && evaluationLesson && evaluationLesson.evaluation_item) {
            await onAction();
            ResetStepByExercises();
            
            if (evaluationLesson?.evaluation_item?.length > stepEvaluation + 1) {
              setStepEvaluation(stepEvaluation + 1);
            } else if (lessonCourse) {
              if (typeUser === 'STUDENT' && !isImportant) {
                await OneTaskByFindOneTask(currentTaskId ?? 'xx');
              }
              setShowEndEvaluation(true);
            } else {
              setShowEndEvaluation(true);
            }
          }

          setIsLoading(false);
        }}
      >
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center mb-1">
            <span className="spinner-grow spinner-grow-sm mx-1"></span>
            <span className="spinner-grow spinner-grow-sm mx-1"></span>
            <span className="spinner-grow spinner-grow-sm mx-1"></span>
          </div>
        ) : (
          <img height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_active.svg`)} alt='' />
        )}
      </button>
    </div>
  )
}

export {ButtonNextFullScreen}
