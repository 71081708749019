import React, { useEffect, useState } from 'react'
import { CreateModal } from '../modal'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { showSimpleAlert } from '../../../../../../commonHooks/alert'
import GenericPaginator from '../../../../../../components/common/Paginator'
import { CardHeaderModule } from '../../../../../../components/common/widgets/statistics/cardHeaderModule'
import { useThemeMode } from '../../../../../../../_metronic/partials'
import moment from 'moment'
import { TaskStatus, TaskType } from '../../../../tasks/interfaces'
import CustomDatePicker from '../../../../../../components/common/DayPicker/DayPickerInputWithImage'
import { UseQualifyPlacementAllPaginate } from '../../placement-test/services'
import { FilterType, UseQualifyPlacementModule } from '../../placement-test/store/main'
import { ViewAnswersModal } from '../../placement-test/components/modal/viewAnswersModal'
import { EvaluationType } from '../../../../evaluation/interfaces'
import { UseTrailModule } from '../../../../user/store/student/main'
import { UpgradeStudentModal } from '../../../../student-actions/promotion-history/components/modal'
import QualifyEvaluationForms from '../forms'
import { useLocation } from 'react-router-dom'

type PaginatorSetter = {
  currentPage: number
  items: number
  total: number
  totalPage: number
}

type Props = { evaluationType: EvaluationType }
const QualifyEvaluationTableList: React.FC<Props> = ({ evaluationType }) => {
  
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const studentIdNumber = searchParams.get('studentIdNumber')
  const { mode } = useThemeMode()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const {
    paginator,
    modal,
    student,
    limit,
    currentPage,
    itemSelected,
    isActive,
    where,
    filterType,
    modalQualify,
    reload,
    setCurrentPage,
    setLimit,
    setWhere,
    setFilterType,
    setReload,
  } = UseQualifyPlacementModule()
  const { modalUpgrade } = UseTrailModule()

  // const { data } = useQualifyState(TaskType.PLACEMENT_TEST);
  const [data, setData] = useState<any>()
  const [before, setBefore] = useState<Date>()
  const [after, setAfter] = useState<Date>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getTaskTypeByEvaluation = (): TaskType => {
    switch (evaluationType) {
      case EvaluationType.FINAL_PRESENTATION:
        return TaskType.FINAL_PRESENTATION
      case EvaluationType.PLACEMENT_TEST:
        return TaskType.PLACEMENT_TEST
      case EvaluationType.QUIZ:
        return TaskType.QUIZ
      case EvaluationType.WELCOME_DAY:
        return TaskType.WELCOME_DAY
      default:
        return TaskType.NORMAL
    }
  }
  const fetchData = async () => {
    setIsLoading(true)
    try {
      if(studentIdNumber){
        setFilterType(FilterType.DOCUMENT)
        setWhere(studentIdNumber)
      }
      const currentTaskType = getTaskTypeByEvaluation();
      if (before && after) {
        const result = await UseQualifyPlacementAllPaginate(currentTaskType, before, after)
        setData(result)
      } else if (before || after) {
        showSimpleAlert({ icon: 'info', message: 'Elija la otra fecha' })
      } else {
        const result = await UseQualifyPlacementAllPaginate(currentTaskType)
        setData(result)
      }
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [before, after, where, filterType, currentPage, limit, reload])

  const changePage = (number: number) => {
    setCurrentPage(number)
  }
  const changeLimit = (number: number) => {
    setLimit(number)
  }

  const handleBeforeDate = (date: Date) => {
    setBefore(date)
  }

  const handleAfterDate = (date: Date) => {
    setAfter(date)
  }
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['avatar'] })
  }, [isActive, where, currentPage, limit])
  return (
    <>
      <CardHeaderModule
        title={t(`${evaluationType}`)}
        leftIcon={`/media/svg/michigan-icons/header/${mode === 'light' ? 'white/back_white' : 'dark-modules/back_purple'
          }.svg`}
        svgIcon={`/media/svg/michigan-icons/header/${mode === 'light' ? 'white/support_white' : 'dark-modules/support_purple'
          }.svg`}
        background={'#a500b7'}
        colorTitle={'#FFFFFF'}
      />
      <div className='mt-5'>
        <div className='row mt-3 d-flex justify-content-between  mb-5'>
          <div className='col-md-8 d-flex'>
            <div
              className='card w-100 p-0'
              style={{
                backgroundColor: mode === 'light' ? '#E6E6FA' : '',
                borderRadius: 30,
                border: mode === 'dark' ? '1px solid green' : '',
              }}
            >
              <div className='row'>
                <div className='col-md-6 d-flex'>
                  <span
                    className='poppins-bold fs-bold ps-10 pt-2'
                    style={{
                      color: mode === 'light' ? '#2d30e1' : '#E6E6FA',
                      fontSize: 15,
                      margin: 'auto 0',
                      display: 'inline-flex',
                      alignItems: 'center',
                      height: '100%',
                      lineHeight: 'normal',
                    }}
                  >
                    {`From: ${moment(before).format('DD-MM-YYYY')}`}
                  </span>

                  <div className='ms-5'>
                    <CustomDatePicker
                      calendarPosition='right'
                      valueCalendarPosition={-100}
                      symbolWidth='25px'
                      onSelectDate={(date) => {
                        handleBeforeDate(date)
                      }}
                      calendarIconSrc='/media/svg/michigan-icons/elements/calendar_blue.svg'
                    // buttonStyle={{marginLeft: '5px'}}
                    />
                  </div>
                </div>
                <div className='col-md-6 d-flex'>
                  <span
                    className='poppins-bold fs-bold ps-10 pt-2'
                    style={{
                      color: mode === 'light' ? '#2d30e1' : '#E6E6FA',
                      fontSize: 15,
                      margin: 'auto 0',
                      display: 'inline-flex',
                      alignItems: 'center',
                      height: '100%',
                      lineHeight: 'normal',
                    }}
                  >
                    {`To: ${moment(after).format('DD-MM-YYYY')}`}
                  </span>
                  <div className='ps-5'>
                    <CustomDatePicker
                      symbolWidth='25px'
                      onSelectDate={(date) => {
                        handleAfterDate(date)
                      }}
                      calendarIconSrc='/media/svg/michigan-icons/elements/calendar_blue.svg'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4 d-flex'>
            <div
              className='card w-100 p-2 d-flex flex-row justify-content-between'
              style={{
                backgroundColor: mode === 'light' ? '#E6E6FA' : '',
                borderRadius: 30,
                border: mode === 'dark' ? '1px solid green' : '',
              }}
            >
              <div className='form-floating col-md-6 px-3 d-flex justify-content-start'>
                <div className='row align-content-center flex-wrap'>
                  <label className='col poppins-bold align-content-center' style={{ margin: 'auto' }}>
                    Filter by:
                  </label>
                  <select
                    className='col form-select-sm align-content-center mt-auto'
                    style={{
                      minWidth: 'fit-content',
                      border: 'none',
                      height: '32px',
                      borderRadius: '5px',
                    }}
                    onChange={(e) => {
                      setFilterType(e.target.value as FilterType)
                    }}
                  >
                    <option selected value={FilterType.NAME}>Student name</option>
                    <option value={FilterType.DOCUMENT}>Student id</option>
                    <option value={FilterType.CODE}>Test code</option>
                    {evaluationType === EvaluationType.PLACEMENT_TEST &&
                      <option value={FilterType.STATUS}>Status</option>
                    }
                  </select>
                </div>
              </div>
              <div className='col-md-6 me-5 d-flex pe-5 py-1'>
                {filterType === FilterType.STATUS ?
                  <select
                    className='col form-select-sm align-content-center mt-auto'
                    style={{
                      minWidth: 'fit-content',
                      border: 'none',
                      height: '32px',
                      borderRadius: '5px',
                    }}
                    onChange={(e) => {
                      setWhere(e.target.value)
                    }}
                  >
                    <option selected value={TaskStatus.PENDING}>Asignado</option>
                    <option value={TaskStatus.IN_REVIEW}>Pendiente por revisar</option>
                    <option value={TaskStatus.QUALIFIED}>Calificado</option>
                    <option value={TaskStatus.APPROVED}>Nivelado</option>
                  </select>
                  :
                  <input
                    type='text'
                    onChange={(e) => {
                      setWhere(e.target.value)
                    }}
                    style={{ maxHeight: '30px' }}
                    className='form-control form-control-sm'
                    name='Search'
                    value={where}
                    placeholder='Search'
                  />
                }

              </div>
            </div>
          </div>
        </div>
        <div
          className='card content-support'
          style={{ border: mode === 'light' ? '' : '1px solid #FFFFFF' }}
        >
          <div className='card-body'>
            <div className='table-responsive overflow-x-hidden'>
              {isLoading ?
                <span className='indicator-progress d-flex justify-content-center  align-items-center ms-5'>
                  <span className='spinner-border spinner-border-lg align-middle' style={{ color: "#a500b7" }}> </span>
                </span>
                :
                <QualifyEvaluationForms evaluationType={evaluationType} data={data} />
              }
              <GenericPaginator
                limit={limit}
                currentPage={paginator.current_page}
                items={paginator.result.length}
                total={paginator.total}
                totalPage={paginator.total_page}
                changePage={changePage}
                changeLimit={changeLimit}
              />
            </div>
          </div>
        </div>
      </div>
      <CreateModal
        show={modal}
        handleClose={() => {
          setReload(!reload);
        }}
        evaluationType={evaluationType}
      />
      {(student && itemSelected) && (
        <>
          <UpgradeStudentModal typeModal='PLACEMENT' show={modalUpgrade} student={student} taskId={itemSelected.id} />
          <ViewAnswersModal show={modalQualify} student={student} />
        </>
      )}
    </>
  )
}

export { QualifyEvaluationTableList }
