import React, { useEffect } from 'react'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { CreateModal } from '../modal'
import { useQueryClient } from '@tanstack/react-query'
import { useFaqState } from '../../hooks/useFaqRepo'
import { UseFaqModule } from '../../store/main'
import { DataFaq, Faq } from '../../interfaces'
import { BooleanCell } from '../../../../../components/common/BooleanCell'
import * as Swal from 'sweetalert2'
import { FaqDelete } from '../../services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import GenericPaginator from '../../../../../components/common/Paginator'
import supabase from '../../../../../supabase/supabase';
import { UseFaqCategoryAll } from '../../modules/faq-category/services'
import { FaqCategory } from '../../modules/faq-category/interfaces'
import useAuth from '../../../../../api/main'
import { CardHeaderModule } from '../../../../../components/common/widgets/statistics/cardHeaderModule'
import { useThemeMode } from '../../../../../../_metronic/partials'
import { useTranslation } from 'react-i18next';
import './index.css';
import YouTubeLinkExtractor from './link-youtube-text'

type PaginatorSetter ={
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
}
type Props = {

}
const FaqTableList: React.FC<Props> = ({ }) => {
  const {t} = useTranslation();
  const {mode} = useThemeMode();
  const queryClient = useQueryClient();
  const { 
      root,
      paginator, 
      modal,
      limit,
      currentPage,
      isActive,
      where,
      setCurrentPage,
      setLimit, 
      setIsActive, 
      setSelectedToEdit, 
      setSelectedToView,
      setModal,
      setWhere,
      setToAdd,
      setRoot
  } = UseFaqModule()
  const { data, isLoading } = useFaqState();

  const { typeUser } = useAuth.getState();

  const [faqCategoryData, setFaqCategoryData] = React.useState<FaqCategory[]>([]);

  let [page, setPage] = React.useState<PaginatorSetter>({
    currentPage:1,
    items:0,
    total:0,
    totalPage:0
  });

  const [activeAccordion, setActiveAccordion] = React.useState<string | undefined>();

  const deleteItem = async (id:string)=>{
    //@ts-ignore
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        try{
          await FaqDelete(id)
          showSimpleAlert({message:'Ok!',icon:'success'})
         }catch(e){
            
         }
      }
    })
  }
  const changePage = (number: number) => {
      setCurrentPage(number)
  };
  const changeLimit =(number:number)=>{
      setLimit(number)
  }

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['faq'] });
  }, [isActive,where,currentPage,limit,root]);

  const fetchFaqCategory = async () => {
    try {
      const faqCategoryResponse = await UseFaqCategoryAll();
      setFaqCategoryData(faqCategoryResponse);
    } catch (e) {
        showSimpleAlert({ message: 'Error al encontrar Categorias!', icon: 'error' })
        throw 'Error'
      }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value === 'Todas' ? undefined : (e.target.value as string);
    setRoot(selectedValue);
  };

  React.useEffect(() => {
    fetchFaqCategory();
  }, []);

  return (<>
    <CardHeaderModule
        title={t(`FAQ'S`)} 
        leftIcon={`/media/svg/michigan-icons/header/${mode==='light'?'blue/back_blue':'white/back_white'}.svg`} 
        svgIcon={`/media/svg/michigan-icons/header/${mode==='light'?'blue/faqs_blue':'white/faqs_white'}.svg`} 
        background={'#E5E6FA'}
        colorTitle={'#2d30e1'}
    />
      <div className='d-flex mt-3 justify-content-between'>
        <div className='d-flex'>
          <div className='card d-flex justify-content-start select-category' style={{border:mode==='light'?'':'1px solid #FFFFFF'}}>
            <div className='d-flex p-5'>
              <label className='me-2 d-flex align-items-center champ-light w-100 fw-bold'>{t('CATEGORY')}:</label>
              <select
                name='ticketPriority'
                value={root}
                onChange={handleChange}
                className='form-select form-select-sm w-100 champ-light fw-bold'
                aria-label='Default select example'
              >
                <option value={undefined}>
                  {'Todas'}
                </option>
                {faqCategoryData && faqCategoryData?.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className='d-flex'>
          {typeUser === 'ADMIN' && <>
            <a href="/faq/category" className='btn btn-sm me-1 text-decoration-none poppins-light'>
              {t('CREATE_CATEGORY')}
            </a>
            <button
              type='button'
              onClick={() => setToAdd()}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              style={{
                textDecoration: 'none',
                transition: 'background-color 0.3s',
              }}
            >
              <i style={{ color: 'blue', fontSize: 17 }} className="fa-solid fa-plus"></i>
            </button>
          </>}
        </div>
      </div>

      <div className='mt-5'>
        <div className='card content-faq' style={{borderRadius: '30px'}}>
          <div className="card-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <div className="accordion" id="accordionExample">
            {data?.result?.map((x: DataFaq) => (
              <div className="accordion-item border-0 mb-3" key={x?.id}>
                <h2 className="accordion-header" id={`heading${x?.id}`}>
                  <button className={`accordion-button`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${x?.id}`} aria-expanded="true" aria-controls={`collapse${x?.id}`}
                    style={{
                      backgroundColor:mode==='light'?'#E5E6FA':'',
                      borderRadius: 30,
                      border:mode==='dark'?'1px solid #E5E6FA':'',
                      borderBottomLeftRadius: activeAccordion === x?.id ? 0 : 30,
                      borderBottomRightRadius: activeAccordion === x?.id ? 0 : 30,
                    }}
                    onClick={() => setActiveAccordion(prev => x?.id === prev ? undefined : x?.id)}
                  >
                    <span className='poppins-bold' style={{color:mode==='light'?'#2d30e1':'#E5E6FA'}}>
                      {x?.question} 
                    </span>
                  </button>
                </h2>
                <div id={`collapse${x?.id}`} className="accordion-collapse collapse" aria-labelledby={`heading${x?.id}`} data-bs-parent="#accordionExample">
                  <div className="accordion-body bg-light rounded-top-0" style={{borderRadius:30}}>
                    {typeUser !== 'ADMIN' && 
                      <span className='poppins-light'>
                        <YouTubeLinkExtractor text={x?.answer}  />
                      </span>}
                    {typeUser === 'ADMIN' && <div className='row'>
                      <div className='col-md-10 p-0'>
                        <span className='poppins-light'>
                          <YouTubeLinkExtractor text={x?.answer}  />
                        </span>
                      </div>
                      <div className='col-md-2 p-0 text-end'>
                        <button
                          onClick={()=>setSelectedToEdit(x)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i style={{ color: 'blue', fontSize: 17 }} className="fas fa-pen"></i>
                        </button>
                        <button
                          onClick={() => deleteItem(x.id??'xxx')}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <i style={{ color: 'red', fontSize: 17 }} className="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <GenericPaginator
              limit={limit}
              currentPage={paginator.current_page}
              items={paginator.result.length}
              total={paginator.total}
              totalPage={paginator.total_page}
              changePage={changePage}
              changeLimit={changeLimit}
            />
        </div>
        </div>
      </div>
      
    <CreateModal show={modal} handleClose={() => alert()} />
    {/* <div className="d-flex">
      <div className="card col-3 me-4">
        <div className="card-body">
          <ul className="list-unstyled">
            {faqCategoryData &&
              faqCategoryData?.map((x) => (
                <li
                  key={x?.id}
                  onClick={() => setRoot(x.id ?? 'xxx')}
                  className="mb-2 py-1 px-3 rounded cursor-pointer border"
                  style={{ backgroundColor: '#f0f0f0' }}
                >
                  {x?.name}
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div className={`card col-9`}>
        {typeUser === 'ADMIN' && <>
        <div className="card-header border-0 pt-5">
          <div
            className='card-toolbar d-flex justify-content-end'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
              <button
                onClick={() => setToAdd()}
                className='btn btn-sm btn-light-primary me-2'
              >
                <KTIcon iconName='plus' className='fs-3' />
              </button>
              <button
              onClick={() => setIsActive(!isActive)}
              className='btn btn-sm btn-light-secondary p-2'
              >
                <BooleanCell value={isActive} />
              </button>
          </div>
        </div>
            </>
            }
        <div className="card-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <div className="accordion" id="accordionExample">
            {data?.result?.map((x: DataFaq) => (
              <div className="accordion-item" key={x?.id}>
                <h2 className="accordion-header" id={`heading${x?.id}`}>
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${x?.id}`} aria-expanded="true" aria-controls={`collapse${x?.id}`}>
                    {x?.question}
                  </button>
                </h2>
                <div id={`collapse${x?.id}`} className="accordion-collapse collapse" aria-labelledby={`heading${x?.id}`} data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    {x?.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div> */}
  </>
  )
}

export { FaqTableList }
