import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {TransferStudent} from '../../interfaces'

import { TransferStudentAdd, TransferStudentUpdate } from '../../services'
import { UseTransferStudentModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { UseAppointmentAllPaginate, UseGetOneAppointment } from '../../../appointment/services'
import { BlockHour } from '../../../../abroad/block-hours/interfaces'
import { UseBlockHourAll } from '../../../../abroad/block-hours/services'
import { UseAppointmentModule } from '../../../appointment/store/main'
import { TrailClassTransferStudents, UseGetAllTrailClassByAppointment } from '../../../../abroad/trail/services'
import { TrailClass } from '../../../../abroad/trail/interfaces'
import { extractIDs } from '../../../../../components/common/ExcelExport/utils'
import Swal from 'sweetalert2'

export type FormFields = {
  date: string
  students: string[]
  appointmentOrigin: string;
  appointmentDestiny: string;
  description?:string
}

const entitySchema = Yup.object().shape({
  date: Yup.date()
    .min(moment().subtract(1, 'day').toDate(), 'La fecha no puede ser anterior a hoy')
    .required('La fecha es requerida'),
  appointmentOrigin: Yup.string().required('La clase origen es requerida'),
  appointmentDestiny: Yup.string().required('La clase destino es requerida')
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const TransferStudentForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {itemSelected, mode,resetData} = UseTransferStudentModule();
  
  const {blockHour, setBlockHour, setDate, setLimit} = UseAppointmentModule()

  const [dataAppointment, setDataAppointment] = useState<any[]>([]);
  
  const [dataTrailClassOrigin, setDataTrailClassOrigin] = useState<TrailClass[]>([]);
  const [dataTrailClassDestiny, setDataTrailClassDestiny] = useState<TrailClass[]>([]);

  const [transferredIds, setTransferredIds] = React.useState<string[]>([]);

  const [dataBlockHour, setDataBlockHour] = React.useState<BlockHour[]>([]);

  const [loading, setLoading] = React.useState<boolean>(false)

  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        date: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.date ?? moment().format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        students: (mode === 'EDIT' || mode === 'VIEW') ? (itemSelected?.students.map((x) => x.id) ?? []).filter((x): x is string => !!x) : [],
        appointmentOrigin: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.appointmentOrigin.id ?? '' : '',
        appointmentDestiny: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.appointmentDestiny.id ?? '' : ''
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            Swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            }).then(async(result) => {
              if (result.isConfirmed) {
                setLoading(true);
                await TransferStudentAdd({
                  ...formData,
                  students: transferredIds ? transferredIds : []
                })
                await TrailClassTransferStudents({
                  appointmentOrigin: values.appointmentOrigin,
                  appointmentDestiny: values.appointmentDestiny,
                  students: transferredIds ? transferredIds : [],
                })
                resetData();
                setLoading(false)
                queryClient.invalidateQueries({ queryKey: ['transfer-student'] })
              }
            })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then(async(result) => {
                if (result.isConfirmed) {
                  setLoading(true);
                  await  TransferStudentUpdate({
                    ...formData,
                    students: transferredIds ? transferredIds : []
                  })
                  await TrailClassTransferStudents({
                    appointmentOrigin: values.appointmentOrigin,
                    appointmentDestiny: values.appointmentDestiny,
                    students: transferredIds ? transferredIds : [],
                  })
                  resetData();
                  setLoading(false)
                  queryClient.invalidateQueries({ queryKey: ['transfer-student'] })
                }
   
              })
              }catch(e){
                setLoading(false);
              }
          }
      },
    })

  const fetchAppointmentByDate = async () => {
    try {
      if (values.date) {
        let appointmentResponse = await UseAppointmentAllPaginate();
        setDataAppointment(appointmentResponse.result)
      }
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Appointment!', icon: 'error' })
      throw 'Error'
    }
  };

  const fetchBlocksHours = async () => {
    try {
      const response = await UseBlockHourAll();
      setDataBlockHour(response);
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar bloques horarios!', icon: 'error' })
      throw 'Error'
    }
  };

  const fetchGetAllTrailClassByAppointment = async (id: string, origin: boolean) => {
    try {
      let trailClassResponse = await UseGetAllTrailClassByAppointment(id);
      if (origin) {
        setDataTrailClassOrigin(trailClassResponse);
      } else {
        setDataTrailClassDestiny(trailClassResponse);
      };
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Trail Class!', icon: 'error' })
      throw 'Error'
    }
  };

  React.useEffect(() => {
    fetchBlocksHours();
    setLimit(1000);
  }, []);

  React.useEffect(() => {
    if (values.appointmentOrigin) {
      fetchGetAllTrailClassByAppointment(values.appointmentOrigin, true);
    }
  }, [values.appointmentOrigin]);

  React.useEffect(() => {
    if (values.appointmentDestiny) {
      fetchGetAllTrailClassByAppointment(values.appointmentDestiny, false);
    }
  }, [values.appointmentDestiny]);

  React.useEffect(() => {
    if (values.date && blockHour) {
      setDate({
        after: moment(values.date).toDate(),
        before: moment(values.date).toDate()
      })
      fetchAppointmentByDate();
    }
  }, [values.date, blockHour]);

  React.useEffect(() => {
    if (mode === 'EDIT' || mode === 'VIEW') {
      setBlockHour(itemSelected?.appointmentOrigin?.blockHour[0]?.order);
      setTransferredIds((itemSelected?.students.map((x) => x.id) ?? []).filter((x): x is string => !!x))
    }
  }, [itemSelected]);

  const transferStudent = (item: TrailClass, values: boolean) => {
    if (values) {
      setDataTrailClassDestiny(prevItem => [...prevItem, item]);
      setDataTrailClassOrigin(prevItem =>  prevItem.filter((x) => x.id !== item.id));
      setTransferredIds(prevIds => [...prevIds, item.student_setting.user.id??'']);
    }
    if (!values) {
      setDataTrailClassDestiny(prevItem => prevItem.filter((x) => x.id !== item.id));
      setDataTrailClassOrigin(prevItem => [...prevItem, item]);
      setTransferredIds(prevIds => prevIds.filter(id => id !== item.student_setting.user.id));
    }
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Fecha</label>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'date'}
              value={values.date}
              onChange={handleChange}
            />
            {touched.date && errors.date && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.date}</span>
                </div>
              </div>
            )}
          </div>

          <div className="mb-1 col-6">
            <label className='form-label mb-0'>Filtro de Bloque horario</label>
            <select
              name='dataBlockHour'
              value={blockHour}
              onChange={(e) => setBlockHour(parseInt(e.target.value))}
              className='form-select form-select-sm me-4 w-100'
              aria-label='Default select example'
            >
              <option value={undefined}>
                {' '}
              </option>
              {dataBlockHour?.map((x) => (
                <option key={x.id} value={x.order}>
                  {`${x.start} ${x.end}`}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-1 col-6">
            <label className='form-label mb-0'>Clases del bloque horario</label>
            <select
              name='appointmentOrigin'
              value={values.appointmentOrigin}
              onChange={handleChange}
              className='form-select form-select-sm me-4 w-100'
              aria-label='Default select example'
            >
              <option value={undefined}>
                {' '}
              </option>
              {dataAppointment?.map((x) => (
                <option key={x.id} value={x.id}>
                  {`${x?.classroom?.name} - ${x?.activity?.name}`}
                </option>
              ))}
            </select>
            {touched.appointmentOrigin && errors.appointmentOrigin && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.appointmentOrigin}</span>
                </div>
              </div>
            )}
          </div>

          <div className="mb-1 col-6">
            <label className='form-label mb-0'>Clases a transferir</label>
            <select
              name='appointmentDestiny'
              value={values.appointmentDestiny}
              onChange={handleChange}
              className='form-select form-select-sm me-4 w-100'
              aria-label='Default select example'
            >
              <option value={undefined}>
                {' '}
              </option>
              {dataAppointment?.filter((y) => y.id !== values.appointmentOrigin).map((x) => (
                <option key={x.id} value={x.id}>
                  {`${x?.classroom?.name} - ${x?.activity?.name}`}
                </option>
              ))}
            </select>
            {touched.appointmentDestiny && errors.appointmentDestiny && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.appointmentDestiny}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-3 col-6'>
            <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-120px'>Nombre</th>
                    <th className='min-w-120px'>Contrato</th>
                    <th className='min-w-120px'>{' '}</th>
                  </tr>
                </thead>
                <tbody>

                  {dataTrailClassOrigin && dataTrailClassOrigin?.map((x, i) => (
                      <tr key={i}>
                        <td className='p-0'>
                          <div className="d-flex justify-content-start flex-column">
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.student_setting?.user.fullName}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {x?.student_setting?.user?.email}
                            </span>
                          </div>
                        </td>
                        <td className="p-0">
                          <span className='text-dark fw-semibold d-block fs-7'>
                            {x?.student_setting?.codeContract}
                          </span>
                        </td>
                        <td className='p-0'>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                              <button
                                disabled={mode==='VIEW'}
                                type='button'
                                onClick={()=> transferStudent(x, true)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i className="fa-solid fa-arrow-right" style={{ color: 'green', fontSize: 15 }}></i>
                              </button>
                          </div>
                        </td>
                      </tr>
                  ))}
                </tbody>
            </table>
          </div>

          <div className='mb-3 col-6'>
            <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-120px'>Nombre</th>
                    <th className='min-w-120px'>Contrato</th>
                    <th className='min-w-120px'>{' '}</th>
                  </tr>
                </thead>
                <tbody>

                  {dataTrailClassDestiny && dataTrailClassDestiny?.map((x, i) => (
                      <tr key={i} className={`${transferredIds.includes(x.student_setting.user.id??'') ? 'bg-light-success' : 'bg-light-primary'}`}>
                        <td className='p-0'>
                          <div className="d-flex justify-content-start flex-column">
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.student_setting?.user.fullName}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {x?.student_setting?.user?.email}
                            </span>
                          </div>
                        </td>
                        <td className="p-0">
                          <span className='text-dark fw-semibold d-block fs-7'>
                            {x?.student_setting?.codeContract}
                          </span>
                        </td>
                        <td className='p-0'>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                              <button
                                disabled={mode==='VIEW'}
                                type='button'
                                onClick={()=> transferStudent(x, false)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i className="fa-solid fa-xmark" style={{ color: 'red', fontSize: 15 }}></i>
                              </button>
                          </div>
                        </td>
                      </tr>
                  ))}
                </tbody>
            </table>
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {TransferStudentForm}
