import moment from 'moment'
import {UseQualifyPlacementModule} from '../../placement-test/store/main'
import { useState } from 'react'
import { ContactStudentModal, ContactStudentInterface } from '../modal/contactStudent'
import { StudentSetting } from '../../../../history-writing-response/interfaces'

type Props = {
  data: any
}

const QualifyWelcomeDayForm: React.FC<Props> = ({data}: Props) => {
  const {setSelectedToView} = UseQualifyPlacementModule()
  const [showModalContact, setShowModalContact] = useState<boolean>(false)
  const [student, setStudent] = useState<StudentSetting>()
  const [contactInfo, setContactInfo] = useState<ContactStudentInterface>()

  return (
    <>
      <table className='table'>
      <thead className=''>
          <tr className={`fs-5 fw-bold bg-light poppins-bold`}>
            <th className='text-center align-middle'>Student</th>
            <th className='text-center align-middle'>Document</th>
            <th className='text-center align-middle'>Test code</th>
            <th className='text-center align-middle'>Status</th>
            <th className='text-center align-middle'>Date</th>
            <th className='text-center align middle'>Needs contact</th>
          </tr>
        </thead>
        <tbody className=''>
          {data?.result?.map((x: any) => (
            <tr className={`cursor-pointer `} onClick={() => setSelectedToView(x)}>
              <td className='text-center align-middle'>{x.student_name}</td>
              <td className='text-center align-middle'>{x.student_setting.user.document_id}</td>
              <td className='text-center align-middle'>{x.student_setting.code}</td>
              <td
                className={`text-center align-middle 
                  text-${
                      x?.status === 'PENDING'
                    ? 'primary'
                    : x?.status === 'IN_REVIEW' 
                    ? 'danger'
                    : x?.status === 'QUALIFIED'
                    ? 'warning'
                    : x?.status === 'APPROVED' 
                    ? 'success'
                    :x?.status === 'REPROVED'
                    ? 'danger'
                    : 'dark'
                  }`
                }
              >
                { x?.status === 'PENDING'
                  ? 'Asignado'
                  : x?.status === 'IN_REVIEW' 
                  ? 'Pendiente por revisar'
                  : x?.status === 'QUALIFIED'
                  ? 'Calificado'
                  : x?.status === 'APPROVED' 
                  ? 'Aprobado'
                  :x?.status === 'REPROVED'
                  ? 'Reprobado'
                  : 'Cancelada'
                }
              </td>
              
              <td className='text-center align-middle'><span className='poppins-light fw-bold'>{moment(x?.createdAt).format('DD/MM/YYYY')}</span></td>
              <td className='text-center align-middle'
                onClick={(e)=>{
                  e.stopPropagation() //para evitar el onClick del TR
                }}
              >
                <button className={`btn btn-${x.needsContact? (x.hasBeenContacted? "success" : "warning") : "danger"} fs-7 p-2`} type='button' 
                  onClick={()=>{
                    setStudent(x?.student_setting)
                    setShowModalContact(true)
                    setContactInfo({
                      needsContact: x.needsContact,
                      hasBeenContacted: x.hasBeenContacted,
                      contactedBy: x.contactedBy,
                      taskId: x.id,
                      taskStatus: x.status
                    })
                  }}>
                  Contact
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {(student && contactInfo)&& 
        <ContactStudentModal 
          show={showModalContact} 
          setShow={setShowModalContact}
          student={student} 
          contactInfo={contactInfo}
        />
      }
    </>
  )
}

export default QualifyWelcomeDayForm
