import React, { useRef } from 'react'
import {HeaderBook} from '../header'
import {ToolbarBook} from '../toolbar'
import {RenderBook} from '../render-book'
import {CurrentPage, UseBookTestModule} from '../../modules/test/store/main'
import {RenderItemExercise} from '../interactions/RenderItemExercise'
import {UseBookContainerModule} from '../../store/main'
import { useLocation } from 'react-router-dom';

type Props = {}

const ContainerBookTest: React.FC<Props> = () => {
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    lessonData,
    currentsItemsTest,
    statePage,
    currentPage,
    setStatePage,
    setCurrentsItemsTest,
    setCurrentPage,
    setDeleteCurrentItem,
    resetCurrentItems,
  } = UseBookTestModule()

  const {toolsFullscreen} = UseBookContainerModule()

  React.useEffect(() => {
    setCurrentPage(1)
  }, [])

  React.useEffect(() => {
    resetCurrentItems();

    window.addEventListener('beforeunload', resetCurrentItems);
    window.addEventListener('popstate', resetCurrentItems);
    window.addEventListener('hashchange', resetCurrentItems);

    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-' || event.key === '0')) {
        event.preventDefault(); // Previene el zoom con Ctrl + (+/-/0)
      }
    };

    const handleWheel = (event: WheelEvent) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault(); // Previene el zoom con Ctrl + Scroll
      }
    };

    const handleTouchStart = (event: TouchEvent) => {
      // Aquí puedes manejar eventos táctiles si es necesario
      if (event.touches.length > 1) {
        event.preventDefault(); // Previene el zoom con gestos multitáctiles
      }
    };

    const handleTouchMove = (event: TouchEvent) => {
      // Aquí puedes manejar el movimiento táctil si es necesario
      if (event.touches.length > 1) {
        event.preventDefault(); // Previene el zoom con gestos multitáctiles
      }
    };

    window.addEventListener('keydown', handleKeyDown, { passive: false });

    if (containerRef.current) {
      containerRef.current.addEventListener('wheel', handleWheel, { passive: false });
      containerRef.current.addEventListener('touchstart', handleTouchStart, { passive: false });
      containerRef.current.addEventListener('touchmove', handleTouchMove, { passive: false });
    }

    return () => {
      window.removeEventListener('beforeunload', resetCurrentItems);
      window.removeEventListener('popstate', resetCurrentItems);
      window.removeEventListener('hashchange', resetCurrentItems);
      window.removeEventListener('keydown', handleKeyDown);
      if (containerRef.current) {
        containerRef.current.removeEventListener('wheel', handleWheel);
      }
    };
    
  }, [location]);


  return (
    <div ref={containerRef}>
      {!toolsFullscreen && (
        <HeaderBook
          mode='ADMIN'
          logoCourse={lessonData ? lessonData.chapter.path.logo.location : ''}
          colorCourse={lessonData ? lessonData.chapter.path.colorBase : ''}
          lessonName={lessonData ? lessonData.name : 'Michigan Master'}
        />
      )}
      <div className='d-none d-lg-flex'>
        <RenderBook
          numberPage={currentPage}
          numberRenderPage={statePage}
          pagesBook={
            lessonData?.book_pages ? lessonData.book_pages.sort((a, b) => a.page - b.page) : []
          }
        />
      </div>
      <div className='d-flex d-lg-none'>
        <RenderBook
          numberPage={currentPage}
          numberRenderPage={1}
          pagesBook={
            lessonData?.book_pages ? lessonData.book_pages.sort((a, b) => a.page - b.page) : []
          }
        />
      </div>
      <ToolbarBook
        onChangeStatePage={(e) => setStatePage(e ? 2 : 1)}
        statePage={statePage === 1 ? false : true}
        currentPage={currentPage}
        setCurrentPage={(e: CurrentPage) => setCurrentPage(e)}
        mode='ADMIN'
      />
      <RenderItemExercise
        typeUser='ADMIN'
        exercisesLesson={lessonData?.evaluation?.evaluation_item ?? []}
        currentExercises={currentsItemsTest}
        onDeleteExercise={(e) => setDeleteCurrentItem(e)}
        setExercise={(e) => setCurrentsItemsTest(e)}
        addClickedInteractionsId={(e) => {}}
      />
      {/* <TopicsEbookDrawer
      courseId={lessonData ? lessonData.chapter.path.id : ''}
      mode={'ADMIN'}
      /> */}
    </div>
  )
}

export {ContainerBookTest}
