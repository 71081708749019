import useAuth from "../../../../../../api/main"
import { showSimpleAlert } from "../../../../../../commonHooks/alert"
import { api } from "../../../../../auth/services/auth.services"
import { TaskType } from "../../../../tasks/interfaces"
import { UseQualifyPlacementModule } from "../store/main"

const { token, logout, authChecked } = useAuth.getState()


export const UseQualifyPlacementAllPaginate = async (type: TaskType, before?: Date, after?: Date): Promise<any> => {
    try {
      const { setPaginator, limit, currentPage, where, isActive, filterType } = UseQualifyPlacementModule.getState()

      let url =   `/tasks/paginated?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}&type=${type}&filterType=${filterType}`
      if( before && after){
        url += `&before=${before}&after=${after}`
      }

      const response: any = await api.get(url,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setPaginator(response.data)
      return response.data
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      if (e.response.status === 400) {
        if (e.response.data.message) {
          e.response.data.message.map((x)=>{
            //@ts-ignore
            showSimpleAlert({ message: x, icon: 'error' })
          })
          throw 'Error'
        } else {
          showSimpleAlert({message: e.response.data.message??'Error', icon: 'error'});
        }
      }
      //@ts-ignore
      showSimpleAlert({ message: 'Error!', icon: 'error' })
      throw 'Error'
    }
  };

export const FinishPlacementTestUseCase = async (id: string) => {
  try {
  //   // await authChecked()
    const response: any = await api.patch(
      `/tasks/finish-placement/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      if (e.response.data.message) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      } else {
        showSimpleAlert({message: e.response.data.message??'Error', icon: 'error'});
      }
      }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
};