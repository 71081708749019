import React from "react";
import YouTube from "react-youtube";

const YouTubeLinkExtractor = ({ text }) => {
  // Expresión regular para detectar enlaces de YouTube
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g;

  // Función para extraer los IDs de video de YouTube
  const extractYouTubeVideoIds = (text) => {
    const matches = [...text.matchAll(youtubeRegex)];
    // Extrae solo los IDs de los videos
    const videoIds = matches.map((match) => match[1]);
    return videoIds;
  };

  // Extraer los IDs de video del texto proporcionado
  const videoIds = extractYouTubeVideoIds(text);

  return (
    <div>
 
      {videoIds.length === 0 ? (
        <p className="poppins-light">{text}</p>
      ) : (
        videoIds.map((videoId, index) => (
          <YouTube key={index} videoId={videoId} />
        ))
      )}
    </div>
  );
};

export default YouTubeLinkExtractor;