import React, { useEffect, useState } from 'react'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { CreateModal } from '../modal'
import { useQueryClient } from '@tanstack/react-query'
import { useAppointmentState } from '../../hooks/useAppointmentRepo'
import { UseAppointmentModule } from '../../store/main'
import { Appointment, AppointmentStatus, DataAppointment } from '../../interfaces'
import { BooleanCell } from '../../../../../components/common/BooleanCell'
import * as Swal from 'sweetalert2'
import { AppointmentDelete } from '../../services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import GenericPaginator from '../../../../../components/common/Paginator'
import supabase from '../../../../../supabase/supabase';
import { Country } from '../../../country/interfaces'
import { UseCountryAll } from '../../../country/services'
import { ButtonSelector } from '../../../../../components/common/ButtonSelector'
import { DayPickerRangeSelector } from '../../../../../components/common/DayRangePicker/indext'
import moment from 'moment'
import { Campus } from '../../../../abroad/campus/interfaces'
import { UseCampusAll } from '../../../../abroad/campus/services'
import { UseBlockHourAll } from '../../../../abroad/block-hours/services'
import { BlockHour } from '../../../../abroad/block-hours/interfaces'
import { UseUserTypePaginate } from '../../../user/services'
import { Classroom } from '../../../classroom/interfaces'
import { UseClassroomAll } from '../../../classroom/services'
import { UseActivityAll } from '../../../../abroad/activity/services'
import { CreateModalTrailClass } from '../modal/createTrailClassModal'
import { t } from 'i18next'
type PaginatorSetter = {
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
}
export interface DateRange {
  before: Date
  after: Date
}
type Props = {

}
const AppointmentTableList: React.FC<Props> = ({ }) => {
  const [dataBlockHour, setDataBlockHour] = React.useState<BlockHour[]>([]);
  const [teacherData, setTeacherData] = React.useState<any[]>([]);
  const [classroomData, setClassroomData] = React.useState<Classroom[]>([]);
  const [activityData, setActivityData] = React.useState<any[]>([]);
  const [campusData, setCampusData] = React.useState<Campus[]>([]);

  const queryClient = useQueryClient()
  const {
    paginator,
    modalTrailClass,
    typeClassroom,
    blockHour,
    campus,
    classroom,
    modal,
    limit,
    currentPage,
    isActive,
    where,
    teacher,
    after,
    before,
    withStudents,
    setCurrentPage,
    setLimit,
    setIsActive,
    setSelectedToEdit,
    setSelectedToView,
    setModal,
    setActivity,
    activity,
    setWhere,
    setToAdd,
    setTeacher,
    setDate,
    setClassroom,
    setBlockHour,
    setTypeClassroom,
    setCampus,
    setWithStudents,
    setAddTrailClassInAppointment,
  } = UseAppointmentModule()
  const { data } = useAppointmentState();
  const [dateRange, setDateRange] = React.useState<DateRange>({
    after: after,
    before: before
  })
  let [page, setPage] = React.useState<PaginatorSetter>({
    currentPage: 1,
    items: 0,
    total: 0,
    totalPage: 0
  });
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const deleteItem = async (id: string) => {
    //@ts-ignore
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await AppointmentDelete(id)
        } catch (e) {

        }
      }
    })
  }
  const changeDate = (date: DateRange) => {
    setDate(date)
  };
  const changePage = (number: number) => {
    setCurrentPage(number)
  };
  const changeLimit = (number: number) => {
    setLimit(number)
  }

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        await queryClient.invalidateQueries({ queryKey: ['appointment'] })
      } catch (error) {
        console.error("Error invalidating queries:", error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [
    isActive,
    where,
    currentPage,
    limit,
    after,
    before,
    teacher,
    activity,
    classroom,
    typeClassroom,
    blockHour,
    campus,
    withStudents
  ])

  const handleChangeBlockHour = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBlockHour(parseInt(e.target.value));
  };

  const handleChangeTeacher = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTeacher(e.target.value);
  };

  const handleChangeActivity = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setActivity(e.target.value);
  };

  const handleChangeClassroom = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setClassroom(e.target.value);
  };

  const handleChangeTypeClassroom = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTypeClassroom(e.target.value);
  };
  const handleChangeCampus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCampus(e.target.value);
  };

  const handleChangeWithStudents = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setWithStudents(e.target.value as "YES"| "NO" |"");
  };

  const fetchBlocksHours = async () => {
    try {
      const response = await UseBlockHourAll();
      setDataBlockHour(response);
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar bloques horarios!', icon: 'error' })
      throw 'Error'
    }
  };

  const fetchTeacher = async () => {
    try {
      let teacher = await UseUserTypePaginate('TEACHER');
      setTeacherData(teacher)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar profesores!', icon: 'error' })
      throw 'Error'
    }
  };

  const fetchClassroom = async () => {
    try {
      let classroomResponse = await UseClassroomAll();
      setClassroomData(classroomResponse)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar salones!', icon: 'error' })
      throw 'Error'
    }
  };

  const fetchAllActivity = async () => {
    try {
      let classroomResponse = await UseActivityAll();
      setActivityData(classroomResponse)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar actividades!', icon: 'error' })
      throw 'Error'
    }
  };

  const fetchAllCampus = async () => {
    try {
      let response = await UseCampusAll();
      setCampusData(response);
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar actividades!', icon: 'error' })
      throw 'Error'
    }
  };
  const colorBackground=(data:DataAppointment)=>{

    // if(moment(data.start).isSame(moment(), 'day')){
    //   return 'bg-secondary';
    // }
    if( data.status_appointment ==='TO_BE_CLOSED'){
      return 'bg-dark text-white';
    }
    if( data.status_appointment ==='EMPTY_CLASS'){
      return 'bg-light-warning text-dark';
    }
    if( data.status_appointment ==='FINISHED'){
      return 'bg-light-success';
    }
    if(!data.trail_class?.length  ){
        return 'bg-light-danger';
    }
    if(data.trail_class?.length && data.trail_class?.length>0  && data.status_appointment ==='PENDING'){
      return 'bg-light-info';
    }
    if(data.trail_class?.length && data.trail_class?.length>0  && data.status_appointment ==='IN_PROCESS'){
      return 'bg-light-secondary';
    }
  }

  React.useEffect(() => {
      fetchBlocksHours();
      fetchTeacher();
      fetchClassroom();
      fetchAllActivity();
      fetchAllCampus();
  }, []);

  return (<>
    <div className={`card `}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Clases</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Gestión de Clases</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
        </div>

        <a
          href='/transfer-student'
          className={`nav-link btn btn-sm btn-active btn-active-light-primary fw-bold px-4 me-1`}
        >
          <span className='text-primary' style={{ paddingLeft: '5px' }}>Transferir Estudiantes</span>
        </a>

        <a
          href='/transfer-class'
          className={`nav-link btn btn-sm btn-active btn-active-light-primary fw-bold px-4 me-1`}
        >
          <span className='text-primary' style={{ paddingLeft: '5px' }}>Transferir Profesores</span>
        </a>

        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >

          {/* <div className='position-relative my-1'>
            <KTSVG
              path='/media/svg/icons/earth.svg'
              className='svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-10'
            />
            <input
              type='text'
              onChange={(e) => setWhere(e.target.value)}
              className='form-control form-control-sm form-control-solid w-350px ps-10'
              name='Search'
              value={where}
              placeholder='Search'
            />
          </div> */}
          <button
            onClick={() => setToAdd()}
            className='btn btn-sm btn-light-primary mr-8'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='plus' className='fs-3' />
          </button>
          <button
            onClick={() => setIsActive(!isActive)}
            className='btn btn-sm btn-light-secondary  mr-8 p-2'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
            <BooleanCell value={isActive} />
          </button>
        </div>
      </div>
      {/* end::Header */}

      <div className='py-3 row'>
        {/* begin::Filters */}
        <div className='mb-5 d-flex align-items-center overflow-x-auto px-4'>
          <div className='col-auto d-flex align-items-center mx-3'>
            <label className='form-label mb-0 me-2'>Actividades</label>
            <div style={{display: 'inline-block', width: 'auto', minWidth: '100px', maxWidth: '200px'}}>
              <select
                name='activity'
                value={activity}
                onChange={handleChangeActivity}
                className='form-select form-select-sm'
                aria-label='Default select example'
                style={{ width: '100%' }}
              >
                <option value={undefined}>
                  {' '}
                </option>
                {activityData?.map((x) => (
                  <option key={x.id} value={x.id}>
                    {`${x.name}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='col-auto d-flex align-items-center mx-3'>
            <label className='form-label mb-0 me-2'>Profesor</label>
            <div style={{display: 'inline-block', width: 'auto', minWidth: '100px', maxWidth: '200px'}}>
              <select
                name='teacher'
                value={teacher}
                onChange={handleChangeTeacher}
                className='form-select form-select-sm'
                aria-label='Default select example'
              >
                <option value={undefined}>
                  {' '}
                </option>
                {teacherData?.map((x) => (
                  <option key={x.id} value={x.id}>
                    {`${x.fullName}` || `${x.firstName.toUpperCase()} ${x.lastName.toUpperCase()}`}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-auto d-flex align-items-center mx-3">
            <label className='form-label mb-0 me-2'>Salón</label>
            <div style={{display: 'inline-block', width: 'auto', minWidth: '100px', maxWidth: '200px'}}>
              <select
                name='classroom'
                value={classroom}
                onChange={handleChangeClassroom}
                className='form-select form-select-sm'
                aria-label='Default select example'
              >
                <option value={undefined}>
                  {' '}
                </option>
                {classroomData?.map((x) => (
                  <option key={x.id} value={x.id}>
                    {`${x.name}`}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-auto d-flex align-items-center mx-3">
            <label className='form-label mb-0 me-2'>Tipo salon</label>
            <div style={{display: 'inline-block', width: 'auto', minWidth: '100px', maxWidth: '200px'}}>
              <select
                name={'typeClassroom'}
                value={typeClassroom}
                onChange={handleChangeTypeClassroom}
                className="form-select form-select-sm"
                aria-label="Default select example"
              >
                <option value={undefined}></option>
                <option value={'VIRTUAL'}>Virtual</option>
                <option value={'FACE'}>Presencial</option>
              </select>
            </div>
          </div>

          <div className="col-auto d-flex align-items-center mx-3">
            <label className='form-label mb-0 me-2'>Bloque horario</label>
            <div style={{display: 'inline-block', width: 'auto', minWidth: '100px', maxWidth: '200px'}}>
              <select
                name='dataBlockHour'
                value={blockHour}
                onChange={handleChangeBlockHour}
                className='form-select form-select-sm pe-0'
                aria-label='Default select example'
              >
                <option value={undefined}>
                  {' '}
                </option>
                {dataBlockHour?.map((x) => (
                  <option key={x.id} value={x.order}>
                    {`${x.start} ${x.end}`}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-auto d-flex align-items-center mx-3">
            <label className='form-label mb-0 me-2'>Con estudiantes</label>
            <select
              name='withStudents'
              value={withStudents}
              onChange={handleChangeWithStudents}
              className='form-select form-select-sm pe-0'
              aria-label='Default select example'
            >
              <option value={undefined}>
                {' '}
              </option>
              <option value={"YES"}>Si</option>
              <option value={"NO"}>No</option>
            </select>
          </div>

          <div className="col-auto d-flex align-items-center mx-3">
            <label className='form-label mb-0 me-2'>Sede</label>
            <select
              name='campus'
              value={campus}
              onChange={handleChangeCampus}
              className='form-select form-select-sm'
              aria-label='Default select example'
            >
                <option value={undefined}>
                {' '}
              </option>
              {campusData?.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* end::Filters */}
      </div>

      {/* begin::Body */}
      <div className='py-3 row'>
        {/* begin::Table container */}
        <div className='mb-10 col-3'>
          <DayPickerRangeSelector
            after={after}
            before={before}
            changeDate={(e) => setDate({
              after: e.after,
              before: e.before
            })}
          />
        </div>
        <div className='mb-10 col-9'>
          <div className='table-responsive'>
            {isLoading? 
              <span className='indicator-progress d-flex justify-content-center  align-items-center ms-5'>
                <span className='spinner-border spinner-border-lg align-middle text-primary'/> 
              </span> 
            :  data && 
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted  text-start'>
                    <th className='min-w-140px text-start'>Actividad</th>
                    <th className='min-w-140px text-start'>Salon</th>
                    <th className='min-w-120px text-start'>Fecha Hora</th>
                    <th className='min-w-120px text-start'>Url</th>
                    <th className='min-w-120px text-start'>Leads</th>
                    <th className='min-w-120px  text-start'>Active</th>
                    <th className='min-w-50px text-start'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.result.map((x: DataAppointment) => (
                    <tr key={x.id}
                      className={`p-0
                        ${colorBackground(x)} rounded`}
                    >
                      <td className='p-0'>
                        <div className='d-flex align-items-center'>
                          {/* <div className='symbol symbol-45px me-5'>
                          <img src={toAbsoluteUrl('/media/svg/icons/earth.svg')} alt='' />
                        </div> */}
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className=' fw-bold text-hover-primary fs-6'>
                              {x?.activity.name}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {x.teacher.fullName}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='p-0'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='fw-bold text-hover-primary fs-6'>
                              {x.classroom.name}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {x?.classroom.type} - {t(x.status_appointment)}
                              {/* <strong>{x.level && x.level.country && x.level.country.name}</strong>  */}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='p-0'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='fw-bold text-hover-primary fs-6'>
                              {x.start}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {x?.blockHour?.map((hour) => `${hour.start} - ${hour.end}`).join(', ')}
                              {/* {x.event && x.event.time && moment(x.event.time.start).format('YYYY-MM-DD')}
                          &nbsp;
                          {x.hour_start &&  x.hour_start} -
                          {x.hour_finish &&  x.hour_finish} */}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='text-start'>
                        {x.classroom && <a
                          href={x.classroom.url}
                          target='blank'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i style={{ color: 'green', fontSize: 17 }} className="fas fa-link"></i>
                        </a>}
                      </td>
                      <td className='text-start'>
                        {x.trail_class ? x.trail_class.length : 0} / {x.activity ? x.activity?.limit : 0} 
                       
                      </td>
                      <td className='text-start'>
                        <BooleanCell value={x.isActive} />
                      </td>
                      <td>
                        <div className='d-flex justify-content-start flex-shrink-0'>
                          <button
                            onClick={() => setSelectedToView(x)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i style={{ color: 'grey', fontSize: 17 }} className="fas fa-eye"></i>
                          </button>
                          {x.trail_class && x.trail_class.length<1  && <button
                            onClick={() => setSelectedToEdit(x)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i style={{ color: 'blue', fontSize: 17 }} className="fas fa-pen"></i>
                          </button>}
                          {x.trail_class && x.trail_class.length<1 && <button
                            onClick={() => deleteItem(x.id ?? 'xxx')}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          >
                            <i style={{ color: 'red', fontSize: 17 }} className="fas fa-trash-alt"></i>
                          </button>}
                          {x && <a
                            href={'/booking-dashboard/' + x.id}
                            target='blank'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i style={{ color: 'green', fontSize: 17 }} className="fas fa-play"></i>
                          </a>}
                          {x.status_appointment === AppointmentStatus.PENDING &&
                            <button
                              onClick={() => setAddTrailClassInAppointment(x)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <i style={{ color: 'blue', fontSize: 17 }} className="fa-solid fa-plus"></i>
                            </button>
                          }
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody> 
            
              </table>    
            }
          </div>
          <GenericPaginator
            limit={limit}
            currentPage={paginator.current_page}
            items={paginator.result.length}
            total={paginator.total}
            totalPage={paginator.total_page}
            changePage={changePage}
            changeLimit={changeLimit}
          />
        </div>
      </div>
    </div>
    <CreateModal show={modal} handleClose={() => alert()} />
    <CreateModalTrailClass show={modalTrailClass} />
  </>
  )
}

export { AppointmentTableList }
