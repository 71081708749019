import React, { useEffect } from 'react';
import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { QuestionHistoryWriting } from '../interfaces';


type Props = {
    questions:QuestionHistoryWriting
};

const HistoryWritingItem: React.FC<Props> = ({ questions }: Props) => {

    return (
        <div className='row p-5'>  

            <div className='col my-auto me-4' style={{color:'white'}}>
                <h2 className='champ-light text-white'>{questions.name}</h2>
            </div>
            
            {questions.mode==='IMG' && questions.items.map((x) => (<div  
                className='col'
                >
                <img src={x.title} width={80} height={80} style={
                    x.isTrue?{borderRadius:30}:{borderRadius:30}
                    
                    } className=" cursor-pointer" alt="Cinque Terre" />
            </div>))}
            
            {questions.mode==='WORD'&& questions.items.map((x, i) => (<h2
                key={i}
                className='champ-light option-basic-vocabulary' >
                    <li className="" style={{color:'white',fontSize:20}}>{x.title}</li>
            </h2>))}
        </div>
    );
};

export { HistoryWritingItem };