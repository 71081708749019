import React, { useEffect, useState } from 'react';
import './index.css';
import { CheckWritingQuestionIE, CheckWritingQuestionAnswerIE } from '../interfaces';
import CheckWritingHeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/CheckWritingHeaderCard';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { AddCheckWrittingResponse } from '../services';
import { UseCheckWritingModule } from '../store';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';


type Props = {
    userMode?: ModeTypeExercise
    question: CheckWritingQuestionIE[];
    responses?: CheckWritingQuestionAnswerIE[];
};
const divStyle = {
    backgroundColor: '#3f8bff',
    minWidth: '400px',
    minHeight: '400px',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    padding: '27px 10px 40px 20px'
};

export interface ThemeAnswer {
    title: string
    order: number
    text?: string
}

const CheckWritingSingleExItem: React.FC<Props> = ({
    userMode,
    question,
    responses
}: Props) => {
    const [answers, setAnswers] = useState<CheckWritingQuestionAnswerIE[]>([]);

    const {
        lessonData,
        stepBookItem,
        currentTaskId,
        setStepBookItem,
        addCompletedExerciseId,
        onChangeTaskByAnswer,
        setIsLoadingSubmit
    } = UseBookStudentModule();
    const { exerciseId, instructions } = UseCheckWritingModule();

    useEffect(() => {
        if (responses) {
            setAnswers(responses.map(x => ({
                ...x
            })));
        } else {
            setAnswers(question.map(q => ({
                title: q.title,
                text: '',
                order: q.order,
                isTrue: false
            })));
        }
    }, [question, responses]);

    const handleInputChange = (title: string, value: string, isTrue: boolean) => {
        const existingAnswer = answers.find(answer => answer.title === title && answer.text === value);

        if (existingAnswer) {
            setAnswers(answers.filter(answer => !(answer.title === title && answer.text === value)));
        } else {
            setAnswers([...answers, { title, text: value, isTrue, order: 0 }]);
        }
    };

    const handleSubmit = async () => {
        if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
            try {
                setIsLoadingSubmit(true)
                const response = await AddCheckWrittingResponse({
                    isActive: true,
                    isReviewed: false,
                    isPending: false,
                    answer: answers,
                    task: currentTaskId ?? '',
                    exercises: exerciseId,
                    percentage: 100 /*score ?? 0*/, // se manda 100% porque es un ejercicio que no tiene respuestas validas
                    answerThemes: []
                });
                onChangeTaskByAnswer(response, 'check_writing');
            } catch (e) {
                showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' })
            } finally {
                setIsLoadingSubmit(false)
            }
        }
        if (lessonData && lessonData.evaluation &&
            lessonData.evaluation.evaluation_item) {
            addCompletedExerciseId(exerciseId ?? "")
            if (lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
                setStepBookItem(exerciseId ?? '')
            }
            else {
                setStepBookItem('FINISH_LESSON')
            }
        }
    }
    return (
        <>
            <div className="container-fluid" style={{ display: 'flex' }}>
                <div style={divStyle}>
                    <CheckWritingHeaderCard />
                    <div className='scroll-y overflow-y-auto mb-2 px-3' style={{ maxHeight: '70px', maxWidth: '435px' }}>
                        <span className=" text-white  mb-0 poppins-light">{instructions}</span><br />
                    </div>
                    <div className="container">
                        <div className="row py-2">
                            <div className='col-12 '>
                                <div className='row scrollable-div mb-2' style={{ maxHeight: '350px', maxWidth: '435px' }}>
                                    {question.map((q, questionIndex) => (
                                        <div className='col-12' key={q.title}>
                                            <div>
                                                <p className="champ-bold mb-0 text-white" style={{ fontSize: 17 }}>{q.title}</p>
                                                {q.options.map((option, optionIndex) => (
                                                    <div className='d-flex align-items-start mb-2' key={option.title}>
                                                        <input
                                                            checked={!!answers.find(answer => answer.title === q.title && answer.text === option.title)}
                                                            type='checkbox'
                                                            className='round-checkbox'
                                                            onChange={() => handleInputChange(q.title, option.title, option.isTrue)}
                                                        />
                                                        <span
                                                            className="poppins-light text-white ms-2"
                                                            style={{ fontSize: 15 }}
                                                        >
                                                            {option.title}
                                                        </span><br />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end align-items-end'>
                            <ButtonSaveExercise
                                isChecked={true}
                                onNext={() => { }}
                                onSubmit={handleSubmit}
                                exerciseId={exerciseId}
                            />
                        </div>
                    </div>
                </div>
                <div className='position-absolute bottom-0 start-0'
                    style={{
                        marginLeft: '24px',
                        marginBottom: '12px'
                    }}
                >
                </div>
                
            </div>
        </>
    );
};

export { CheckWritingSingleExItem };