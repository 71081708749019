import React, { useRef } from "react";
import { UseBookingWindows } from "./store";
import CalendarSelector from "./components/calendar-selector";
import BookingButton from "./components/clas-type-selector";
import { useThemeMode } from "../../../_metronic/partials";
import { StartBooking } from "./components/start-booking";
import { UseMyRoadmapModule } from "../profile/components/my-road-step/store";
import ModalityBookingButton from "./components/modality-selector";
interface Props {

}

const BookingWindows: React.FC<Props> = ({ }) => {
    const {mode} = useThemeMode()
    const {settings, isLoading} = UseMyRoadmapModule();
    let { setStep, step,  } = UseBookingWindows()
    
    React.useEffect(() => {
        const handleBeforeUnload = () => {
            setStep(0);
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handleBeforeUnload);
        window.addEventListener('hashchange', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handleBeforeUnload);
            window.removeEventListener('hashchange', handleBeforeUnload);
        };
    }, [setStep]);
    return (
        <div
            className="col-12 h-100"
            style={
                mode==='light'?{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    border: settings && settings?.isSuspense ? '': '2px solid #2d30e1', // Added border style
                    borderRadius: 50,
                    minHeight: '280px',
                    maxHeight: '280px',
                    overflow: 'hidden',
                    paddingTop:0,
                    backgroundColor: 
                        settings && settings?.isSuspense ? '#ffc3d3': 
                        settings?.isFrozen ? '#3e94ff' :
                        ''
                }:{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor:'#14141a',
                    flexDirection: 'column',
                    borderRadius: 50,
                    maxHeight: '280px',
                    overflow: 'hidden',
                    paddingTop:0
                }
            }
        >
        {isLoading ? 
            <div className="d-flex justify-content-center align-items-center mb-1 h-100">
                <span className="spinner-grow spinner-grow-lg mx-1"></span>
            </div> 
            :
            <>
                <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb">
                        {step>0 &&<li className="breadcrumb-item champ-light"><a href="#" onClick={()=>setStep(0)}>Back</a></li>}
                    </ol>
                </nav>
                {step === 0 && <StartBooking />}
                {step === 1 && <ModalityBookingButton />}
                {step === 2 && <BookingButton />}
                {step >=3 && <CalendarSelector />}
            </>
        }
        </div>
    );
};

export default BookingWindows;