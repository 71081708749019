import React from 'react'
import './index.css'
import {MovieWritingData} from '../store'

type Props = {
  movie: MovieWritingData
  handleChangeMovie: (value: MovieWritingData) => void
  name?: string
  description?: string
}

const MovieWritingLeftInfo: React.FC<Props> = ({
  handleChangeMovie,
  movie,
  name,
  description,
}: Props) => {
  return (
    <div className='d-flex flex-column h-100'>
      <div className='row my-5 text-white mt-0'>
        <div className='row text-center'>
          <div className='col-6'>
            <label className='poppins-light'>Title of the movie:</label>
          </div>
          <div className='col-6'>
            <input
              type='text'
              className='form-control poppins-light'
              style={inputStyles}
              aria-describedby='passwordHelpInline'
              value={movie.title}
              onChange={(e) =>
                handleChangeMovie({
                  ...movie,
                  title: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className='row text-center'>
          <div className='col-6'>
            <label>Genre:</label>
          </div>
          <div className='col-6'>
            <input
              type='text'
              className='form-control poppins-light'
              style={inputStyles}
              aria-describedby='passwordHelpInline'
              value={movie.genre}
              onChange={(e) =>
                handleChangeMovie({
                  ...movie,
                  genre: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className='row text-center'>
          <div className='col-6'>
            <label className='poppins-light'> Duration:</label>
          </div>
          <div className='col-6'>
            <input
              type='text'
              className='form-control poppins-light'
              style={inputStyles}
              aria-describedby='passwordHelpInline'
              value={movie.duration}
              onChange={(e) =>
                handleChangeMovie({
                  ...movie,
                  duration: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className='row text-center'>
          <div className='col-6'>
            <label className='poppins-light'>Main characters:</label>
          </div>
          <div className='col-6'>
            <input
              type='text'
              className='form-control poppins-light'
              style={inputStyles}
              aria-describedby='passwordHelpInline'
              value={movie.mainCharter}
              onChange={(e) =>
                handleChangeMovie({
                  ...movie,
                  mainCharter: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className='row text-center'>
          <div className='col-6'>
            <label className='poppins-light'>Rating:</label>
          </div>
          <div className='col-6'>
            <input
              type='number'
              className='form-control poppins-light'
              aria-describedby='passwordHelpInline'
              value={movie.rating}
              min={0}
              max={5}
              onChange={(e) => {
                let value: number = parseInt(e.target.value)
                if (value < 0) {
                  value = 0
                } else if (value > 5) {
                  value = 5
                }
                handleChangeMovie({
                  ...movie,
                  rating: value,
                })
              }}
              style={inputStyles}
            />
          </div>
        </div>
      </div>
      <div className='row mt-auto overflow-y-auto'>
        <h3 className='poppins-bold text-white' style={{fontSize: 15, textAlign: 'center'}}>
          {name ?? ''}
        </h3>
        <p className='poppins-light text-gray' style={{fontSize: 13, textAlign: 'center'}}>
          {description ?? ''}
        </p>
      </div>
    </div>
  )
}
const inputStyles = {
  marginTop: 3,
  width: '80%',
  backgroundColor: 'rgba(0,0,0,0.05)',
  borderBottom: '1px solid white',
  borderTop: 'none',
  borderLeft: 'none', 
  borderRight: 'none',
  color: 'white',
  height: 10,
  boxShadow: 'none',
}
export {MovieWritingLeftInfo}
