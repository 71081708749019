import {Route, Routes} from 'react-router-dom'
import { CourtesyCodeDetails } from '../components/details'
import { CourtesyCodeTableList } from '../components/list'


const CourtesyCodePage = () => (
  <Routes>
      <Route path='/details' element={<CourtesyCodeDetails />} />
      <Route path='/' element={<CourtesyCodeTableList />} />
  </Routes>
)

export {CourtesyCodePage}
