import { create } from 'zustand';

export type ModeSpeakOut = 'SPEAK_OUT' | 'WRITE_DOWN';

export interface SpeakOutAnswer {
  title: string
  description: string
}
interface SpeakOutInterface {
  studentAnswer?: SpeakOutAnswer;
  title: string;
  instructions: string;
  limit: number;
  min: number;
  tips:string[];
  mode?:ModeSpeakOut;
  exerciseId?: string;
  startQuiz: () => void;
  resetData: () => void;
  setStudentAnswer: (value: SpeakOutAnswer)=>void;
  setTip:(exerciseId: string, 
    value: string[], 
    mode: ModeSpeakOut, 
    instructions: string, 
    limit: number, 
    min: number)=>void;
}

export const UseSpeakOutModule = create<SpeakOutInterface>((set) => ({
  status: 'TITLE',
  title: "Este es un titulo de quiz",
  instructions: "",
  questions: '',
  tips: [],
  step: 0,
  trueAnswer: [],
  falseAnswer: [],
  list: [],
  limit: 200,
  min: 1,
  total: 0,
  startQuiz: () => {},
  resetData: () => {},
  setTip:(exerciseId, value, mode, instructions, limit, min)=>{
    set({tips: value, exerciseId, mode, instructions, limit, min, studentAnswer: undefined});
  },
  setStudentAnswer:(value:SpeakOutAnswer)=>{
      set({
          studentAnswer: value
      })
  }
  
}));