import {create} from 'zustand';
import { StudentSetting } from '../../../../history-writing-response/interfaces';
import { TraditionalLevelEnum } from '../../../../../abroad/road-map-step/interfaces';

interface QualifyPlacementModules {
    itemSelected?: any;
    mode:'ADD'|'EDIT'|'VIEW';
    modal:boolean;
    isActive:boolean;
    paginator:any;
    where:string;
    currentPage:number;
    modalQualify: boolean;
    student?: StudentSetting;
    limit:number,
    total:number;
    filterType: FilterType;
    reload: boolean;
    levelRecommended: TraditionalLevelEnum;
    setStudent:(value: StudentSetting)=>void;
    setModalQualify:(value: boolean)=>void;
    setIsActive:(mode:boolean)=>void;
    setModal:(mode:boolean)=>void;
    setSelectedToEdit:(item:any)=>void;
    setSelectedToView:(item:any)=>void;
    resetData:()=>void;
    setPaginator:(paginator:any)=>void;
    setCurrentPage:(value:number)=>void;
    setLimit:(value:number)=>void;
    setWhere:(value:string)=>void;
    setToAdd:()=>void;
    setFilterType: (value: FilterType)=>void;
    setReload: (value: boolean)=>void;
    setLevelRecommended: (v: TraditionalLevelEnum) => void
}

export enum FilterType {
    NAME = 'name',
    DOCUMENT = 'document_id' ,
    CODE= 'code' ,
    LEVEL= 'level',
    STATUS = 'status',
    QUIZZES= 'quizzes'
}

export const UseQualifyPlacementModule = create<QualifyPlacementModules>( (set) => ({
    mode:'ADD',
    modal:false,
    currentPage:1,
    where:'',
    limit:10,
    isActive:true,
    total:0,
    modalQualify: false,
    filterType: FilterType.NAME,
    paginator:{
        result:[],
        total:0,
        limit:0,
        current_page:1,
        total_page:0
    },
    reload: true,
    levelRecommended: TraditionalLevelEnum.PRE_A1_1,
    setStudent:(value)=>{
        set({student: value})
    },
    setModalQualify:(value)=>{
        set({modalQualify: value, modal: !value});
    },
    setIsActive:(value:boolean)=>{
        set({isActive:value})
    },
    setModal:(mode)=>{
        set({modal:mode})
    },
    setToAdd:()=>{
        set({mode:'ADD',modal:true,itemSelected:undefined})
    },
    setSelectedToEdit:(item)=>{
        set({itemSelected:item,mode:'EDIT',modal:true})
    },
    setSelectedToView:(item)=>{
        set({itemSelected:item,mode:'VIEW',modal:true})
    },
    resetData:()=>{
        set({itemSelected:undefined,mode:'ADD',modal:false})
    },
    setPaginator:(pagination)=>{
        set({paginator:{
            result:pagination.result,
            total:pagination.total,
            limit:pagination.limit,
            current_page:pagination.current_page,
            total_page:pagination.total_page
        }})
    },
    setCurrentPage:(value)=>{
        set({currentPage:value})
    },
    setLimit:(value)=>{
        set({limit:value})
    },
    setWhere:(value)=>{
        set({where:value})
    },
    setFilterType:(value)=>{
        set({filterType: value})
    },
    setReload:(value)=>{
        set({reload: value})
    },
    setLevelRecommended: (value)=> {
        set({levelRecommended: value})
    }
}));