
import { useState, useRef } from 'react'
import { UseExecutiveSearch } from '../services'
import moment from 'moment';
import { t } from 'i18next';

interface User {
    fullName: string;
    phoneNumber: string;
    email: string;
}

interface StudentSetting {
    placementTest: string;
    welcomeDay: string;
}

interface Task {
    type: string;
    createdAt: string;
    status: string;
}

interface Result {
    user: User;
    student_setting: StudentSetting;
    firstClass: {
        hour: string,
        date: string,
        assistantStudent: boolean
    }
}

interface Response {
    result: Result;
    tasks: Task[];
}


type Props = {}
const ExecutiveSearchView = ({ }: Props) => {
    let [loading, setLoading] = useState<boolean>(false)
    let [where, setWhere] = useState<string>('');
    let [type, setType] = useState<string>('PHONE_NUMBER');
    let [user, setUser] = useState<Response>();
    let findUserState = async () => {
        setLoading(true)
        let user = await UseExecutiveSearch(type, where);
        setLoading(false)
        setUser(user)
    }

    return (<div>
        <div className="input-group mb-3">
            <h3 className='champ-bold' style={{ color: '#3e94ff' }}>Estatus de estudiantes </h3>
            <div className="input-group">
                <select onChange={(e) => setType(e.target.value)} className="form-select" id="inputGroupSelect04" aria-label="Example select with button addon">
                    <option value="PHONE_NUMBER">Teléfono</option>
                    <option value="DOCUMENT_NUMBER">Documento de identificación</option>
                    <option value="CONTRACT">Contrato</option>
                </select>
                <input type="text" onChange={(e) => setWhere(e.target.value)} className="form-control" aria-label="Text input with dropdown button" />

                {loading ?
                    <div className="spinner-border text-success" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    :
                    <button className="btn btn-primary" type="button"
                        onClick={() => findUserState()}
                    >
                        Buscar
                    </button>}
            </div>
        </div>
        {user ? <div className="card mb-3">
            <div className="row g-0">
                <div className="col-md-12">
                    <div className="card-body">
                        <h5 className="card-title">Nombre: {user.result.user.fullName}</h5>
                        <h5 className="card-title">Teléfono: {user.result.user.phoneNumber}</h5>
                        <h5 className="card-title">Email: {user.result.user.email}</h5>
                        <hr></hr>
                        <div className='row'>
                            <div className='col-4'>
                                <h6>Welcome Day {t(user.result.student_setting.welcomeDay)}</h6>
                            </div>
                            <div className='col-4'>
                                <h6>Placement Test {t(user.result.student_setting.placementTest)}</h6>
                            </div>
                            <div className='col-4'>
                                <h6>Primera clase {t(user.result.firstClass.assistantStudent?"asistida" :"no asistida")}</h6>
                            </div>
                        </div>
                        <hr></hr>
                        {user.result.firstClass ?
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className='champ-bold'>Primera clase?</th>
                                        <th scope="col" className='champ-bold'>Hora</th>
                                        <th scope="col" className='champ-bold'>Fecha</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className='poppins-bold  text-muted' scope="row">{t(user.result.firstClass.assistantStudent? "Asistido" : "No asistido")}</th>
                                        <td className='poppins-bold  text-muted'>{user.result.firstClass.hour}</td>
                                        <td className='poppins-bold  text-muted'>{moment(user.result.firstClass.date).format('DD-MM-YYYY')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        :
                            <h3 className='poppins-light'>No tiene ninguna clase programada</h3>
                        }
                        <hr></hr>
                        {user.result.firstClass ?
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className='champ-bold'>Evaluación</th>
                                        <th scope="col" className='champ-bold'>Estado</th>
                                        <th scope="col" className='champ-bold'>Fecha</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {user.tasks.map((x)=>(<tr>
                                        <th className='poppins-bold  text-muted' scope="row">{t(x.type)}</th>
                                        <td className='poppins-bold  text-muted'>{t(x.status)}</td>
                                        <td className='poppins-bold  text-muted'>{moment(x.createdAt).format('DD-MM-YYYY')}</td>
                                    </tr>))}
                                </tbody>
                            </table>
                        :
                            <h3 className='poppins-light'>No tiene tareas en su lista</h3>
                        }

                    </div>
                </div>
            </div>
        </div>:
        <h3 className='poppins-light'>No hay resultados que mostrar</h3>
        }
    </div>)
}

export { ExecutiveSearchView }
