import React from 'react';
import WritingDownHeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/WritingDownHeaderCard';
import { ModeSpeakOut} from '../store';
import { UseBookContainerModule } from '../../../../book/store/main';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';

type Props = {
  tips: string[];
  mode: ModeSpeakOut;
  instruction: string;
  error: string | null;
  description:string;
  onSubmit: () => void | Promise<void>;
  setDescription:(value: string)=>void;
  exerciseId: string;
  };
  
  const ExerciseTimeAndWriting: React.FC<Props> = ({
    tips, 
    mode, 
    instruction,
    error,
    onSubmit,
    description,
    setDescription,
    exerciseId
  }) => {
  const { setOffDraggable } = UseBookContainerModule()
  return(
    <div className='container-fluid d-flex '>
      <div style={divStyle}>
        <WritingDownHeaderCard typeExercise={mode} />
        <div>  
          <p className='poppins-light text-white'>{instruction} </p>
        </div> 
        <div className='row p-2'>
          <div className='col-5 d-flex flex-column custom-scrollable overflow-auto  ' style={{height:200}}>
            {tips && tips.map((x,i) => (
              <div className='mb-4 d-flex' key={i}>
                <div
                  className={`rounded-circle me-2`}
                  style={{
                    minWidth: '6px',
                    maxWidth: '6px',
                    minHeight: '6px',
                    maxHeight: '6px',
                    backgroundColor: '#d0ff00',
                    marginTop: '7px',
                  }}
                />
                <span className='poppins-light text-white'>{x}</span>
              </div>
            ))}
          </div>
          <div className='col-7 d-flex flex-column'>
            <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onMouseEnter={ () => setOffDraggable(true)}
                onMouseLeave={ () => setOffDraggable(false)}
                className='poppins-light'
                rows={6}
                style={{
                  borderRadius: '20px',
                  borderColor: 'white',
                  background:"#3f8bff",
                  color: "white",
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  fontSize: 15,
                  padding: '10px',
                  width: "100%",
                  height: "100%",
                  outline: 'none',
                  resize: 'none', // Opcional: Desactiva la capacidad de redimensionar el área de texto
                }}
                placeholder="Type here..."
            />
            {error && 
              <div className='text-secondary poppins-bold'>
                  {error}
              </div>
            }
          </div>
        </div>
        {(!error && description.length > 0) &&
          <div className='d-flex justify-content-end align-items-center me-2'>
            <ButtonSaveExercise
              isChecked={true}
              onNext={()=>{}}
              onSubmit={onSubmit}
              exerciseId={exerciseId}
            />
          </div>
        }
      </div>
    </div>
  )
};

export { ExerciseTimeAndWriting };

const divStyle = {
  backgroundColor: '#3f8bff',
  width: '625px',
  height: '400px',
  borderRadius: '30px',
  borderColor: 'rgb(255, 255, 255)',
  borderStyle: 'solid',
  padding: '27px 10px 40px 20px'
};