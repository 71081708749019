/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'

const mode = process.env.REACT_APP_THEME_DEMO;

const MegaMenu: FC = () => (
  <div className='row' data-kt-menu-dismiss='true'>
    <div className='col-lg-4 border-left-lg-1'>
      <div className='menu-inline menu-column menu-active-bg'>
        <div className='menu-item'>
          <a href='/template-table-time' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Plantilla</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/template-pusher' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Template pusher</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/faq' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Preguntas Frecuentes</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/support-ticket' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Soporte</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/appointment' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Control de clases</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/book-admin' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Libros</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/activity' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Actividades</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/qualify/placement-test' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Control de Placement Test</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/qualify/welcome-day' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Control de Welcome day</span>
          </a>
        </div>
      </div>
    </div>
    <div className='col-lg-4 border-left-lg-1'>
      <div className='menu-inline menu-column menu-active-bg'>
        <div className='menu-item'>
          <a href='/faq/category' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Categorias de Preg. Frecuentes</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/settings' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Parametros del sistema</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/advertisements' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Anuncios</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/classroom' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Salones</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/file-content' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Recursos</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/avatar' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Avatares</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/qualify/final-presentation' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Control de Final Presentation</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/placement' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Placement Test y Welcome</span>
          </a>
        </div>
        {mode === 'SALES' &&
          <div className='menu-item'>
            <a href='/courtesy-code' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>Accesos de cortesia</span>
            </a>
          </div>
        }
        {/*<div className='menu-item'>
          <a href='#' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Example link</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='#' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Example link</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='#' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Example link</span>
          </a>
        </div> */}
      </div>
    </div>

    <div className='col-lg-4 border-left-lg-1'>
      <div className='menu-inline menu-column menu-active-bg'>
        <div className='menu-item'>
          <a href='/survey-section' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Seccion de encuesta</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/survey-section-response' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Respuestas de encuestas</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/exercises' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Ejercicios</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/campus' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Campus</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/trail-suspense-reason' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Razones de Cancelamientos de Clase</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/country' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Paises</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/evaluation' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Historial de Evaluaciones</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/admin-suspense' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Historial de Cancelamientos</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='/qualify/quiz' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Control de Quiz</span>
          </a>
        </div>
      </div>
    </div>
    {/* <div className='col-lg-4 border-left-lg-1'>
      <div className='menu-inline menu-column menu-active-bg'>
        <div className='menu-item'>
          <a href='#' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Example link</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='#' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Example link</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='#' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Example link</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='#' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Example link</span>
          </a>
        </div>
        <div className='menu-item'>
          <a href='#' className='menu-link'>
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-title'>Example link</span>
          </a>
        </div>
      </div>
    </div> */}
  </div>
)

export { MegaMenu }
