import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik, } from 'formik'
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {User} from '../../interfaces'
import InputMask from 'react-input-mask';
import { UserAdd, UserUpdate } from '../../services'
import { UseUserModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { UseCountryAll } from '../../../country/services'
import { Country } from '../../../country/interfaces'
import { showSimpleAlert } from '../../../../../commonHooks/alert'

export type FormFields = {
  id?: string
  email: string
  phoneNumber: string
  fullName: string
  isActive: boolean
  type?: string
  avatar?: any 
  password?:string
  country?: string
  document_id?: string;
}
const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const entitySchema = Yup.object().shape({
  email: Yup.string()
  .email('Wrong email format')
  .min(3, 'Minimum 3 symbols')
  .max(50, 'Maximum 50 symbols')
  .required('Email is required'),
  isActive: Yup.boolean().required(' name is required'),
  fullName: Yup.string().required(' name is required'),
  phoneNumber: Yup.string()
  .matches(phoneRegExp, 'Formato de número de teléfono incorrecto')
  .required('El número de teléfono es obligatorio'),
  password: Yup.string()
  .min(3, 'Minimum 3 symbols')
  .max(50, 'Maximum 50 symbols')
  .required('Password is required'),
  country: Yup.string().required('País es requerido'),

})

const toUpdateSchema = Yup.object().shape({
  email: Yup.string()
  .email('Wrong email format')
  .min(3, 'Minimum 3 symbols')
  .max(50, 'Maximum 50 symbols')
  .required('Email is required'),
  isActive: Yup.boolean().required(' name is required'),
  fullName: Yup.string().required(' name is required'),
  phoneNumber: Yup.string()
  .matches(phoneRegExp, 'Formato de número de teléfono incorrecto')
  .required('El número de teléfono es obligatorio'),
  country: Yup.string().required('País es requerido'),

})

const CustomInput = props => (
  <InputMask {...props}>{inputProps => <input 
    style={{color:'black'}}
    {...inputProps} />}</InputMask>
);

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'|'CREDENTIALS';
  byStudent: boolean;
  setValuesUser?:(v: FormFields) => void;
}
const UserForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {itemSelected, mode,type, resetData} = UseUserModule();
  const [data, setData] = useState<User>()
  const [countries, setCountries] = React.useState<Country[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
   useFormik<FormFields>({
      initialValues: {
        email:  (mode==='EDIT' || mode==='VIEW')? itemSelected?.email??'': '',
        phoneNumber:  (mode==='EDIT' || mode==='VIEW')? itemSelected?.phoneNumber??'': '',
        fullName:  (mode==='EDIT' || mode==='VIEW')? itemSelected?.fullName??'': '',
        isActive: (mode==='EDIT' || mode==='VIEW')? itemSelected?.isActive??true:true,
        country: (mode === 'EDIT' || mode === 'VIEW') ? (itemSelected?.country && itemSelected?.country.id) ?? '' : '',
        type:  type,
        password: '',
        document_id: (mode==='EDIT' || mode==='VIEW')? itemSelected?.document_id ?? '' : ''
      },
      validationSchema: mode==="ADD"?entitySchema:toUpdateSchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await UserAdd({
              ...formData,
              email: formData.email.toLowerCase()
            });
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['user'] });
            }catch(e){
              setLoading(false);
            }
          }
          else{
            try{
              setLoading(true);
              await  UserUpdate({
                ...formData,
                email: formData.email.toLowerCase()
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['user'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })

  React.useEffect(() => {
    if (props.byStudent && props.setValuesUser) {
      props.setValuesUser({...values})
    }
  }, [props.byStudent, props.setValuesUser, values]);

   const fetchCountries = async () => {
     try {
       let countries = await UseCountryAll()
       setCountries(countries)
     } catch (e) {
       showSimpleAlert({ message: 'Error al encontar paises!', icon: 'error' })
       throw 'Error'
     }
   }
   useEffect(() => {
      fetchCountries()
    }, [])
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={(e) => {
        e.preventDefault();
        if (!props.byStudent) { handleSubmit(e)} 
        }} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Email</label>
            <input
              type='email'
              className='form-control form-control-lg form-control-solid'
              placeholder='dummy@gmail.com'
              disabled={mode==='VIEW'}
              name={'email'}
              value={values.email}
              onChange={handleChange}

            />
            {errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.email}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Username </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Artemisa'
              disabled={mode==='VIEW'}
              name={'fullName'}
              value={values.fullName}
              onChange={handleChange}
            />
            { errors.fullName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.fullName}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Teléfono </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='+57321280903'
              disabled={mode==='VIEW'}
              name={'phoneNumber'}
              value={values.phoneNumber}
              onChange={handleChange}
            />
            {errors.phoneNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.phoneNumber}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>País</label>
            <select
              disabled={mode === 'VIEW'}
              name={'country'}
              value={values.country}
              onChange={handleChange}
              className="form-select" aria-label="Default select example">
              <option selected></option>
              {countries.map((x) => (<option value={x.id}>{x.name}</option>))}
            </select>
            {errors.country && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.country}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Numero de Identificación</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='0000000000'
              disabled={mode==='VIEW'}
              name={'document_id'}
              value={values.document_id}
              onChange={handleChange}
            />
            { errors.document_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.document_id}</span>
                </div>
              </div>
            )}
          </div>

          {mode==='ADD' &&<div className='mb-10 col-6'>
            <label className='form-label mb-3'>Password </label>
            <input
              type='password'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'password'}
              value={values.password}
              onChange={handleChange}
            />
            {errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.password}</span>
                </div>
              </div>
            )}
          </div>}


          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Code</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'code'}
              value={values.code}
              onChange={handleChange}
            />
            {errors.code &&<label style={{
              color:'red'
            }}>Campo inválido</label>}
          </div> */}

          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {errors.description &&<label style={{
              color:'red'
            }}>Campo inválido</label>}
          </div> */}
        </div>

        {!props.byStudent && <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>}
      </form>
    </div>
  )
}

export {UserForm}
