 import {create} from 'zustand';
import { Answer, QuestionAudioTest, quiz,QuestionAndAnswer, questions } from '../interfaces';
 
type StatusQuiz = 'ANSWER'|'RESUME'|'THROW_ERROR'|'TITLE';

interface AudioTestInterface {
  status:StatusQuiz
  questions:QuestionAudioTest[]
  title:string;
  instructions:string;
  step:number;
  answers:QuestionAndAnswer[]
 
  list:Answer[]
  total:number
  setAnswer:(Answer)=>void;
  setStatus:(StatusQuiz)=>void
  setStep:(number)=>void;
  startQuiz:()=>void;
  setQuestionAudioTest:(value:QuestionAudioTest[], title: string, instructions: string)=>void,
  resetData:()=>void
}

export const UseAudioTestModule = create<AudioTestInterface>( (set) => ({
    status:'TITLE',
    title:"",
    instructions:"",
    questions:[],
    step:0,
    answers:[],
    list:[],
    total:0,
    
    setAnswer:(value:Answer)=>{
      let step:number=UseAudioTestModule.getState().step;
      let currentQuestion:QuestionAudioTest =UseAudioTestModule.getState().questions[step+1];
      let questionsLength:number=UseAudioTestModule.getState().questions.length;
      let answers:QuestionAndAnswer[]=UseAudioTestModule.getState().answers;
      let list:Answer[]=UseAudioTestModule.getState().list;
      let status:StatusQuiz ='ANSWER';
      if(questionsLength===(step+1)){
        status = 'RESUME'
      }
        answers.push(value)
        set({
          answers:answers,
          step:step+1,
          status:status
        })
   
    },
    setStep:(value:number)=>{
      let step:number=UseAudioTestModule.getState().step;
      let questionsLength:number=UseAudioTestModule.getState().answers.length;
      let status:StatusQuiz ='THROW_ERROR';
      if(questionsLength===value){
        status = 'RESUME'
      }
      set({
        step:value,
        status:status
      })
    },
    startQuiz(){
      set({
        status:'ANSWER'
      })
    },
    setStatus:(value:StatusQuiz)=>{
      if(value==='ANSWER'){
        set({
          answers:[],
          step:0,
          status:'ANSWER'
        })
      }
      if(value==='THROW_ERROR'){
        set({
          step:0,
          status:'THROW_ERROR'
        })
      }
      if(value==='RESUME'){
        set({
          step:0,
          status:'RESUME'
        })
      }
    },
    setQuestionAudioTest:(value, title, instructions)=>{
      set({
        questions: value,
        title,
        instructions,
      })
    },
    resetData:()=>{
      set({
        status:'TITLE',
        title:"",
        instructions:"",
        questions:[],
        step:0,
        answers:[],
        list:[],
        total:0,
      })
    }
   
}));