import React, { useEffect, useState } from 'react'
import useAuth from '../../../api/main';

type Props = {}



const ChatComponent: React.FC<Props> = ({ }) => {
  const {id, email} = useAuth();
  const [state, setState] = useState('exit');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(email){
        setState(email); 
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []); 

  const CLOVER_CHAT = process.env.REACT_APP_SUPABASE_URL;
  const url = `${CLOVER_CHAT}?u=${state}&p=${id}`;
  return (<>
 

    {state && id &&
      <iframe
      id="myIframe"
      src={url} style={{ marginTop: 0, width: '100%', height: '800px' }}></iframe>
    }
  </>
  )
}

export { ChatComponent }