import React, { useEffect } from 'react';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import useSound from 'use-sound';
import { AddSpeakOutResponse } from '../services';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { ModeSpeakOut, SpeakOutAnswer, UseSpeakOutModule } from '../store';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { ExerciseTimeAndWriting } from './writingAndTime';
import { countWords } from '../../history-wrinting/services';

type Props = {
    studentAnswer?: SpeakOutAnswer
    userMode?: ModeTypeExercise
    question: string
    type: ModeSpeakOut;
    handleSendAnswer: (x: any) => void
    setStudentAnswer: (value: SpeakOutAnswer) =>void
};
const BasicVocabularyExerciseItem: React.FC<Props> = ({
    studentAnswer,
    userMode,
    question,
    type,
    handleSendAnswer,
    setStudentAnswer,
}: Props) => {
    
    const {
        lessonData, 
        stepBookItem, 
        currentTaskId,
        setStepBookItem,
        addCompletedExerciseId,
        onChangeTaskByAnswer,
        setIsLoadingSubmit
    } = UseBookStudentModule();

    const {tips, instructions, limit, min, exerciseId} = UseSpeakOutModule();
    const [selected, setSelected] = React.useState<any>();
    const [error, setError] = React.useState<string | null>(null);
    const [descriptionData, setDescriptionData] = React.useState<string>('');
    const [titleData, setTitleData] = React.useState<string>('');
    const [playbackRate] = React.useState(0.75);
    const [play] = useSound(toAbsoluteUrl(`/media/audio/click.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const [success] = useSound(toAbsoluteUrl(`/media/audio/success.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const [wrong] = useSound(toAbsoluteUrl(`/media/audio/wrong.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const handleClick = () => {
        setStudentAnswer({
            title: titleData,
            description: descriptionData
        })
        play();
    };
    const handleSend = () => {
        if (selected?.isTrue) {
            handleSendAnswer(selected)
            setSelected(undefined)
        }
        if (selected?.isTrue === false) {
            handleSendAnswer(selected)
            setSelected(undefined)
        }
    };
    
    useEffect(() => {
        setSelected(undefined)
    }, [question]);
    
    useEffect(()=>{
        if(studentAnswer){
            setDescriptionData(studentAnswer.description)
            setTitleData(studentAnswer.title)
        } else {
            setDescriptionData("")
            setTitleData("")
        }
    },[studentAnswer])
    
    const handleSubmit = async () => {
        if (error) {
            showSimpleAlert({message: 'Revisa la cantidad de palabras', icon: 'error'})
            return;
        }
        if(userMode === 'STUDENT' && currentTaskId && exerciseId){
            try {
                setIsLoadingSubmit(true)
                const response = await AddSpeakOutResponse({
                    name: titleData??'', 
                    description: descriptionData??'',
                    isActive: true,
                    isReviewed: false,
                    isPending: true,
                    exercises: exerciseId, 
                    task: currentTaskId??'', 
                    percentage: 100 // se manda quemado por si el profesor no lo califica que no afecte la tarea al estudiante
                });
                onChangeTaskByAnswer(response, 'speak_out');
            } catch (e) {
                showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' })
            } finally {
                setIsLoadingSubmit(false)
            }
        }
        if (lessonData && lessonData.evaluation && 
        lessonData.evaluation.evaluation_item){
            addCompletedExerciseId(exerciseId ??"")
            if(lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
                setStepBookItem(exerciseId??'')
            } else {
                setStepBookItem('FINISH_LESSON')
            }
        } 
    };

    const handleChangeText = (value: string) => {
        const words = countWords(min, limit, value);
        setDescriptionData(value)
        if (words.state === 'allow') {
            setError(null)
        } else if (words.state === 'max') {
            setError(`${words.count} palabras extra.`);
        } else if (words.state === 'min') {
            setError(`Necesitas mínimo ${words.count} palabras mas.`);
        }
    };

    return (
        <>
            {exerciseId&&
                <ExerciseTimeAndWriting
                    tips={tips}
                    error={error}
                    mode={type}
                    instruction={instructions}
                    onSubmit={handleSubmit}
                    description={descriptionData}
                    setDescription={(e) => handleChangeText(e)}
                    exerciseId={exerciseId}
                />
            }
        </>
    );
};

export { BasicVocabularyExerciseItem };