/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { DashboardLeads } from './leads'
import { DashboardWidget } from './widget'
import { DashboardResume } from './resume'
import { useParams } from 'react-router-dom'
import { AppointmentUpdateStatus, UseGetOneAppointment } from '../../../admin/appointment/services'
import { UseBookingDashboard } from '../../store'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { StatusClassParser } from '../../../../components/common/AllExcel/utils'
import Swal from 'sweetalert2'
import moment from 'moment'

const BookingDashboard: React.FC = () => {
  let { code } = useParams();
  const { setItem, itemSelected } = UseBookingDashboard();
  let findBooking = async (status?: string) => {
    if (code) {
      try {
        let data = await UseGetOneAppointment(code);
        if (status && (status === 'IN_PROCESS' || status === 'TO_BE_CLOSED')) {
          if (data && data.trail_class && data?.activity && data?.activity?.toWorkshop === false && data.trail_class?.length > 0) {
            const classRandom = data.trail_class?.find(x => x.lesson?.sequence === 'RANDOM');
            if (classRandom) {
              Swal.fire({
                title: 'El topico seleccionado fue',
                text: `${data.trail_class[0]?.lesson?.name}`,
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Si!'
              })
            }
          }
        }
        setItem(data)
      } catch (e) {
      }
    }
  }
  const changeStatus = async (status: string, lessonId?: string) => {
    //@ts-ignore
    Swal.fire({
      title: 'Seguro?',
      text: `Desea cambiar el estado a: ${StatusClassParser(status)}!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!'
    }).then(async (result) => {
      if (result.isConfirmed && itemSelected) {
        try {
          await AppointmentUpdateStatus(itemSelected?.id, status, lessonId)
          showSimpleAlert({ message: 'Ok!', icon: 'success' })
          findBooking(status);
        } catch (e) {

        }
      }
    })
  }
  useEffect(() => {
    findBooking();
  }, [code])

  return (
    <div className='row'>
      <div className='col-md-12'>
        <DashboardWidget />
      </div>
      <div className='col-sm-12 col-md-8 col-lg-8'>
        <DashboardLeads />
      </div>
      <div className='col-sm-12 col-md-8 col-lg-4'>
        {itemSelected && itemSelected.time && <DashboardResume
         showButtonThreshold={5}
          startData={itemSelected?.time.start}
          endData={itemSelected?.time.end}
          className='card-xl-stretch mb-xl-8'
          chartColor='primary'
          chartHeight='200px'
          weight={itemSelected?.stats ?? 0}
          leads={itemSelected?.lead}
          dateMasterClass={itemSelected?.start}
          status={itemSelected?.status_appointment}
          changeStatus={(value, lessonId) => changeStatus(value, lessonId)}
        />}
      </div>
    </div>
  )
}

export { BookingDashboard }
