import {create} from 'zustand';
import { BookingDetails, Lead, TrailClassDashboard } from '../interfaces';
 
interface AppointmentModules {
    mode:'ADD'|'EDIT'|'VIEW'
    studentToActions:string[]
    showButton: boolean;
    itemSelected?: BookingDetails
    modal:boolean,
    lead?:Lead,
    trail_class?:TrailClassDashboard,
    setLead:(value:Lead)=>void;
    addUser:(user:string)=>void,
    removeUser:(id:string)=>void,
    setTrialClass:(value:TrailClassDashboard)=>void;
    setItem:(value:BookingDetails)=>void;
    setModal:(mode:boolean)=>void;
    resetData:()=>void,
    setShowButton:(value: boolean)=>void;
}

export const UseBookingDashboard = create<AppointmentModules>( (set) => ({
    mode:'ADD',
    modal:false,
    showButton: false,
    studentToActions: [],
    setShowButton:(value) => {
        set({showButton: value});
    },
    setItem:(value:BookingDetails)=>{
        const response = value.trail_class.filter((x) => !x.isSuspense)
        value.trail_class = response;
        set({itemSelected:value})
    },
    setTrialClass:(value:TrailClassDashboard)=>{
        set({trail_class:value,modal:true})
    },
    setLead:(value:Lead)=>{
        set({lead:value,modal:true})
    },
    setModal:(mode)=>{
        set({modal:mode})
    },
    resetData:()=>{
        set({itemSelected:undefined,mode:'ADD',modal:false})
    },
    addUser: (user: string) => {
        set((state) => {
            console.log(`Id agarrado: ${user}`);
            const userExists = state.studentToActions.includes(user);
            if (!userExists) {
                return { studentToActions: [...state.studentToActions, user] };
            }
            return state;
        });
    },

    removeUser: (id: string) => {
        set((state) => {
            console.log(`id removido: ${id}`); 
            return { studentToActions: state.studentToActions.filter((userId) => userId !== id) };
        });
    },

}));