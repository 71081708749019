import moment from 'moment';
import 'moment-timezone';

interface ResponseTranslateBogotaTZ {
  yearMonthDay: string;
  yearMonthDayHour: string;
  onlyHour: string;
  endOfDay: string;
  endOfWeek: string;
  endOfMonth: string;
  startOfDay: string;
  startOfWeek: string;
  startOfMonth: string;
  addOneWeekEndWeek: string;
}

const UseTranslateToBogotaTimeZone = (): ResponseTranslateBogotaTZ => {
  // Formatos de fecha y hora
  const yearMonthDay = moment.tz('America/Bogota').format('YYYY-MM-DD');
  const yearMonthDayHour = moment.tz('America/Bogota').format('YYYY-MM-DD HH:mm:ss');
  const onlyHour = moment.tz('America/Bogota').format('HH:mm:ss');
  
  const endOfDay = moment.tz('America/Bogota').endOf('day').format('YYYY-MM-DD HH:mm:ss');
  const endOfWeek = moment.tz('America/Bogota').endOf('week').format('YYYY-MM-DD');
  const endOfMonth = moment.tz('America/Bogota').endOf('month').format('YYYY-MM-DD HH:mm:ss');

  const startOfDay = moment.tz('America/Bogota').startOf('day').format('YYYY-MM-DD HH:mm:ss');
  const startOfWeek = moment.tz('America/Bogota').startOf('week').format('YYYY-MM-DD');
  const startOfMonth = moment.tz('America/Bogota').startOf('month').format('YYYY-MM-DD HH:mm:ss');
  const addOneWeekEndWeek = moment.tz('America/Bogota').add(1, 'week').endOf('week').format('YYYY-MM-DD HH:mm:ss');

  return {
    yearMonthDay,
    yearMonthDayHour,
    onlyHour,
    endOfDay,
    endOfWeek,
    endOfMonth,
    startOfDay,
    startOfMonth,
    startOfWeek,
    addOneWeekEndWeek,
  };
};

export default UseTranslateToBogotaTimeZone;
