import React, { useEffect, useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import '../../trail-freezing/components/list/index.css'
import { useThemeMode } from '../../../../../_metronic/partials'
import { UseTrailFreezingModule } from '../../trail-freezing/store/main'
import useAuth from '../../../../api/main'
import moment from 'moment'
import { useTrailFreezingState } from '../../trail-freezing/hooks/useTrailFreezingRepo'
import { TrailFreezingAdd, TrailFreezingDelete, UpdateActiveTrailFreezing } from '../../trail-freezing/services'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { DataTrailFreezing, FreezingStatus } from '../../trail-freezing/interfaces'
import { UseTrailClassPaginated } from '../../../abroad/trail/services'
import { CardHeaderModule } from '../../../../components/common/widgets/statistics/cardHeaderModule'
import CustomDatePicker from '../../../../components/common/DayPicker/DayPickerInputWithImage'
import GenericPaginator from '../../../../components/common/Paginator'
import { AssistantClassModal } from '../../trail-freezing/components/modal/assistantClassModal'
import {  useParams } from 'react-router-dom'
import { StudentSettingsOneByUser } from '../../user/services/student/StudentSettingsService'
import { StudentSetting } from '../../../profile/components/my-road-step/interfaces'
import Swal from 'sweetalert2'
import { TrailFreezingModal } from '../../trail-freezing/components/modal'

type Props = {

}
const StudentActionTrailFreezingTableList: React.FC<Props> = ({ }) => {

  const { id } = useParams()
  const {mode} = useThemeMode();
  const queryClient = useQueryClient()
  const { 
      root,
      paginator, 
      modal,
      modalForm,
      limit,
      currentPage,
      isActive,
      where,
      setCurrentPage,
      setLimit, 
      setIsActive, 
      setSelectedToEdit, 
      setSelectedToView,
      setModal,
      setRoot
  } = UseTrailFreezingModule()

  const {typeUser } = useAuth();

  const [before, setBefore] = React.useState<Date>(moment().toDate());
  const [after, setAfter] = React.useState<Date>(moment().toDate());
  const [loading, setLoading] = React.useState<boolean>(false);
  const [enabledItem, setEnabledItem] = React.useState<string>();

  const { data, isLoading } = useTrailFreezingState();

  const memoizedValues = useMemo(() => ({ currentPage, limit, root }), [currentPage, limit, root]);

  const [numberTotalDaysFreezing, setNumberTotalDaysFreezing] = React.useState<number>(0);
  const [dataTrailClass, setDataTrailClass] = React.useState<any[]>([]); 
  const [numberAppointmentAssistant, setNumberAppointmentAssistant] = React.useState<number>(0);
  const [numberDaysFreezing, setNumberDaysFreezing] = React.useState<number>(0);
  const [studentSettings, setStudentSettings] = React.useState<StudentSetting>();

  // const activeItem = async (id:string, value: boolean)=>{
  //   //@ts-ignore
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then(async(result) => {
  //     if (result.isConfirmed) {
  //       try{
  //         await UpdateActiveTrailFreezing(id,value);
  //         queryClient.invalidateQueries({ queryKey: ['trail-freezing'] });
  //       }catch(e){
  //         console.error(e); 
  //       }
  //     }
  //   })
  // }

  const changePage = (number: number) => {
      setCurrentPage(number)
  };
  const changeLimit =(number:number)=>{
      setLimit(number)
  }

  const handleBeforeSelectDate = (date: Date) => {
    if (moment(date).isAfter(after)) {
      showSimpleAlert({message:'La primera fecha es mayor a la segunda fecha',icon:'error'})
    } else {
      setBefore(date);
    }
  };

  const handleAfterSelectDate = (date: Date) => {
    if (moment(date).isBefore(before)) {
      showSimpleAlert({message:'La segunda fecha es mayor a la primera fecha',icon:'error'})
    } else {
      setAfter(date);
    }
  };

  const responseStudentSettings = async () => {
    const response = await StudentSettingsOneByUser(id??"")
    setStudentSettings(response)
  }

  const handleSubmitFreezing = async () => {
    Swal.fire({
      title: '<span class="poppins-bold">¿Estas Seguro?</span>',
      html: `<p clasName='poppins light justify-content-center'>Ten en cuenta que durante este periodo de congelamiento no podrás agendar clases y congelará únicamente el tiempo académico (no congela pagos en caso que aplique).</p>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
      cancelButtonText: 'Cancelar',
      customClass:{
        confirmButton:'btn btn-danger',
        cancelButton:'btn btn-success'
      },
    }).then(async(result) => {
      if (result.isConfirmed) {
        try{
          if (before && after && studentSettings) {
            if ((numberDaysFreezing + numberTotalDaysFreezing) 
              > studentSettings.daysFreezingAvailable) {
                showSimpleAlert({message: 'Los dias a congelar exceden los dias maximos permitidos', icon: 'error'});
                return;
            }
            setLoading(true);
            await TrailFreezingAdd({
              start: moment(before).format('YYYY-MM-DD'),
              end: moment(after).format('YYYY-MM-DD'),
              status: moment().isBefore(before) ? FreezingStatus.RETROACTIVE : FreezingStatus.ANTICIPATED,
              student: studentSettings.id,
              isActive: true,
              days: numberDaysFreezing,
            });
            setLoading(false);
          }
          queryClient.invalidateQueries({ queryKey: ['trail-freezing'] });
          showSimpleAlert({message:'Ok!',icon:'success'})
        }catch(e){
          setLoading(false)
          console.error(e);
          showSimpleAlert({message:'UPS! ocurrio algo inesperado, comuniquese con soporte',icon:'error'})
        }
      } else {
        return showSimpleAlert({message: 'Congelamiento cancelado', icon: 'info'});
      }
    })
  };

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['trail-freezing'] });
  }, [memoizedValues]);

  useEffect(() => {
    if ( data && data?.result) {
      let totalDays = 0;
      data?.result.map(x => {
        if (x.isActive) {
          totalDays += x?.days??0
        }
      })
      setNumberTotalDaysFreezing(totalDays);
    };    
  }, [data]);

  useEffect(() => {
    if ((typeUser === 'ADMIN' || typeUser === 'ASSISTANT' || typeUser === 'ASSISTANT_WELCOME')  && studentSettings) {
      setRoot(studentSettings.id);
    };
  }, [studentSettings]);

  useEffect(()=>{
    responseStudentSettings()
  },[id])

  useEffect(() => {
    if (after && before && studentSettings) {
      if ((numberDaysFreezing + numberTotalDaysFreezing) 
        > studentSettings.daysFreezingAvailable) {
          showSimpleAlert({message: 'Los dias a congelar exceden los dias maximos permitidos', icon: 'error'});
      }
      const findTrailClass = async () => {
        try {
          const responseTrail = await UseTrailClassPaginated(studentSettings?.id, after, before);
          setDataTrailClass(responseTrail.result?.filter((x) => x.assistantStudent === true));
          setNumberAppointmentAssistant(responseTrail.result?.filter((x) => x.assistantStudent === true)?.length??0)
        } catch (e) {
          console.error(e);
        }
      };
      findTrailClass();
      setNumberDaysFreezing(moment(after).set({ hour: 12 }).diff(moment(before).set({ hour: 12 }), 'days') + 1);
    }
  }, [after, before, studentSettings]);

  return (<>
    <CardHeaderModule
        title={'Congelamientos'} 
        leftIcon={`/media/svg/michigan-icons/header/${mode==='light'?'blue/back_blue':'dark-modules/back_aquamarine'}.svg`}
        svgIcon={`media/svg/michigan-icons/header/${mode==='light'?'blue/freezing_blue':'dark-modules/freezing_aquamarine'}.svg`}
        background={'#1bebd4'}
        colorTitle={'#2d30e1'}
        routeBack='/user/student-setting'
    />

    {(typeUser === 'ADMIN' || typeUser === 'ASSISTANT' || typeUser === 'ASSISTANT_WELCOME') && studentSettings &&
    <>
      <div className='card mt-8 content-student'
        style={{ border:mode==='light'?'2px solid #2d30e1':'2px solid #FFFFFF' }}
      >
        <div className='row p-6' style={{ color:mode==='light'?'#2d30e1':'#FFFFFF' }}>
          <div className='col-md-6 mt-2 d-flex'>
            <div className='col-6'>
              <span className='poppins-bold fs-3 ms-5'
              >Nombre:</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fw-bold fs-3'
              >{studentSettings.user.fullName}</span>
            </div>
          </div>  
          <div className='col-md-6 mt-2 d-flex'>
            <div className='col-6'>
              <span className='poppins-bold fs-3 ms-5'
              >Email:</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fw-bold fs-3'
              >{studentSettings.user.email}</span>
            </div>
          </div>
          <div className='col-md-6 mt-2 d-flex'>
            <div className='col-6'>
              <span className='poppins-bold fs-3 ms-5'
              >Telefono:</span>
            </div>
            <div className='col-md-6'>
              <span className='poppins-light fw-bold fs-3'
              >{studentSettings.user.phoneNumber}</span>
            </div>
          </div>
          <div className='col-md-6 mt-2 d-flex'>
            <div className='col-6'>
              <span className='poppins-bold fs-3 ms-5'
              >Dias de programa:</span>
            </div>
            <div className='col-md-6'>
              <span className='poppins-light fw-bold fs-3'
              >{moment().diff(studentSettings.start, 'days')}</span>
            </div>
          </div>
          <div className='col-md-6 mt-2 d-flex'>
            <div className='col-6'>
              <span className='poppins-bold fs-3 ms-5'
              >Inicio del programa:</span>
            </div>
            <div className='col-md-6'>
              <span className='poppins-light fw-bold fs-3'
              >{moment(studentSettings?.start).format('DD/MM/YYYY')}</span>
            </div>
          </div>
          <div className='col-md-6 mt-2 d-flex'>
            <div className='col-6'>
              <span className='poppins-bold fs-3 ms-5' >
                Dias congelados:
              </span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fw-bold fs-3' >{data && data?.totalDays}</span>
            </div>
          </div>
          <div className='col-md-6 mt-2 d-flex'>
            <div className='col-6'>
              <span className='poppins-bold fs-3 ms-5'
              >Fin del programa:</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fw-bold fs-3'
              >{moment(studentSettings?.finish).format('DD/MM/YYYY')}</span>
            </div>
          </div>
          <div className='col-md-6 mt-2 d-flex'>
            <div className='col-6'>
              <span className='poppins-bold fs-3 ms-5'
              >Maximo a congelar:</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fw-bolder fs-3'
              >{studentSettings?.daysFreezingAvailable??0}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-column flex-lg-row'>
          <div className='mt-8 mb-lg-0' style={{ flex: '0 0 49%' }}>
            <div className='d-flex card' style={{ borderRadius:30,border:mode==='dark'?'1px solid #E6E6FA':'', backgroundColor:mode==='light'?'#E6E6FA':'' }}>
              <div className='d-flex align-items-center justify-content-between'>
                <span className='poppins-bold ps-12 py-4 d-flex align-items-center fs-3'
                  style={{ color:mode==='light'?'#2d30e1':'#FFFFFF' }}
                >
                  {`Desde: ${moment(before).format('DD-MM-YYYY')}`}
                </span>
                <div className='pe-5'>
                  <CustomDatePicker
                    symbolWidth='30px'
                    onSelectDate={handleBeforeSelectDate}
                    calendarIconSrc="/media/svg/michigan-icons/elements/calendar_blue.svg"
                    buttonStyle={{ marginLeft: '5px' }} />
                </div>
              </div>
            </div>
            <div className='d-flex card mt-3' style={{ borderRadius:30,border:mode==='dark'?'1px solid #E6E6FA':'', backgroundColor:mode==='light'?'#E6E6FA':'' }}>
              <div className='d-flex align-items-center justify-content-between'>
                <span className='poppins-bold ps-12 py-4 d-flex align-items-center fs-3'
                  style={{ color: mode==='light'?'#2d30e1':'#FFFFFF' }}
                >
                  {`Hasta: ${moment(after).format('DD-MM-YYYY')}`}
                </span>
                <div className='pe-5'>
                  <CustomDatePicker
                    symbolWidth='30px'
                    onSelectDate={handleAfterSelectDate}
                    calendarIconSrc="/media/svg/michigan-icons/elements/calendar_blue.svg"
                    buttonStyle={{ marginLeft: '5px' }} />
                </div>
              </div>
            </div>
            <div className='mt-3 d-flex'>
              {dataTrailClass.length > 0 &&
                <button className={`btn w-50 py-3 champ-bold fs-3`} 
                  style={{ backgroundColor:mode==='light'?'red':'', 
                          borderRadius: 30, 
                          color:mode==='light'?'white':'red',
                          border:mode==='dark'?'1px solid red':''
                        }} 
                  onClick={() => setModal(true)}>
                  Asististe a {numberAppointmentAssistant} {numberAppointmentAssistant === 1 ? 'clase' : 'clases'}
                </button>
              }
              <button 
                disabled={
                  numberAppointmentAssistant > 0 ? true : false || 
                  studentSettings && studentSettings.daysFreezingAvailable < (numberDaysFreezing + numberTotalDaysFreezing) ? true : false ||
                  numberAppointmentAssistant > numberTotalDaysFreezing || 
                  numberDaysFreezing < 7
                } 
                className={`btn ${dataTrailClass.length > 0 ? 'w-50' : 'w-100'} py-3 champ-bold fs-2`} 
                style={{ backgroundColor:mode==='light'?'#1bebd4':'', borderRadius: 30, color:mode==='light'?'#2d30e1':'#1bebd4', border:mode==='dark'?'1px solid #1bebd4':'' }} 
                onClick={() => handleSubmitFreezing()}
              >
                {!loading && `Generar ${numberDaysFreezing}${' día' + (numberDaysFreezing === 1 ? '' : 's')}`}
                {loading && 
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>}
              </button>
            </div>
          </div>

          <div style={{ flex: '0 0 2%' }}></div>

          <div className='card mt-8 mb-lg-0' style={{ flex: '0 0 49%', backgroundColor:mode==='light'?'#E6E6FA':'', borderRadius: '50px', border:mode==='dark'?'1px solid #E6E6FA':''  }}>
            <div className='card-body pt-3'>
              <div className='table-responsive'>
                <span className='fs-4 poppins-bold d-flex justify-content-center align-items-center' style={{ color:mode==='light'?'#2d30e1':'#E6E6FA' }}>Historial de Congelamientos</span>
                <table className='table align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold poppins-bold fs-5' style={{ color:mode==='light'?'#2d30e1':'#E6E6FA', borderBottom: `1px solid ${mode==='light'?'#2d30e1':'#E6E6FA'}` }}>
                      <th className='min-w-120px ps-0 pb-5'>Desde</th>
                      <th className='min-w-120px ps-0 pb-5'>Hasta</th>
                      <th className='min-w-120px ps-0 pb-5'>Días</th>
                      <th className='min-w-120px ps-0 pb-5'>Facilitador</th>
                      <th className='pb-5'></th>
                    </tr>
                  </thead>
                  <tbody className='mt-2'>
                    {data && data?.result?.map((x: DataTrailFreezing) => (
                        <tr key={x.id} className='poppins-light' style={{ color:mode==='light'?'#2d30e1':'#E6E6FA' }}>
                          <td className='p-0'>
                            <span className='d-block fs-5'>
                              {moment(x.start).format('DD/MM/YYYY')}
                            </span>
                          </td>
                          <td className='p-0'>
                            <span className='d-block fs-5'>
                              {moment(x.end).format('DD/MM/YYYY')}
                            </span>
                          </td>
                          <td className='p-0'>
                            <span className='d-block fs-5'>
                              {x.days}
                            </span>
                          </td>
                          <td className='p-0'>
                            <span className=' d-block fs-3'>
                              {x.userUpdate ? x.userUpdate.fullName : "Empty"}
                            </span>
                          </td>
                          <td className='p-0'>
                            {data?.enabledItem && data.enabledItem === x.id &&
                              <div className='d-flex justify-content-end flex-shrink-0'>
                                <button
                                  type='button'
                                  onClick={()=>setSelectedToEdit(x)}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                  <i style={{ color: 'blue', fontSize: 17 }} className="fas fa-pen"></i>
                                </button>
                              </div>
                            }
                          </td>
                        </tr>
                    ))}
                  </tbody>
                </table>
                <GenericPaginator
                  limit={limit}
                  currentPage={paginator.current_page}
                  items={paginator.result.length}
                  total={paginator.total}
                  totalPage={paginator.total_page}
                  changePage={changePage}
                  changeLimit={changeLimit} />
              </div>
            </div>
          </div>
        </div>
    </>}


    <TrailFreezingModal show={modalForm} handleClose={() => alert()} />
    {dataTrailClass && 
      <AssistantClassModal dataTrailClass={dataTrailClass} show={modal} handleClose={() => alert()} />
    }
  </>
  )
}

export { StudentActionTrailFreezingTableList }
