import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { AppointmentTeacherDashboard } from '../interfaces';
import { SequenceType } from '../../../../abroad/road-map-step/interfaces';
import { ClassroomType } from '../../../../admin/classroom/interfaces';
interface CountdownTimerProps {
  targetDate: string;  
  showButtonThreshold: number; 
  appointment:AppointmentTeacherDashboard 
  isToday: boolean; 
  isCurrent? : boolean
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate, showButtonThreshold,appointment,isToday, isCurrent }) => {

  const [timeRemaining, setTimeRemaining] = useState<string>('');
  const [showButton, setShowButton] = useState<boolean>(false); 

  useEffect(() => {
    const interval = setInterval(() => {
      const now: Moment = moment();
      const futureDate: Moment = moment(targetDate);
      const duration = moment.duration(futureDate.diff(now));
      
      const days: number = Math.floor(duration.asDays());
      const hours: number = duration.hours();
      const minutes: number = duration.minutes();
      const seconds: number = duration.seconds();

      setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);

      if (duration.asMilliseconds() <= 0) {
        clearInterval(interval);
        setTimeRemaining('Countdown ended');
        setShowButton(true); // Muestra el botón cuando el conteo regresivo ha terminado
      } else if (duration.asMinutes() <= showButtonThreshold) {
        setShowButton(true); // Muestra el botón cuando se alcanza el umbral de minutos
      } else {
        setShowButton(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate, showButtonThreshold]);
  // {!isToday &&<p className='champ-light min-w-100px' style={{fontSize:13}}>Neverland</p>}
  return (
    <div>
      
      {/* {showButton && !isCurrent && appointment.status_appointment !== 'FINISHED' &&<p className='champ-light mt-2 text-danger' style={{fontSize:13}}>{isToday? "Debe terminar sus clases anteriores" : "No Disponible"}</p>} */}
      {
        <td className='d-flex justify-content-center align-items-center champ-light' style={{fontSize: 14}}>
          { isCurrent &&
            <>
            {/* {appointment.classroom && appointment.classroom.type === ClassroomType.VIRTUAL &&  
            <a
            href={appointment.classroom.url}
              target='blank'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
            <i style={{ color: 'green', fontSize: 17 }} className="fas fa-link"/>
            </a>
          } */}
          {appointment.classroom && /*appointment.status_appointment === 'PENDING' && */
            <a
              href={'/booking-dashboard/' + appointment.id}
              target='blank'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <i style={{ color: 'green', fontSize: 17 }} className="fas fa-play"/>
            </a>
          }
          {/* //Para ver estudiantes antes de  
          <a
            href={'/' + appointment.id}
            target='blank'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
          <i style={{ color: 'green', fontSize: 17 }} className="bi bi-people-fill"/>
          </a> */}
          {/* {appointment.type_appointment === SequenceType.STANDARD && appointment.firstLesson && appointment.firstLesson.lesson_id &&
            <a
            href={'/book/class?lesson=' + appointment.firstLesson.lesson_id +'&appointment=' + appointment.id + '&mode=TEACHER'}
            target='blank'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <i style={{ color: 'green', fontSize: 17 }} className="fa-solid fa-book-atlas"/>
            </a>
          } */}

            </>
          }
          
          {!showButton &&<>{timeRemaining}</>}
        </td>
      }
    </div>
  );
};

export default CountdownTimer;