import {create} from 'zustand';
import { persist } from 'zustand/middleware';
import { Evaluation, EvaluationItem } from '../../../../admin/evaluation/interfaces';
import { Lessons } from '../../../../abroad/learning-path/modules/lessons/interfaces';
import { setQuestionsEbook } from '../../../utils/setQuestionsEBook';
import { ExercisesType } from '../../../../admin/exercises/interfaces';
import { SkipInteractions } from '../services';
import { UseModeCourseModule } from '../../../../courses/components/front-page/store/main';
import { CurrentPage } from '../../test/store/main';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { UseMyRoadmapModule } from '../../../../profile/components/my-road-step/store';
import { AddTaskByStudentSettingsManual } from '../../../../abroad/trail/services';
import { TaskStatus, TaskType } from '../../../../admin/tasks/interfaces';
import { OneTaskByFindOneTask, updateTaskStatusById } from '../../../../courses/exercises/basic-vocabulary/services';
import { setAnswersEbook } from '../../../utils/setAnswersEBook';
import { ExerciseSix } from '../../../../../components/abroad/e-book/beyong-the-reading/ExerciseSix/ExerciseSix';
import { UseEvaluationCourseModule } from '../../../../courses/screen/store/main';
import { UseChapterAllByLearningPath } from '../../../../abroad/learning-path/modules/chapter/services';

interface BookStudentModules {
    currentTaskId?: string;
    currentItemBook: EvaluationItem | null;
    stepBookItem:number;
    currentInteractiveItems: EvaluationItem[];
    evaluationBook?: Evaluation;
    lessonData?: Lessons;
    task?: any;
    statePage: 1 | 2;
    currentPage: 1 | 2;
    allAnswered: boolean;
    answeredItems: number;
    clickedInteractionsIds: string[];
    completedExerciseIds: string[];
    isLoadingSubmit: boolean;
    setStatePage:(value: 1 | 2)=>void;
    setCurrentsItemsTest:(value: EvaluationItem, skipExercise: boolean)=>void;
    setEvaluationBook:(value: Evaluation)=>void;
    setLessonData:(values: Lessons)=>void;
    setCurrentPage:(value: CurrentPage)=>Promise<void>;
    setDeleteCurrentItem:(value: string)=>void;
    setCloseExercise:()=>void;
    setCurrentTask:(value: string) => void;
    setStepBookItem:(value: string)=>void;
    setAllAnswered:(value: boolean)=>void;
    addCompletedExerciseId: (id: string) => void;
    setTask:(value: any)=>void;
    onChangeTaskByAnswer:(value: any, exerciseType: string) => void;
    refreshExercisesId:()=>void;
    resetCurrentItems:()=>void;
    setIsLoadingSubmit: (value: boolean)=>void;
}

export const UseBookStudentModule = create(persist<BookStudentModules>( (set, get) => ({
    currentInteractiveItems: [],
    stepBookItem: 0,
    currentItemBook: null,
    currentPage: 1,
    statePage: 2,
    answeredItems: 0,
    clickedInteractions: 0,
    allAnswered: true,
    completedExerciseIds: [],
    clickedInteractionsIds : [],
    isLoadingSubmit: false,
    setTask:(value)=>{
        set({task: value});
    },
    onChangeTaskByAnswer:(value, exerciseType)=>{
        const currentTask = get().task;
        if (!(exerciseType in currentTask)) {
            return;
        }
        currentTask[exerciseType] = Array.isArray(currentTask[exerciseType]) ? currentTask[exerciseType].length === 0 ? 
            [value]
        : currentTask[exerciseType].map((x: any) => {
            if (x.id === value.id) {
                return value
            }
            return x
        }) : [];
        set({task: currentTask});
    },
    resetCurrentItems:()=>{
        set({currentItemBook: null, currentInteractiveItems: []});
    },
    setCloseExercise:()=>{
        set({currentItemBook: null})
    },
    //Configura los elementos interactivos actuales, opcionalmente reemplazando el ejercicio.
    setCurrentsItemsTest:(value, skipExercise)=>{
        const findItem = get().currentInteractiveItems.find(x => x.id === value.id);
        const findTypeExercise = get().currentInteractiveItems.find(x => x.exercise?.type === value.exercise?.type);
        let currentItems: EvaluationItem[]; 
        if (findItem) {
            currentItems = [...get().currentInteractiveItems];
        } else if (findTypeExercise) {
            currentItems =[...get().currentInteractiveItems.filter(x => x.id !== findTypeExercise.id), value]
        } else {
            currentItems = skipExercise ? [value] : [...get().currentInteractiveItems, value];
        }
        let totalInteractionIds: string[] = [];
        currentItems?.forEach((item) => {
            if (item.exercise.type === ExercisesType.AUDIO_INTERACTION) {
                totalInteractionIds.push(item.exercise.id)
            }
            const isExerciseOpen = get().currentInteractiveItems.find(x=>x.id === item.id)
            if(!isExerciseOpen){
                setQuestionsEbook(item);
            }
            const findExercise = get().completedExerciseIds.find((exercise) => exercise === item.exercise.id);
            if (findExercise && !isExerciseOpen) {
                setAnswersEbook(get().task, item.exercise.type, findExercise);
            } 
        });
        if (value.exercise.type === ExercisesType.AUDIO_INTERACTION && value.id) {
            const completedExerciseIds = get().completedExerciseIds.includes(value.id) ? [...get().completedExerciseIds] : [...get().completedExerciseIds, value.id]
            get().addCompletedExerciseId(value.id)
            set({
                completedExerciseIds
            })
        }
        set({currentInteractiveItems: currentItems /*clickedInteractionsIds: totalInteractionIds*/ });
    },
    setEvaluationBook:(value)=>{
        set({evaluationBook: value});
    },
    setLessonData:(value)=>{
        set({lessonData: value});
    },
    setStatePage:(value)=>{
        set({statePage: value});
    },
    //Cambia la página actual o navega entre lecciones.
    setCurrentPage:async(value: CurrentPage)=> {
        /* 
            @param Value 0 es volver a la leccion anterior
            Value 1 es poner la primera pagina
            Value 2 es poner la segunda pagina
            Value 3 es pasar a la siguiente leccion
        */  
        const lessonId = get().lessonData
        const settings = UseMyRoadmapModule.getState().settings 
        if (lessonId && lessonId.book_pages && lessonId.book_pages.length > 0) {
            const lessons = UseModeCourseModule.getState().lessonsId;
            let currentChapter: undefined | any = undefined;
            if (lessons && lessonId) {
                currentChapter = lessons.find(x => x.id === lessonId.id)
            }
            if (value === 3) { 
                if (currentChapter && lessons) {
                    const chapterLessons = lessons.filter(x => x.chapter_order === currentChapter.chapter_order).sort((a, b) => a.order - b.order);
                    let nextLesson = chapterLessons.find(x => x.order > currentChapter.order);
                    if (nextLesson) {
                        if (nextLesson.statusTask === 'NOT_TASK' && get().task.status === TaskStatus.APPROVED) {
                            try{
                                //añadir la siguiente tarea
                                await AddTaskByStudentSettingsManual({
                                    status:!nextLesson.isDisabled ? TaskStatus.APPROVED : TaskStatus.PENDING,
                                    code: 'xx',
                                    description: '',
                                    isActive: true,
                                    lesson: nextLesson.id,
                                    student_setting: settings?.id ?? 'xx',
                                    isArtificial: false,
                                    type: nextLesson.typeScreen === 'QUIZ' ? TaskType.QUIZ : 
                                    nextLesson.typeScreen === 'FINAL_PRESENTATION' ? TaskType.FINAL_PRESENTATION : TaskType.NORMAL,
                                    isComplete: !nextLesson.isDisabled ? true : false,
                                });
                                showSimpleAlert({icon: 'success', message: 'lección completada'})
                            } catch (e){
                                console.error(e)
                                showSimpleAlert({icon: 'error', message: 'Error al crear la siguiente tarea'})
                            }
                        }
                        if(nextLesson.typeScreen === 'BOOK'){
                            window.location.replace(`container?lesson=${nextLesson.id}&mode=STUDENT`);
                        } else {
                            const {setCurrentLessonId} = UseEvaluationCourseModule.getState();
                            setCurrentLessonId(nextLesson.id)
                            window.location.replace(`/evaluation/screen/course/${nextLesson.evaluationId}`);
                        }
                    } else {
                        // Buscar que exista el próximo capítulo
                        const nextChapter = lessons.filter(x => x.chapter_order > currentChapter.order).sort((a, b) => a.chapter_order - b.chapter_order)[0];
                        
                        if (nextChapter) {
                            // Filtrar y ordenar las lecciones del próximo capítulo
                            const nextChapterLessons = lessons.filter(x => x.chapter_order === nextChapter.chapter_order).sort((a, b) => a.order - b.order);
                            nextLesson = nextChapterLessons.find(x => x.order >= 0);
                            if (nextLesson) {
                                if(nextLesson.typeScreen === 'BOOK'){
                                    window.location.replace(`container?lesson=${nextLesson.id}&mode=STUDENT`);
                                } else {
                                    window.location.replace(`/evaluation/screen/course/${nextLesson.evaluationId}`);
                                }
                            } else {
                                showSimpleAlert({ message: 'No hay lecciones posteriores', icon: 'info' });
                            }
                        } else {
                            showSimpleAlert({ message: 'No hay lecciones posteriores', icon: 'info' });
                        }
                    }
                }
            } 
            else if ( value === 0){
                if( currentChapter && lessons){
                    const chapterLessons = lessons.filter(x => x.chapter_order === currentChapter.chapter_order).sort((a, b) => b.order - a.order);
                    let prevLesson = chapterLessons.find(x=> x.order < currentChapter.order)
                    if (prevLesson){
                        if (prevLesson.statusTask === 'NOT_TASK') {
                            try{
                                //añadir la tarea tarea
                                await AddTaskByStudentSettingsManual({
                                    status:!prevLesson.isDisabled ? TaskStatus.APPROVED : TaskStatus.PENDING,
                                    code: 'xx',
                                    description: '',
                                    isActive: true,
                                    lesson: prevLesson.id,
                                    student_setting: settings?.id ?? 'xx',
                                    isArtificial: false,
                                    type: prevLesson.typeScreen === 'QUIZ' ? TaskType.QUIZ : 
                                    prevLesson.typeScreen === 'FINAL_PRESENTATION' ? TaskType.FINAL_PRESENTATION : TaskType.NORMAL,
                                    isComplete: !prevLesson.isDisabled ? true : false
                                });
                                showSimpleAlert({icon: 'success', message: 'lección completada'})
                            } catch (e){
                                console.error(e)
                                showSimpleAlert({icon: 'error', message: 'Error al crear la tarea'})
                            }
                        }
                        if(prevLesson.typeScreen === 'BOOK'){
                            window.location.replace	(`container?lesson=${prevLesson.id}&mode=STUDENT`)
                        } else {
                            window.location.replace(`/evaluation/screen/course/${prevLesson.evaluationId}`);
                        }
                    } else {
                        const prevChapter = lessons.filter(x => x.chapter_order < currentChapter.order).sort((a, b) => a.chapter_order - b.chapter_order)[0];
                        // const prevChapter = lessons.find(x=> x.order === 1 && x.chapter_order === currentChapter.order -1)
                        if (prevChapter) {
                            const prevChapterLessons = lessons.filter(x => x.chapter_order === prevChapter.chapter_order).sort((a, b) => b.order - a.order);
                            prevLesson = prevChapterLessons[0];  // Tomar la última lección del capítulo anterior
                            if (prevLesson) {
                                if (prevLesson.statusTask === 'NOT_TASK') {
                                    try{
                                        //añadir la tarea previa
                                        await AddTaskByStudentSettingsManual({
                                            status:!prevLesson.isDisabled ? TaskStatus.APPROVED : TaskStatus.PENDING,
                                            code: 'xx',
                                            description: '',
                                            isActive: true,
                                            lesson: prevLesson.id,
                                            student_setting: settings?.id ?? 'xx',
                                            isArtificial: false,
                                            type: prevLesson.typeScreen === 'QUIZ' ? TaskType.QUIZ : 
                                            prevLesson.typeScreen === 'FINAL_PRESENTATION' ? TaskType.FINAL_PRESENTATION : TaskType.NORMAL,
                                            isComplete: !prevLesson.isDisabled ? true : false
                                        });
                                        showSimpleAlert({icon: 'success', message: 'lección completada'})
                                    } catch (e){
                                        console.error(e)
                                        showSimpleAlert({icon: 'error', message: 'Error al crear la tarea'})
                                    }
                                }
                                if(prevLesson.typeScreen === 'BOOK'){
                                    window.location.replace(`container?lesson=${prevLesson.id}&mode=STUDENT`);
                                } else {
                                    window.location.replace(`/evaluation/screen/course/${prevLesson.evaluationId}`);
                                }
                            } else {
                                showSimpleAlert({ message: 'No hay lecciones anteriores', icon: 'info' });
                            }
                        } else {
                            showSimpleAlert({message: 'No hay lecciones anteriores', icon: 'info'})	
                        }
                    }
                } 
            } else {
                set({currentPage: value})
                return
            }
        }
        set({currentPage: 1});
    },
    setDeleteCurrentItem:(value)=> {
        const filteredItems = get().currentInteractiveItems.filter(x=>x.id !== value)
        set({currentInteractiveItems: filteredItems})
    },
    setCurrentTask:(value)=>{
        set({currentTaskId: value});
    },
    //Avanza al siguiente ejercicio o interacción.
    setStepBookItem:(value)=>{
        set((state) => {
            let nextExercise: EvaluationItem | undefined = undefined;
            if (state.lessonData && state.lessonData.evaluation && state.lessonData.evaluation.evaluation_item) {
                const current = state.lessonData.evaluation.evaluation_item.find(x => x.exercise?.id === value);
                if (current) {
                    nextExercise  = state.lessonData.evaluation.evaluation_item.sort((a, b) => a.order - b.order).find(x => x.order > current.order);
                }
            }   
            
            if (nextExercise) {
                if(nextExercise?.exercise.type===ExercisesType.AUDIO_INTERACTION && state.lessonData && state.lessonData.evaluation && state.lessonData.evaluation.evaluation_item){
                    const skipInteractions = SkipInteractions(nextExercise, state.lessonData.evaluation.evaluation_item)
                    // set({currentInteractiveItems: skipInteractions?.itemsArray ??[]});
                    if (skipInteractions?.itemsArray) {
                        skipInteractions.itemsArray.map(x => {
                            get().setCurrentsItemsTest(x, false);
                        })
                    }
                    setQuestionsEbook(skipInteractions?.newCurrent??nextExercise)
                    nextExercise = skipInteractions?.newCurrent??nextExercise
                } else {
                    setQuestionsEbook(nextExercise);
                }
                get().setCurrentsItemsTest(nextExercise, true);
            } else {
                get().resetCurrentItems();
            }
            return {}
        });
    },
    setAllAnswered:(value)=>{
        set({allAnswered: value})
    },
    // Añade un ejercicio completado y crea una nueva tarea si es necesario.
    addCompletedExerciseId: async(value) => {
        //Crear una tarea en el momento que se cumple el ultimo ejercicio
        const lessonData = get().lessonData
        const {settings} = UseMyRoadmapModule.getState();
        const isIncluded = get().completedExerciseIds.some(x => x === value);
        const answeredExercises = get().completedExerciseIds.length;
        const exercisesLength = lessonData?.evaluation?.evaluation_item?.length ?? 0
        if(!isIncluded && exercisesLength === answeredExercises + 1){
            const lessonId = get().lessonData
            get().resetCurrentItems();
            if (lessonId && lessonId.book_pages && lessonId.book_pages.length > 0) {
                const lessons = UseModeCourseModule.getState().lessonsId;
                let currentChapter: undefined | any = undefined;
                if (lessons && lessonId) {
                    currentChapter = lessons.find(x => x.id === lessonId.id)
                }
                if (currentChapter && lessons) {
                    const chapterLessons = lessons.filter(x => x.chapter_order === currentChapter.chapter_order).sort((a, b) => a.order - b.order);
                    let nextLesson = chapterLessons.find(x => x.order > currentChapter.order)
                    if (nextLesson && nextLesson.statusTask === 'NOT_TASK') {
                        try{                            
                            //añadir la siguiente tarea
                            const response = await AddTaskByStudentSettingsManual({
                                code: 'xx',
                                description: '',
                                isActive: true,
                                lesson: nextLesson.id,
                                student_setting: settings?.id ?? 'xx',
                                isArtificial: false,
                                type:  nextLesson.typeScreen === 'QUIZ' ? TaskType.QUIZ : 
                                nextLesson.typeScreen === 'FINAL_PRESENTATION' ? TaskType.FINAL_PRESENTATION : TaskType.NORMAL,
                                status:!nextLesson.isDisabled ? TaskStatus.APPROVED : TaskStatus.PENDING,
                                isComplete: !nextLesson.isDisabled ? true : false
                            }); 

                            const {setCurrentTask} = UseEvaluationCourseModule.getState();
                            if (!response) return;
                            setCurrentTask(response?.id??'')
                            showSimpleAlert({icon: 'success', message: 'lección completada'})
                            set({allAnswered: true});
                        } catch (e){
                            set({allAnswered: false})
                            console.error(e)
                            showSimpleAlert({icon: 'error', message: 'Error al crear la siguiente tarea'})
                        } finally {
                            await UseChapterAllByLearningPath(UseModeCourseModule.getState().currentCourseId);
                        }
                    }
                }
            }
            const taskId = get().currentTaskId
            if (taskId) {
                await OneTaskByFindOneTask(taskId)
            }
            set({statePage: undefined}); 
        }
        set((state) => {
            const updatedIds = state.completedExerciseIds.includes(value)
            ? state.completedExerciseIds
            : [...state.completedExerciseIds, value];
            updatedIds.filter(x=>x !== null)
            return {
                completedExerciseIds: updatedIds,
            }
        });
    },

    refreshExercisesId:() => {
        set({completedExerciseIds: [], clickedInteractionsIds:[]})
    },
    setIsLoadingSubmit: (value)=>{
        set({isLoadingSubmit: value})
    }
    }),
    {
        name: 'content-book'
    }
));

