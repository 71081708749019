import React, { useCallback, useState } from 'react';
import { DraggableModal } from '../modal';
import { UseBookContainerModule } from '../../store/main';
import { TimerToolComponent } from '../toolbar/timer';
import { ExercisesType } from '../../../admin/exercises/interfaces';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import RenderExerciseBook, { ModeTypeExercise } from '../RenderExerciseBook';
import { EvaluationItem } from '../../../admin/evaluation/interfaces';
import { UseBookStudentModule } from '../../modules/screen/store/main';
import useAuth from '../../../../api/main';
import { UseExerciseTimerModule } from '../../../courses/exercises/common/store/main';
import { showSimpleAlert } from '../../../../commonHooks/alert';

type Props = {
  typeUser: ModeTypeExercise;
  exercisesLesson: EvaluationItem[];
  currentExercises: EvaluationItem[];
  addClickedInteractionsId: (v: string) => void;
  onDeleteExercise: (v: string) => void;
  setExercise: (v: EvaluationItem) => void;
};

const RenderItemExercise: React.FC<Props> = ({
  exercisesLesson,
  typeUser: user,
  currentExercises,
  addClickedInteractionsId,
  onDeleteExercise,
  setExercise }) => {
  const { toolsExercise,
    toolsTimer,
    setToolsExercise,
    setOffDraggable, } = UseBookContainerModule();
  const { clickedInteractionsIds, completedExerciseIds } = UseBookStudentModule()
  const [isTransitioning, setIsTransitioning] = React.useState({ exercises: false, interactions: false })
  const [viewExercises, setViewExercises] = React.useState<boolean>(false)
  const [mobileViewVisible, setMovilViewVisible] = React.useState<boolean>(window.innerWidth <= 991)
  const [viewInteraction, setVIewInteractions] = React.useState<boolean>(false)
  const [isAnimating, setIsAnimating] = useState(false);
  const transitionDelay = 160
  let { typeUser } = useAuth();
  const exercisesLength = exercisesLesson.filter((x) => x.exercise.type !== ExercisesType.AUDIO_INTERACTION).length ?? 0
  const interactionsLength = exercisesLesson.filter((x) => x.exercise.type === ExercisesType.AUDIO_INTERACTION).length ?? 0
  const completedExercises = completedExerciseIds.length
  const clickedInteractions = clickedInteractionsIds.length
  const handleToggleExercises = () => {
    setIsTransitioning(prevState => ({
      ...prevState,
      exercises: true,
    }));
    // setmovilViewVisible(!mobileViewVisible)
    setVIewInteractions(false)
    setTimeout(() => {
      setViewExercises(!viewExercises)
      setIsTransitioning(prevState => ({
        ...prevState,
        exercises: false
      }));
    }, transitionDelay);
  }

  React.useEffect(() => {
    const handleResize = () => {
      setMovilViewVisible(window.innerWidth <= 991);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { timeLeft } = UseExerciseTimerModule();
  const handleCloseDraggable = useCallback((lessonExercisesId: string, exerciseId: string) => {
    const currentTimer = timeLeft.find(element => element.exerciseId === exerciseId);
    const exerciseBeforeTime = (currentTimer?.time ?? 0) > 0
    if (!exerciseBeforeTime || typeUser !== 'STUDENT') {
      onDeleteExercise(lessonExercisesId)
      setOffDraggable(false);
    } else {
      showSimpleAlert({ message: `Espera ${currentTimer?.time ?? "x "}s. Analiza todo el contenido`, icon: 'error' })
    }
  }, [timeLeft])

  return (<>
    <div>
      {toolsExercise && (<>
        <div
          className='d-flex flex-row-reverse'
          style={{
            position: 'fixed',
            top: mobileViewVisible ? 65 : 65,
            right: mobileViewVisible ? 0 : 145,
            bottom: 0,
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto',
            zIndex: 2,
            width: '150px',
          }}
        >
          <div className='me-2 d-flex flex-column justify-content-center'>
            {interactionsLength > 0 &&
              <div
                className='d-flex justify-content-center'
                onClick={() => {
                  setVIewInteractions(!viewInteraction)
                  setViewExercises(false)
                }}
              >
                <OverlayTrigger 
                  overlay={
                    <Tooltip className='tooltip-inverse'> 
                      <div className='fs-8 champ-bold d-flex justify-content-start text-primary'>Ver Interacciones</div>
                    </Tooltip>
                  } 
                  placement='top'  
                  popperConfig={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [-40, 10], 
                        },
                      },
                    ],
                  }}
                >       
                  <img
                    src={toAbsoluteUrl('/media/svg/michigan-icons/tools/blue/play_timer_blue.svg')}
                    style={{ width: '60px', transition: 'opacity 0.5s', background: '#D9D9D9', padding: '5px 5px 5px 5px', borderRadius: ` 30px 30px ${exercisesLength > 0 ? "0px 0px" : "30px 30px"}` }}
                    className={` ${isTransitioning.interactions ? 'fade-out' : 'fade-in'} cursor-pointer  ${(typeUser === 'STUDENT' && clickedInteractions !== interactionsLength && !viewInteraction) ? 'breathing-blue-effect' : ''}`}
                    alt='Toggle View Interaction'
                  />
                </OverlayTrigger>
              </div>
            }
            {exercisesLength > 0 &&
              <div
                className='d-flex justify-content-center mt-1'
                onClick={handleToggleExercises}
                >
                  <OverlayTrigger 
                    overlay={
                      <Tooltip className='tooltip-inverse'> 
                        <div className='fs-8 champ-bold d-flex justify-content-start text-primary'>Ver Ejercicios</div>
                      </Tooltip>
                    } 
                    placement='bottom'
                    popperConfig={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [-40, 10], 
                          },
                        },
                      ],
                    }}
                  >
                    <img
                      src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/${viewExercises?"white/beyond_white": "blue/beyond_blue"}.svg`)}
                      style={{ width: '60px', transition: 'opacity 0.5s', background:'#D9D9D9' ,padding:'5px 5px 5px 5px',borderRadius:`${interactionsLength > 0 ?"0px 0px" : "30px 30px"} 30px 30px`}}
                      className={`${isTransitioning.exercises ? 'fade-out': 'fade-in'} cursor-pointer ${(typeUser === 'STUDENT' && completedExercises !== exercisesLength && !viewExercises) ? 'breathing-blue-effect' : ''}`}
                      alt='Toggle View Exercises'
                    />
                  </OverlayTrigger>
                </div>
              }
              </div>

          <div
            className='d-flex justify-content-center align-items-center flex-column'
            style={{ overflowY: 'auto' }}
          >
            {exercisesLesson && (
              <>
                <div className='d-flex flex-column' style={{ transition: 'opacity: 0.5s', marginTop: '75px' }}>
                  {/* Lista de ejercicios */}
                  {viewExercises &&
                    exercisesLesson.filter((x) => x.exercise.type !== ExercisesType.AUDIO_INTERACTION).sort((a, b) => a.order - b.order).map((x,) => (
                    <OverlayTrigger overlay={<Tooltip className='tooltip-inverse'> <div className='fs-8 champ-bold d-flex justify-content-start text-primary'>{x.name}</div></Tooltip>} placement='left'>
                      <button
                        type='button'
                        key={x.id}
                        className='d-flex align-items-center my-2 py-0 ms-0 border-none btn btn-transparent '
                        onClick={() => {
                          setExercise(x);
                          setToolsExercise(false);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <div className={`symbol symbol-30px symbol-circle me-2`}>
                          <span
                            className={`symbol-label`}
                            style={{
                              backgroundColor: typeUser === 'STUDENT' ? completedExerciseIds.find(exercise => exercise === x.exercise?.id) ? '#50cd89' : '#F4819D' : '#50cd89',
                              color: 'black',
                            }}
                          >
                            {x.name.charAt(0).toUpperCase()}
                          </span>
                        </div>
                      </button>
                    </OverlayTrigger>
                  ))}
                </div>
                {/* lista de audios e interactuables */}
                <div className='d-flex flex-column'>
                  {viewInteraction && exercisesLesson.filter((x) => x.exercise.type === ExercisesType.AUDIO_INTERACTION).sort((a, b) => a.order - b.order).map((x) => (
                    <OverlayTrigger overlay={<Tooltip className='tooltip-inverse mx-3'> <div className='fs-8 champ-bold d-flex justify-content-start text-primary'>{x.name}</div></Tooltip>} placement='left'>
                      <button
                        type='button'
                        key={x.id}
                        className='d-flex align-items-center mb-4'
                        style={{ backgroundColor: '#D9D9D9', border: 'none', borderRadius: '24px 24px 24px 24px', padding: '5px 5px 5px 5px ', marginRight: '10px' }}
                        onClick={() => {
                          if (x.id && x.exercise.id) {
                            setExercise(x);
                            setToolsExercise(false);
                            addClickedInteractionsId(x.id);
                          }
                        }}
                      >
                        <div className={'symbol symbol-30px symbol-circle'}>
                          <span className={'symbol-label bg-primary'}>
                            {x.name.charAt(0).toUpperCase()}
                          </span>
                        </div>
                      </button>
                    </OverlayTrigger>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </>
      )}

      <div style={{
        display: 'flex',
      }}>
        {currentExercises && currentExercises.map(x => (
          <DraggableModal key={x.id}>
            <div id='animatedClose'
              key={x.id}
              className={`animate__animated ${isAnimating ? ' animate__bounceOut ' : 'animate__flipInX'} animate__faster`}
              style={{
                display: "flex",
                alignContent: "flex-end",
                flexDirection: "column"
              }}
            >
              <img className="cursor-pointer"
                onClick={() => handleCloseDraggable(x.id ?? '', x.exercise.id)} src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} style={{ width: '40px', height: '40px', alignSelf: "flex-end" }} />
              <RenderExerciseBook key={x.id} exercise={x.exercise} userMode={user} />
            </div>
          </DraggableModal>
        ))}
        {toolsTimer &&
          <DraggableModal>
            <TimerToolComponent />
          </DraggableModal>}
      </div>
    </div>
  </>)
};

export { RenderItemExercise };