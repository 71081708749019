import { User, DataAllUserResponse, DataPaginateUserResponse } from '../interfaces/index'
import useAuth from '../../../../api/main'

import { catchServerError } from '../../../../hooks/useCatchError'
import { useStore } from 'zustand'
import { UseUserModule } from '../store/main'
import { api } from '../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import moment from 'moment'

const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export const useUserAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/user/simple?type_category=all`,
    {
      headers: {
        Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
      },
    })
    return response.data
  } catch (e: any) {
    catchServerError(e.response)
  }
}

export const UseUserChatLink = async (ids:string[]): Promise<any> => {
  try {
    const response = await api.post(`/auth/transferchat`,{
      users:ids
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e:any) {
    catchServerError(e.response)
  }
}

export const UseChangeCredential = async (data:any): Promise<any> => {
  try {
    const response = await api.post(`/auth/change-credentials`,{
      ...data
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Success', icon: 'success' })
    return response.data
  } catch (e: any) {
    catchServerError(e.response)
  }
}

export const useUserAllPaginate = async (): Promise<any> => {
  try {
    // await authChecked()
    const { setPaginator, limit, currentPage, where, isActive, type } = await UseUserModule.getState()

    const response: any = await api.get(
      `/auth/paginated?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}

export const useStudentSettingPaginate = async (): Promise<any> => {
  try {
    // await authChecked()
    const { setPaginator, limit, currentPage, where, isActive, type, filterStudent, before, after } = await UseUserModule.getState()
    const currenDate = moment().toISOString();

    let url = `/student-settings/paginated?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}&filterStudent=${filterStudent}&date=${currenDate}`

    if (before) {
      url += `&before=${before}`;
    };
    if (after) {
      url += `&after=${after}`;
    }

    const response: any = await api.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}

export const UseUserTypePaginate = async (type:string): Promise<any> => {
  try {
    // await authChecked()
    const response: any = await api.get(
      `/auth/paginated-selector?isActive=${1}&limit=10000&&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data.result;
  } catch (e:any) {
    catchServerError(e.response);
  }
}

export const UserAdd = async (data: User): Promise<any> => {
  try {
    const response: any = await api.post(
      '/auth/user',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
    
  } catch (e:any) {
    catchServerError(e.response);
  }
}

export const signInUserPlacement = async (data: User): Promise<any> => {
  try {
    const response: any = await api.post(
      '/auth/sign-in',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showSimpleAlert({ message: 'Registrado Correctamente!', icon: 'success' })
    return response.data
    
  } catch (e:any) {
    catchServerError(e.response);
  }
}

export const UserUpdate = async (data: User): Promise<any> => {
  try {
    const { itemSelected } = UseUserModule.getState()
    const response: any = await api.patch(
      `/auth/user/${itemSelected?.id}`,
      {
        ...data,
        password:undefined,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}
export const UserDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`auth/user/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e: any) {
    catchServerError(e.response);
  }
}
