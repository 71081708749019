/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'
import useTrailTask, { NextLesson } from '../student/store'
import { useThemeMode } from '../../../../../_metronic/partials'
import { t } from 'i18next'
 
type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const NexLessonModal = ({show, handleClose}: Props) => {
  const {mode} = useThemeMode();
  const {modal,setModal,data} = useTrailTask();
  const submit = () => {
    window.location.reload()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
     // onHide={handleClose}
      //   onEntered={loadStepper}
      backdrop={true}
    >
    
 
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={()=>setModal(false)}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
 
 
        
           <div className='card-body p-6'>
              <div className='table-responsive'>
 
                <table className='table align-middle gs-0 gy-4 table-hover'>
                  <thead>
                    <tr className='fw-bold poppins-bold fs-5' style={{ color:mode==='light'?'#2d30e1':'#E6E6FA', borderBottom: `1px solid ${mode==='light'?'#2d30e1':'#E6E6FA'}` }}>
                      <th className='min-w-120px ps-0 pb-5'>Lesson</th>
                      <th className='min-w-120px ps-0 pb-5'>Tasks Status</th>
                      <th className='min-w-120px ps-0 pb-5'>Schedule</th>
      
          
                    </tr>
                  </thead>
                  <tbody className='mt-2'>
                    {data && data?.lessons?.slice(0, 7).map((x: NextLesson,index) => (
               
                        <tr key={x.lesson.id} className={`poppins-light table-striped ${index<1 ?'table-primary':''}`} style={{ color:mode==='light'?'#2d30e1':'#E6E6FA' }}>
                          <td className='p-0'>
                            <span className='d-block fs-5'>
                                {t(x.lesson.name)}
                            </span>
                          </td>
                          <td className='p-0'>
                            <span className='d-block fs-5'>
                             {x.status =='IN_REVIEW' &&<i style={{fontSize:20,color:'green'}} className="bi bi-eye"></i>}
                             {x.status =='PENDING' &&<i style={{fontSize:20,color:'blue'}} className="bi bi-journal-bookmark"></i>}
                             {x.status =='NOT_TASK' &&<i style={{fontSize:20,color:'grey'}} className="bi bi-journal-x"></i>}
                            </span>
                          </td>
                          <td className='p-0'>
                            <span className='d-block fs-5'>
                            {x.trailClass.id =='NOT_TRAIL'?
                              <i style={{fontSize:20,color:'grey'}} className="bi bi-calendar-x"></i>:
                              <i style={{fontSize:20,color:'green'}} className="bi bi-calendar-week-fill"></i>
                              }
       
                            </span>
                          </td>
 
 
                        </tr>
                    ))}
                  </tbody>
                </table>
 
              </div>
            </div>
           

 
    </Modal>,
    modalsRoot
  )
}

export {NexLessonModal}
