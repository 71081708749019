import React, { useEffect, useState } from 'react';
import { es } from 'date-fns/locale';
import { DateRange, DayPicker } from 'react-day-picker';
import moment from 'moment';
import 'react-day-picker/dist/style.css';
import useDateRange from './utils-date';
import { useThemeMode } from '../../../../_metronic/partials';
import { UseBookingWindows } from '../../../modules/booking-window/store';
import './day-picker.css'

type Props = {
  day: Date | undefined;
  list: moment.Moment[];
  changeDate: (date: Date) => void;
}

const DayPickerRangeBookingSelector: React.FC<Props> = ({ day, list, changeDate }: Props) => {
  const { mode } = useThemeMode();
  const { dates, loading } = useDateRange(list);
  let { setStep } = UseBookingWindows();
  
  const changeDateEvent = (selectedDay: Date | undefined) => {
    // Verificamos si la fecha seleccionada es válida
    if (selectedDay) {
      changeDate(selectedDay);
    }
  };

  const [isSixWeeks, setIsSixWeeks] = useState(false);

  // Verificar si el mes tiene seis semanas
  const checkIfMonthHasSixWeeks = (month: Date) => {
    let firstDayOfMonth = moment(month).startOf('month').day();
    if (firstDayOfMonth === 0) {
      firstDayOfMonth = 7; // Ajuste cuando el mes comienza en domingo (inicio lunes)
    }

    const daysInMonth = moment(month).daysInMonth(); // Días totales del mes
    const totalWeeks = Math.ceil((daysInMonth + (firstDayOfMonth - 1)) / 7);
    setIsSixWeeks(totalWeeks === 6); // Cambiar estado si el mes tiene 6 semanas
  };

  useEffect(() => {
    checkIfMonthHasSixWeeks(moment().toDate());
  }, []);

  return (
    <div className='d-flex'>
      <div className='d-flex flex-column breadcrumb-item champ-light cursor-pointer' 
        onClick={() => setStep(0)}
        style={{marginTop: '100px'}}>
        {'back'.split('').map(x => (
          <span>{x}</span>
        ))}
      </div>
      <div className={`${isSixWeeks? "day-picker-container six-weeks":""}`}>
        <DayPicker
          className='champ-bold'
          id="test"
          mode="single"
          disabled={dates}
          locale={es}
          selected={day}
          defaultMonth={moment().toDate()}
          onSelect={changeDateEvent}
          onMonthChange={checkIfMonthHasSixWeeks}
          style={{ color: mode === 'light' ? '#2d30e1' : '#FFFFFF' }}
        />
      </div>
    </div>
  );
};

export { DayPickerRangeBookingSelector };
