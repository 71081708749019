import useAuth from "../../../../../../api/main";
import { showSimpleAlert } from "../../../../../../commonHooks/alert";
import { api } from "../../../../../auth/services/auth.services";
import { TemplateTableTimeDay, TemplateTableTimeItem } from "../../../interfaces";
import { UseTemplateTableTimeItemModule } from "../store/main";

export interface TemplateTimeItemDataTeacher {
  root: string;
  teacher?: string;
  day?: TemplateTableTimeDay;
}

export interface TemplateTimeItemValidateTeacher {
  teacher: string;
  room: string;
  template: string;
  day: TemplateTableTimeDay;
}

const { token, logout, authChecked } = useAuth.getState();

export const useTemplateTableTimeItemAll = async (): Promise<any> => {
    try {
      const response = await api.get(`/template-table-time-item`,
      {
        headers: {
          Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
        },
      })
      return response.data
    } catch (e) {
      //@ts-ignore
      catchServerError(e.response)
    }
  }

export const UseTemplateTableTimeItemByTeacherAll = async (id:string, day:TemplateTableTimeDay, root:string): Promise<any> => {
  try {
    const response = await api.get(`/template-table-time-item/all-by-teacher/${id}/${root}/${day}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}

export const useTemplateTableTimeItemAllPaginate = async (): Promise<any> => {
  try {
      // await authChecked()
      const { setPaginator, limit, currentPage, where, isActive } = await UseTemplateTableTimeItemModule.getState()

      const response: any = await api.get(
      `/template-table-time/paginated?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}`,
      {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      }
      )
      setPaginator(response.data)
      return response.data
  } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
      logout()
      }
      if (e.response.status === 400) {
      console.log(e.response)
          e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
          })
          throw 'Error'
      }
      //@ts-ignore
      showSimpleAlert({ message: 'Error!', icon: 'error' })
      throw 'Error'
  }
}

export const TemplateTableTimeItemAdd = async (data: TemplateTableTimeItem): Promise<any> => {
  try {
    const response: any = await api.post(
      '/template-table-time-item',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const TemplateTableTimeItemUpdate = async (data: TemplateTableTimeItem): Promise<any> => {
  try {
    const { itemSelected } = UseTemplateTableTimeItemModule.getState()
    const response: any = await api.patch(
      `/template-table-time-item/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const TemplateTableTimeItemDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/template-table-time-item/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {

    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const useTemplateTimeItemTeacherPaginated = async (): Promise<any> => {
  try {
    const { setPaginatorForm,limitForm,currentPageForm,
      day,teacherId,root,activity,tabList, dayList, blockHour
    } = await UseTemplateTableTimeItemModule.getState();
    let url = `/template-table-time-item/paginated?root=${root}&teacher=${teacherId}&day=${day}&limit=${limitForm}&page=${currentPageForm}`;
    if (activity && activity !== 'all') {
      url += `&param=${activity}`
    }
    const response: any = tabList === 'TEACHER_FORM' ? await api.get(
      `/template-table-time-item/paginated?root=${root}&teacher=${teacherId}&day=${day}&limit=${limitForm}&page=${currentPageForm}${activity === 'all' ? '' : activity ? `&param=${activity}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ) : tabList === 'ACTIVITY_VIEW' && await api.get(
      `/template-table-time-item/paginated?root=${root}&limit=${limitForm}&page=${currentPageForm}&param=${activity}&blockHour=${blockHour}${dayList ? `&day=${dayList}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    
    setPaginatorForm(response.data)
    return response.data
  } catch (e:any) {
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseTemplateTableTimePaginatedByTeacher = async (root: string): Promise<any> => {
  try {
    // await authChecked()
    const { country, dayList, isActive, limit, currentPage, where, setPaginator } = UseTemplateTableTimeItemModule.getState()
    let url = `/template-table-time-item/paginated-by-teacher?isActive=${isActive?1:0}&limit=${limit}&page=${currentPage}&where=${where}&root=${root}&day=${dayList??''}`;

    if (country) {
      url += `&type=${country}`;
    } 
    const response: any = await api.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const useTemplateTableTimePaginatedByClassroom = async (): Promise<any> => {
  try {
    // await authChecked()
    const { root, dayList, isActive, limit, currentPage, where, setPaginator,type, searchTeacher} = await UseTemplateTableTimeItemModule.getState()

    const response: any = await api.get(
      `/template-table-time-item/paginated-by-classroom?isActive=${isActive?1:0}&limit=${limit}&page=${currentPage}&where=${where}&root=${root}&day=${dayList??''}&type=${type??''}${searchTeacher &&searchTeacher?.length>3?'&teacher='+searchTeacher:''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}


export const useTemplateTableTimePaginatedByActivity = async (): Promise<any> => {
  try {
    // await authChecked()
    const { root, dayList, isActive, limit, currentPage, where, setPaginator, blockHour,searchTeacher,campus } = await UseTemplateTableTimeItemModule.getState()

    const response: any = await api.get(
      `/template-table-time-item/paginated-by-activity?isActive=${isActive?1:0}&limit=${limit}&page=${currentPage}&where=${where}&root=${root}&day=${dayList??''}&blockHour=${blockHour??undefined}${searchTeacher &&searchTeacher?.length>3?'&teacher='+searchTeacher:''}${campus &&campus?.length>3?'&campus='+campus:''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}


export const UseResumeTableTimeTemplate = async (id:string): Promise<any> => {
  try {

    const response: any = await api.get(
      `/template-table-time/resume/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}