import React, { useEffect, useState } from 'react';
import './index.css';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import HeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import { QuestionTrueOptionsIE } from '../interfaces';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { AddTrueOptionsResponse } from '../services';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseTrueOptionsModule } from '../store';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { UseBookContainerModule } from '../../../../book/store/main';


type Props = {
  userMode?: ModeTypeExercise
  responses?: Answer[];
  question: QuestionTrueOptionsIE
  instruction: string,
};
interface Answer {
  title: string;
  sentences: string;
  order: number
  letter: string;
}
const divStyle = {
  backgroundColor: '#3f8bff',
  borderRadius: '30px',
  borderColor: 'rgb(255, 255, 255)', borderStyle: 'solid',
  padding: '27px 20px 20px 20px'
};
const inputStyle: React.CSSProperties = {
  display: 'flex', width: '32px',
  height: '16px', marginRight: '10px', borderRadius: '40px',
  borderWidth: '0px', borderStyle: 'solid', borderColor: '#ffffff',
  textAlign: 'center', backgroundColor: '#d0ff00', outline: 'none', marginBottom: '0',
  marginTop: '1px'
};

const TrueOptionImageItem: React.FC<Props> = ({
  userMode,
  question,
  instruction,
  responses,
}: Props) => {
  const {
    lessonData,
    stepBookItem,
    currentTaskId,
    setStepBookItem,
    addCompletedExerciseId,
    onChangeTaskByAnswer,
    setIsLoadingSubmit
  } = UseBookStudentModule();

  const {exerciseId} = UseTrueOptionsModule();
  const {modeViewAnswer} = UseModeCourseModule();

  const [allAnswered, setAllAnswered] = useState<boolean>(false);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [percentageCorrect, setPercentageCorrect] = useState<number | null>(null);

  const handleSubmit = async () => {
    if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
      try {
        setIsLoadingSubmit(true)
        const response = await AddTrueOptionsResponse({
          task: currentTaskId,
          exercises: exerciseId ?? '',
          percentage: percentageCorrect ? percentageCorrect : 0,
          isActive: true,
          answers: answers,
        });
        onChangeTaskByAnswer(response, 'true_option');
      } catch (e) {
        showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' });
      } finally {
        setIsLoadingSubmit(false)
      }
    }
    if (lessonData && lessonData.evaluation &&
    lessonData.evaluation.evaluation_item){
      addCompletedExerciseId(exerciseId ?? "")
      if(lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
        setStepBookItem(exerciseId??'')
      } else {
        setStepBookItem('FINISH_LESSON')
      }
    } 
  };
  const tryAgain = () => {
    setAllAnswered(false)
    const initialAnswers = question.options.map((x) => ({
      title: x.title,
      sentences: '',
      order: x.order,
      letter: '',
    }));
    setAnswers(initialAnswers);
  }
  useEffect(() => {
    if (responses && responses.length > 0) {
      setAnswers(responses);
    } else {
      // Inicializa las respuestas cuando el componente se monta o cuando las preguntas cambian
      const initialAnswers = question.options.map((x) => ({
        title: x.title,
        sentences: '',
        order: x.order,
        letter: modeViewAnswer ? x.letter : '',
      }));
      setAnswers(initialAnswers);
    }
  }, [question, responses, modeViewAnswer]); // Asegúrate de reaccionar a cambios en las preguntas

  const handleInputChange = (order: number, value: string, letter: string) => {
    // Actualiza las respuestas basándose en el input del usuario
    const updatedAnswers = answers.map((answer) =>
      answer.order === order ? { ...answer, sentences: value.trim(), letter: letter } : answer
    );
    setAnswers(updatedAnswers);
  };

  useEffect(() => {
    // Comprueba si todas las preguntas han sido respondidas
    const allAnsweredCheck = answers.every((answer) => answer.letter !== '');
    setAllAnswered(allAnsweredCheck);

    if (allAnsweredCheck) {
      // Si todas están respondidas, calcula el porcentaje de correctas
      let correctCount = 0;
      answers.forEach((answer, index) => {
        if (question.options[index].letter.trim().toLowerCase() === answer.letter.trim().toLocaleLowerCase() && question.options[index].order === answer.order) {
          correctCount += 1;
        }
      });
      setPercentageCorrect((correctCount / question.options.length) * 100);
    } else {
      // Si no, asegúrate de que el porcentaje de correctas esté en null
      setPercentageCorrect(null);
    }
  }, [answers]); // Reacciona a cambios en las respuestas

  const { setOffDraggable } = UseBookContainerModule()

  return (
    <>
      <div className="container-fluid" style={{ display: 'flex' }}>
        <div  style={divStyle}>
          <HeaderCard />
          <div className='scroll-y overflow-y-auto mb-2' style={{maxHeight:'70px', maxWidth:'435px', minWidth:'400px'}}>
          <p className=" text-white  mb-0 poppins-light fs-9 p-2" >{instruction}</p>
          </div>
          <div className="row px-2 mb-5">
            <div className="col-5">
              <div className="col text-center rounded-4 border border-2 border-white poppins-bold text-white fs-8"><span>Column A </span></div>
              <div className="row p-2 d-flex justify-content-center scroll-y overflow-y-auto" style={{maxHeight:'200px',maxWidth:'435px'}}>
                {question.options
                .sort((a,b) => (a.letter > b.letter ? 1 : -1))// ordena alfabeticamente cada imagen dentro de la columna A de manera asendente
                .map((x, index) => (
                  <div className='col-2 fs-8 text-center text-white' style={{ width: '65px', height: '40px', marginTop: '13px' }} key={index}>
                    <div className="rounded-circle border border-2 border-white" style={{ maxWidth: '35px', maxHeight: '35px'}}>
                      <img src={x.title} className="rounded-circle" style={{ width: '35px', height: '35px', pointerEvents: 'none', userSelect: 'none' }} alt="Cinque Terre"/>
                    </div>
                    <div className=''>
                      <span className=' align-text-bottom' >{x.letter}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-7">
              <div className="col text-center rounded-4 border border-2 border-white poppins-bold text-white fs-8"><span>Column B</span></div>
              <div className="p-2 scroll-y overflow-y-auto" style={{maxHeight:'200px', maxWidth:'435px'}}>
                {question.options.map((x, index) => (
                  <p style={{ fontSize: 12, maxWidth: '210px' }} className='text-white  poppins-bold mb-0 my-1'>
                    {question.sentences[index].title ?? ''}
                    <input
                      style={{ width: '34px', borderRadius: '50px', border: '0px', outline: 'none', background: '#D0FF00', margin: '10px' }} className='mb-0'
                      type="text"
                      maxLength={1}
                      value={answers[index]?.letter || ''}
                      onChange={(e) => handleInputChange(x.order, question.sentences[index].title ?? '', e.target.value.toUpperCase())}
                      onMouseEnter={()=> setOffDraggable(true)}
                      onMouseLeave={()=> setOffDraggable(false)}
                    />
                  </p>
                ))}
              </div>
            </div>
          </div>
          {allAnswered &&
            <>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <ButtonTryAgain onAction={tryAgain} />
              </div>
              <div className='d-flex flex-column'>
                <h3 className='text-center' style={{ fontSize: 10 }}> <span className=" text-white poppins-light">Porcentaje correcto: {percentageCorrect && percentageCorrect.toFixed(2)}%</span></h3>
              </div> 
                <ButtonSaveExercise
                isChecked={allAnswered}
                onNext={()=>{}}
                onSubmit={handleSubmit}
                exerciseId={exerciseId}
                />
              </div>
              
            </>
          }
        </div>
      </div>
    </>
  );
};

export { TrueOptionImageItem };