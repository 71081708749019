import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import FillTheGaps from './FillTheGaps';
import { UseParagraphDragDropModule } from './store';


interface DraggableWordsListProps {
  colorSecondary?: string
  descriptionContent?: string;
  titleContent?: string;
  renderType?: 'left' | 'right';
  showContent?:(v: boolean | undefined)=>void;
}

export const ParagraphDragDrop: React.FC<DraggableWordsListProps> = ({
  colorSecondary,
  descriptionContent,
  titleContent,
  renderType,
  showContent
}) => {

  return (
    <DndProvider backend={HTML5Backend}>
      <FillTheGaps
        colorSecondary={colorSecondary??'blue'}
        titleContent={titleContent??'Lionel Pessi'}
        descriptionContent={descriptionContent??'El genio mundial'}
        renderType={renderType}
        showContent={(e) => showContent && showContent(e)}
      />
    </DndProvider>
  );
};