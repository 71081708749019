import '../index.css'
import MyNext from '../img/NEXT_btn 1.svg';

type Props = {
   onCLickButton?:()=>void
}

const NexBtnOne: React.FC<Props> = ({onCLickButton}) => {
   return (
      <>
         <div className="position-relative ">
            <div className="position-absolute bottom-0 end-0 "style={{marginRight:'20px',marginBottom:'12px'}}> 
               <img className="cursor-pointer" src={MyNext} style={{ width: '40px', height: '40px'}} onClick={onCLickButton}/>
            </div>
         </div>
      </>     
   );
};
export default NexBtnOne;