import { create } from 'zustand';
import { AnswerOrderSentences, QuestionOrderSentences, quiz } from '../interfaces';

interface OrderSentencesInterface {
  questions: QuestionOrderSentences[]
  title: string;
  instructions: string;
  trueAnswer: QuestionOrderSentences[]
  falseAnswer: QuestionOrderSentences[]
  percentage: number
  exerciseId?:string;
  responseStudent?: AnswerOrderSentences[];
  resetData: () => void;
  setQuestionsOrderSentences: (
    exerciseId: string,
    instruction: string,
    value: QuestionOrderSentences[]) => void;
  setResponseStudent: (value: AnswerOrderSentences[]) => void;
}

export const UseOrderSentencesModule = create<OrderSentencesInterface>((set) => ({
  status: 'ANSWER',
  title: "Este es un titulo de quiz",
  instructions: "",
  questions: [],
  trueAnswer: [],
  falseAnswer: [],
  percentage: 0,
  resetData: () => {

  },
  setQuestionsOrderSentences: (exerciseId, instructions, value) => {
    set({ questions: value, exerciseId, instructions, responseStudent: undefined });
  },
  setResponseStudent: (value) => {
    set({ responseStudent: value });
  }
}));