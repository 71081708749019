import React from 'react';
import { MovieWritingExerciseItem } from './components/item';
import { UseMovieWritingModule } from './store';
import { UseEvaluationCourseModule } from '../../screen/store/main';
import { MovieWritingLeftInfo } from './components/history-item';

type Props = {
    colorSecondary: string;
    renderType?: 'left' | 'right'
};

const MovieWritingFullExercise: React.FC<Props> = ({ renderType, colorSecondary }) => {
    const {questionsEvaluation} = UseEvaluationCourseModule();
    let {
        status,
        movie,
        setAnswerMovie,
        setDescriptionMovie,
    } = UseMovieWritingModule();

    return (
        <>
        {renderType && renderType === 'left' ?
            <MovieWritingLeftInfo 
                movie={movie} 
                handleChangeMovie={(e) => setAnswerMovie(e)} 
                name={questionsEvaluation?.exercise?.movie_writing[0]?.name}
                description={questionsEvaluation?.exercise?.movie_writing[0]?.description}
            />
        : <>
            {(status !== 'RESUME' && status !== 'TITLE') &&
                <MovieWritingExerciseItem
                    movie={movie}
                    handleChangeDescription={(e) => setDescriptionMovie(e)}
                />
            }
        </>}
            <style>
                {`.card-with-extended-background::before {
                background-color: ${colorSecondary};
                }`}
            </style>
        </>
    );
};

export default React.memo(MovieWritingFullExercise);