import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { KTIcon } from '../../../helpers'
import { Item1 } from '../../content/activity/Item1'
import { Item2 } from '../../content/activity/Item2'
import { Item3 } from '../../content/activity/Item3'
import { Item4 } from '../../content/activity/Item4'
import { Item5 } from '../../content/activity/Item5'
import { Item6 } from '../../content/activity/Item6'
import { Item7 } from '../../content/activity/Item7'
import { Item8 } from '../../content/activity/Item8'
import { NotificationList } from '../../../../app/modules/notifications/components/NotificationList'
import { MyAppointmentNotification } from '../../../../app/modules/notifications/my-appointment'
import { MegaMenu } from '../../../layout/components/header/MegaMenu'

const MyAppointmentDrawer: FC = () => (
  <div
    id='my_appointment'
    className='bg-body'
    data-kt-drawer='true'
    data-kt-drawer-name='my_appointment'
    data-kt-drawer-activate='true'
    data-kt-drawer-overlay='true'
    data-kt-drawer-width="{default:'300px', 'md': '390px'}"
    data-kt-drawer-direction='end'
    data-kt-drawer-toggle='#kt_my_appointment_toggle'
    data-kt-drawer-close='#kt_my_appointment_close'
  >
    <div className='card shadow-none rounded-0'>
      <div className='card-header' id='kt_my_appointment_header'>
        <h3 className='card-title fw-bolder text-dark'>Menu Admin</h3>

        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-light-primary me-n5'
            id='kt_my_appointment_close'
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>
      </div>
      <div className='card-body position-relative' id='kt_my_appointment_body'>
        <div
          id='kt_my_appointment_scroll'
          className='position-relative scroll-y me-n5 pe-5'
          data-kt-scroll='true'
          data-kt-scroll-height='auto'
          data-kt-scroll-wrappers='#kt_my_appointment_body'
          data-kt-scroll-dependencies='#kt_my_appointment_header, #kt_my_appointment_footer'
          data-kt-scroll-offset='5px'
        >
          <div className='timeline'>
            <MegaMenu />
            {/* <MyAppointmentNotification /> */}
          </div>
        </div>
      </div>
      {/* <div className='card-footer py-5 text-center' id='kt_activities_footer'>
        <Link to='/crafted/pages/profile' className='btn btn-bg-body text-primary'>
          View All Activities
          <KTIcon iconName='arrow-right' className='fs-3 text-primary' />
        </Link>
      </div> */}
    </div>
  </div>
)

export { MyAppointmentDrawer }
