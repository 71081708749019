import React from 'react'
import { UseAppointmentModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useStudentSettingState } from '../../../user/hooks/useUserRepo'
import { UseUserModule } from '../../../user/store/main'
import { UseAddTrialClassInAppointment } from '../../../../me-booking/services/trail-class'
import { FilterStudentSetting } from '../../../user/interfaces'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { StudentSetting } from '../../../history-writing-response/interfaces'
import Swal from 'sweetalert2'
import GenericPaginator from '../../../../../components/common/Paginator'
import { AppointmentStatus } from '../../interfaces'

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
}
const AddTrailInAppointmentForm: React.FC<Props> = (props: Props) => {
  const queryClient = useQueryClient()
  const { itemSelected, mode, resetData } = UseAppointmentModule();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [studentSelected, setStudentSelected] = React.useState<StudentSetting>();
  const { data, isLoading, isFetching } = useStudentSettingState();
  const { 
    limit,
    paginator,
    currentPage,
    filterStudent, 
    where, 
    setWhere, 
    setLimit,
    setCurrentPage,
    setFilterStudent } = UseUserModule();

  const memorizedValues = React.useMemo(() => ({
    where, filterStudent, limit, currentPage
  }), [where, filterStudent, limit, currentPage]);

  const changePage = (number: number) => {
    setCurrentPage(number)
  }
  const changeLimit = (number: number) => {
    setLimit(number)
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Swal.fire({
      title: 'Are you sure?',
      html: "<p class='poppins-light'>You won't be able to revert this!</p>",
      icon: 'warning',
      iconColor: '#2d30e1',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: "<span class='poppins-light fs-4'>Yes!</span>",
      cancelButtonText: "<span class='poppins-light fs-4'>No</span>",
      customClass: {
        title: 'champ-light',
        confirmButton: 'confirm-button-sweet-alert',
        cancelButton: 'cancel-button-sweet-alert',
      },
    }).then(async(res) => {
      if ((!itemSelected || !itemSelected.id) || !studentSelected)
        return showSimpleAlert({ message: 'No hay ningun estudiante seleccionado', icon: 'error' });
      if (!res.isConfirmed) return; 

      if (itemSelected.status_appointment !== AppointmentStatus.PENDING)
        return showSimpleAlert({message: 'La clase no esta en estado pendiente', icon: 'error'}); 
      
      try {
        setLoading(true);
        await UseAddTrialClassInAppointment(itemSelected.id, studentSelected.id);
        showSimpleAlert({message: 'Clase Reservada!', icon: 'success'})
        resetData();
      } catch (e) {

      } finally {
        setLoading(false);
      }
    })
    queryClient.invalidateQueries({ queryKey: ['appointment'] });
  };

  React.useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['student-setting'] });
  }, [memorizedValues]);

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>

        {/* begin::Header */}

        <div className='card-body border-top p-8 row' /*style={{ height: 530 }}*/>
          <div className=''>
            <div className='row'>
              <div className='mb-1 col-9'>
                <div className='position-relative my-1'>
                  <i className='fa-solid fa-magnifying-glass svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-10'></i>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid ps-10'
                    placeholder=''
                    disabled={mode === 'VIEW'}
                    name={'where'}
                    value={where}
                    onChange={(e) => setWhere(e.target.value)}
                  />
                </div>
                <div>
                </div>
                {/* <label className='form-label mb-0'>Inicio</label> */}
              </div>
              <div className='mb-1 col-3 d-flex align-items-center'>
                <select
                  name='teacher'
                  value={filterStudent}
                  onChange={(e) => setFilterStudent(e.target.value as FilterStudentSetting)}
                  className='form-select-sm'
                  aria-label='Default select example'
                >
                  {Object.keys(FilterStudentSetting).map((x) => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </select>
              </div>

              <div className='col-12'>
                <div className=''>
                  <p className='d-flex'>
                    Estudiante Seleccionado:
                  </p>
                  {studentSelected && <>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {studentSelected.user.fullName}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {studentSelected.user.email}
                        </span>
                      </div>
                    </div>
                  </>}
                </div>
              </div>

              <div className='col-12'>
                <div className='card-body py-3'>
                  {!isLoading && data && (
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-140px'>Name</th>
                            <th className='min-w-120px'>Number</th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {data.result.map((x: any) => (
                            <tr key={x.id} className='p-0 cursor-pointer' onClick={() => setStudentSelected(x)}>
                              <td className='p-0'>
                                <div className='d-flex align-items-center'>
                                  {/* <div className='symbol symbol-45px me-5'>
                                    <img src={toAbsoluteUrl('/media/svg/icons/earth.svg')} alt='' />
                                  </div> */}
                                  <div className='d-flex justify-content-start flex-column'>
                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                      {x.user.fullName}
                                    </a>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                      {x.user.email}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className='p-0'>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                  {x.user.phoneNumber}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {/* end::Table body */}
                      </table>
                      <GenericPaginator
                        limit={limit}
                        currentPage={paginator.current_page}
                        items={paginator.result.length}
                        total={paginator.total}
                        totalPage={paginator.total_page}
                        changePage={changePage}
                        changeLimit={changeLimit}
                      />
                      {/* end::Table */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary'
            disabled={
              loading || mode === 'VIEW' ||
              !itemSelected || !studentSelected
            }>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { AddTrailInAppointmentForm }
