import React from 'react';
import clsx from 'clsx';
import { LinkComponent } from '../LinkComponent';
import { LinkComponentTwo } from '../LinkComponent/index_two';
import { NexLessonModal } from './modal';
import useTrailTask from '../student/store';
import { UseMyRoadmapModule } from '../../../profile/components/my-road-step/store';
import { SequenceType } from '../../../abroad/road-map-step/interfaces';

type Props = {};

const btnClass = 'btn btn-icon btn-active-light-primary w-50px h-50px w-md-60px h-md-60px';

const FloatingButton: React.FC<Props> = () => {
    const {modal,setModal} = useTrailTask();
    const { settings } = UseMyRoadmapModule.getState()
    return (
    <>
      {settings && settings.typeStep === SequenceType.STANDARD && <div
      className='d-none d-md-block '
      style={{   }}> 
            {/* begin::Drawer toggle */}
 
            <LinkComponentTwo
                url='#'
                colorTitle='#2d30e1'
                title=' '
                background='#1cebcc'
                className=' container-link-component-item py-2 '
                icon='/media/svg/michigan-icons/header/blue/roadmap_blue.svg'
           
              />
        
            {/* end::Drawer toggle */}
        </div>}
 
        <NexLessonModal show={modal} handleClose={() => alert()} />
    </>
    );
};

export { FloatingButton };