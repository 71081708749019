import { Route, Routes} from 'react-router-dom'
import { LearningPathDetails } from '../components/details'
import { LearningPathTableList } from '../components/list'
import { ChapterDashboard } from '../modules/chapter/components/dashboard'
import { LessonsDashboard } from '../modules/lessons/components/dashboard'
import { TopicsDashboard } from '../modules/topics/components/dashboard'

const LearningPathPage = () => (
  <Routes>
    <Route path='/topic/:id' element={<TopicsDashboard />} />
    <Route path='/lesson/:id' element={<LessonsDashboard />} />
    <Route path='/chapter/:id' element={<ChapterDashboard />} />
    <Route path='/details' element={<LearningPathDetails />} />
    <Route path='/' element={<LearningPathTableList />} />
  </Routes>
)

export {LearningPathPage}
