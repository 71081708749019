import {Route, Routes} from 'react-router-dom'
import { FrontPageEnglishExplorers } from '../english-explorers/page/components/front-page';
import { FrontPage } from '../components/front-page/list';
import { HeaderCourses } from '../components/header';
// import { ContainerFather } from '../components/container';
import { ContainerCourse } from '../screen/container';
import useAuth from '../../../api/main';
import { FrontTestPage } from '../components/front-page/list/test';

const CoursesPage = () => {
    const {typeUser} = useAuth()
    return(
    <Routes>
        {/* <Route path='/header' element={<HeaderCourses logoCourse={'https://michigan-b.s3.amazonaws.com/69f05a96-c313-45cc-adbf-80e8baa0a762.svg'} nameTopic='Image time' colorCourse='blue' />}/> */}
        {/* <Route path='/container' element={<ContainerFather />} /> */}
        {(typeUser === 'STUDENT' || typeUser === 'COURTESY') && <>
            <Route path='/container/:id' element={<ContainerCourse />} />
            <Route path='/:id' element={<FrontPage />} />
        </>}
        {(typeUser === 'ADMIN' || typeUser === 'TEACHER' || typeUser === 'ASSISTANT' || typeUser === 'ASSISTANT_WELCOME') && 
            <Route path='/test/:id' element={<FrontTestPage />} />    
        }
    </Routes>
)};

export {CoursesPage};