import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik, } from 'formik'
import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {HoursExperienceByStudent, StudentSettings, User} from '../../../interfaces'
import InputMask from 'react-input-mask';
import { UserAdd, UserUpdate } from '../../../services'
import { UseUserModule } from '../../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { UseCountryAll } from '../../../../country/services'
import { Country } from '../../../../country/interfaces'
import { showSimpleAlert } from '../../../../../../commonHooks/alert'
import { RoadMapStep, TraditionalLevelEnum } from '../../../../../abroad/road-map-step/interfaces'
import { FindHoursExperienceByStudent, UpgradeStudentAdd, UpgradeStudentManual } from '../../../services/student/UpgradeStudentService'
import { UseTrailModule } from '../../../store/student/main'
import { RoadMap } from '../../../../../abroad/road-map/interfaces'
import { UseRoadMapAll } from '../../../../../abroad/road-map/services'
import { RoadMapStepByRoadMap } from '../../../../../abroad/road-map-step/services'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import {PromotionHistoryType } from '../../../../student-actions/promotion-history/interfaces'

export type FormFields = {
    typeUpdate?: PromotionHistoryType;
    description?: string;
    isActive: boolean;
    hours: number;
    level: TraditionalLevelEnum;
    date: string;
    newCurrentStep: string;
}
const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const entitySchema = Yup.object().shape({
  typeUpdate: Yup.string()
    .required('Type of update is required'),
  description: Yup.string(),
  hours: Yup.number()
    .required('Hours are required')
    .min(0, 'Hours must be greater than or equal to 0'),
  level: Yup.string()
    .required('Level is required'),
  date: Yup.string()
    .required('Date is required'),
  newCurrentStep: Yup.string()
    .required('New current step is required'),
});


const CustomInput = props => (
  <InputMask {...props}>{inputProps => <input 
    style={{color:'black'}}
    {...inputProps} />}</InputMask>
);

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const UpgradeStudentForm: React.FC<Props>  = (props:  Props) => {
  const {t} = useTranslation();
  const queryClient = useQueryClient()
  //const {itemSelected, mode,type, resetData} = UseUserModule();
  const {itemSelectedStudent} = UseUserModule();
  const {itemSelectedUpgrade, mode, resetData} = UseTrailModule();

  const [roadMap, setRoadMap] = React.useState<RoadMap[]>([]);
  const [roadMapStep, setRoadMapStep] = React.useState<RoadMapStep[]>([]);
  const [roadMapId, setRoadMapId] = React.useState<string>('');
  const [experienceData, setExperienceData] = React.useState<HoursExperienceByStudent[]>([]);
  const [dataList, setDataList] = React.useState<HoursExperienceByStudent[]>([]);
  const [totalHours, setTotalHours] = React.useState<number>(0);
  const [upgradeType, setUpgradeType] = React.useState<PromotionHistoryType|"">("")

  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
  useFormik<FormFields>({
    initialValues: {
        isActive: (mode==='EDIT' || mode==='VIEW')? itemSelectedUpgrade?.isActive ?? true : true,
        hours: (mode==='EDIT' || mode==='VIEW')? itemSelectedUpgrade?.hours ?? 0 : 0,
        level: (mode==='EDIT' || mode==='VIEW')? itemSelectedUpgrade?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
        date: (mode==='EDIT' || mode==='VIEW')? itemSelectedUpgrade?.date ?? '' : '',
        description: (mode==='EDIT' || mode==='VIEW')? itemSelectedUpgrade?.description ?? '' : '',
        newCurrentStep: (mode==='EDIT' || mode==='VIEW')? itemSelectedUpgrade?.newCurrentStep ?? '' : '',
        typeUpdate: undefined
    },
    validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            // await UpgradeStudentManual(itemSelectedStudent?.id??'xxx', formData.newCurrentStep, upgradeType!== '' ? upgradeType : PromotionHistoryType.UPGRADE, formData.date, false)
            // await UpgradeStudentAdd({
            //   ...formData,
            //   studentSetting: itemSelectedStudent?.id??'xxx'
            // })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['user'] })
            }catch(e){
              setLoading(false);
            }
          }
          else{
            try{
              setLoading(true);
              // await UpgradeStudentManual(itemSelectedStudent?.id??'xxx', formData.newCurrentStep, upgradeType!== '' ? upgradeType : PromotionHistoryType.UPGRADE, formData.date, false)
              // await  UpgradeStudentAdd({
              //   ...formData,
              //   studentSetting: itemSelectedStudent?.id??'xxx'
              // })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['user'] })
              
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

    React.useEffect(() => {
      const fetchRoadMap = async () => {
        try {
          let roadMapData = await UseRoadMapAll();
          setRoadMap(roadMapData)
        } catch (e) {
          showSimpleAlert({ message: 'Error al encontrar Road Map!', icon: 'error' })
          throw 'Error';
        }
      };
      fetchRoadMap();
    }, []);

    React.useEffect(() => {
      if (itemSelectedStudent) {
        const findHoursExperience = async () => {
          try {
            const response = await FindHoursExperienceByStudent(itemSelectedStudent?.id??'xxx');
            let updateResponse = response.map((x) => ({...x, hoursRemaining: Math.max(0, x.hoursToSee - x.hours)}))
            setExperienceData(updateResponse);
          } catch (e) {
            showSimpleAlert({ message: 'Error al encontrar las experecias del estudiante!', icon: 'error' })
            throw 'Error';
          }
        };
        findHoursExperience();
      };
    }, [itemSelectedStudent?.id]);

    React.useEffect(() => {
      if (roadMapId) {
        const fetchRoadMapStep = async () => {
          try {
            let response = await RoadMapStepByRoadMap(roadMapId);
            setRoadMapStep(response);
          } catch (e) {
            showSimpleAlert({ message: 'Error al encontrar Road Map Step!', icon: 'error' })
            throw 'Error';
          }
        };
        fetchRoadMapStep();
      }
    }, [roadMapId]);
    
    React.useEffect(() => {
      if (experienceData && values.newCurrentStep) {
        let total = calculateHoursByLevel(values.newCurrentStep);
        setTotalHours(total);
      }
      if (!values.newCurrentStep) {
        setTotalHours(0);
      }
    }, [values.newCurrentStep]);

    React.useEffect(() => {
      if (totalHours > 0) {
        alertHoursLeftover();
      }
    }, [dataList, totalHours])

    const calculateHoursByLevel = (level: string) => {
      let totalHours = 0;
      let result:any[] = [];
      let levelIndex = experienceData.findIndex(x => x.id === level);
      if (levelIndex === -1) {
        showSimpleAlert({ message: `Nivel no encontrado.`, icon: 'error' })
        throw 'Error';
      };

      for (let i = levelIndex; i >= 0; i--) {
        const currentObject = experienceData[i];
        result.push(currentObject);
        totalHours += currentObject.hoursRemaining??0;
      }
      setDataList(result); 
      return totalHours;
    };

    const alertHoursLeftover = () => {

      Swal.fire({
        html: 
        `<div class='poppins-light d-flex flex-column'>
          ${dataList.map(item => `<span>${t(item.level)} - ${item.hoursRemaining}</span>`)}
        </div>`,
        icon: 'warning',
        iconColor: '#2d30e1',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonText: "<span class='poppins-light fs-4'>Yes!</span>",
        cancelButtonText: "<span class='poppins-light fs-4'>No</span>",
        customClass: {
          title: 'champ-light',
          confirmButton: 'confirm-button-sweet-alert',
          cancelButton: 'cancel-button-sweet-alert',
        }}).then(async(result) => {
          if (result.isDismissed) {
            resetData();
          }
        })
    }

    const handleRoadMapChange = (e) => {
      const selectedId = e.target.value;
      setRoadMapId(selectedId);
    };

    const filterLevels = () => {
        if(itemSelectedStudent){
          const levelValues = Object.values(TraditionalLevelEnum);
          const currentIndex = levelValues.indexOf(itemSelectedStudent.level);
      
          if (upgradeType === PromotionHistoryType.UPGRADE) {
            return levelValues.slice(currentIndex + 1); // Filtrar niveles superiores
          } else if (upgradeType === PromotionHistoryType.DOWNGRADE) {
            return levelValues.slice(0, currentIndex); // Filtrar niveles inferiores
          }
          return levelValues; // Si no hay upgradeType, mostrar todos los niveles}
      } else {
        return Object.values(TraditionalLevelEnum);
      }
    }


    return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Level</th>
                  <th>Hours</th>
                  <th>Road Map Name</th>
                  <th>Road Map Step Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t(itemSelectedStudent?.level as string)}</td>
                  <td>{itemSelectedStudent?.hours}</td>
                  <td>{itemSelectedStudent?.currentStep?.roadMap?.name}</td>
                  <td>{itemSelectedStudent?.currentStep?.name}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          {/* <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Hours</label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                name={'hours'}
                value={values.hours}
                onChange={handleChange}
              />
              {touched.hours && errors.hours && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.hours}</span>
                  </div>
                </div>
              )}
            </div> */}
            <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Upgrade or Downgrade</label>
            <select
              disabled={mode === 'VIEW'}
              name='typeUpdate'
              value={values.typeUpdate}
              onChange={(e) => {
                handleChange(e);
                const selectedValue = e.target.value;
                if (selectedValue === PromotionHistoryType.UPGRADE || selectedValue === PromotionHistoryType.DOWNGRADE) {
                  setUpgradeType(selectedValue as PromotionHistoryType);
                } else {
                  setUpgradeType('');
                }
              }}
              className='form-select'
              aria-label='Default select example'
            >
              <option value='' disabled selected>Select an option</option>
              {Object.keys(PromotionHistoryType).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.level && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.level}</span>
                </div>
              </div>
            )}
          </div>
            {upgradeType && 
            <>
            <div className='mb-10 col-6'>
              <label className='form-label mb-0'>level</label>
              <select
                disabled={mode === 'VIEW'}
                name='level'
                value={values.level}
                onChange={handleChange}
                className='form-select'
                aria-label='Default select example'
                > 
                {filterLevels().map((x) => (
                  <option key={x} value={x}>
                    {t(x)}
                  </option>
                ))}
                {/* {Object.keys(TraditionalLevelEnum).map((x) => (
                  <option key={x} value={x}>
                    {t(x)}
                  </option>
                ))} */}
              </select>
              {errors.level && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.level}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-1 col-6'>
                <label className='form-label mb-0'>Date Change</label>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'date'}
                  value={values.date}
                  onChange={handleChange}
                  />
                {touched.date && errors.date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.date}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='mb-1 col-6'>
                <label className='form-label mb-0'>Road Map</label>
                <select
                  name='roadMap'
                  className='form-select'
                  aria-label='Default select example'
                  onChange={handleRoadMapChange}
                  value={roadMapId}
                  >
                  <option value='' selected>
                    
                  </option>
                  {roadMap?.map((x, i) => (
                    <option value={x.id} key={i}>
                      {x.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='mb-1 col-6'>
                <label className='form-label mb-0'>Road Map Step</label>
                <select
                  name='newCurrentStep'
                  className='form-select'
                  aria-label='Default select example' 
                  onChange={handleChange}
                  value={values.newCurrentStep}
                  >
                  <option value='' selected>
                    
                  </option>
                  {roadMapStep?.map((x, i) => (
                    <option value={x.id} key={i}>
                      {x.name}
                    </option>
                  ))}
                </select>
                
                {errors.newCurrentStep && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.newCurrentStep}</span>
                    </div>
                  </div>
                )}
              </div>
              </>
              }

        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          {totalHours > 0 && 
            <div className='me-3 btn pe-none' style={{backgroundColor: '#ff336b'}}>
              <span>Horas pendientes</span>
            </div>
          }
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {UpgradeStudentForm}
