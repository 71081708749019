import { Campus } from "../../../abroad/campus/interfaces";
import { TraditionalLevelEnum,SettingPlacementTestStatus,SettingWelcomeDayStatus,SettingVerificationStatus } from "../../../abroad/road-map-step/interfaces";
import { PromotionHistoryType } from "../../student-actions/promotion-history/interfaces";

export interface DataPaginateUserResponse {
    status:  string;
    message: string;
    data:    DataUser;
}

export interface DataAllUserResponse {
    status:  string;
    message: string;
    data:    User[];
}

export interface DataUser {
    id?: string;
    email: string
    phoneNumber: string
    fullName: string
    isActive: boolean
    roles?: string[]
    type?: string
    profile?: Profile
    placementTest?:SettingPlacementTestStatus
    welcomeDay?:SettingWelcomeDayStatus
    verification?:SettingVerificationStatus
    avatar?: string
    country?: any
    createdAt?:string;
    updatedAt?:string;
}

export interface User  {
    id?: string
    email: string
    phoneNumber: string
    fullName: string
    isActive: boolean
    roles?: string[]
    type?: string
    profile?: Profile
    avatar?: string
    country?: any
    document_id?:string;
}

export interface Pagination {
    result: User[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export interface Profile {
    id?: string
    country?: string
    firstName?: string
    lastName?: string
    documentNumber?: string
    gender?: string
    zipCode?: string
    city?: string
    address?: string
    state?: string
    birthDay?: string
    personalWeb?: string
    btcWallet?: string
    tronWallet?: string
    phoneNumber?: string
    aboutMe?: string
    photo?: any
  }

export interface StudentSettings {
    id?: string;
    code: string;
    codeContract: string;
    roadMap: any;
    currentStep: any;
    description?: string;
    isActive: boolean;
    start: string;
    finish: string;
    hours: number;
    hoursActivity: number;
    hoursUnits: number;
    campus?: any;
    placementTest?:SettingPlacementTestStatus
    welcomeDay?:SettingWelcomeDayStatus
    verification?:SettingVerificationStatus
    user: any;
    level: TraditionalLevelEnum;
    classAvailable: number;
    status: SettingStudentStatus;
    modality: SettingModalityType;
}


export interface StudentSettingsData {
    id?: string;
    code: string;
    codeContract: string;
    roadMap: any;
    currentStep: any;
    description?: string;
    isActive: boolean;
    start: string;
    finish: string;
    hours: number;
    hoursActivity: number;
    hoursUnits: number;
    user: any;
    campus?: Campus;
    placementTest?:SettingPlacementTestStatus
    welcomeDay?:SettingWelcomeDayStatus
    verification?:SettingVerificationStatus
    level: TraditionalLevelEnum;
    classAvailable: number;
    status: SettingStudentStatus;
    daysFreezingAvailable: number;
    modality: SettingModalityType;
}

export interface TrailUpgradeStudent {
    description?: string;
    isActive: boolean;
    hours: number;
    level: TraditionalLevelEnum;
    date: string;
    studentSetting: any;
    newCurrentStep?: any;
    typeUpdate?: PromotionHistoryType
}

export enum SettingStudentStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    SANCTIONED = 'SANCTIONED',
    FROZEN = 'FROZEN',
    SUSPENDED = 'SUSPENDED',
    GRADUATED = 'GRADUATED'
}

export interface HoursExperienceByStudent {
    level: string;
    id: string;
    hours: number;
    hoursToSee: number;
    hoursRemaining:number;
    order: number;
}

export enum SettingModalityType {
    VIRTUAL = 'VIRTUAL',
    FACE = 'FACE',
    ALTERNATION = 'ALTERNATION',
}

export enum FilterStudentSetting {
    ID = 'ID',
    CONTRACT = 'CONTRACT',
    CODE = 'CODE',
    NAME= 'NAME',
    EMAIL='EMAIL',
    PHONE='PHONE',
    DATE_FINISH = 'DATE_FINISH',
    DATE_START = 'DATE_START',
    DATE_CREATED = 'DATE_CREATED',
};