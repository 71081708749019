
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { StudentSetting } from '../../../../history-writing-response/interfaces'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { BooleanCell } from '../../../../../../components/common/BooleanCell'
import { UpdateTestStatus } from '../../../../placement-test/services'
import { TaskStatus } from '../../../../tasks/interfaces'
import useAuth from '../../../../../../api/main'
import { showSimpleAlert } from '../../../../../../commonHooks/alert'
import { useState } from 'react'
import { UseQualifyPlacementModule } from '../../placement-test/store/main'

export interface ContactStudentInterface {
  needsContact: boolean,
  hasBeenContacted: boolean, 
  contactedBy: string,
  taskId: string,
  taskStatus: TaskStatus
}

type Props = {
  show: boolean,
  student: StudentSetting,
  setShow: (v:boolean) => void,
  contactInfo: ContactStudentInterface
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ContactStudentModal = ({show, student, setShow, contactInfo}: Props) => {
  const {fullName} = useAuth()
  const {reload, setReload} =UseQualifyPlacementModule()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    needsContact,
    hasBeenContacted, 
    contactedBy,
    taskId,
    taskStatus
  } = contactInfo
  const updateStudentContacted = async()=>{
    try{
      setIsLoading(true)
      await UpdateTestStatus(taskId, taskStatus === TaskStatus.IN_REVIEW ? TaskStatus.REPROVED : taskStatus, needsContact, true, fullName)
      setShow(false)
      setReload(!reload)
    } catch {
      showSimpleAlert({icon: 'error', message: 'Error al cambiar estado'})
    } finally {
      setIsLoading(false)
    }
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered modal-lg modal-fullscreen-md-down'
      show={show}
     // onHide={handleClose}
      //   onEntered={loadStepper}
      backdrop={true}
    >

      <div className='modal-header'>
        <h2>{`Contactar a ${student.user.fullName}`}</h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={()=>setShow(false)}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div
        style={{
          position: 'relative',
          padding: '50px',
          overflowY: 'auto',
        }}
      >
        <table className="table">
          <thead>
              <tr>
                  <th className='text-center align-middle champ-bold'>Nombre</th>
                  <th className='text-center align-middle champ-bold'>Teléfono</th>
                  <th className='text-center align-middle champ-bold'>Correo</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <th className='poppins-bold  text-muted text-center' scope="row">{student.user.fullName}</th>
                  <td className='poppins-bold  text-muted text-center'>{student.user.phoneNumber}</td>
                  <td className='poppins-bold  text-muted text-center'>{student.user.email}</td>
              </tr>
          </tbody>
      </table>
      <hr></hr>
      <table className="table">
          <thead>
              <tr>
                  <th className='text-center align-middle champ-bold'>¿Quiere ser contactado?</th>
                  <th className='text-center align-middle champ-bold'>¿Ya fue contactado?</th>
                  {hasBeenContacted && 
                    <th className='text-center align-middle champ-bold'>Contactado por</th>
                  }
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td className='poppins-bold  text-muted text-center'><BooleanCell value={needsContact}/></td>
                  <td className='poppins-bold  text-muted text-center'><BooleanCell value={hasBeenContacted}/></td>
                  {hasBeenContacted && 
                    <td className='poppins-bold  text-muted text-center'>{contactedBy}</td>
                  }
              </tr>
              <tr>
                <th colSpan={hasBeenContacted? 3 : 2} className='text-center align-middle pt-5'>
                  <button 
                    type='button' 
                    disabled={isLoading}
                    className='btn btn-success mt-5'
                    onClick={()=>updateStudentContacted()}
                  >   
                  {isLoading ? 
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                    :
                    <span>
                      Marcar como contactado
                    </span>
                  }
                  </button>
                </th>
              </tr>
          </tbody>
      </table>
      </div>
    </Modal>, 
    modalsRoot
  )
}

export {ContactStudentModal}
