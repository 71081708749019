import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UseSocket from './modules/auth/services/SocketServices'
import './hooks/i18n'
import useAuthCheck from './hooks/useAuthChecked'
import { authChecked } from './modules/auth/services/auth.services'
const App = () => {
  let socket = UseSocket()
  useAuthCheck(authChecked, 5400000);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </ThemeModeProvider>
          <ToastContainer />
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
