import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { PromotionHistoryForm } from '../forms'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { UseTrailModule } from '../../../../user/store/student/main'
import { StudentSetting } from '../../../../history-writing-response/interfaces'
import { UseQualifyPlacementModule } from '../../../../qualify/modules/placement-test/store/main'
type Props = {
  show: boolean
  student: StudentSetting
  taskId?: string
  typeModal: 'PLACEMENT' | 'STUDENT'
}

const modalsRoot = document.getElementById('root-modals') || document.body

const UpgradeStudentModal = ({show, student, taskId, typeModal}: Props) => {
  const {mode, setModalUpgradeStudent} = UseTrailModule();
  const {reload, setReload} =UseQualifyPlacementModule()
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px modal-fullscreen-sm-down'
      show={show}
      backdrop={true}
    >
      <div className='modal-header champ-bold'>
        {mode === 'ADD' && <h2>Crear Upgrade para {student.user.fullName}</h2>}
        {mode === 'EDIT' && <h2>Editar </h2>}
        {mode === 'VIEW' && <h2>Ver </h2>}
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={()=>{
          setModalUpgradeStudent(false)
          setReload(!reload)
          }}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>
      <PromotionHistoryForm mode={mode} student={student} taskId={taskId} typeModal={typeModal}/> 
    </Modal>,
    modalsRoot
  )
}

export {UpgradeStudentModal}
