import {Route, Routes} from 'react-router-dom'
import { CustomDashboardMain } from '../components'

const  CustomDashboard = () => (
  <Routes>
      <Route path='/' element={<CustomDashboardMain />} />
  </Routes>
)

export {CustomDashboard}
