import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { UseCountryAll } from '../../../../admin/country/services';
import { Country } from '../../../../admin/country/interfaces';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { UseMyRoadmapModule } from '../../../../profile/components/my-road-step/store';
import { GetCourtesyDataUseCase, UpdateCourtesyCodeUseCase } from '../services';
import useAuth from '../../../../../api/main';

export type FormFieldsCourtesy = {
  id?: string
  email: string
  phoneNumber: string
  fullName: string
  password?: string
  country?: string
  document_id?: string;
};

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const entitySchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  fullName: Yup.string().required(' name is required'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Formato de número de teléfono incorrecto')
    .required('El número de teléfono es obligatorio'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  // country: Yup.string().required('País es requerido'),
})

const CourtesyRegisterDataForm = () => {

  const {courtesy, settings, setCourtesy} = UseMyRoadmapModule();
  const {id, typeUser} = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<Country[]>([]);

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<FormFieldsCourtesy>({
      initialValues: {
        email: '',
        phoneNumber: '',
        fullName: '',
        // country: '',
        password: '',
        document_id: ''
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (courtesy && !courtesy.isUsed) {
          try {
            setLoading(true);
            await UpdateCourtesyCodeUseCase(courtesy.id, {
              ...formData,
              userId: id??'',
              courtesy: courtesy.type,
              type: typeUser,
              isActive: true
            });

            if (settings && typeUser === 'COURTESY') {
              const response = await GetCourtesyDataUseCase(settings.codeContract);
              setCourtesy(response);
            };

            showSimpleAlert({message: '¡Credenciales registradas correctamente!', icon: 'success'})
            //  await UserAdd({
            //    ...formData,
            //    email: formData.email.toLowerCase()
            //  });
            setLoading(false)
          } catch (e) {
            setLoading(false);
          }
        }
      },
    })


  // const fetchCountries = async () => {
  //   try {
  //     let countries = await UseCountryAll()
  //     setCountries(countries)
  //   } catch (e) {
  //     console.log(e)
  //     showSimpleAlert({ message: 'Error al encontar paises!', icon: 'error' })
  //     throw 'Error'
  //   }
  // }
  // useEffect(() => {
  //   fetchCountries()
  // }, [])

  return (<>
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body p-9 py-0 row poppins-light'>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3 poppins-bold'>Email</label>
            <input
              type='email'
              className='form-control form-control-lg form-control-solid'
              placeholder='dummy@gmail.com'
              disabled={courtesy ? courtesy.isUsed : true}
              name={'email'}
              value={values.email}
              onChange={handleChange}

            />
            {errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.email}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3 poppins-bold'>Username </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Artemisa'
              disabled={courtesy ? courtesy.isUsed : true}
              name={'fullName'}
              value={values.fullName}
              onChange={handleChange}
            />
            {errors.fullName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.fullName}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3 poppins-bold'>Teléfono </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='+57321280903'
              disabled={courtesy ? courtesy.isUsed : true}
              name={'phoneNumber'}
              value={values.phoneNumber}
              onChange={handleChange}
            />
            {errors.phoneNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.phoneNumber}</span>
                </div>
              </div>
            )}
          </div>

          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-3 poppins-bold'>País</label>
            <select
              disabled={courtesy ? courtesy.isUsed : true}
              name={'country'}
              value={values.country}
              onChange={handleChange}
              className="form-select" aria-label="Default select example">
              <option selected></option>
              {countries.map((x) => (<option value={x.id}>{x.name}</option>))}
            </select>
            {errors.country && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.country}</span>
                </div>
              </div>
            )}
          </div> */}

          <div className='mb-10 col-6'>
            <label className='form-label mb-3 poppins-bold'>Numero de Identificación</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='0000000000'
              disabled={courtesy ? courtesy.isUsed : true}
              name={'document_id'}
              value={values.document_id}
              onChange={handleChange}
            />
            {errors.document_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.document_id}</span>
                </div>
              </div>
            )}
          </div>

          {courtesy && !courtesy.isUsed && <div className='mb-10 col-6'>
            <label className='form-label mb-3 poppins-bold'>Password </label>
            <input
              type='password'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'password'}
              value={values.password}
              onChange={handleChange}
            />
            {errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.password}</span>
                </div>
              </div>
            )}
          </div>}

        {courtesy && !courtesy.isUsed && <div className='d-flex justify-content-end py-0 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||courtesy ? courtesy.isUsed : true}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>}
        </div>
      </form>
    </div>
  </>)
}

export { CourtesyRegisterDataForm }