import { useState, useEffect } from 'react';
import  moment from 'moment';

interface DateHookResult {
  loading: boolean;
  error: string | null;
  dates: Date[];
}

const useDateRange = (excludedDates: moment.Moment[]): DateHookResult => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [dates, setDates] = useState<Date[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const startDate = moment().subtract(10, 'months');
        const endDate = moment().add(10, 'months');

        const allDates: moment.Moment[] = [];
        let currentDate = startDate.clone();

        while (currentDate.isSameOrBefore(endDate, 'day')) {
          allDates.push(currentDate.clone());
          currentDate.add(1, 'day');
        }

        const filteredDates = allDates.filter(date => !excludedDates.some(excludedDate => excludedDate.isSame(date, 'day')));
        const arrayDeFechas: Date[] = filteredDates.map(momentObj => momentObj.toDate());


        setDates(arrayDeFechas);
        setLoading(false);
      } catch (error) {
        setError('Error al generar las fechas.');
        setLoading(false);
      }
    };

    fetchData();
  }, [excludedDates]);

  return { loading, error, dates };
};

export default useDateRange;