import React from 'react'
import { UseEvaluationCourseModule } from '../../screen/store/main'
import { useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { EvaluationType } from '../../../admin/evaluation/interfaces'
import { confirmWelcomeDay, FinishWelcome, UpdateTestStatus } from '../../../admin/placement-test/services'
import { UseModeCourseModule } from '../front-page/store/main'
import FeedbackResponses from './feedbackResponses'
import useIsImportantEvaluation from '../../hooks/useIsImportantEvaluation'
import { TaskStatus, TaskType } from '../../../admin/tasks/interfaces'
import { UseMyRoadmapModule } from '../../../profile/components/my-road-step/store'
import { SequenceType } from '../../../abroad/road-map-step/interfaces'
import { AddTaskByStudentSettingsManual } from '../../../abroad/trail/services'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import useAuth from '../../../../api/main'


const FinalTestRightContent: React.FC = () => {
  const {
    currentTaskId,
    evaluationLesson,
    evaluationResponses,
    lessonCourse,
    setShowEndEvaluation,
    setStepEvaluation,
  } = UseEvaluationCourseModule()
  const { modeViewAnswer } = UseModeCourseModule()
  const [isMovil,setIsMovil] = React.useState(false)
  const isImportantQuiz = useIsImportantEvaluation()
  const { settings } = UseMyRoadmapModule();
  const { lessonsId } = UseModeCourseModule();
  const { typeUser } = useAuth()

  const navigate = useNavigate();

  const isWelcomeDay = evaluationLesson?.type === EvaluationType.WELCOME_DAY;

  const nextLessonFunction = React.useCallback(async () => {
    let currentChapter: undefined | any = undefined;
    if (lessonCourse && lessonsId) {
      currentChapter = lessonsId.find(x => x.id === lessonCourse.id)
    }
    if (currentChapter && lessonsId) {
      const chapterLessons = lessonsId.filter(x => x.chapter_order === currentChapter.chapter_order).sort((a, b) => a.order - b.order);
      let nextLesson = chapterLessons.find(x => x.order > currentChapter.order)
      if (nextLesson && nextLesson.statusTask === 'NOT_TASK') {
        try {
          let status = !nextLesson.isDisabled ? TaskStatus.APPROVED : TaskStatus.PENDING;
          //añadir la siguiente tarea
          await AddTaskByStudentSettingsManual({
            code: 'xx',
            description: '',
            isActive: true,
            lesson: nextLesson.id,
            student_setting: settings?.id ?? 'xx',
            isArtificial: false,
            type: nextLesson.typeScreen === 'QUIZ' ? TaskType.QUIZ : 
            nextLesson.typeScreen === 'FINAL_PRESENTATION' ? TaskType.FINAL_PRESENTATION : TaskType.NORMAL,
            status: status,
            isComplete: status === TaskStatus.APPROVED ? true : false
          });
          showSimpleAlert({ icon: 'success', message: 'lección completada' })
        } catch (e) {
          console.error(e)
          showSimpleAlert({ icon: 'error', message: 'Error al crear la siguiente tarea' })
        }
      }
    }
  }, [settings, lessonsId, lessonCourse]);

  // React.useEffect(() => {
  //   if (settings) {
  //     if (settings.typeStep === SequenceType.STANDARD) {
  //       nextLessonFunction();
  //     }
  //   }
  // }, [settings, lessonsId]);

  React.useEffect(() => {
    const handleResize= () => {
      if (window.innerWidth < 768) {
        setIsMovil(true)
      } else {
        setIsMovil(false)
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className='d-flex flex-column  justify-content-between'
      style={{
        height: '420px',
        borderRadius: isMovil ?'0px 0px 50px 50px' : '0px 50px 50px 0px',
        background: 'white',
        position: 'relative',
        border: '1px solid',
        borderColor: '#3e94ff',
        padding: '50px',
        overflowY: 'auto',
      }}
    >
      <div className='d-flex justify-content-end bg-body champ-bold'>
        <h2 style={{ color: '#3e94ff' }}>
          {modeViewAnswer ? 'Respuestas correctas' : isWelcomeDay ? 'Tus respuestas' : 'Feedback'}
        </h2>
      </div>
      <FeedbackResponses />
      <div className='d-flex justify-content-end bg-body'>
        <button
          type='button'
          className='cursor-pointer bg-body'
          style={{ backgroundColor: 'none', border: 'none' }}
          onClick={async () => {
            if (settings && settings.typeStep === SequenceType.STANDARD && typeUser === 'STUDENT') {
              await nextLessonFunction();
            }
            if (currentTaskId) {
              if (isImportantQuiz) {
                await UpdateTestStatus(currentTaskId, TaskStatus.IN_REVIEW)
              }
              if (evaluationLesson && evaluationLesson.type === EvaluationType.WELCOME_DAY) {
                if (evaluationResponses.every((x) => x.percentage === 100)) {
                  await FinishWelcome(currentTaskId)
                } else {
                  await confirmWelcomeDay(currentTaskId)
                }
                return
              }
            }
            setShowEndEvaluation(false)
            setStepEvaluation(0)
            navigate('/dashboard')
          }}
        >
          <img
            height={30}
            src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_session.svg`)}
            alt=''
          />
        </button>
      </div>
    </div>
  )
}

export default FinalTestRightContent
