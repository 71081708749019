import React, {useEffect} from 'react'
import moment from 'moment'
import {useParams} from 'react-router-dom'
import {useThemeMode} from '../../../../../../../_metronic/partials'
import {StudentSetting} from '../../../../history-writing-response/interfaces'
import {CardHeaderModule} from '../../../../../../components/common/widgets/statistics/cardHeaderModule'
import CustomDatePicker from '../../../../../../components/common/DayPicker/DayPickerInputWithImage'
import AllExcel from '../../../../../../components/common/AllExcel'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import GenericPaginator from '../../../../../../components/common/Paginator'
import {TableItem} from './tableItem'
import {PromotionHistoryTableItem} from '../../interfaces'
import {UpgradeStudentModal} from '../modal'
import {UseTrailModule} from '../../../../user/store/student/main'
import {StudentSettingsOneByUser, UseTrailUpgradeStudent} from '../../services'
import { UsePromotionHistoryModule } from '../../store/main'
import { UseQualifyPlacementModule } from '../../../../qualify/modules/placement-test/store/main'

type Props = {}

export type StudentSettingsResponse = {
  response: any
  newMode: 'ADD' | 'EDIT' | 'VIEW'
}

const PromotionHistoryTableList: React.FC<Props> = ({}) => {
  const {id} = useParams()
  const {mode} = useThemeMode()

  const {modalUpgrade, setModalUpgradeStudent} = UseTrailModule()

  const {
    after,
    before,
    paginator,
    limit,
    currentPage,
    setCurrentPage,
    setLimit,
    setBefore,
    setAfter,
  } = UsePromotionHistoryModule()

  const {reload} = UseQualifyPlacementModule()

  const [studentSettings, setStudentSettings] = React.useState<StudentSetting>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [promotionHistory, setPromotionHistory] = React.useState<PromotionHistoryTableItem[]>([])
  const [hasInputDates, setHasInputDates] = React.useState<boolean>(false)

  //datos para el excel:
  const theadName = [
    'Fecha',
    'Tipo de cambio',
    'Nivel previo',
    'Nuevo nivel',
    'Cambio por',
    'Observación'
  ]

  const handleBeforeDate = (date: Date) => {
    setHasInputDates(true)
    setBefore(date)
  }

  const handleAfterDate = (date: Date) => {
    setHasInputDates(true)
    setAfter(date)
  }

  const changePage = (number: number) => {
    setCurrentPage(number)
};
  const changeLimit =(number:number)=>{
      setLimit(Math.min(number, 20))
  }

  const handleInitialStudentSettings = async () => {
    const response = await StudentSettingsOneByUser(id ?? '')
    setStudentSettings(response)
  }

  useEffect(() => {
    handleInitialStudentSettings()
  }, [id, reload])
  
  const findPromotionHistory = async () => {
    if (studentSettings) {
        setLoading(true);
        try {
            let upgrades;
            if (hasInputDates) {
                upgrades = await UseTrailUpgradeStudent({
                    root: studentSettings.user.id,
                    isActive: true,
                    before: before,
                    after: after,
                    limit: limit,
                    page: currentPage,
                });
            } else {
                upgrades = await UseTrailUpgradeStudent({
                    root: studentSettings.user.id,
                    isActive: true,
                    before: moment().startOf('year').toDate(),
                    after: moment().endOf('year').toDate(),
                    limit: limit,
                    page: currentPage,
                });
            }
            setPromotionHistory(upgrades.result);
        } catch (e) {
            console.error('error', e);
        }
        setLoading(false);
    }
};

React.useEffect(() => {
    if (studentSettings) {
      findPromotionHistory()};
}, [studentSettings, before, after, limit, currentPage, reload]);
  
  return (
    <>
      <CardHeaderModule
        title={'Historial de Ascensos'}
        leftIcon={`/media/svg/michigan-icons/header/${
          mode === 'light' ? 'white/back_white' : 'dark-modules/back_orange'
        }.svg`}
        svgIcon={`/media/svg/michigan-icons/header/${
          mode === 'light' ? 'white/history_white' : 'dark-modules/history_orange'
        }.svg`}
        background={'#4bb543'}
        colorTitle={'#FFFFFF'}
        routeBack={'/user/student-setting'}
      />

      <div className='row mt-3'>
        <div className='col-md-8'>
          <div
            className='card mb-5 w-100'
            style={{
              backgroundColor: mode === 'light' ? '#E6E6FA' : '',
              borderRadius: 30,
              border: mode === 'dark' ? '1px solid green' : '',
            }}
          >
            <div className='row'>
              <div className='col-md-6 d-flex'>
                <span
                  className='poppins-bold fs-bold ps-10 d-flex align-items-center'
                  style={{color: mode === 'light' ? '#2d30e1' : '#E6E6FA', fontSize: 15}}
                >
                  {`Desde: ${moment(before).format('DD-MM-YYYY')}`}
                </span>
                <div className='ps-5'>
                  <CustomDatePicker
                    calendarPosition='right'
                    valueCalendarPosition={-100}
                    symbolWidth='25px'
                    onSelectDate={(date) => {
                      handleBeforeDate(date)
                    }}
                    calendarIconSrc='/media/svg/michigan-icons/elements/calendar_blue.svg'
                    buttonStyle={{marginLeft: '5px'}}
                  />
                </div>
              </div>
              <div className='col-md-6 d-flex'>
                <span
                  className='poppins-bold ps-10 d-flex align-items-center'
                  style={{color: mode === 'light' ? '#2d30e1' : '#E6E6FA', fontSize: 15}}
                >
                  {`Hasta: ${moment(after).format('DD-MM-YYYY')}`}
                </span>
                <div className='ps-5'>
                  <CustomDatePicker
                    symbolWidth='25px'
                    onSelectDate={(date) => {
                      handleAfterDate(date)
                    }}
                    calendarIconSrc='/media/svg/michigan-icons/elements/calendar_blue.svg'
                    buttonStyle={{marginLeft: '5px'}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='d-flex flex-column flex-lg-row'>
            <div className='flex-grow-1 mb-5'>
              <AllExcel
                dataExcel={promotionHistory}
                filename={`MeResume`}
                sheetName='Resume'
                theadName={theadName}
                type='MeReport'
                backgroundColor={mode === 'light' ? 'green' : ''}
                titleColor={mode === 'light' ? '#FFFFFF' : 'green'}
              />
            </div>
            <div className='d-flex  mb-5 justify-content-end align-content-end'>
              <button
                type='button'
                onClick={async () => {
                  setModalUpgradeStudent(true)
                }}
                className={`btn btn-${mode === 'light' ? '' : 'outline-'}success ms-5 rounded-pill`}
              >
                <span
                  className='d-flex justify-content-center align-items-center champ-light'
                  style={{color: mode === 'light' ? 'white' : '#ff0e0e'}}
                >
                  <i className='bi bi-plus-circle' />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`card content-report`}
        style={{border: `1px solid ${mode === 'light' ? '#2d30e1' : 'green'}`}}
      >
        <div className='card-body pt-2'>
          {studentSettings && (
            <div
              className='row d-flex align-middle'
              style={{color: mode === 'light' ? '#2d30e1' : '#FFFFFF'}}
            >
              <div className='col mt-2 d-flex'>
                <div className=' pt-5'>
                  <span className='poppins-bold fs-3'>Estudiante:</span>
                </div>
                <div className='pt-5 ps-3'>
                  <span className='poppins-light fw-bold fs-3'>
                    {studentSettings.user.fullName}
                  </span>
                </div>
              </div>
              <div className='col mt-2 d-flex'>
                <div className='pt-5'>
                  <span className='poppins-bold fs-3'>Email:</span>
                </div>
                <div className='pt-5 ps-3'>
                  <span className='poppins-light fw-bold fs-3'>{studentSettings.user.email}</span>
                </div>
              </div>
              <div className='col mt-2 d-flex'>
                <div className='pt-5'>
                  <span className='poppins-bold fs-3'>Teléfono:</span>
                </div>
                <div className='pt-5 ps-3'>
                  <span className='poppins-light fw-bold fs-3'>
                    {studentSettings.user.phoneNumber}
                  </span>
                </div>
              </div>
              <div className='col mt-2 d-flex'>
                <div className='pt-5'>
                  <span className='poppins-bold fs-3'>Horas:</span>
                </div>
                <div className='pt-5 ps-3'>
                  <span className='poppins-light fw-bold fs-3'>
                    {studentSettings.hours}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div
            className='card mt-8 content-student'
            style={{border: mode === 'light' ? '2px solid #2d30e1' : '2px solid #FFFFFF'}}
          ></div>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr
                  className='fw-bold poppins-bold fs-5'
                  style={{
                    color: mode === 'light' ? '#2d30e1' : 'white',
                    borderBottom: `1px solid ${mode === 'light' ? '#2d30e1' : '#FFFFFF'}`,
                  }}
                >
                  <th className='min-w-150px ps-0 pb-5'> Fecha </th>
                  <th className='min-w-150px ps-0 pb-5'> Tipo de cambio</th>
                  <th className='min-w-150px ps-0 pb-5'> Nivel previo </th>
                  <th className='min-w-150px ps-0 pb-5'> Nuevo nivel </th>
                  <th className='min-w-150px ps-0 pb-5'> Cambio por </th>
                  <th className='min-w-150px ps-0 pb-5'> Observación </th>
                </tr>
              </thead>
              <tbody className='mt-2'>
                {loading && (
                  <tr>
                    <td colSpan={6} className='text-center'>
                      <div className='d-flex justify-content-center align-items-center'>
                        <img
                          className='rounded mx-auto'
                          width={'30%'}
                          src={toAbsoluteUrl('/media/svg/icons/loading.svg')}
                          alt='Loading'
                        />
                      </div>
                    </td>
                  </tr>
                )}
                {!loading &&
                  promotionHistory &&
                  promotionHistory?.map((x) => (
                    <>
                      <TableItem item={x} />
                    </>
                  ))}
              </tbody>
            </table>
            <GenericPaginator
              limit={limit}
              currentPage={paginator.current_page}
              items={paginator.result.length}
              total={paginator.total}
              totalPage={paginator.total_page}
              changePage={changePage}
              changeLimit={changeLimit}
            />
          </div>
        </div>
      </div>
      {studentSettings && <UpgradeStudentModal typeModal={'STUDENT'} show={modalUpgrade} student={studentSettings}/>}
    </>
  )
}

export {PromotionHistoryTableList}
