import React, { useEffect } from 'react'
import { CreateModal } from '../modal'
import { useQueryClient } from '@tanstack/react-query'
import { UseMeReportModule } from '../../store/main'
import * as Swal from 'sweetalert2'
import { MeReportDelete } from '../../services'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import GenericPaginator from '../../../../components/common/Paginator'
import moment from 'moment'
import { UseFindPaginatedTrailClass } from '../../../me-booking/services/trail-class'
import { Booking } from '../../../admin/appointment/interfaces'
import AllExcel from '../../../../components/common/AllExcel'
import { CardHeaderModule } from '../../../../components/common/widgets/statistics/cardHeaderModule'
import CustomDatePicker from '../../../../components/common/DayPicker/DayPickerInputWithImage'
import { useThemeMode } from '../../../../../_metronic/partials'
import { TableItem } from './tableItem'
import { UseTrailStrikeMe } from '../../../admin/trail-suspense/services'
import { UseBookingModule } from '../../../me-booking/store';
import './index.css'
import { useTranslation } from 'react-i18next'

type PaginatorSetter ={
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
}
type Props = {

}
const MeReportTableList: React.FC<Props> = ({ }) => {
  const {mode} = useThemeMode();
  const queryClient = useQueryClient();
  const { 
      suspense,
      after,
      before,
      paginator, 
      modal,
      limit,
      currentPage,
      isActive,
      where,
      setCurrentPage,
      setLimit,
      setSuspense,
      setPaginator,
      setBefore,
      setAfter
  } = UseMeReportModule()
  const {level,setStrikes,strikes} = UseBookingModule();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [bookingAvailable, setBookingAvailable] = React.useState<Booking[]>([]);

  const theadName = [
    "FECHA",
    "BLOQUE HORARIO",
    "ASISTENCIA",
    "ACTIVIDAD",
    "SALON",
    "PROFESOR",
    "ASISTENCIA PROFESOR",
    "ESTRELLAS",
    "",
    "OBSERVACION ESTUDIANTE",
    "OBSERVACION PROFESOR"
  ]
    // nota que le da al estudiante el profe
  const changePage = (number: number) => {
      setCurrentPage(number)
  };
  const changeLimit =(number:number)=>{
      setLimit(number)
  }

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['me-report'] })
  }, [isActive,where,currentPage,limit]);

  const findBookingAvailable = async () => {
    setLoading(true)
    let starterList = await UseFindPaginatedTrailClass({
      before: moment(before).format('YYYY-MM-DD').toString(),
      after: moment(after).format('YYYY-MM-DD').toString(),
      limit: limit,
      currentPage: currentPage,
    },);
    setLoading(false)
    setPaginator(starterList);
    setBookingAvailable(starterList?.result)
  };

  const findTrailStrikes = async () => {
    try{
      let response = await UseTrailStrikeMe();
      setStrikes(response);
    }catch(e){
      console.log(e)
    }
  }

  React.useEffect(() => {
    findTrailStrikes();
  }, [level])

  React.useEffect(() => {
    if (after && before) {
      findBookingAvailable();
    }
  }, [after, before, currentPage, limit]);

  const handleBeforeSelectDate = (date: Date) => {
    setBefore(date);
  };

  const handleAfterSelectDate = (date: Date) => {
    setAfter(date);
  };

  return (<>
    <CardHeaderModule
        title={'Historial de Reservas'} 
        leftIcon={`/media/svg/michigan-icons/header/${mode==='light'?'white/back_white':'dark-modules/back_orange'}.svg`}
        svgIcon={`/media/svg/michigan-icons/header/${mode==='light'?'white/history_white':'dark-modules/history_orange'}.svg`}
        background={'#ff7500'} 
        colorTitle={'#FFFFFF'}
    />
      
      <div className='row mt-3'>
        <div className='col-md-8'>
          <div className='card mb-5 w-100' style={{ backgroundColor:mode==='light'?'#E6E6FA':'', borderRadius: 30, border: mode==='dark'?'1px solid #E6E6FA':'' }}>
            <div className="row">
              <div className='col-md-6 d-flex'>
                <span className='poppins-bold fs-bold ps-10 d-flex align-items-center'
                  style={{ color:mode==='light'?'#2d30e1':'#E6E6FA', fontSize: 15 }}    
                >
                  {`Desde: ${moment(before).format('DD-MM-YYYY')}`}
                </span>
                <div className='ps-5'>
                  <CustomDatePicker
                    initialValues={before}
                    calendarPosition='right'
                    valueCalendarPosition={-100}
                    symbolWidth='25px'
                    onSelectDate={handleBeforeSelectDate}
                    calendarIconSrc="/media/svg/michigan-icons/elements/calendar_blue.svg"
                    buttonStyle={{ marginLeft: '5px' }}
                  />
                </div>
              </div>
              <div className='col-md-6 d-flex'>
                <span className='poppins-bold ps-10 d-flex align-items-center'
                  style={{ color: mode==='light'?'#2d30e1':'#E6E6FA', fontSize: 15 }}   
                >
                  {`Hasta: ${moment(after).format('DD-MM-YYYY')}`}
                </span>
                <div className='ps-5'>
                  <CustomDatePicker
                    symbolWidth='25px'
                    onSelectDate={handleAfterSelectDate}
                    calendarIconSrc="/media/svg/michigan-icons/elements/calendar_blue.svg"
                    buttonStyle={{ marginLeft: '5px' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='d-flex flex-column flex-lg-row'>
            <div className="flex-grow-1 mb-5">
              <AllExcel
                dataExcel={bookingAvailable} 
                filename={`MeResume`} 
                sheetName='Resume' 
                theadName={theadName}
                type='MeReport'
                backgroundColor={mode==='light'?'#ff7500':''}
                titleColor={mode==='light'?'#FFFFFF':'#ff7500'}
              />
            </div>
            <div style={{flex:'0 0 2%'}}></div>
            <div className='flex-grow-1 mb-5'>
              <a href='/strikes' className={`btn ${mode==='light'&&'btn-danger'} w-100`} style={{borderRadius:30,border:mode==='dark'?'1px solid #ff0e0e':''}}>
                <span className='d-flex justify-content-center align-items-center champ-light' style={{color:mode==='light'?'white':'#ff0e0e'}}>
                  Strikes
                  <span className='champ-light ms-5'>
                    {strikes?.total??0}
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

    <div className={`card content-report`} style={{ border: `1px solid ${mode==='light'?'#2d30e1':'#FFFFFF'}` }}>
      <div className='card-body pt-2'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold poppins-bold fs-5' style={{ color:mode==='light'?'#2d30e1':'white', borderBottom: `1px solid ${mode==='light'?'#2d30e1':'#FFFFFF'}` }}>
                <th className='min-w-150px ps-0' style={{ paddingBottom: '5px' }}>Fecha</th>
                <th className='min-w-130px ps-0' style={{ paddingBottom: '5px' }}>Inicio</th>
                <th className='min-w-130px ps-0' style={{ paddingBottom: '5px' }}>Final</th>
                <th className='min-w-150px ps-0' style={{ paddingBottom: '5px' }}>Tipo</th>
                <th className='min-w-150px ps-0' style={{ paddingBottom: '5px' }}>Sesion</th>
                <th className='min-w-150px ps-0' style={{ paddingBottom: '5px' }}>Salon</th>
                <th className='min-w-150px ps-0' style={{ paddingBottom: '5px' }}>Profesor</th>
                <th className='min-w-150px ps-0' style={{ paddingBottom: '5px' }}>Estado</th>
                <th className='min-w-150px ps-0' style={{ paddingBottom: '5px' }}>Encuesta</th>
              </tr>
            </thead>
            <tbody className='mt-2'>
             {loading &&<tr className='text-center'>
                  <img  
                  className="rounded mx-auto d-block" 
                  width={'70%'}
                  src={toAbsoluteUrl('/media/svg/icons/loading.svg')} alt='' />
              </tr>}
              {!loading && bookingAvailable && bookingAvailable?.map((x) => (
                <>
                  <TableItem item={x}/>
                </>
              ))}
            </tbody>
          </table>
              <GenericPaginator 
                  limit={limit}
                  currentPage={paginator.current_page}
                  items={paginator.result.length}
                  total={paginator.total}
                  totalPage={paginator.total_page}
                  changePage={changePage}
                  changeLimit={changeLimit}
              />
        </div>
      </div>
    </div>
    <CreateModal show={modal} handleClose={() => alert()} />
  </>
  )
}

export { MeReportTableList }
