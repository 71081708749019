import { DataStudentStatusManager, StudentStatusManager } from '../interfaces/index'
import useAuth from '../../../../../api/main'
import { catchServerError } from '../../../../../hooks/useCatchError'
import { UseStudentStatusManagerModule } from '../store/main'
import { api } from '../../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { Pagination } from '../../../../auth/interfaces'

const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export const useStudentStatusManagerAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/student-status-manager/simple?type_category=all`,
    {
      headers: {
        Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}
export const UseStudentStatusManagerAllPaginate = async (id: string): Promise<Pagination<StudentStatusManager>> => {
  try {
    // await authChecked()
    const { setPaginator, limit, currentPage, where, isActive } = await UseStudentStatusManagerModule.getState()

    const response: any = await api.get(
      `/student-status-manager/paginated?root=${id}&isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const StudentStatusManagerAdd = async (data: DataStudentStatusManager): Promise<any> => {
  try {
    const response: any = await api.post(
      '/student-status-manager',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const StudentStatusManagerUpdate = async (data: DataStudentStatusManager): Promise<any> => {
  try {
    const { itemSelected } = UseStudentStatusManagerModule.getState()
    const response: any = await api.patch(
      `/student-status-manager/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const StudentStatusManagerDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/student-status-manager/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {

    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
