import React, { useEffect, useRef, useState } from 'react'
import {UseMyRoadmapModule} from '../../../profile/components/my-road-step/store'
import {useThemeMode} from '../../../../../_metronic/partials'
import {useTranslation} from 'react-i18next'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {HoursExperienceByStudent} from '../../../admin/user/interfaces'
import {FindHoursExperienceByStudent, UpgradeStudentManual} from '../../../admin/user/services/student/UpgradeStudentService'
import {showSimpleAlert} from '../../../../commonHooks/alert'
import {useNavigate} from 'react-router-dom'
import {logoAppSelectorUrl} from '../../../../hooks/useAppSelectorAssetsMode'
import useAuth from '../../../../api/main'
import { TraditionalLevelEnum } from '../../../abroad/road-map-step/interfaces'
import Swal from 'sweetalert2'
import { UpgradeMySelfModal } from './changeLevelModal'
import moment from 'moment'
import { PromotionHistoryType } from '../../../admin/student-actions/promotion-history/interfaces'

type Props = {}

const MyCoursesTableList: React.FC<Props> = () => {
  const {settings} = UseMyRoadmapModule()
  const {mode} = useThemeMode()
  const {t} = useTranslation()
  const navigate = useNavigate()
  let url = logoAppSelectorUrl()
  const [experienceData, setExperienceData] = React.useState<HoursExperienceByStudent[]>([])
  const [currentStep, setCurrentStep] = React.useState<number>(0)
  const [reload, setReload] = useState<boolean>(false)
  // const [studentSettings, setStudentSettings] = React.useState<StudentSetting>()
  const {id, typeUser} = useAuth()
  const [currentLevel, setCurrentLevel] = useState<TraditionalLevelEnum>(TraditionalLevelEnum.PRE_A1_1)
  const [show, setShow] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  React.useEffect(() => {
    if (settings && settings.id) {
      const findHoursExperience = async () => {
        try {
          const response = await FindHoursExperienceByStudent(settings.id ?? 'xxx')
          let updateResponse = response.map((x) => ({
            ...x,
            hoursRemaining: Math.max(0, x.hoursToSee - x.hours),
          }))
          setExperienceData(updateResponse)
        } catch (e) {
          showSimpleAlert({
            message: 'Error al encontrar las experiencias del estudiante!',
            icon: 'error',
          })
          throw 'Error'
        }
      }
      findHoursExperience()
      setCurrentStep(settings?.roadMap?.step?.find((x) => x.isCurrentStep)?.order ?? 0)
      if(settings.roadMap.step.length>0 ){
        setCurrentLevel(settings?.roadMap?.step?.find(x=>x.isCurrentStep)?.level as TraditionalLevelEnum ?? TraditionalLevelEnum.PRE_A1_1)
      }
    }
  }, [id, settings])

  // const handleInitialStudentSettings = async () => {
  //   const response = await StudentSettingsOneByUser(id ?? '')
  //   setStudentSettings(response)
  // }

  // useEffect(() => {
  //   handleInitialStudentSettings()
  // }, [id])

  const handleRestartMyLevel = ()=>{
    Swal.fire({
      title: `<strong class=''>¿Esta seguro de reiniciar su nivel actual?</strong>`,
      html: 
      `<div>
        <span class='fs-3 text-secondary-emphasis warning-text-emphasis'> Solo puede usar estos cambios 3 veces.</span><br/><br/>
        <p class='fs-5 text-decoration-underline text-danger'>Perderá su progreso en el nivel actual<p>
      <div/>`,
      showCancelButton: true,
      confirmButtonText: "<span class='poppins-light fs-4'>Estoy seguro, quiero reiniciar</span>",
      cancelButtonText: "<span class='poppins-light fs-4'>No estoy seguro</span>",
      width: 600,
      allowOutsideClick: false,
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-success"
      }
    }).then(async(result) => {
      if (result.isConfirmed) {
        try{
          restartCurrentLevel()
        }catch(e){
          
        }
      } else {
        showSimpleAlert({message: 'reinicio cancelado', icon: 'info'});
      }
    })
  }

  const handleChangeMyLevel = ()=>{
    Swal.fire({
      title: `<strong class='fs-1'>¿Esta seguro de cambiar su lección actual?</strong>`,
      html: 
      `<div>
        <span class='fs-3 text-secondary-emphasis warning-text-emphasis'> Solo puede usar estos cambios 3 veces.</span><br/><br/>
        <p class='fs-5 text-decoration-underline text-danger'>Perderá su progreso en el nivel actual<p>
      <div/>`,
      showCancelButton: true,
      confirmButtonText: "<span class='poppins-light fs-4'>Estoy seguro, quiero cambiar de nivel</span>",
      cancelButtonText: "<span class='poppins-light fs-4'>No estoy seguro</span>",
      width: 600,
      allowOutsideClick: false,
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-success"
      }
    }).then(async(result) => {
      if (result.isConfirmed) {
        try{
          setShow(true)
        }catch(e){
          
        }
      } else {
        showSimpleAlert({message: 'reinicio cancelado', icon: 'info'});
      }
    })
  }

  const restartCurrentLevel = async ()=> {
    if(settings){
      try{
        
        // setLoading(true);
        await UpgradeStudentManual({
          studentSetting: settings?.id, 
          newCurrentStep: settings?.roadMap?.step?.find(x=>x.isCurrentStep)?.id ?? "", 
          promotionType: PromotionHistoryType.DOWNGRADE, 
          date:  moment().format('YYYY-MM-DD'), 
          hasLessons: false, 
          description: `Reinicio del nivel ${settings.level} por el mismo estudiante`,
          additionalHours: 0
        })
        showSimpleAlert({message: 'Tu nivel ha sido reiniciado', icon: 'success'});
        // queryClient.invalidateQueries({ queryKey: ['user', 'promotion-history'] })
        }catch(e){
          console.error(e)
        }  finally{
          // setLoading(false);
        }
      }
  }

  const getBackgroundColor = (level: TraditionalLevelEnum):string =>{
    switch (level) {
      case TraditionalLevelEnum.PRE_A1_1:
        return '#ff336b'
      case TraditionalLevelEnum.PRE_A1_2:
      case TraditionalLevelEnum.PRE_A2:
      case TraditionalLevelEnum.PRE_B1:
        return '#a700ba'
      case TraditionalLevelEnum.A1:
      case TraditionalLevelEnum.A2:
      case TraditionalLevelEnum.B1:
      case TraditionalLevelEnum.B2:
        return '#3e94ff'
      case TraditionalLevelEnum.C1:
        return '#00ebd4'
    }
  }
  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        setIsOverflowing(containerRef.current.scrollWidth > containerRef.current.clientWidth);
      }
    };

    // Check on mount and on window resize
    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  return (
    <>
      <div
        className='card cards-general-radius-payments'
        style={{backgroundColor: mode === 'light' ? '#E6E6FA' : ''}}
      >
        <div className='card-body pt-0' style={{color: mode === 'light' ? '#2d30e1' : '#FFFFFF'}}>
          <div className='d-none d-md-flex d-flex row mt-8 mb-0'>
            <div className='col-md-4 d-flex flex-column'>
              <h3
                className='champ-light michigan-title-payments-top'
                style={{color: mode === 'light' ? '#2d30e1' : '#FFFFFF'}}
              >
                {url.name}
              </h3>
              <div className='mb-0'>
                <div className='icon-left-payments'>
                  <img
                    src={toAbsoluteUrl('/media/svg/michigan-icons/header/blue/back_blue.svg')}
                    alt=''
                    onClick={() => navigate('/dashboard')}
                    style={{
                      cursor: 'pointer',
                      transition: 'transform 0.2s ease-in-out',
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                    onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                  />
                </div>
              </div>
              <span className='champ-light title-card-payments'>{t('ROADMAP')}</span>
            </div>
            
            <div className='col-md-6 d-flex justify-content-center p-0'>
              <div className='row'>
                <div className='col-6 d-flex justify-content-end'>
                  <div
                    className='rounded-circle'
                    style={{backgroundColor: '#ff336b', width: '18px', height: '18px'}}
                  />
                </div>
                <div className='col-6'>
                  <span className='poppins-light fs-5'>Explorers</span>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                  <div
                    className='rounded-circle'
                    style={{backgroundColor: '#a700ba', width: '18px', height: '18px'}}
                  />
                </div>
                <div className='col-6'>
                  <span className='poppins-light fs-5'>Prismatic</span>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                  <div
                    className='rounded-circle'
                    style={{backgroundColor: '#3e94ff', width: '18px', height: '18px'}}
                  />
                </div>
                <div className='col-6'>
                  <span className='poppins-light fs-5'>Abroad</span>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                  <div
                    className='rounded-circle'
                    style={{backgroundColor: '#00ebd4', width: '18px', height: '18px'}}
                  />
                </div>
                <div className='col-6'>
                  <span className='poppins-light fs-5'>Preparación examen internacional</span>
                </div>
              </div>
            </div>
            
            <div className='col-md-2 d-flex justify-content-end'>
              <div className='icon-right-payments'>
                <img
                  src={toAbsoluteUrl(
                    `/media/svg/michigan-icons/header/${
                      mode === 'light' ? 'blue/my_courses_blue' : 'white/roadmap_white'
                    }.svg`
                  )}
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {typeUser !== 'COURTESY' && typeUser !== 'PLACEMENT_TEST' && 
        <div
          className='card mt-8 content-student'
          style={{border: mode === 'light' ? '2px solid #2d30e1' : '2px solid #FFFFFF'}}
        >
          <div className='d-flex flex-column flex-md-row p-4 justify-content-between' style={{color: mode === 'light' ? '#2d30e1' : '#FFFFFF'}}>
            <div className='d-flex flex-column flex-md-row align-items-center align-items-md-center justify-content-start text-center text-md-start'>
              <span className='poppins-bold fs-3 ms-md-5' onClick={()=>console.log(settings)}>Nivel actual:</span>
              <span className='poppins-bold fs-3 ms-md-5'>{t(`${currentLevel}`)}</span>
            </div>
            <div className='d-flex flex-column flex-md-row align-items-center align-items-md-center justify-content-end text-center text-md-start mt-3 mt-md-0'>
              <span className='poppins-bold fs-3 mx-md-5'>Cambiar de nivel</span>
              <button className='btn btn-primary py-2 mx-md-3 mt-3 mt-md-0' onClick={handleRestartMyLevel}>Reiniciar nivel</button>
              <button className='btn btn-success py-2 mx-md-3 mt-3 mt-md-0' onClick={handleChangeMyLevel}>Cambiar de lección</button>
            </div>
          </div>
        </div>
      }
      <div className='card mt-3 p-0' style={{borderRadius: 30}}>
        <div className='card-body'>
          <div
            ref={containerRef}
            className={`d-flex ${isOverflowing ? 'justify-content-start' : 'justify-content-center'} align-content-start overflow-x-auto px-5 w-100`}
          >
            {settings &&
              settings?.roadMap?.step?.map((x, i) => {
                let courseActual = experienceData.find((y) => y.id === x.id)
                const value = courseActual
                  ? courseActual?.hoursRemaining === 0
                    ? 100
                    : Math.min( 100, (1 - courseActual.hoursRemaining / courseActual.hoursToSee) * 100)
                  : 0
                let percentage = Math.max(0, value).toFixed(0)
                const order = x.order < currentStep
                return (
                  <div
                    className='d-flex flex-column'
                    key={i}
                    style={{marginRight: '-1px', marginLeft: '-1px'}}
                  >
                    <div
                      className={`mt-4 border-bottom-0`}
                      style={{
                        borderRadius: '75px 75px 0 0',
                        border: mode === 'light' ? (i % 2 === 0 ? '2px solid #2d30e1' : '') : '',
                        paddingLeft: i % 2 === 0 ? '' : '2px',
                        paddingRight: i % 2 === 0 ? '' : '2px',
                      }}
                    >
                      <div
                        className='m-4 mb-0'
                        style={{
                          height: '50px',
                          backgroundColor: `${getBackgroundColor(x.level as TraditionalLevelEnum)}`,
                          borderRadius: '75px 75px 0 0',
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            content: '',
                            position: 'absolute',
                            bottom: '0',
                            left: '0',
                            width: '100%',
                            height: '150px',
                            borderRadius: '0 0 75px 75px',
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className='border-bottom-0 border-top-0 mt-0'
                      style={{border: mode === 'light' ? '2px solid #2d30e1' : ''}}
                    >
                      <div className='ms-4 me-4'
                        style={{
                          backgroundColor: `${getBackgroundColor(x.level as TraditionalLevelEnum)}`
                        }}
                      >
                        <div className='pt-0 pb-20 p-8 d-flex flex-column justify-content-center align-items-center'>
                          <span className='fs-1 mb-8 symbol symbol-40px'>
                            <img
                              src={toAbsoluteUrl(
                                `/media/svg/michigan-icons/${
                                  x.isCurrentStep
                                    ? 'header/white/my_booking_eye'
                                    : order
                                    ? 'elements/ok_white'
                                    : 'elements/equis_white'
                                }.svg`
                              )}
                              alt=''
                            />
                          </span>
                          <span className='fs-4 champ-light'>{percentage}%</span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={` border-bottom-0 border-top-0`}
                      style={{
                        border: mode === 'light' ? (i % 2 === 0 ? '' : '2px solid #2d30e1') : '',
                        paddingLeft: i % 2 === 0 ? '2px' : '',
                        paddingRight: i % 2 === 0 ? '2px' : '',
                      }}
                    >
                      <div
                        className={`ms-4 me-4 mb-4`}
                        style={{
                          height: '50px',
                          borderRadius: '0 0 75px 75px',
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            content: '',
                            position: 'absolute',
                            bottom: '0',
                            left: '0',
                            width: '100%',
                            height: '150px',
                            backgroundColor: `${getBackgroundColor(x.level as TraditionalLevelEnum)}`,
                            borderRadius: '75px 75px 0 0',
                          }}
                        />
                      </div>
                    </div>
                    
                    <div
                      className={`border-top-0 mt-0`}
                      style={{
                        borderRadius: '0 0 75px 75px',
                        border: mode === 'light' ? (i % 2 === 0 ? '' : '2px solid #2d30e1') : '',
                      }}
                    >
                      <div className='ms-4 me-4 mb-4' style={{color: '#2d30e1'}}>
                        <span
                          className='champ-bold d-flex flex-column justify-content-center align-items-center text-center'
                          style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
                        >
                          {x.name.split(" ").map((word, index) => (
                            <span key={index}>{word}</span>
                          ))}
                          <span className='champ-light'>{t(x.level)}</span>
                        </span>
                        <span className='champ-light d-flex flex-column justify-content-center align-items-center fs-9'>
                          Lessons: {x.lessons}
                          <span className='champ-light'>Max hours: {x.hours}</span>
                          <span className='champ-light'>Min hours: {x.hoursToSee}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      <UpgradeMySelfModal show={show} setShow={setShow} reload={reload} setReload={setReload}/>
    </>
  )
}

export {MyCoursesTableList}
