import moment from 'moment';
import 'moment-timezone';

const UseTranslateTimeZone = (date: string) => {
  // Supongamos que recibes una fecha en Colombia (zona horaria 'America/Bogota')
  const dateColombia = moment.tz(date, 'America/Bogota');

  // Convertir la fecha a la zona horaria local del usuario
  const currentDate = dateColombia.clone().tz(moment.tz.guess());

  return currentDate;
};

export default UseTranslateTimeZone;