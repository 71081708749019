import {Route, Routes} from 'react-router-dom'
import { SurveySectionDetails } from '../components/details'
import { SurveySectionTableList } from '../components/list'


const SurveySectionPage = () => (
  <Routes>
      <Route path='/details' element={<SurveySectionDetails />} />
      <Route path='/' element={<SurveySectionTableList />} />
  </Routes>
)

export {SurveySectionPage}
