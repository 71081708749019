export interface DataPaginateSurveySectionResponse {
    status:  string;
    message: string;
    data:    DataSurveySection;
}

export interface DataAllSurveySectionResponse {
    status:  string;
    message: string;
    data:    SurveySection[];
}

export interface DataSurveySection {
    id?: string;
    question:string;
    type: QuestionTypeSurveySection;
    order: number;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface SurveySection  {
    id?: string;
    question:string;
    type: QuestionTypeSurveySection;
    order: number;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export enum QuestionTypeSurveySection {
    TRUE_FALSE = 'TRUE_FALSE',
    TEXT = 'TEXT'
}