import useAuth from "../../../../../api/main"
import { showSimpleAlert } from "../../../../../commonHooks/alert"
import { catchServerError } from "../../../../../hooks/useCatchError";
import { api } from "../../../../auth/services/auth.services"

export const GetCourtesyDataUseCase = async (code: string): Promise<any> => {
    const { token, logout } = useAuth.getState();
    try {
        const response: any = await api.get(
            `/courtesy-code/${code}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        return response.data
    } catch (e: any) {
        catchServerError(e.response);
    }
}

export const UpdateCourtesyCodeUseCase = async (id: string, data: any): Promise<any> => {
    const { token, logout } = useAuth.getState();
    try {
        const response: any = await api.patch(
            `/courtesy-code/change-status/${id}`,
            {
                ...data
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        return response.data
    } catch (e: any) {
        catchServerError(e.response);
    }
}