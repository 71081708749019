
import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FillTheGapsProps } from './types';
import Gap from './Gap';
import './index.css';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { UseParagraphDragDropModule } from './store';
import { showSimpleAlert } from '../../../../commonHooks/alert';
import { AddDragDropResponse } from './services';
import HeaderCard from '../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import WordWordBoxReading from './word-word-box-reading';
import { UseBookStudentModule } from '../../../book/modules/screen/store/main';
import { ButtonTryAgain } from '../common/button-try-again/buttonTryAgain';
import { UseBookContainerModule } from '../../../book/store/main';
import { ButtonSaveExercise } from '../common/button-save-exercise/buttonSaveExercise';

const   WordBoxReadingDragAndDrop: React.FC<FillTheGapsProps> = ({colorSecondary, userMode }) => {

  let {
    allWords,
    percentage,
    answerStudent,
    instructions,
    exerciseId,
    sentences: sentence,
    setSentencesConstructed,
    setWords,
    words
  } = UseParagraphDragDropModule();

  const {
    lessonData,
    stepBookItem,
    currentTaskId,
    setStepBookItem,
    addCompletedExerciseId,
    onChangeTaskByAnswer,
    setIsLoadingSubmit
  } = UseBookStudentModule();

  const {setOffDraggable} = UseBookContainerModule();
  const [reviewMode, setReviewMode] = useState<boolean>(false);
  const [number, setNumber] = useState(0);
  const [usedWords, setUsedWords] = useState<{ [key: string]: boolean }>({
  });
  const [filledGaps, setFilledGaps] = useState<{ [key: string]: string }>({
  });
  const [resetFlag, setResetFlag] = useState(0);
  const [gapsCorrectness, setGapsCorrectness] = useState<{ [key: string]: boolean }>({});
  const [hasChecked, setHasChecked] = useState(false);

  React.useEffect(() => {
    if (answerStudent) {
      setFilledGaps(answerStudent.filledAnswerGaps);
      setUsedWords(answerStudent.usedAnswerWords)
      setReviewMode(true);
      setHasChecked(true);
      checkSentence();
    }
  }, [answerStudent]);

  const handleFillGap = (gapId: string, wordId: string) => {
    setFilledGaps((prevFilledGaps) => ({
      ...prevFilledGaps,
      [gapId]: wordId,
    }));
    setUsedWords((prevUsedWords) => ({
      ...prevUsedWords,
      [wordId]: true,
    }));
    setSentencesConstructed(getUserConstructedSentence())

  };

  function calculateAccuracyPercentage(totalQuestions: number, correctAnswers: number): number {
    if (totalQuestions > 0) {
      const percentage: number = (correctAnswers / totalQuestions) * 100;
      return percentage;
    } else {
      return 0;
    }
  }

  const checkSentence = () => {
    const totalGaps = sentence.reduce((acc, sent) => acc + (sent.match(/\{.*?\}/g) || []).length, 0);
    let _trueWords: string[] = [];
    let _falseWords: string[] = [];
    let _allWords: string[] = [];
    const newGapsCorrectness = Object.keys(filledGaps).reduce((acc, gapId) => {
      const wordId = filledGaps[gapId];
      _allWords.push(wordId)
      // alert(gapId)
      const wordItem = words.find(word => word.id === wordId);
      const isCorrect = wordItem ? wordItem.text === gapId : false;
      if (isCorrect) {
        _trueWords.push(gapId)
      } else {
        _falseWords.push(gapId)
      }
      acc[wordId] = isCorrect;
      return acc;
    }, {} as { [key: string]: boolean });
    setGapsCorrectness(newGapsCorrectness);
    const isOverallCorrect = Object.values(newGapsCorrectness).every(value => value);
    // alert(isOverallCorrect ? 'Correct!' : 'Incorrect, try again.');
    let totalPercentage = calculateAccuracyPercentage(totalGaps, _trueWords.length)
    setWords({
      percentage: totalPercentage,
      allWords: {
        filledAnswerGaps: filledGaps,
        usedAnswerWords: usedWords
      },
      hasChecked: true
    })

  };
  
  const resetExercise = () => {
    setFilledGaps({

    });
    setUsedWords({

    });
    setResetFlag(flag => flag + 1);
    setHasChecked(false)
    setNumber(0)
  };

  const handleRemoveWord = (gapId: string, wordId: string) => {
    setFilledGaps((prev) => {
      const newState = { ...prev };
      delete newState[gapId];
      return newState;
    });
    setUsedWords((prev) => {
      const newState = { ...prev };
      delete newState[wordId];
      return newState;
    });
  };
  // Función para incrementar el número
  const increment = () => {
    const currentNumber = number
    const maxNumber = sentence.length
    if(currentNumber+1 < maxNumber){
      setNumber(prevNumber => prevNumber + 1);
    } else {
      setHasChecked(true);
      checkSentence()
    }
  };

  // Función para disminuir el número
  const decrement = () => {
    setNumber(prevNumber => prevNumber - 1);
  };
  const getUserConstructedSentence = () => {
    return sentence.map((sent) => {
      const parts = sent.split(/(\{.*?\})/).filter(part => part);
      const completedParts = parts.map((part) => {
        if (part.startsWith('{') && part.endsWith('}')) {
          const gapId = part.replace(/[{}]/g, '');
          const wordId = filledGaps[gapId];
          const wordItem = words.find(word => word.id === wordId);
          return wordItem ? wordItem.text : part;
        }
        return part;
      });
      return completedParts.join('');
    }).join(' ');
  };

  const handleSubmit = async () => {
    if(userMode==='STUDENT' && currentTaskId && exerciseId){
      try {
        setIsLoadingSubmit(true)
        const response = await AddDragDropResponse({
          percentage: percentage,
          description: '',
          isActive: true,
          true_answer: allWords,
          exercises: exerciseId ?? '',
          task: currentTaskId ?? 'xx'
        });
        onChangeTaskByAnswer(response, 'drag_drop');
      } catch (e) {
        showSimpleAlert({ message: 'Error al cargar respuesta.', icon: 'error' });
        console.error(e);
      } finally {
        setIsLoadingSubmit(false)
      }
    }
    if (lessonData && lessonData.evaluation && 
      lessonData.evaluation.evaluation_item){
      addCompletedExerciseId(exerciseId??"")
      if(lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
        setStepBookItem(exerciseId??'')
      } else {
        setStepBookItem('FINISH_LESSON')
      }
    } 
  }

  const divStyle = {
    backgroundColor: '#3f8bff', width: '535px',
    height: '400px', borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)', borderStyle: 'solid',
    padding: '27px 20px 20px 20px'
  };

  return (<>
    <DndProvider backend={HTML5Backend}>
      <div className="container-fluid " style={{ display: 'flex' }}
        onMouseUp={() => setOffDraggable(false)}
      >
        <div style={divStyle}>
          <HeaderCard />
          <div className="scroll-y overflow-y-auto mb-6" style={{maxHeight:'70px', maxWidth:'445px'}}>
          <p className=" text-white mx-5 mt-5 mb-0 poppins-light fs-9 " >
            {instructions}
          </p>
          </div>
          <div className='row'   // Evita que comience el arrastre
            onMouseDown={() => setOffDraggable(true)}
            >
            <div className="col-6 my-3 scroll-y"style={{height:'180px'}}>
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center', // Centra los elementos horizontalmente
                gap: '4px', // Espaciado entre elementos
              }}>
                {words.map((word) => (
                  <WordWordBoxReading key={word.id} word={word} isUsed={!!usedWords[word.id]} exerciseId={exerciseId??"dragDropBook"}/>
                ))}
              </div>
            </div>
            <div className='col-6 my-3 scroll-y' style={{height:'180px'}}>
              <p className='champ-light text-end' style={{
                marginTop: 0,
                marginBottom: 0,
                color: '#3e94ff',
                fontSize: 5
              }}>{`${number + 1}${'/'}${sentence.length}`}</p>
              {sentence.map((sentence, sIndex) => (
                <div className='poppins-light' key={sIndex}
                  style={{
                    color: 'white',
                    fontSize: 14,
                    
                  }}>
                  {sentence.split(/(\{.*?\})/).filter(part => part).map((part, pIndex) => {
                    // Comprobación para partes que deben ir en negrita
                    const boldPattern = /.+:\s+/; // Busca cualquier texto seguido de dos puntos y un espacio
                    const matchResult = part.match(boldPattern); // Almacena el resultado de match
                    if (matchResult) { // Verifica si matchResult no es null
                      const boldPart = matchResult[0]; // Asume que hay al menos una coincidencia
                      const restOfPart = part.substring(boldPart.length);
                      return (
                        <span key={`${sIndex}-${pIndex}`} style={{ display: sIndex === number ? '' : 'none' }}>
                          <strong>{boldPart}</strong>{restOfPart}
                        </span>
                      );
                    } else if (part.startsWith('{') && part.endsWith('}')) {
                      // Manejo de los huecos
                      const gapId = part.replace(/[{}]/g, '');
                      return <Gap key={`${sIndex}-${pIndex}`}
                        size={15}
                        gap={{ id: gapId, correctWordId: gapId }}
                        resetFlag={resetFlag}
                        onFill={handleFillGap}
                        colorString='black'
                        reviewMode={reviewMode}
                        onRemove={handleRemoveWord}
                        isCorrect={gapsCorrectness[gapId]}
                        hasChecked={hasChecked}
                        display={sIndex === number ? 'inline-block' : 'none'}
                        initialFill={filledGaps[gapId] ? gapId : undefined}
                        words={words}
                        exerciseId={exerciseId??"dragDropBook"}
                      />;
                    } else {
                      // Partes de la oración que no requieren tratamiento especial
                      return <span key={`${sIndex}-${pIndex}`} style={{ display: sIndex === number ? '' : 'none' }}>{part}</span>;
                    }
                  })}
                </div>
              ))}
            </div>
            
          </div>
            {(number>0||hasChecked) &&
              <div style={{ position: 'absolute', left: 50, bottom: 20 }}>
                <ButtonTryAgain onAction={resetExercise} justGoBack={!hasChecked} goBackAction={decrement}/>
              </div> 
            }
            <div
              className='arrow-right-basic-vocabulary cursor-pointer'
              style={{ position: 'absolute', left: 420 }}
              >
              <ButtonSaveExercise 
                isChecked={hasChecked}
                onNext={increment}
                onSubmit={handleSubmit}
                exerciseId={exerciseId}
                /> 
            </div>
        </div>
      </div>
      <style>
        {`.card-with-extended-background::before {
          background-color: ${colorSecondary};
        }`}
      </style>
    </DndProvider>
  </>);
};

export default WordBoxReadingDragAndDrop;