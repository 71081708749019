import React, { FC, useEffect } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { GroupedLesson, LessonsIdType, UseModeCourseModule } from '../../../../app/modules/courses/components/front-page/store/main'
import { useLocation, useNavigate } from 'react-router-dom'
import { showSimpleAlert } from '../../../../app/commonHooks/alert'
import { AddTaskByStudentSettingsManual } from '../../../../app/modules/abroad/trail/services'
import { UseMyRoadmapModule } from '../../../../app/modules/profile/components/my-road-step/store'
import { UseEvaluationCourseModule } from '../../../../app/modules/courses/screen/store/main'
import { EvaluationType } from '../../../../app/modules/admin/evaluation/interfaces'
import { UseBookTestModule } from '../../../../app/modules/book/modules/test/store/main'
import { UseChapterAllByLearningPath } from '../../../../app/modules/abroad/learning-path/modules/chapter/services'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { UseBookTeacherModule } from '../../../../app/modules/book/modules/class/store/main'
import { UseBookStudentModule } from '../../../../app/modules/book/modules/screen/store/main'
import useAuth from '../../../../app/api/main'
import { TaskStatus, TaskType } from '../../../../app/modules/admin/tasks/interfaces'
import { UseBookPublicModule } from '../../../../app/modules/book/modules/public/store/main'

type Props = {
  courseId?: string
  colorCourse: string
  mode: 'ADMIN' | 'STUDENT' | 'TEACHER' | 'PUBLIC'
}

const TopicsEbookDrawer: FC<Props> = ({ courseId, mode, colorCourse }) => {
  const location = useLocation()
  const { typeUser } = useAuth()
  const {
    currentCourseId,
    lessonsMenu,
    modePublic,
    lessonsId,
    setModePublic,
    setCurrentCourse,
    setLessonsId,
    setLessonsMenu,
  } = UseModeCourseModule()
  const { settings } = UseMyRoadmapModule.getState()
  const { lessonData } = UseBookTestModule.getState()
  const { setCurrentTask: setTaskBook, lessonData: lessonStudent } = UseBookStudentModule()
  const { lessonData: lessonTeacher } = UseBookTeacherModule()
  const { lessonData: lessonPublic } = UseBookPublicModule()
  const { setShowEndEvaluation, setCurrentTask, setStepEvaluation, resetEvaluationResponses, setCurrentLessonId, } = UseEvaluationCourseModule()

  const [show, setShow] = React.useState<boolean>(false);
  const [appointmentID, setAppointmentID] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const navigate = useNavigate()

  const findLessonsByChapterAdmin = async (course: string) => {
    try {
      /*const response =*/ await UseChapterAllByLearningPath(course)
      // setLessonsMenu(response, 'EBOOK')
      // if (response && response.length > 0) {
      //   setLessonsId(
      //     response.flatMap(
      //       (chapter: any) =>
      //         chapter?.lessons &&
      //         chapter.lessons.flatMap(
      //           (unit: any) =>
      //             unit.lessons &&
      //             unit.lessons.map((lesson: any) => ({ 
      //               id: lesson.id,
      //               order: lesson.order,
      //               chapter_order: chapter.chapter_order,
      //               task: lesson.taskId, 
      //               with_teacher: lesson.withTeacher,
      //               typeScreen: lesson.typeScreen,
      //               evaluationId: lesson.evaluation_id,
      //               statusTask: lesson.statusTask,
      //             })
      //           )
      //         )
      //     )
      //   )
      // }
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Tópicos', icon: 'error' })
    }
  }

  const addTaskByStudent = async (status: TaskStatus , id: string, code: string, type: TaskType, isComplete: boolean) => {
    try {
      const response = await AddTaskByStudentSettingsManual({
        status,
        lesson: id,
        student_setting: settings?.id ?? 'xx',
        code: code,
        description: '',
        isArtificial: false,
        isActive: true,
        type,
        isComplete
      })
      if (response?.id) {
        setCurrentTask(response?.id)
      }
    } catch (e) {
      showSimpleAlert({ message: 'Error al cargar tarea', icon: 'error' })
    }
  }

  const isCorrectUUID = (value: string) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
    return uuidRegex.test(value)
  }

  React.useEffect(() => {
    if (courseId && isCorrectUUID(courseId)) {
      setCurrentCourse(courseId)
    }
  }, [courseId])

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const params = Object.fromEntries(searchParams.entries())
    if ((params || typeof mode !== 'undefined') && currentCourseId) {
      findLessonsByChapterAdmin(currentCourseId)
      // queryClient.invalidateQueries({ queryKey: ['chapter-by-path', currentCourseId] });
    }
  }, [settings, currentCourseId, mode]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(searchParams.entries());
    if (params && params?.appointment) {
      setAppointmentID(params.appointment);
    }
  }, [location]);

  React.useEffect(() => {
    const bookTest = location.pathname.includes('/book/test')
    const bookClass = location.pathname.includes('/book/class')
    const bookStudent = location.pathname.includes('/book/container')
    const bookPublic = location.pathname.includes('/book/public')
    if (!bookTest) {
      const evaluationCourse = location.pathname.includes('/evaluation/screen/course/')
      if (evaluationCourse) {
        const pathId: boolean = lessonData?.chapter?.path?.id === currentCourseId
        setCurrentCourse(pathId ? lessonData?.chapter?.path?.id : currentCourseId)
      }
      if (!evaluationCourse) {
        if (lessonData && lessonData?.chapter && lessonData?.chapter?.path) {
          setCurrentCourse(lessonData.chapter.path.id)
        }
      }
    } else if (bookTest) {
      if (lessonData && lessonData?.chapter && lessonData?.chapter?.path) {
        setCurrentCourse(lessonData.chapter.path.id)
      }
    } else if (bookPublic) {
      if (lessonPublic && lessonPublic?.chapter && lessonPublic?.chapter?.path) {
        setCurrentCourse(lessonPublic.chapter.path.id)
      }
    }
    if (bookClass) {
      if (lessonTeacher && lessonTeacher?.chapter && lessonTeacher.chapter?.path) {
        setCurrentCourse(lessonTeacher.chapter.path.id)
      }
    }
    if (bookStudent) {
      if (lessonStudent && lessonStudent?.chapter && lessonStudent.chapter?.path) {
        setCurrentCourse(lessonStudent.chapter.path.id)
      }
    }
  }, [lessonData, location, lessonTeacher, lessonStudent]);

  React.useEffect(() => {
    setSelectedUnit(null);
    setSelectedLesson(null);
  }, [modePublic]);

  const [selectedChapter, setSelectedChapter] = React.useState<number | null>(0)
  const [selectedUnit, setSelectedUnit] = React.useState<number | null>(null)
  const [selectedLesson, setSelectedLesson] = React.useState<number | null>(null)

  const handleChapterClick = (chapterIndex: number) => {
    setSelectedChapter(chapterIndex === selectedChapter ? null : chapterIndex)
    setSelectedUnit(null) // Deseleccionar la unidad al seleccionar otro capítulo
    setSelectedLesson(null) // Deseleccionar lección al seleccionar unidad
  }

  const handleUnitClick = (unitIndex: number) => {
    setSelectedUnit(unitIndex === selectedUnit ? null : unitIndex)
    setSelectedLesson(null)
  }

  const handleLessonClick = (lessonIndex: number) => {
    setSelectedLesson(lessonIndex === selectedLesson ? null : lessonIndex)
  };

  const handleStudentClick = async (lesson: GroupedLesson, nextLesson?: LessonsIdType) => {
    if (!lesson.isDisabled && typeUser === 'STUDENT') {
      if (!modePublic && lesson.statusTask === 'NOT_TASK') {
        let status = nextLesson ? !nextLesson.isDisabled ? TaskStatus.APPROVED : TaskStatus.PENDING : TaskStatus.PENDING;
        await addTaskByStudent(status, lesson.id, `${status.toLowerCase()}`, 
          lesson.typeScreen === 'QUIZ' ? TaskType.QUIZ : 
          lesson.typeScreen === 'FINAL_PRESENTATION' ? TaskType.FINAL_PRESENTATION : TaskType.NORMAL, (status === TaskStatus.APPROVED) ? true : false);
      }
    } else {
      if (!modePublic && lesson.statusTask === 'NOT_TASK') {
        await addTaskByStudent(TaskStatus.PENDING, lesson.id, 'pendiente', 
          lesson.typeScreen === 'QUIZ' ? TaskType.QUIZ : 
          lesson.typeScreen === 'FINAL_PRESENTATION' ? TaskType.FINAL_PRESENTATION : TaskType.NORMAL, false);
      }
    }
    if (lesson?.taskId && lesson.taskId !== 'NOT_TASK') {
      setCurrentTask(lesson.taskId);
      setTaskBook(lesson.taskId);
    }
    setCurrentLessonId(lesson.id)
    setStepEvaluation(0);
    setShowEndEvaluation(false);
    resetEvaluationResponses();
    navigateToLesson(lesson);
  };
  
  const navigateToLesson = (lesson: GroupedLesson) => {
    if (lesson.typeScreen === EvaluationType.BOOK) {
      navigate(`/book/container?lesson=${lesson.id}&mode=STUDENT`);
    } else if (lesson.typeScreen === EvaluationType.SINGLE_QUIZ) {
      navigate(`/courses/container/${lesson.id}`);
    } else {
      navigate(`/evaluation/screen/course/${lesson.evaluation_id}`);
    }
  };
  
  const handlePublicClick = (lesson: GroupedLesson) => {
    if (lesson.typeScreen === EvaluationType.SINGLE_QUIZ) {
      navigate(`/evaluation/test/${lesson.evaluation_id}`);
    } else if (lesson.typeScreen === EvaluationType.BOOK) {
      navigate(`/book/public?lesson=${lesson.id}&mode=ADMIN`);
    } else {
      return showSimpleAlert({message: 'La lección no es de tipo libro.', icon: 'error'});
    }
  };
  
  const handleAdminClick = (lesson: GroupedLesson) => {
    if (lesson.typeScreen === EvaluationType.SINGLE_QUIZ) {
      navigate(`/evaluation/test/${lesson.evaluation_id}`);
    } else if (lesson.typeScreen === EvaluationType.BOOK) {
      navigate(`/book/test?lesson=${lesson.id}&mode=ADMIN`);
    } else {
      navigate(`/evaluation/screen/course/${lesson.evaluation_id}`);
    }
  };
  
  const handleTeacherClick = (lesson: GroupedLesson) => {
    if (lesson.typeScreen === EvaluationType.BOOK) {
      navigate(`/book/class?lesson=${lesson.id}&appointment=${appointmentID}&mode=TEACHER`);
    }
  };
  
  const handleClick = async (lesson: GroupedLesson, nextLesson?: LessonsIdType) => {
    setStepEvaluation(0)
    if (lesson.evaluation_id === null || lesson.typeScreen === null) {
      showSimpleAlert({ message: 'La lección no tiene una evaluación', icon: 'error' });
      return;
    }
  
    if (!modePublic && (mode === 'STUDENT' || typeUser === 'STUDENT')) {
      if(!isLoading){
        try{
          setIsLoading(true)
          await handleStudentClick(lesson, nextLesson);
        } finally{
          setIsLoading(false)
        }
      }
      
    } else if (modePublic) {
      handlePublicClick(lesson);
    } else if (mode === 'ADMIN' || typeUser === 'ADMIN') {
      handleAdminClick(lesson);
    } else if (mode === 'TEACHER' || typeUser === 'TEACHER') {
      handleTeacherClick(lesson);
    }
  
    handleClose();
  };

  const nonClickedStyles = { color: 'white', background: colorCourse !== '' ? colorCourse : '#3e94ff' }
  const clickedStyles = { color: '#D0FF00', background: colorCourse !== '' ? colorCourse : '#3e94ff' }

  const nonClickedGrayStyles = { color: 'gray', background: colorCourse !== '' ? colorCourse : '#3e94ff' }
  const clickedGrayStyles = { color: '#D0FF00', background: colorCourse !== '' ? colorCourse : '#3e94ff' }

  const nonIconStyles = {
    color: 'white',
    width: '27px',
    height: '27px',
  }
  const IconStyles = {
    color: 'blue',
    width: '27px',
    height: '27px',
    background: '#D0FF00',
  }

  const IconGrayStyles = {
    color: 'gray',
    width: '27px',
    height: '27px',
    background: '#D0FF00',
  }

  const nonIconGrayStyles = {
    color: 'gray',
    width: '27px',
    height: '27px',
  }
  
  return (
    <>
      <button
        type='button'
        className='btn bg-white cursor-pointer champ-light py-2 p-2'
        style={{ borderRadius: '30px', color: '#2d30e1' }}
        onClick={handleShow}
      >
        <img
          className='mx-3'
          style={{ width: '10px' }}
          src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/left_arrow.svg`)}
          alt=''
        />
        <span className='pt-1 me-4'>Topics</span>
      </button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement='end'
        style={{ background: colorCourse !== '' ? colorCourse : '#3e94ff' }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            <div className='mt-10 d-flex'>
              <button
                type='button'
                onClick={handleClose}
                className='btn bg-white cursor-pointer p-2 py-1 champ-light d-flex justify-content-center align-items-center'
                style={{ borderRadius: '30px', color: '#2d30e1' }}
              >
                <img
                  className='me-1 ms-4'
                  style={{ width: '10px' }}
                  src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/right_arrow.svg`)}
                  alt=''
                />
                <span className='pt-1 me-4'>Topics</span>
              </button>
              <button
                type='button'
                onClick={() => setModePublic(!modePublic)}
                className={`${modePublic ? 'bg-success text-white' : 'bg-secondary text-dark'} btn ms-2 bg-white cursor-pointer p-2 py-1 champ-light d-flex justify-content-center align-items-center`}
                style={{ 
                  borderRadius: '30px', 
                  color: '#2d30e1',
                }}
              >
                {modePublic ? (
                  <i className="fa-solid fa-check text-white me-2"></i>
                ) : (
                  <i className="bi bi-dash-square-fill me-2"></i>
                )}
                <span className='pt-1 me-4'>Modo libro</span>
              </button>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='card-body'>
            <div className='d-flex flex-column scroll-y' style={{ maxHeight: '550px' }}>
              {/* Array Principal */}
              {lessonsMenu && lessonsMenu.sort((a, b) => a.chapter_order - b.chapter_order).map((chapter, i) => ( // 
                <div key={i} className=''>
                  <button
                    className='accordion-button mb-1 rounded-pill'
                    type='button'
                    style={{
                      backgroundColor: selectedChapter === i ? '#C6F711' : '',
                      color: selectedChapter=== i ? '#3E94FF':'white',
                      border: selectedChapter === i ? 'none':'1px solid',
                      
                    }}
                    data-bs-toggle='collapse'
                    data-bs-target={`#collapse${chapter.chapter_id}`}
                    aria-expanded={selectedChapter === i}
                    aria-controls={`collapse${chapter.chapter_id}`}
                    onClick={() => handleChapterClick(i)}
                  >
                    <div className='d-flex'>
                      <div className='d-flex align-items-center ms-7'>
                        <span  className={`${'champ-bold'} fs-6`} style={{marginTop:'4px' }}>
                          {chapter.chapter_name}
                        </span>
                      </div>
                    </div>
                  </button>
                  
                  <div
                    id={`collapse${chapter.chapter_id}`}
                    className={`accordion-collapse collapse ${selectedChapter === i ? 'show' : ''}`}
                    aria-labelledby={`heading${chapter.chapter_id}`}
                    data-bs-parent='#accordionSecondary'
                  >
                    <div className='accordion-body'>
                      <div className='accordion d-flex flex-column py-3'>
                        {chapter.lessons && chapter.lessons.filter((lesson) => {
                            if (modePublic) {
                              return !['QUIZ', 'FINAL PRESENTATION'].some(keyword => lesson.name.includes(keyword));
                            }
                            return true;
                        }).map((unit, iU) => {
                          const isUnitSelected = selectedUnit === iU
                          const buttonStyle = isUnitSelected ? clickedStyles: nonClickedStyles
                          const buttonGrayStyle = isUnitSelected ? clickedGrayStyles: nonClickedGrayStyles
                          const iconStyle = isUnitSelected ? IconStyles : nonIconStyles
                          const iconGrayStyle = isUnitSelected ? IconGrayStyles : nonIconGrayStyles;
                          const unitLesson = unit.lessons ?? []
                          return (
                            <div key={iU}>
                              <button
                                style={(unitLesson.find( x=> !x.isDisabled) || typeUser!=='STUDENT' || modePublic)? buttonStyle : buttonGrayStyle}
                                onClick={() => handleUnitClick(iU)}
                                className='rounded-pill border border-0 '
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target={`#collapseUnit${iU}`}
                                aria-expanded={isUnitSelected}
                                aria-controls={`collapseUnit${iU}`}
                              >
                                <div className='d-flex'>
                                  <div
                                    className={`border border-${(unitLesson.find( x=> !x.isDisabled) || typeUser!=='STUDENT' || modePublic)? "white" : "dark border-opacity-25 "} rounded-circle d-flex align-items-center justify-content-center`}
                                    style={(unitLesson.find( x=> !x.isDisabled) || typeUser!=='STUDENT'|| modePublic)? iconStyle : iconGrayStyle}
                                  >
                                    <span className='champ-bold m-0'>{iU + 1}</span>
                                  </div>
                                  <div className='d-flex align-items-center ms-2'>
                                    <span className='champ-bold fs-5' >{unit.name}</span>
                                  </div>
                                </div>
                              </button> 
                            
                              <div
                                id={`collapseUnit${iU}`}
                                className={`accordion-collapse collapse ${isUnitSelected ? 'show' : ''}`}
                                aria-labelledby={`heading${iU}`}
                                data-bs-parent={`#collapse${chapter.chapter_id}`}
                              >
                                <div className='accordion-body p-2 '>
                                  <div className='accordion d-flex flex-column px-1'>
                                    {unit.lessons && unit.lessons.sort((a, b) => a.order - b.order).filter((lesson) => modePublic ? lesson.typeScreen === 'BOOK' : true).map((lesson, iL) => {
                                      const isLessonSelected = isUnitSelected && selectedLesson === iL
                                      const buttonStyleTwo = isLessonSelected ? clickedStyles : nonClickedStyles
                                      return (
                                        <div key={lesson.id}>
                                          <button
                                            className='d-flex align-items-center ms-2 cursor-pointer rounded-pill border-0 bg-transparent'
                                            data-bs-toggle='collapse'
                                            data-bs-target={`#collapseLesson${iU}-${iL}`}
                                            aria-expanded={isLessonSelected}
                                            aria-controls={`collapseLesson${iU}-${iL}`}
                                            disabled={modePublic ? false : (lesson.isDisabled && typeUser==='STUDENT')}
                                            onClick={() => handleLessonClick(iL)}
                                          >
                                            <div style={{width: '8px',height: '8px',background: `${isLessonSelected? '#D0FF00':'#ffffff'}`,borderRadius: '50px',marginRight: '10px'}}/>
                                            <span className='fs-6' style={(!modePublic && lesson.isDisabled && typeUser==='STUDENT') ? {color: 'gray'} : buttonStyleTwo}>
                                              {lesson.name}
                                            </span>
                                          </button>
                                          
                                          <div
                                            id={`collapseLesson${iU}-${iL}`}
                                            className={`accordion-collapse collapse ${isLessonSelected ? 'show' : ''}`}>
                                              
                                            <div className='accordion d-flex flex-column px-2 cursor-pointer'>
                                              {lesson.items && lesson.items.sort((a, b) => a.order - b.order).map((child, iI) => (
                                                <div
                                                  key={iI}
                                                  className='d-flex align-items-center px-12'
                                                  onClick={() => {
                                                    const nextLesson = lessonsId ? lessonsId.find((next) => next.order > lesson.order): undefined;
                                                    handleClick(lesson, nextLesson);
                                                  }}
                                              >
                                                {typeUser !== 'STUDENT' ?
                                                  <div style={{width: '13px',height: '13px',background: '#ffffff',borderRadius: '50px',marginRight: '10px'}}/>
                                                :
                                                  lesson.statusTask === 'PENDING' ?
                                                    <div style={{ width: '13px', height: '13px', border: '2px solid #ffffff', borderRadius: '50%', background: 'transparent', marginRight: '10px'}}/>
                                                  :
                                                  lesson.statusTask === 'REPROVED' ?
                                                  <i className="bi bi-x-circle-fill" style={{color: 'red'}}/>
                                                  :
                                                  <i className="bi bi-check-circle-fill" style={{color : '#D0FF00'}}/>
                                                }
                                                <span className='ms-2 fs-6' style={{ color: 'white' }} >
                                                  {child.name}
                                                </span>
                                              </div>
                                            ))}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* End Array Principal */}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export { TopicsEbookDrawer }
