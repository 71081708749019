import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { Appointment, AppointmentStatus } from '../../interfaces'

import { AppointmentAdd, AppointmentUpdate, BlockHourAllByClassroomTeacher } from '../../services'
import { UseAppointmentModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { UseUserTypePaginate } from '../../../user/services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { MasterClassLevel } from '../../../master-class-level/interfaces'
import { UseMasterClassLevelAll } from '../../../master-class-level/services'
import { UseClassroomAll } from '../../../classroom/services'
import { Classroom } from '../../../classroom/interfaces'
import { MonitorBooking } from '../monitor-booking'
import { TabMonitorBooking } from '../monitor-booking/tab-monitor-booking'
import moment from 'moment'
import { UseActivityAll } from '../../../../abroad/activity/services'
import { Activity } from '../../../../abroad/activity/interfaces'
import { BlockHour } from '../../../../abroad/block-hours/interfaces'
import { UseBlockHourAll } from '../../../../abroad/block-hours/services'
import Multiselect from 'multiselect-react-dropdown'
import { extractOrder } from '../../../../../components/common/ExcelExport/utils'

export type FormFields = {
    description: string
    isActive: boolean
    teacher?: string
    responsible?: string
    activity?: string; 
    classroom: any
    start?: string
    status_appointment: AppointmentStatus;
    blockHour?: number[];
}

const entitySchema = Yup.object().shape({
  isActive: Yup.boolean().required('First es requerido'),
  //level: Yup.string().required('Nivel es requerido'),
  teacher: Yup.string().required('Tutor es requerido'),
  //responsible: Yup.string().required('Ejecutivo es requerido'),
  //hour_start: Yup.string().required('Hoora de inicio es requerida'),
  //hour_finish: Yup.string().required('Hora de cierre es requerida'),
  start: Yup.date().required('Fecha es requerida'),
  classroom: Yup.string().required('classroom es requerido'),
  status_appointment: Yup.string().required('Estado es requerido'),
})
type EventResume ={
  id:string;
  start: string;
  end: string;
}
type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
}
const AppointmentForm: React.FC<Props> = (props: Props) => {
  const queryClient = useQueryClient()
  const { modal, itemSelected, mode, step, campus,
    setModal, resetData, setSelectedToEdit, setSelectedToView, setLevel, resetLevel, setStep } = UseAppointmentModule();
  const [levels, setLevels] = React.useState<MasterClassLevel[]>([])
  const [activities, setActivities] = React.useState<Activity[]>([]);
  const [teacher, setTeacher] = React.useState<any[]>([])
  const [responsible, setResponsible] = React.useState<any[]>([])
  const [classroom, setClassroom] = React.useState<Classroom[]>([]);

  const [blockHours, setBlockHours] = React.useState<BlockHour[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false)

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<FormFields>({
      initialValues: {
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        teacher: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.teacher.id ?? '' : '',
        responsible: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.responsible.id ?? undefined : undefined,
        start: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.start ?? moment().format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        classroom: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.classroom.id ?? '' : '',
        status_appointment: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.status_appointment ?? AppointmentStatus.PENDING : AppointmentStatus.PENDING,
        blockHour: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.blockHour ?? [] : [],
        activity: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.activity.id ?? '' : ''
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (props.mode === 'ADD') {
          try {
            setLoading(true);
            await AppointmentAdd({
              ...formData,
              //@ts-ignore
              blockHour: [parseInt(values.blockHour)] ?? [],// values.blockHour ? extractOrder(values.blockHour) : [],
              //finish: formData.start
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['appointment'] })

          } catch (e) {
            setLoading(false);
          }

        }
        else {
          try {
            setLoading(true);
            await AppointmentUpdate({
              ...formData,
              //@ts-ignore
              blockHour: [parseInt(values.blockHour)] ?? [],// values.blockHour ? extractOrder(values.blockHour) : [],
              responsible:formData.responsible && formData.responsible?.length>5 ? formData.responsible : undefined
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['appointment'] })

          } catch (e) {
            setLoading(false);
          }
        }
      },
    });
    
  const fetchLevels = async () => {
    try {
      let levels = await UseMasterClassLevelAll()
      setLevels(levels)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Nivles!', icon: 'error' })
      throw 'Error'
    }
  }
  const fetchTeacher = async () => {
    try {
      let teacher = await UseUserTypePaginate('TEACHER')
      setTeacher(teacher)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar profesores!', icon: 'error' })
      throw 'Error'
    }
  }
  const fetchResponsible = async () => {
    try {
      // let responsible = await UseUserTypePaginate('EXECUTIVE')
      let responsible = await UseUserTypePaginate('TEACHER')
      setResponsible(responsible)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar ejecutivos!', icon: 'error' })
      throw 'Error'
    }
  }
  const fetchClassroom = async () => {
    try {
      let classroom = await UseClassroomAll()
      setClassroom(classroom)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar salones!', icon: 'error' })
      throw 'Error'
    }
  }
  
  const fetchActivity = async () => {
    try {
      let activity = await UseActivityAll()
      setActivities(activity);
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar salones!', icon: 'error' })
      throw 'Error'
    }
  };

  useEffect(() => {
    fetchTeacher();
    fetchResponsible();
    // fetchLevels();
    fetchClassroom();
    fetchActivity();
  }, []);

  React.useEffect(() => {
    const fetchBlockHoursFree = async () => {
      try {
          if (values.start && values.classroom && values.teacher) {
            const res = await BlockHourAllByClassroomTeacher({start: values.start, classroom:values.classroom,teacher:values.teacher});
            setBlockHours(res)
        }
      } catch (e) {
        showSimpleAlert({ message: 'Error al encontrar bloques horarios disponibles!', icon: 'error' })
        throw 'Error'
      };
    };
    fetchBlockHoursFree();
  }, [values.start, values.classroom, values.teacher]);
  
  const modifiedOptions = blockHours.map(option => ({
    ...option,
    displayText: `${option.start} - ${option.end}`
  }));

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>

        {/* begin::Header */}

        <div className='card-body border-top p-8 row' /*style={{ height: 530 }}*/>
          <div className=''>
            <div className='row'>
              <div className='mb-1 col-12'>
                <label className='form-label mb-0'>Inicio</label>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'start'}
                  value={values.start}
                  onChange={handleChange}
                />
                {touched.start && errors.start && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.start}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className='mb-1 col-12'>
                <label className='form-label mb-0'>Niveles</label>
                <select
                  disabled={mode === 'VIEW'}
                  name={'level'}
                  value={values.level}
                  onChange={handleChange}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {levels.map((x) =>
                    (<option value={x.id}>{x.name.toUpperCase() ?? ''} {x.country && x.country.name.toUpperCase()}</option>)
                  )}
                </select>
                {errors.level && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.level}</span>
                    </div>
                  </div>
                )}
              </div> */}
              <div className='mb-1 col-6'>
                <label className='form-label mb-0'>Tutor</label>
                <select
                  disabled={mode === 'VIEW'}
                  name={'teacher'}
                  value={values.teacher}
                  onChange={handleChange}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {teacher.map((x) =>
                    (<option value={x.id}>{`${x.fullName}` || `${x.firstName.toUpperCase()} ${x.lastName.toUpperCase()}`}</option>)
                  )}
                </select>
                {errors.teacher && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.teacher}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='mb-1 col-6'>
                <label className='form-label mb-0'>Actividad</label>
                <select
                  disabled={mode === 'VIEW'}
                  name={'activity'}
                  value={values.activity}
                  onChange={handleChange}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {activities.map((x) =>
                    (<option value={x.id}>{`${x.name}`}</option>)
                  )}
                </select>
                {errors.activity && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.activity}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='mb-1 col-6'>
                <label className='form-label mb-0'>Salón </label>
                <select
                  disabled={mode === 'VIEW'}
                  name={'classroom'}
                  value={values.classroom}
                  onChange={handleChange}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {classroom.map((x) =>
                    (<option value={x.id}>{x.name.toUpperCase() ?? ''}</option>)
                  )}
                </select>
              </div>
              
              <div className='mb-1 col-6'>
                <label className='form-label mb-0'>Block Hour</label>
                <select
                  disabled={mode === 'VIEW'}
                  name={'blockHour'}
                  // value={values?.blockHour && values.blockHour.length > 0 ? values.blockHour : 0}
                  onChange={handleChange}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {blockHours && blockHours.map((x) =>
                    (<option key={x.order} value={x.order}>{x.start} - {x.end}</option>)
                  )}
                </select>
                {/* <Multiselect
                  options={blockHours}
                  selectedValues={values.blockHour}
                  onSelect={(x) => setFieldValue('blockHour', x)}
                  onRemove={(x) => setFieldValue('blockHour', x)}
                  displayValue={`start`}
                  disable={mode === 'VIEW'|| mode==='EDIT'}
                  className='custom-multiselect'
                /> */}
              </div>

              <div className='mb-1 col-6'>
                <label className='form-label mb-0'>Monitor</label>
                <select
                  disabled={mode === 'VIEW'}
                  name={'responsible'}
                  value={values.responsible}
                  onChange={handleChange}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {responsible.map((x) =>
                    (<option value={x.id}>{`${x.fullName}` || `${x.firstName.toUpperCase()} ${x.lastName.toUpperCase()}`}</option>)
                  )}
                </select>
                {errors.responsible && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.responsible}</span>
                    </div>
                  </div>
                )}
              </div>

              {mode != 'ADD' && <div className='mb-10 col-6'>
                <label className='form-label mb-0'>Status</label>
                <select
                  disabled={mode === 'VIEW'}
                  name='status_appointment'
                  value={values.status_appointment}
                  onChange={handleChange}
                  className='form-select'
                  aria-label='Default select example'
                >
                  {Object.keys(AppointmentStatus).map((x) => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </select>
                {errors.status_appointment && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.status_appointment}</span>
                    </div>
                  </div>
                )}
              </div>}

            </div>
          </div>
          {/* <div className='col-7 mh-500px scroll-y'>
            <TabMonitorBooking 
              getEvent={(e)=>{setEvents(e)}}
              date={values.start}
              classroom={values.classroom}
              tutor={values.teacher}
            />
          </div> */}
        </div>




        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { AppointmentForm }
