import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { UserPage } from '../modules/admin/user/pages'
import { AppointmentPage } from '../modules/admin/appointment/pages'
import { ClassroomPage } from '../modules/admin/classroom/pages'
import { CustomDashboard } from '../modules/dashboard/pages'
import { RoadMapPage } from '../modules/abroad/road-map/pages'
import { RoadMapStepPage } from '../modules/abroad/road-map-step/pages'
import { LearningPathPage } from '../modules/abroad/learning-path/pages'
import { ActivityPage } from '../modules/abroad/activity/pages'
import { WorkshopPage } from '../modules/abroad/learning-path/modules/workshop/pages'
import { TemplateTableTimePage } from '../modules/abroad/template-table-time/pages'
import { TemplatePusherPage } from '../modules/abroad/template-pusher/pages'
import { FaqPage } from '../modules/abroad/faq/pages'
import { AdvertisementsPage } from '../modules/admin/advertisements/pages'
import { EvaluationPage } from '../modules/admin/evaluation/pages'
import { BookContainerPage } from '../modules/book/pages'
import { QualifyPage } from '../modules/admin/qualify/pages'
import { ChatAbroadPage } from '../modules/chat/pages'
import { SurveySectionPage } from '../modules/admin/survey-section/pages'
import { SurveySectionResponsePage } from '../modules/admin/survey-section/modules/survey-section-response/pages'
import { TrailSuspensePage } from '../modules/admin/trail-suspense/pages'
import { CoursesPage } from '../modules/courses/page'
import { StudentActionsPage } from '../modules/admin/student-actions/pages'
import { TrailSuspenseReasonPage } from '../modules/abroad/trail/trail-suspense-reason/pages'

const AssistantRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route path='dashboard' element={<CustomDashboard />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />

        <Route
          path='template-table-time/*'
          element={
            <SuspensedView>
              <TemplateTableTimePage />
            </SuspensedView>
          }
        />

        <Route
          path='trail-suspense-reason/*'
          element={
            <SuspensedView>
              <TrailSuspenseReasonPage />
            </SuspensedView>
          }
        />

        <Route
          path='template-pusher/*'
          element={
            <SuspensedView>
              <TemplatePusherPage />
            </SuspensedView>
          }
        />

        <Route
          path='faq/*'
          element={
            <SuspensedView>
              <FaqPage />
            </SuspensedView>
          }
        />

        <Route
          path='appointment/*'
          element={
            <SuspensedView>
              <AppointmentPage />
            </SuspensedView>
          }
        />

        <Route
          path='activity/*'
          element={
            <SuspensedView>
              <ActivityPage />
            </SuspensedView>
          }
        />

        <Route
          path='qualify/*'
          element={
            <SuspensedView>
              <QualifyPage />
            </SuspensedView>
          }
        />

        <Route
          path='advertisements/*'
          element={
            <SuspensedView>
              <AdvertisementsPage />
            </SuspensedView>
          }
        />

        <Route
          path='classroom/*'
          element={
            <SuspensedView>
              <ClassroomPage />
            </SuspensedView>
          }
        />

        {/* Survey Section */}
        <Route
          path='survey-section/*'
          element={
            <SuspensedView>
              <SurveySectionPage />
            </SuspensedView>
          }
        />
        <Route
          path='survey-section-response/*'
          element={
            <SuspensedView>
              <SurveySectionResponsePage />
            </SuspensedView>
          }
        />
        {/* End Survey Section */}

        <Route
          path='admin-suspense/*'
          element={
            <SuspensedView>
              <TrailSuspensePage />
            </SuspensedView>
          }
        />

        <Route
          path='chat/*'
          element={
            <SuspensedView>
              <ChatAbroadPage />
            </SuspensedView>
          }
        />

        <Route
          path='user/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />

        <Route
          path='road-map/*'
          element={
            <SuspensedView>
              <RoadMapPage />
            </SuspensedView>
          }
        />

        <Route
          path='road-map-step/*'
          element={
            <SuspensedView>
              <RoadMapStepPage />
            </SuspensedView>
          }
        />

        {/* Learning Path */}
        <Route
          path='learning-path/*'
          element={
            <SuspensedView>
              <LearningPathPage />
            </SuspensedView>
          }
        />
        {/* End Learning Path */}

        <Route
          path='workshop/*'
          element={
            <SuspensedView>
              <WorkshopPage />
            </SuspensedView>
          }
        />

        <Route
          path='evaluation/*'
          element={
            <SuspensedView>
              <EvaluationPage />
            </SuspensedView>
          }
        />

        <Route
          path='book/*'
          element={
            <SuspensedView>
              <BookContainerPage />
            </SuspensedView>
          }
        />

        <Route
          path='courses/*'
          element={
            <SuspensedView>
              <CoursesPage />
            </SuspensedView>
          }
        />

        <Route
          path='student-actions/*'
          element={
            <SuspensedView>
              <StudentActionsPage />
            </SuspensedView>
          }
        />

        {/* ***************** */}

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { AssistantRoutes }