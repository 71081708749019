/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { CardLobbyClass } from '../components/card-lobby-class'
import { AssistantLobbyClass } from '../components/assistant-lobby-class'
import { useParams } from 'react-router-dom'
import { UseGetInformationTrailClass } from '../../abroad/trail/services'
import { showSimpleAlert } from '../../../commonHooks/alert'
import { FindOneSettingByType } from '../../abroad/settings/services'
import { SettingEnumType } from '../../abroad/settings/interfaces'
import { CardHeaderModule } from '../../../components/common/widgets/statistics/cardHeaderModule'
import { useThemeMode } from '../../../../_metronic/partials'
import CountdownTimer from '../components/count-down-timer'
import moment, { Moment } from 'moment';
import UseTranslateToBogotaTimeZone from '../../../hooks/useBogotaTranslateTz'



type Props = {
  className: string
}

const LobbyContainer: React.FC<Props> = ({ className }) => {
  const { mode } = useThemeMode()
  const { id } = useParams();
  const [timeRemaining, setTimeRemaining] = useState<string>('');
  const [showButton, setShowButton] = useState<boolean>(false);
  const [dataTrailClass, setDataTrailClass] = React.useState<any>();
  const [dataSettings, setDataSettings] = React.useState<any>();
  const [showRating, setShowRating] = React.useState(false);
  const fetchTrailClass = async () => {
    try {
      if (id) {
        const responseTrailClass = await UseGetInformationTrailClass(id);
        setDataTrailClass(responseTrailClass);
      }
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Trail Class!', icon: 'error' })
      throw 'Error'
    }
  };

  const fetchSettings = async () => {
    try {
      const responseSettings = await FindOneSettingByType(SettingEnumType.MINUTER_PRIOR_THE_START_CLASS_BY_ZOOM);
      setDataSettings(responseSettings);
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Settings!', icon: 'error' })
      throw 'Error'
    }
  };

  React.useEffect(() => {
    if (id) {
      fetchTrailClass();
    }
  }, [id]);

  React.useEffect(() => {
    fetchSettings();
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentDateTime = moment(UseTranslateToBogotaTimeZone().yearMonthDayHour);

      if (currentDateTime.isSameOrAfter(dataTrailClass?.time)) {
        setShowRating(true);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [dataTrailClass?.time]);
  useEffect(() => {
    const interval = setInterval(() => {
      const now: Moment = moment(UseTranslateToBogotaTimeZone().yearMonthDayHour);
      const futureDate: Moment = moment(dataSettings?.value);

      const duration = moment.duration(futureDate.diff(now));

      const days: number = Math.floor(duration.asDays());
      const hours: number = duration.hours();
      const minutes: number = duration.minutes();
      const seconds: number = duration.seconds();

      setTimeRemaining(`${days > 0 ? days + "D" : ''} ${hours > 0 ? hours + "H" : ''} ${minutes > 0 ? minutes + "m" : ''} ${seconds > 0 ? seconds + "s" : ''}`);

      if (duration.asMilliseconds() <= 0) {
        clearInterval(interval);
        setTimeRemaining('Countdown ended');
        setShowButton(true); // Muestra el botón cuando el conteo regresivo ha terminado
      } else if (duration.asMinutes() <= dataSettings?.value) {
        setShowButton(true); // Muestra el botón cuando se alcanza el umbral de minutos
      } else {
        setShowButton(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [dataTrailClass?.time, dataSettings?.value]);

  return (<>
    <CardHeaderModule
      title={'Lobby'}
      leftIcon={`/media/svg/michigan-icons/header/${mode==='light'?'blue/back_blue':'white/back_white'}.svg`}
      svgIcon={`/media/svg/michigan-icons/header/${mode==='light'?'blue/lobby_blue':'white/lobby_white'}.svg`}
      background={'#dfe0fa'}
      colorTitle={'#2d30e1'}
      routeBack={'/me-booking'}
    />

    {/* begin::Header */}
    <div className='card-header border-0 pt-5' style={{ padding: 0 }}>
      {/* <div className='row col-12'>
            <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Lobby</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{dataTrailClass?.title ?? 'XXXX'}</span>
            </h3>
        </div> */}
      <div className='row g-5 g-xxl-8' >
        {dataTrailClass && <>
          <div className='col-sm-12 col-md-6 col-lg-6 h-100'>
            <CardLobbyClass
              className=''
              showButtonThreshold={dataSettings?.value ?? 1}
              teacherName={dataTrailClass?.teacher}
              teacherEmail={dataTrailClass?.teacherEmail ?? '@michigan.edu.co'}
              targetDate={dataTrailClass?.time}
              starsRating={dataTrailClass?.stars ?? 0}
              teacherPhoto={dataTrailClass?.teacherPhoto}
              horizontalBanner={dataTrailClass?.horizontalBaner}
            />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <AssistantLobbyClass
              className=''
              studentsTrail={dataTrailClass?.students} 
            />
            <CountdownTimer
              studentAssistant={dataTrailClass?.assistantStudent}
              targetDate={dataTrailClass?.time.start}
              showButtonThreshold={dataSettings?.value}
              enabledButton={showRating}
            />
          </div>
        </>}
      </div>
    </div>
  </>)
}

export { LobbyContainer }
