
import React, { useEffect } from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { Advertisements } from '../../../admin/advertisements/interfaces';
import { UseAdvertisementsPublic } from '../../../admin/advertisements/services';
import './styles.css'
import { t } from 'i18next';
import { useThemeMode } from '../../../../../_metronic/partials';
import useAuth from '../../../../api/main';
import useTrailTask from '../student/store';
type Props = {
    title:string;
    className?:string
    icon:string
    colorTitle:string
    background:string;
    url?:string;
}
const LinkComponentTwo: React.FC<Props> = ({ className, icon, background,title,colorTitle,url }: Props) => {
    const { mode } = useThemeMode()
    const {token} = useAuth();
    const {modal,setModal} = useTrailTask.getState();
    const [item, setItem] = React.useState<Advertisements[]>([]);
    const findAllItems = async () => {
        let response = await UseAdvertisementsPublic();
        setItem(response)
    }

    useEffect(() => {
            findAllItems()
    }, [])
    return (<>
        <div className={`${''}`}
        >
            <a href={url??'#'}
             onClick={()=>setModal(!modal)}
            className='d-flex flex-column text-decoration-none    ' 
                style={
                    mode != 'dark' ?{ 
                        borderRadius: '100px',
                        background:background,
                        padding:10
                    }:{ 
                        borderRadius: '100px',
                        background:'#1e1e2d',
                        padding:10,
                        border: `1px solid`, 
                        borderColor: background
                    }
                }>
 
                <div className='pb-5 d-flex justify-content-center align-items-center'>
                    <img className='link-icon-michigan' src={toAbsoluteUrl(icon)} alt="" style={{ width: 20,height:40}} />
                </div>
            </a>
        </div>
    </>);
}
export { LinkComponentTwo }