import { TrailFreezing, DataAllTrailFreezingResponse, DataPaginateTrailFreezingResponse } from '../interfaces/index'
import useAuth from '../../../../api/main'

import { catchServerError } from '../../../../hooks/useCatchError'
import { useStore } from 'zustand'
import { UseTrailFreezingModule } from '../store/main'
import { api } from '../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../commonHooks/alert'

const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export const useTrailFreezingAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/trail-freezing/simple?type_category=all`,
    {
      headers: {
        Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
      },
    })
    return response.data
  } catch (e: any) {
    catchServerError(e.response)
  }
}
export const useTrailFreezingAllPaginate = async (): Promise<any> => {
  try {
    // await authChecked()
    const { setPaginator, limit, currentPage, where, isActive, root } = await UseTrailFreezingModule.getState()
    
    if (!root) return [];

    let url = `/trail-freezing/paginated?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}&root=${root}`

    const response: any = await api.get(
      `${url}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}
export const TrailFreezingAdd = async (data: TrailFreezing): Promise<any> => {
  try {
    const response: any = await api.post(
      '/trail-freezing',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}
export const TrailFreezingUpdate = async (data: TrailFreezing): Promise<any> => {
  try {
    const { itemSelected } = UseTrailFreezingModule.getState()
    const response: any = await api.patch(
      `/trail-freezing/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}
export const TrailFreezingDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/trail-freezing/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}

export const GetTrailFreezingByDate = async (id: string, date: Date): Promise<any> => {
  try {
    const response: any = await api.get(
      `/trail-freezing/by-date/${id}/${date}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}

export const UpdateActiveTrailFreezing = async (id: string, isActive: boolean): Promise<any> => {
  try {
    const response: any = await api.patch(`/trail-freezing/active-value/${id}`, 
    {
      isActive
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}