import React from 'react';
import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { UseEvaluationCourseModule } from '../../../screen/store/main';
import { ButtonIntroEvaluationItem } from '../../common/button-intro-evaluation-item/buttonIntroEvaluationItem';

type Props = {
    title:string
    instructions:string
    startQuiz:(value:boolean)=>void
};

const IntroBasicVocabulary: React.FC<Props> = ({ title,instructions,startQuiz }: Props) => {
    const { mode } = useThemeMode();
    const {evaluationLesson, questionsEvaluation} = UseEvaluationCourseModule();

    return (
        <>
            <div className="card-basic-vocabulary"
                style={
                    mode !== 'dark' ? {
                        height: 420,
                        background: 'white',
                    } : {
                        height: 420,
                        background: '#1e1e2d',
                    }
                }>
                <h2 className='champ-light title-basic-vocabulary-resume text mb-5D'
                    style={{
                        color: evaluationLesson?.colorPrimary??'#3e94ff',
                    }}>
                    {title}
                </h2>
                <h3 className='poppins-light subtitle-basic-vocabulary text'
                    style={{
                        color: evaluationLesson?.colorPrimary??'#3e94ff',
                    }}
                >
                    <strong style={{ fontWeight: 'bold' }}>{instructions}</strong>
                </h3>
                <ButtonIntroEvaluationItem onNext={startQuiz} exerciseId={questionsEvaluation?.id}/>
            </div>
        </>
    );
};

export { IntroBasicVocabulary };