import { DocumentPortfolio, DataAllDocumentPortfolioResponse, DataPaginateDocumentPortfolioResponse } from '../interfaces/index'
import useAuth from '../../../../api/main'
import { catchServerError } from '../../../../hooks/useCatchError'
import { UseDocumentPortfolioModule } from '../store/main'
import { api } from '../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../commonHooks/alert'

const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export const useDocumentPortfolioAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/document-portfolio/simple?type_category=all`,
    {
      headers: {
        Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
      },
    })
    return response.data
  } catch (e: any) {
    catchServerError(e.response)
  }
}
export const UseDocumentPortfolioAllPaginate = async (id: string): Promise<any> => {
  try {
    // await authChecked()
    const { setPaginator, typeDocument, limit, currentPage, where, isActive } = UseDocumentPortfolioModule.getState()

    let url = `/document-portfolio/paginated?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}&root=${id}`;
    if (typeDocument) {
      url += `&type=${typeDocument}`
    }

    const response: any = await api.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}
export const DocumentPortfolioAdd = async (data: DocumentPortfolio): Promise<any> => {
  try {
    const response: any = await api.post(
      '/document-portfolio',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}
export const DocumentPortfolioUpdate = async (data: DocumentPortfolio): Promise<any> => {
  try {
    const { itemSelected } = UseDocumentPortfolioModule.getState()
    const response: any = await api.patch(
      `/document-portfolio/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}
export const DocumentPortfolioDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/document-portfolio/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}
