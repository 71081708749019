import { HistoryWritingResponse, DataAllHistoryWritingResponseResponse, DataPaginateHistoryWritingResponseResponse } from '../interfaces/index'
import useAuth from '../../../../api/main'
import { catchServerError } from '../../../../hooks/useCatchError'
import { UseHistoryWritingResponseModule } from '../store/main'
import { api } from '../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../commonHooks/alert'

const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export const useHistoryWritingResponseAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/history-writing-response/simple?type_category=all`,
    {
      headers: {
        Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}
export const useHistoryWritingResponseAllPaginate = async (): Promise<any> => {
  try {
    // await authChecked()
    const { setPaginator, limit, currentPage, where, isActive, after, before,isPending} = await UseHistoryWritingResponseModule.getState()

    const response: any = await api.get(
      `/history-writing-response/paginated?isActive=${isActive ? 1 : 0}&isPending=${isPending ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}&after=${after}&before=${before}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const HistoryWritingResponseAdd = async (data: HistoryWritingResponse): Promise<any> => {
  try {
    const response: any = await api.post(
      '/history-writing-response',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const HistoryWritingResponseUpdate = async (data: any): Promise<any> => {
  try {
    const { itemSelected } = UseHistoryWritingResponseModule.getState()
    const response: any = await api.patch(
      `/history-writing-response/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const HistoryWritingResponseDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/history-writing-response/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {

    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
