import React, { useEffect, useRef } from 'react';
import './index.css';
import { useThemeMode } from '../../../../_metronic/partials';
import { CheckExtension } from '../../../modules/courses/exercises/utils';
import WaveSurfer from 'wavesurfer.js';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { TypeContentCourse } from '../../../modules/admin/file-folder/interfaces';

type Props = {
  coverFile?: string;
  coverExtension?: string;
  children?: React.ReactNode; // Agregando la capacidad de recibir hijos
  contentFile?:string;
  contentExtension?: string;
  iFrame?: string;
  typeContent?: TypeContentCourse;
};

const LeftCardContent: React.FC<Props> = ({ 
    iFrame, 
    children, 
    coverFile, 
    contentFile, 
    coverExtension, 
    contentExtension,
    typeContent
}: Props) => {
    const { mode } = useThemeMode();
    const checkAudioFile = CheckExtension('AUDIO',coverExtension??'')
    const checkImageFile = CheckExtension('IMG',contentExtension??'')

    const waveformRef = useRef<HTMLDivElement | null>(null);
    const wavesurfer = useRef<WaveSurfer | null>(null);
    
    React.useEffect(() => {
        if (waveformRef.current && coverFile) {
            wavesurfer.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: 'transparent',
                progressColor: 'transparent',
                cursorWidth: 0,
                barWidth: 0,
                height: 0
            })
             // Load the audio file
            wavesurfer.current.load(coverFile);
        }
        return () => wavesurfer.current?.destroy();
    }, [coverFile]);

    const togglePlayback = () => {
        if (wavesurfer.current) {
            if (wavesurfer.current.isPlaying()) {
                wavesurfer.current.pause();
            } else {
                wavesurfer.current.play();
            }
        }
    };
    const imageDefault = 'https://th.bing.com/th/id/R.34be4bd35c651e24a47b5fc31079aea6?rik=cXiKHyLM1EtJ0A&pid=ImgRaw&r=0'
    // const imageDefault = toAbsoluteUrl('/media/svg/michigan-icons/elements/quiz_time.png');

    if (checkAudioFile) {
        return(
            <div className='left-card-content-exercises cursor-pointer'
                ref={waveformRef}
                style={{
                    background:  `url(${(checkImageFile || typeContent === TypeContentCourse.IMAGE)?contentFile:'https://th.bing.com/th/id/R.34be4bd35c651e24a47b5fc31079aea6?rik=cXiKHyLM1EtJ0A&pid=ImgRaw&r=0'}) center center / cover no-repeat`,
                     position: 'relative',
                     overflow: 'hidden',
                }}
                onClick={togglePlayback}
            >
                {children}
            </div>
        );
    }

  
    return (
        <div className="left-card-content-exercises"
             style={
                 mode !== 'dark' ? {
                     background:  `url(${
                        iFrame ? coverFile??imageDefault:
                        coverFile?coverFile:(checkImageFile || typeContent === TypeContentCourse.IMAGE)?contentFile:
                        imageDefault}) center center / cover no-repeat`,
                     position: 'relative',
                     overflow: 'hidden',
                 } : {
                     background:  `url(${
                        iFrame ? coverFile??imageDefault:
                        coverFile?coverFile:checkImageFile?contentFile:
                        imageDefault}) center center / cover no-repeat`,
                     position: 'relative',
                     overflow: 'hidden',
                 }
             }>
            {children} {/* Renderizar hijos aquí */}
        </div>
    );
};

export default React.memo(LeftCardContent);
