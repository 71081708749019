/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {EvaluationType} from '../../../../evaluation/interfaces'
import {FilterType, UseQualifyPlacementModule} from '../../placement-test/store/main'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {TasksDetails} from '../../../../tasks/components/details'
import {UseTrailModule} from '../../../../user/store/student/main'
import {useTranslation} from 'react-i18next'
import {useEffect, useState} from 'react'
import { LevelUpgradeUseCase } from '../../../../appointment/services'
import { UseQualifyPlacementAllPaginate } from '../../placement-test/services'
import { TaskStatus, TaskType } from '../../../../tasks/interfaces'
import Swal from 'sweetalert2'
import { useLocation, useNavigate } from 'react-router-dom'

type Props = {
  show: boolean
  handleClose: () => void
  evaluationType: EvaluationType
}
type LocationState = {
  studentIdNumber?: string 
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateModal = ({show, handleClose, evaluationType}: Props) => {
  const {setModal, itemSelected, setModalQualify, setStudent, setFilterType, setWhere} = UseQualifyPlacementModule()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pendingQuizzes, setPendingQuizzes] = useState<any>()
  const {setModalUpgradeStudent} = UseTrailModule()
  const {t} = useTranslation()
  
  const handleQualifyModal = () => {
    setModal(false)
    setModalQualify(true)
  }
  
  const handleUpdateModule = () => {
    setModal(false)
    setModalUpgradeStudent(true)
  }

  const checkOnQuizzes = async () => {
    setFilterType(FilterType.QUIZZES)    
    setWhere(JSON.stringify({
      studentSettingId: itemSelected.student_setting.id,
      level: itemSelected.student_setting.level
    }))
    const response =await UseQualifyPlacementAllPaginate(TaskType.QUIZ)
    setPendingQuizzes(response.result)
  }

  useEffect(() => {
    if(evaluationType === EvaluationType.FINAL_PRESENTATION && itemSelected){
      checkOnQuizzes()
    }
  }, [itemSelected])

  const handleApproveNextLevel = async()=>{
    console.log(itemSelected);
    
    if(pendingQuizzes && pendingQuizzes.every(x=>x.status === TaskStatus.APPROVED)){
      alert("si")
      await LevelUpgradeUseCase({
        student: itemSelected.student_setting.id??'',
      });
    } else {
      Swal.fire({
        title: 'ERROR',
        text: `El estudiante aún no aprueba todos los quizzes del nivel ${itemSelected.student_setting.level}`,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ir a calificarlos',
      }).then(async(result) => {
        if (result.isConfirmed) {
          try{
            window.open(`/qualify/quiz?studentIdNumber=${itemSelected.student_setting.user.document_id}`, '_blank')
          }catch(e){
            console.error(e)
          }
        }
      })
  }
}
  
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered modal-xl modal-fullscreen-lg-down'
      show={show}
      backdrop={true}
      onExit={()=>{setWhere("")}}
    >
      {itemSelected && (
        <>
          <div className='modal-header'>
            <h2>
              Calificar{' '}
              {t(
                itemSelected.type === 'PLACEMENT_TEST'
                  ? itemSelected.placement_test
                  : itemSelected.type
              )}{' '}
              de {itemSelected.student_name}
            </h2>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => {
                setModal(false)
                handleClose()
              }}
            >
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>
          <div className='overflow-y-auto'>
            <TasksDetails
              item={itemSelected}
              setStudent={(e) => setStudent(e)}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            {!isLoading && (
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='button' onClick={handleQualifyModal} className='btn btn-primary me-5'>
                  Ver respuestas
                </button>
                {evaluationType === EvaluationType.PLACEMENT_TEST && (
                  <button type='button' onClick={handleUpdateModule} className='btn btn-success'>
                    Elegir nivel del estudiante
                  </button>
                )}
                {evaluationType === EvaluationType.FINAL_PRESENTATION &&
                  <button 
                    type='button' 
                    className='btn btn-success'
                    onClick={(e)=>{
                      // e.preventDefault()
                      handleApproveNextLevel()}}
                  >
                    Aprobar nivel del estudiante
                  </button>
                }
              </div>
            )}
          </div>
        </>
      )}
    </Modal>,
    modalsRoot
  )
}

export {CreateModal}
