import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useTemplateTableTimeItemAllPaginate, useTemplateTableTimePaginatedByActivity, useTemplateTableTimePaginatedByClassroom, UseTemplateTableTimePaginatedByTeacher, useTemplateTimeItemTeacherPaginated } from '../services'



export function useTemplateTableTimeItemStateTeacher(){
  return  useQuery({ queryKey: ['template-table-time-item-teacher'], queryFn: useTemplateTimeItemTeacherPaginated })
}

export function useTemplateTableTimeItemState(tab:string, root: string){

  return  useQuery(
    tab === 'TEACHER' ? 
      { queryKey: ['template-table-time-item'], queryFn: () => UseTemplateTableTimePaginatedByTeacher(root) } 
    :  tab === 'ACTIVITY'?
      { queryKey: ['template-table-time-item-activity'], queryFn: useTemplateTableTimePaginatedByActivity }:
      { queryKey: ['template-table-time-item-classroom'], queryFn: useTemplateTableTimePaginatedByClassroom }
  )

 
};