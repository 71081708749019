import React, { useEffect, useMemo } from 'react'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { CreateModal } from '../modal'
import { useQueryClient } from '@tanstack/react-query'
import { useDocumentPortfolioState } from '../../hooks/useDocumentPortfolioRepo'
import { UseDocumentPortfolioModule } from '../../store/main'
import { DocumentPortfolio, DocumentPortfolioType } from '../../interfaces'
import { BooleanCell } from '../../../../../components/common/BooleanCell'
import * as Swal from 'sweetalert2'
import { DocumentPortfolioDelete } from '../../services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import GenericPaginator from '../../../../../components/common/Paginator'
import supabase from '../../../../../supabase/supabase';
import { CardHeaderModule } from '../../../../../components/common/widgets/statistics/cardHeaderModule'
import { useThemeMode } from '../../../../../../_metronic/partials'
import { Card4 } from '../../../../../../_metronic/partials/content/cards/Card4'
import { t } from 'i18next'
import { useParams } from 'react-router-dom'
type PaginatorSetter = {
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
}
type Props = {

}
const DocumentPortfolioTableList: React.FC<Props> = ({ }) => {

  const {id} = useParams();
  const { mode } = useThemeMode();
  const queryClient = useQueryClient()
  const {
    paginator,
    modal,
    typeDocument,
    limit,
    currentPage,
    isActive,
    where,
    setCurrentPage,
    setLimit,
    setIsActive,
    setSelectedToEdit,
    setSelectedToView,
    setModal,
    setWhere,
    setToAdd,
    setTypeDocument,
  } = UseDocumentPortfolioModule()
  const { data, isLoading } = useDocumentPortfolioState(id??'');

  // const memoizedValues = useMemo(() => ({ isActive,where,currentPage,limit }), [isActive,where,currentPage,limit]);

  let [page, setPage] = React.useState<PaginatorSetter>({
    currentPage: 1,
    items: 0,
    total: 0,
    totalPage: 0
  });

  const deleteItem = async (id: string) => {
    //@ts-ignore
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await DocumentPortfolioDelete(id)
          showSimpleAlert({ message: 'Ok!', icon: 'success' })
          queryClient.invalidateQueries({ queryKey: ['document-portfolio'] })
        } catch (e) {

        }
      }
    })
  }
  const changePage = (number: number) => {
    setCurrentPage(number)
  };
  const changeLimit = (number: number) => {
    setLimit(number)
  }

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['document-portfolio', id] })
  }, [isActive, where, currentPage, limit, id, typeDocument /* memorizedValues */])
  return (<>
    <CardHeaderModule
      title={'Portafolio'}
      leftIcon={`/media/svg/michigan-icons/header/${mode === 'light' ? 'blue/back_blue' : 'dark-modules/back_aquamarine'}.svg`}
      svgIcon={`media/svg/michigan-icons/header/${mode === 'light' ? 'blue/freezing_blue' : 'dark-modules/freezing_aquamarine'}.svg`}
      background={'#1bebd4'}
      colorTitle={'#2d30e1'}
    />

    <div className='d-flex justify-content-end my-2 mb-5'>
      <div className='d-flex align-items-center position-relative me-4'>
        <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
        <input
          type='text'
          id='kt_filter_search'
          className='form-control form-control-white form-control-sm w-150px ps-9'
          placeholder='Search'
        />
      </div>

      <button type='button' onClick={() => setTypeDocument(undefined)} className='btn btn-primary btn-sm'>
        File Manager
      </button>
    </div>

    <div className='row g-6 g-xl-9'>
      {!typeDocument && Object.keys(DocumentPortfolioType).map((item) => (
        <div className="col-12 col-sm-12 col-xl" onClick={() => setTypeDocument(item as DocumentPortfolioType)}>
          <Card4
            icon='/media/svg/files/folder-document.svg'
            title={t(item)}
            description='7 files'
          />
        </div>
      ))}
      {typeDocument && (data && (data?.result && data.result.length > 0) ? data.result.map((x: DocumentPortfolio) => {
        return (
          <div className='col-6 col-sm-12 col-xl'>
            <Card4
              icon='/media/svg/files/pdf.svg'
              title={x?.description??''}
              description='3 days ago'
            />
          </div>
        )
      }) : 
      <div className='justify-content-center align-items-center'>
        Sin Resultados
      </div>)}
    </div>
    <CreateModal show={modal} handleClose={() => alert()} />
  </>
  )
}

export { DocumentPortfolioTableList }
