import React, { useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, useFormik } from 'formik'
import { Workshop } from '../../interfaces'

import { WorkshopAdd, WorkshopUpdate } from '../../services'
import { UseWorkshopModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { TraditionalLevelEnum } from '../../../../../road-map-step/interfaces'
import { ResourceType } from '../../../chapter/interfaces'
import { setTypeToSpanish } from '../../../../../../admin/user/utils/setTypeToSpanish'

export type FormFields = {
  name: string
  isActive: boolean
  description?: string
  level: TraditionalLevelEnum[];
  isOptional: boolean;
  totalHours: number;
  resource?: string;
  resourceType?: ResourceType;
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
}
const WorkshopForm: React.FC<Props> = (props: Props) => {
  const queryClient = useQueryClient()
  const { modal, itemSelected, mode, setModal, resetData, setSelectedToEdit, setSelectedToView } = UseWorkshopModule();
  const [data, setData] = useState<Workshop>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? [TraditionalLevelEnum.PRE_A1_1] : [TraditionalLevelEnum.PRE_A1_1],
        isOptional: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isOptional ?? true : true,
        totalHours: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.totalHours ?? 0 : 0,
        resourceType: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.resourceType ?? undefined : undefined
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (props.mode === 'ADD') {
          try {
            setLoading(true);
            await WorkshopAdd({ ...formData })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['workshop'] })

          } catch (e) {
            setLoading(false);
          }

        }
        else {
          try {
            setLoading(true);
            await WorkshopUpdate({ ...formData })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['workshop'] })

          } catch (e) {
            setLoading(false);
          }
        }
      },
    })


  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                disabled={mode === 'VIEW'}
                checked={values.isActive}
                value={values.isActive ? 1 : 0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>is Optional ?</label>
            <div className="form-check form-switch">
              <input
                name={'isOptional'}
                disabled={mode === 'VIEW'}
                checked={values.isOptional}
                value={values.isOptional ? 1 : 0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>levels</label>
            <select
              disabled={mode === 'VIEW'}
              name='level'
              value={values.level}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
              multiple
            >
              {Object.keys(TraditionalLevelEnum).map((x) => (
                <option key={x} value={x}>
                  {setTypeToSpanish(x)}
                </option>
              ))}
            </select>
            {errors.level && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.level}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Total Hours</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'totalHours'}
              value={values.totalHours}
              onChange={handleChange}
            />
            {touched.totalHours && errors.totalHours && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.totalHours}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Resource</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'resource'}
              value={values.resource}
              onChange={handleChange}
            />
            {touched.resource && errors.resource && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.resource}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>resourceType</label>
            <select
              disabled={mode === 'VIEW'}
              name='resourceType'
              value={values.resourceType}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(ResourceType).map((x) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </select>
            {errors.resourceType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.resourceType}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { WorkshopForm }
