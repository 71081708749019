import { useQuery, useQueryClient } from '@tanstack/react-query'
import { UseFindPaginatedTrailClass } from '../../../../me-booking-active/services'
import moment from 'moment'
import UseTranslateToBogotaTimeZone from '../../../../../hooks/useBogotaTranslateTz'

export function useTrailClassStudentState(){
  return  useQuery({ queryKey: ['trail-active-dashboard'], queryFn: () => 
    UseFindPaginatedTrailClass({
        before: UseTranslateToBogotaTimeZone().yearMonthDay,
        after: UseTranslateToBogotaTimeZone().endOfWeek,
      })
  })
}