import React from 'react';
import * as XLSX from 'xlsx';
import { Lead } from '../../../modules/booking-dashboard/interfaces';
import moment from 'moment';
import { attendedParser, enrolledParse, interestParser, levelParse, originParser } from './utils';
import { LeadResume } from '../../../modules/resume/interfaces';
import { useThemeMode } from '../../../../_metronic/partials';

type Props = {
  dataExcel: Array<any>;
  filename: string;
  sheetName: string;
  theadName: Array<string>;
  type?: string;
  backgroundColor?: string;
  titleColor?: string;
  // moreSheet: boolean;
}
const AllExcel: React.FC<Props> = ({ dataExcel, filename, sheetName, theadName, type, backgroundColor, titleColor }: Props) => {
  const { mode } = useThemeMode();

  const copyData = JSON.parse(JSON.stringify(dataExcel));

  const modified = !type ? copyData.map(item => {
    item.attended = attendedParser(item.attended)
    item.interest = interestParser(item.interest)
    item.level = levelParse(item.level)
    item.source = originParser(item.source)
    item.enrolled = enrolledParse(item.enrolled)
    item.createdAt = moment(item.createdAt).format('DD/MM/YYYY')
    item.updatedAt = moment(item.updatedAt ?? moment()).format('DD/MM/YYYY')
    //@ts-ignore
    item.isActive = item.isActive ? 'Activo' : 'Inactivo'
    return item;
  }) : type === 'all' ? 
  copyData.map((item: any) => {
    // item.createdAt = moment(item.createdAt).format('DD/MM/YYYY')
    // item.updatedAt = moment(item.updatedAt ?? moment()).format('DD/MM/YYYY')
    return item;
  }) : copyData.map(item => ({
      start: moment(item?.start?.split(' ')[0]).format('DD/MM/YYYY'),
      hour: item?.hour?.map(x => `${x.start} - ${x.end}`).join(', '),
      assistantStudent: item?.assistantStudent ? 'Asistio' : 'No Asistio',
      title: item?.title,
      room: item?.room,
      teacher: item?.teacher,
      assistantTeacher: item?.assistantTeacher ? 'Asistio' : 'No Asistio',
      stars: item?.stars,
      space: "",
      studentObservation: item?.studentObservation ?? '',
      teacherObservation: item?.teacherObservation ?? ''
    }));

  const dataArray = modified.map(obj => Object.values(obj));
  const exportToExcel = () => {
    const sheetsData: Array<{
      name: string;
      data: Array<Array<any>>;
    }> = [
        {
          name: sheetName,
          data: [[...theadName],
          ...dataArray],
        }
      ];
    const wb = XLSX.utils.book_new();
    sheetsData.forEach((sheet) => {
      const ws = XLSX.utils.aoa_to_sheet(sheet.data);
      XLSX.utils.book_append_sheet(wb, ws, sheet.name);
    });

    XLSX.writeFile(wb, `${filename}.xlsx`);
  };


  return (

    <div className=''>
      <button onClick={exportToExcel}
        className={`btn card w-100 d-flex justify-content-center align-items-center`}
        style={{ backgroundColor: backgroundColor, borderRadius: '30px', color: titleColor, border: mode === 'dark' ? `1px solid ${backgroundColor}` : '' }}
      >
        <span className='d-flex justify-content-center align-items-center champ-light'>
          Excel
        </span>
      </button>
    </div>

  )
}

export default AllExcel;