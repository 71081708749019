import { create } from "zustand"
import { QuestionsCompleteSentenceIE } from "../../../../../../modules/admin/exercises/templates-exercise/book/complete-sentences/interfaces";

export interface AnswerCompleteSentence {
  col: string;
  row: string;
  value: string;
}
interface InterfaceModuleCompleteSentence {
  totalCells: number;
  percentage: number | null;
  checked: boolean;
  questions?: QuestionsCompleteSentenceIE[],
  instruction: string,
  numberRow: number;
  inputValues: any[],
  answer?: {[key: string]: string};
  exerciseId?: string;
  setChecked: (value: boolean) => void
  setPercentage: (value: number) => void
  setInputValuesMo: (value: any[]) => void;
  setQuestions: (
    exerciseId: string, 
    instruction: string,
    value: QuestionsCompleteSentenceIE[]) => void;
  setNumberRow: (value: number) => void;
  setAnswer:(value: AnswerCompleteSentence[], percentage: number) => void;
}
export const UseModuleCompleteSentence = create<InterfaceModuleCompleteSentence>((set) => ({
  percentage: null,
  totalCells: 0,
  instruction: '',
  checked: false,
  numberRow: 0,
  questions: [],
  inputValues: [],
  setInputValuesMo(value) {
    set({ inputValues: value })
  },
  setChecked: (value) => {
    set({ checked: value })
  },
  setPercentage: (value) => {
    set({ percentage: value })
  },
  setQuestions: (exerciseId, instruction, value) => {
    set({ questions: value, exerciseId, instruction, answer: undefined });
  },
  setNumberRow: (value) => {
    set({ numberRow: value });
  },
  setAnswer:(value, percentage) => {
    const newInputValues = {};

    for (const item of value) {
        const { col, row, value } = item;
        const key = `${col}-${row}`;
        newInputValues[key] = value;
    }

    set({answer: newInputValues, checked: true, percentage})
  }
}))