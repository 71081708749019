import React from "react";

type Props = {
  justGoBack? : boolean
  goBackAction?: () =>void
  onAction: () => void;
}

const ButtonTryAgain: React.FC<Props> = ({ onAction, justGoBack, goBackAction }) => {
  return(<>
    <button type="button" onClick={justGoBack? goBackAction: onAction} 
      className="btn champ-bold py-1 btn-light-primary btn-outline-white"
      style={{fontSize: 14, backgroundColor: 'white', borderRadius: '20px'}}
    >

      {justGoBack? 
      <span>
          <i className="bi bi-chevron-left me-2"></i>Back
      </span>
      :
      <span>Try again</span>
      }
    </button>
  </>)
};

export {ButtonTryAgain};