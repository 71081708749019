import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import * as Swal from 'sweetalert2'
import { KTSVG, KTIcon, toAbsoluteUrl } from '../../../../../../../../_metronic/helpers';
import { showSimpleAlert } from '../../../../../../../commonHooks/alert';
import { BooleanCell } from '../../../../../../../components/common/BooleanCell';
import GenericPaginator from '../../../../../../../components/common/Paginator';
import { TemplateTableTimeDay } from '../../../../interfaces';
import { TemplateTableTimeDelete, TemplateTableTimeFindOne } from '../../../../services';
import { useTemplateTableTimeItemState } from '../../hooks/useTemplateTableTimeItemRepo';
import { UseTemplateTableTimeItemModule } from '../../store/main';
import { CreateModal } from '../modal';
import { useNavigate, useParams } from 'react-router-dom';
import { ViewModal } from '../modal/activityModal';
import { setTypeToSpanish } from '../../../../../../admin/user/utils/setTypeToSpanish';
import { UseBlockHourAll } from '../../../../../block-hours/services';
import { BlockHour } from '../../../../../block-hours/interfaces';
import { UseUserTypePaginate } from '../../../../../../admin/user/services';
import { UseCampusAll } from '../../../../../campus/services';
import { Campus } from '../../../../../campus/interfaces';
import { StatTemplateTableTime } from '../stats';
import { DraggableModal } from '../../../../../../book/components/modal';
import moment from 'moment';
import { UseCountryAll } from '../../../../../../admin/country/services';
import { Country } from '../../../../../../admin/countries/interfaces';
import SummaryByDayDropdown from '../summary';

type PaginatorSetter = {
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
}
type Props = {

}
const TemplateTableTimeItemTableList: React.FC<Props> = ({ }) => {
  const [_campus, set_campus] = useState<Campus[]>([])
  const [teacher, setTeacher] = React.useState<any[]>([])
  const queryClient = useQueryClient()
  const {
    blockHour,
    tabList,
    dayList,
    country,
    paginator,
    root,
    modal,
    limit,
    currentPage,
    isActive,
    where,
    setCurrentPage,
    setLimit,
    setIsActive,
    setSelectedToEdit,
    setSelectedToView,
    setModal,
    setWhere,
    setType,
    type,
    setCampus,
    campus,
    setToAdd,
    teacherId,
    setTeacherId,
    setSearchTeacher,
    searchTeacher,
    setRoot,
    setDayList,
    setActivity,
    setTabList,
    setDayAvailable,
    setBlockHour,
    setCountry,
  } = UseTemplateTableTimeItemModule();

  const { id } = useParams();

  const navigate = useNavigate();

  const { data, isLoading } = useTemplateTableTimeItemState(tabList, id??'');

  const [dataBlockHour, setDataBlockHour] = React.useState<BlockHour[]>([]);
  const [dateShow, setDateShow] = React.useState<string>("");
  const [countries, setCountries] = React.useState<Country[]>([])


  let [page, setPage] = React.useState<PaginatorSetter>({
    currentPage: 1,
    items: 0,
    total: 0,
    totalPage: 0
  });

  const deleteItem = async (id: string) => {
    //@ts-ignore
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await TemplateTableTimeDelete(id)
          showSimpleAlert({ message: 'Ok!', icon: 'success' })
        } catch (e) {

        }
      }
    })
  };
  const fetchTeacher = async () => {
    try {
      let teacher = await UseUserTypePaginate('TEACHER')
      setTeacher(teacher)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar profesores!', icon: 'error' })
      throw 'Error'
    }
  }
  const fetchCampus = React.useCallback(async () => {
    try {
      let campus = await UseCampusAll()
      set_campus(campus)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontar sedes academicas!', icon: 'error' })
      throw 'Error'
    }
  }, [])
  const changePage = (number: number) => {
    setCurrentPage(number)
  };
  const changeLimit = (number: number) => {
    setLimit(number)
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value === 'Todos los días' ? undefined : (e.target.value as TemplateTableTimeDay);
    setDayList(selectedValue);
  };

  const handleChangeCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value === 'Todos los paises' ? undefined : (e.target.value);
    setCountry(selectedValue);
  };

  const handleChangeBlockHour = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBlockHour(parseInt(e.target.value))
  };
  const handleTypeClassroom = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.value)
  };
  const fetchTemplateTableItem = async () => {
    try {
      if (id) {
        const templateResponse = await TemplateTableTimeFindOne(id);
        let dayAvailableResponse = {
          monday: templateResponse?.monday,
          tuesday: templateResponse?.tuesday,
          wednesday: templateResponse?.wednesday,
          thursday: templateResponse?.thursday,
          friday: templateResponse?.friday,
          saturday: templateResponse?.saturday
        }
        setDayAvailable(dayAvailableResponse);
      }
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Template!', icon: 'error' })
      throw 'Error'
    }
  };

  const fetchBlocksHours = async () => {
    try {
      const response = await UseBlockHourAll();
      setDataBlockHour(response);
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar bloques horarios!', icon: 'error' })
      throw 'Error'
    }
  };

  const fetchCountry = React.useCallback(async() => {
    const response = await UseCountryAll();
    setCountries(response)
  }, []); 

  React.useEffect(() => {
    fetchBlocksHours();
    fetchTeacher();
    fetchCampus();
    fetchCountry();
  }, []);

  React.useEffect(() => {
    if (id) {
      setRoot(id);
      fetchTemplateTableItem();

    }
  }, [id]);


  React.useEffect(() => {
    if (tabList === 'TEACHER') {
      queryClient.invalidateQueries({ queryKey: ['template-table-time-item'] })
    }

    if (tabList === 'ACTIVITY') {
      queryClient.invalidateQueries({ queryKey: ['template-table-time-item-activity'] })
    }
    if (tabList === 'CLASSROOM') {
      queryClient.invalidateQueries({ queryKey: ['template-table-time-item-classroom'] })
    }
    const _dateShow = moment().format('YYYYMMDDHHmmss');
    setDateShow(_dateShow)
  }, [
    isActive, 
    where, 
    currentPage, 
    limit, 
    dayList, 
    tabList, 
    blockHour, 
    type, 
    searchTeacher, 
    campus,
    country]);

  return (<>
    <button className='btn btn-icon btn-light btn-sm border-0 me-1' onClick={() => navigate(-1)}>
      <KTIcon iconName='arrow-left' className='fs-2 text-primary' />
    </button>

    {id &&
      <DraggableModal>
        <StatTemplateTableTime id={id} timer={dateShow} />
      </DraggableModal>}

    <div className={`card `}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Gestions de la Plantilla</span>
          <span className='text-muted mt-1 fw-semibold fs-7'></span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >

          <div className='position-relative my-1'>
            <KTSVG
              path='/media/svg/icons/earth.svg'
              className='svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-10'
            />
            <input
              type='text'
              onChange={(e) => setWhere(e.target.value)}
              className='form-control form-control-sm form-control-solid w-350px ps-10'
              name='Search'
              value={where}
              placeholder='Search'
            />
          </div>
          {/* <button
            onClick={() => setIsActive(!isActive)}
            className='btn btn-sm btn-light-secondary  mr-8 p-2'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
             <BooleanCell value={isActive} />
          </button> */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='overflow-auto'>
          <div className='d-flex  justify-content-between'>

            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item mb-4 col-sm d-flex justify-content-center'>
                <button
                  type='button'
                  className={`btn nav-link text-active-primary me-6 ${tabList === 'TEACHER' && 'bg-primary text-white'}`}
                  onClick={() => setTabList('TEACHER')}
                >
                  <span style={{ paddingLeft: '5px' }}>Profesores</span>
                </button>
              </li>
              <li className='nav-item mb-4 col-sm d-flex justify-content-center'>
                <button
                  type='button'
                  className={`btn nav-link text-active-primary me-6 ${tabList === 'ACTIVITY' && 'bg-primary text-white'}`}
                  onClick={() => setTabList('ACTIVITY')}
                >
                  <span style={{ paddingLeft: '5px' }}>Actividades</span>
                </button>
              </li>
              <li className='nav-item mb-4 col-sm d-flex justify-content-center'>
                <button
                  type='button'
                  className={`btn nav-link text-active-primary me-6 ${tabList === 'CLASSROOM' && 'bg-primary text-white'}`}
                  onClick={() => setTabList('CLASSROOM')}
                >
                  <span style={{ paddingLeft: '5px' }}>Salones</span>
                </button>
              </li>
            </ul>
          </div>
          <div>
            <div className='row w-100'>
              < div className='col-3'>
                <label className='form-label mb-0 me-4'>Day</label>
                <select
                  name='dayList'
                  value={dayList}
                  onChange={handleChange}
                  className='form-select form-select-sm me-4 w-100'
                  aria-label='Default select example'
                >
                  <option value={undefined}>
                    {'Todos los días'}
                  </option>
                  {Object.keys(TemplateTableTimeDay).map((x) => (
                    <option key={x} value={x}>
                      {setTypeToSpanish(x)}
                    </option>
                  ))}
                </select>
              </div>
              {tabList === 'TEACHER' &&
                <div className='col-3'>
                  <label className='form-label mb-0 me-4'>Pais</label>
                  <select
                    name='country'
                    value={country}
                    onChange={handleChangeCountry}
                    className='form-select form-select-sm me-4 w-100'
                    aria-label='Default select example'
                  >
                    <option value={undefined}>
                      {'Todos los paises'}
                    </option>
                    {countries.map((x) => (
                      <option key={x.id} value={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </select>
                </div>}
              {tabList === 'ACTIVITY' && < div className='col-3'>
                <label className='form-label mb-0 me-4'>Bloque horario</label>
                <select
                  name='dataBlockHour'
                  value={blockHour}
                  onChange={handleChangeBlockHour}
                  className='form-select form-select-sm me-4 w-100'
                  aria-label='Default select example'
                >
                  <option value={undefined}>
                    {'Todo el día'}
                  </option>
                  {dataBlockHour?.map((x) => (
                    <option key={x.id} value={x.order}>
                      {`${x.start} ${x.end}`}
                    </option>
                  ))}
                </select>
              </div>}
              {tabList === 'CLASSROOM' && <>
                <div className='col-3'>
                  <label className='form-label mb-0 me-4'>Tipo de salon</label>
                  <select
                    name='dataBlockHour'
                    value={type}
                    onChange={handleTypeClassroom}
                    className='form-select form-select-sm me-4 w-100'
                    aria-label='Default select example'
                  >
                    <option value={'ALL'}>
                      Todos
                    </option>
                    <option value={'VIRTUAL'}>
                      Virtual
                    </option>
                    <option value={'FACE'}>
                      Presencial
                    </option>
                  </select>
                </div>
              </>}
              {tabList === 'CLASSROOM' || tabList === 'ACTIVITY' && <>
                <div className='col-3'>
                  <label className='form-label mb-0'>Tutor</label>
                  <select
                    name={'searchTeacher'}
                    value={searchTeacher}
                    className='form-select form-select-sm me-4 w-100'
                    onChange={(e) => setSearchTeacher(e.target.value)}
                    aria-label="Default select example">
                    <option selected></option>
                    {teacher.map((x) =>
                      (<option value={x.id}>{`${x.fullName}` || `${x.firstName.toUpperCase()} ${x.lastName.toUpperCase()}`}</option>)
                    )}
                  </select>
                </div>
              </>}
              {tabList === 'ACTIVITY' && <>
                <div className='col-3'>
                  <label className='form-label mb-0'>Sedes</label>
                  <select
                    name={'searchTeacher'}
                    value={searchTeacher}
                    className='form-select form-select-sm me-4 w-100'
                    onChange={(e) => setCampus(e.target.value)}
                    aria-label="Default select example">
                    <option selected></option>
                    {_campus.map((x) =>
                      (<option value={x.id}>{x.name}</option>)
                    )}
                  </select>
                </div>
              </>}
            </div>
          </div>
        </div>
        {!isLoading && data && <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>Name</th>
                <th className='min-w-120px'>{tabList === 'TEACHER' && `Phone Number`} {tabList === 'ACTIVITY' && ` Workshop`}</th>
                <th className='min-w-120px'>Hours</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {isLoading &&
                <tr className='text-center'>
                  <img
                    className="rounded mx-auto d-block"
                    width={'70%'}
                    src={toAbsoluteUrl('/media/svg/icons/loading.svg')} alt='' />
                </tr>
              }
              {data?.result?.map((x) => (
                <tr key={x.id} className='p-0'>
                  <td className='p-0'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        <img src={toAbsoluteUrl('/media/svg/icons/earth.svg')} alt='' />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {x?.fullName || x?.name}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {tabList === 'ACTIVITY' && `${x?.description}`}
                          {tabList === 'TEACHER' && `${x?.email}`}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className='p-0'>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {tabList === 'TEACHER' && <SummaryByDayDropdown summaryByDay={x.summaryByDay} />}
                      {tabList === 'ACTIVITY' && <><BooleanCell value={x?.toWorkshop} /></>}
                    </span>
                  </td>
                  <td className='p-0'>
                    <span className='    d-block fs-7'>
                      {x.count}{tabList === 'TEACHER' && <>/{x.total}</>}
                    </span>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      {tabList === 'TEACHER' && <>
                        <button
                          onClick={() => setTeacherId(x.id)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i className="fa-solid fa-hourglass-start"
                            style={{ color: 'green', fontSize: 17 }}></i>
                        </button>
                      </>}

                      {tabList === 'ACTIVITY' && <>
                        <button
                          onClick={() => setActivity(x.id)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i className="fa-solid fa-list"
                            style={{ color: 'blue', fontSize: 17 }}></i>
                        </button>
                      </>}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          <GenericPaginator
            limit={limit}
            currentPage={paginator.current_page}
            items={paginator.result.length}
            total={paginator.total}
            totalPage={paginator.total_page}
            changePage={changePage}
            changeLimit={changeLimit}
          />
          {/* end::Table */}
        </div>}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    {tabList === 'TEACHER_FORM' && <>
      <CreateModal show={modal} handleClose={() => alert()} />
    </>}
    {tabList === 'ACTIVITY_VIEW' && <>
      <ViewModal show={modal} handleClose={() => alert()} />
    </>}
  </>
  )
}

export { TemplateTableTimeItemTableList }
