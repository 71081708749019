import React, {useEffect} from 'react'

import './index.css'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Answer, QuestionHistoryWriting, QuestionAndAnswer} from '../interfaces'
import useSound from 'use-sound'
import {AddHistoryWritingResponse} from '../services'
import {showSimpleAlert} from '../../../../../commonHooks/alert'
import HeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard'
import {ModeTypeExercise} from '../../../../book/components/RenderExerciseBook'
import {UseBookStudentModule} from '../../../../book/modules/screen/store/main'
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise'
import { UseHistoryWritingModule } from '../store'

type Props = {
  userMode?: ModeTypeExercise
  question: QuestionHistoryWriting
  handleSendAnswer: (x: Answer) => void
}
const divStyle = {
  backgroundColor: '#3f8bff',
  width: '525px',
  height: '290px',
  borderRadius: '30px',
  borderColor: 'rgb(255, 255, 255)',
  borderStyle: 'solid',
  padding: '27px 10px 40px 20px',
}
const BeyondReadingItemOne: React.FC<Props> = ({userMode, question, handleSendAnswer}: Props) => {
  const {
    lessonData,
    stepBookItem,
    currentTaskId,
    setStepBookItem,
    addCompletedExerciseId,
    onChangeTaskByAnswer,
    setIsLoadingSubmit
  } = UseBookStudentModule();

  const {exerciseId} = UseHistoryWritingModule();

  const [selected, setSelected] = React.useState<Answer>()
  const [descriptionData, setDescriptionData] = React.useState<string>('')

  const [playbackRate] = React.useState(0.75)
  const [play] = useSound(toAbsoluteUrl(`/media/audio/click.mp3`), {
    playbackRate,
    volume: 0.1,
  })
  const [success] = useSound(toAbsoluteUrl(`/media/audio/success.mp3`), {
    playbackRate,
    volume: 0.1,
  })
  const [wrong] = useSound(toAbsoluteUrl(`/media/audio/wrong.mp3`), {
    playbackRate,
    volume: 0.1,
  })
  const handleClick = () => {
    play()
  }
  const handleSend = () => {
    if (selected?.isTrue) {
      handleSendAnswer(selected)
      setSelected(undefined)
    }
    if (selected?.isTrue === false) {
      handleSendAnswer(selected)
      setSelected(undefined)
    }
  }

  useEffect(() => {
    setSelected(undefined)
  }, [question])

  const handleSubmit = async () => {
    if (userMode == 'STUDENT' && currentTaskId && exerciseId) {
      try {
        setIsLoadingSubmit(true)
        const response = await AddHistoryWritingResponse({
          exercises: exerciseId ?? 'xx',
          task: currentTaskId ?? 'xx',
          answers: [],
          isActive: true,
          isReviewed: false,
          isPending: true,
        });
        onChangeTaskByAnswer(response, 'history_writing');
      } catch (e) {
        showSimpleAlert({message: 'Error al cargar respuesta', icon: 'error'})
      } finally {
        setIsLoadingSubmit(false)
      }
    }
    if (lessonData && lessonData.evaluation && lessonData.evaluation.evaluation_item) {
      addCompletedExerciseId(exerciseId??'')
      if (lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
        setStepBookItem(exerciseId??'')
      } else {
        setStepBookItem('FINISH_LESSON')
      }
    }
  }

  return (
    <>
      <div className='container-fluid ' style={{display: 'flex'}}>
        <div style={divStyle}>
          <HeaderCard />
          <span
            className=' text-white cuestion mb-0 poppins-light'
            style={{marginLeft: '10px', fontSize: '80%', color: '#fffff', marginBottom: '20px'}}
          >
            {question.mode}
          </span>
          <div className='row p-2'>
            <div className='col-6'>
              <span className='text-white cuestion mb-1 p-2 poppins-light'>
                {question.name}b: What does the author refer to the as'an attempt?:
              </span>
            </div>
            <div className='col-6'>
              <textarea
                value={descriptionData}
                onChange={(e) => setDescriptionData(e.target.value)}
                className='poppins-light'
                style={{
                  borderRadius: '30px',
                  borderColor: '#3e94ff',
                  color: /*evaluationLesson?.colorPrimary??*/ '#2d30e1',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  fontSize: 15,
                  padding: '10px',
                  width: '100%',
                  height: '100%',
                  outline: 'none',
                  resize: 'none', // Opcional: Desactiva la capacidad de redimensionar el área de texto
                }}
                placeholder='Type here...'
              />
            </div>
          </div>
        </div>
        <div 
          className='d-flex justify-content-end align-items-end'
          style={{ 
              position: 'absolute',
              bottom: '5%',
              right: '22%'
          }}
        >
          <ButtonSaveExercise
            isChecked={true}
            onNext={()=>{}}
            onSubmit={handleSubmit}
            exerciseId={exerciseId}
          />
        </div>
      </div>
    </>
  )
}

export {BeyondReadingItemOne}
