import { Route, Routes } from 'react-router-dom'
import { ContainerBook } from '../components/container';
import { HeaderBook } from '../components/header';
import { ToolbarBook } from '../components/toolbar';
import { DraggableModal } from '../components/modal';
import { HighlightWord } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseSeven/ExerciseSeven';
import { ExerciseSix } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseSix/ExerciseSix';
import { CrosswordGrid } from '../components/example';
import { CrosswordExercise } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseTwo';
import { CreateModalCross } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseTwo/forms/modal';
import { AudioInteraction } from '../components/interactions/audio';
import { SpeakingExercise } from '../../courses/exercises/speaking';
import { ContainerTestBook } from '../modules/test';
import { ContainerScreenBook } from '../modules/screen';
import useAuth from '../../../api/main';
import { ContainerTeacherBook } from '../modules/class';
import { ContainerPublicBook } from '../modules/public';

const BookContainerPage = () => {
    const { typeUser } = useAuth()

    return (
        <Routes>
            {/* <Route path='/toolbar' element={<ToolbarBook />} /> */}
            {/* <Route path='/form' element={<CreateModalCross show />} /> */}
            {/* <Route path='/cross' element={<DraggableModal>
            <CrosswordExercise />
        </DraggableModal>} /> */}
            {/* <Route path='/test' element={<DraggableModal>
            <AudioInteraction />
        </DraggableModal>} /> */}
            {/* <Route path='/audio' element={<SpeakingExercise />} /> */}
            {typeUser === 'STUDENT' &&
                <Route path='/container' element={<ContainerScreenBook />} />
            }
            {(typeUser === 'ADMIN' || typeUser === 'TEACHER') &&
                <Route path='/test' element={<ContainerTestBook />} />
            }
            {(typeUser === 'TEACHER') &&
                <Route path='/class' element={<ContainerTeacherBook />} />
            }
                <Route path='/public' element={<ContainerPublicBook />} />
        </Routes>
    )
};

export { BookContainerPage };