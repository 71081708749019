import React, { useEffect, useMemo } from 'react'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { CreateModal } from '../modal'
import { useQueryClient } from '@tanstack/react-query'
import { useCourtesyCodeState } from '../../hooks/useCourtesyCodeRepo'
import { UseCourtesyCodeModule } from '../../store/main'
import { CourtesyCode } from '../../interfaces'
import { BooleanCell } from '../../../../../components/common/BooleanCell'
import * as Swal from 'sweetalert2'
import { CourtesyCodeDelete } from '../../services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import GenericPaginator from '../../../../../components/common/Paginator'
import supabase from '../../../../../supabase/supabase';
import AllCodeExcel from '../excel'
import { useThemeMode } from '../../../../../../_metronic/partials'
import moment from 'moment'
type PaginatorSetter = {
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
}
type Props = {

}
const CourtesyCodeTableList: React.FC<Props> = ({ }) => {
  const { mode } = useThemeMode();
  const queryClient = useQueryClient()
  const {
    paginator,
    modal,
    limit,
    currentPage,
    isActive,
    where,
    setCurrentPage,
    setLimit,
    courtesy,
    setCourtesy,
    setIsActive,
    setSelectedToEdit,
    setSelectedToView,
    setModal,
    setStatus,
    status,
    setWhere,
    setToAdd
  } = UseCourtesyCodeModule()
  const { data, isLoading } = useCourtesyCodeState();

  // const memoizedValues = useMemo(() => ({ isActive,where,currentPage,limit }), [isActive,where,currentPage,limit]);

  let [page, setPage] = React.useState<PaginatorSetter>({
    currentPage: 1,
    items: 0,
    total: 0,
    totalPage: 0
  });

  const deleteItem = async (id: string) => {
    //@ts-ignore
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await CourtesyCodeDelete(id)
          showSimpleAlert({ message: 'Ok!', icon: 'success' })
          queryClient.invalidateQueries({ queryKey: ['courtesy-code'] })
        } catch (e) {

        }
      }
    })
  }
  const changePage = (number: number) => {
    setCurrentPage(number)
  };
  const changeLimit = (number: number) => {
    setLimit(number)
  }

  const handleChangeCourtesy = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCourtesy(e.target.value);
  };

  const handleChangeStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(e.target.value);
  };

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['courtesy-code'] })
  }, [isActive, where, currentPage, limit, courtesy, status])
  return (<>
    <div className={`card `}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        {data && data?.result && data.result.length > 0 &&
          <AllCodeExcel
            dataExcel={data.result}
            filename={`CODE-${moment().toISOString()}`}
            sheetName='Resume'
            theadName={['URL', 'CODE', 'DAYS', 'TYPE', 'STATUS', 'DNI']}
            type='MeReport'
            backgroundColor={mode === 'light' ? 'green' : ''}
            titleColor={mode === 'light' ? '#FFFFFF' : 'green'}
          ></AllCodeExcel>
        }
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>courtesy-code</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Gestión de courtesy-code </span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >

          <div className='position-relative my-1'>
            <KTSVG
              path='/media/svg/icons/earth.svg'
              className='svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-10'
            />
            <input
              type='text'
              onChange={(e) => setWhere(e.target.value)}
              className='form-control form-control-sm form-control-solid w-350px ps-10'
              name='Search'
              value={where}
              placeholder='Search'
            />
          </div>
          <button
            onClick={() => setToAdd()}
            className='btn btn-sm btn-light-primary mr-8'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='plus' className='fs-3' />
          </button>
          <button
            onClick={() => setIsActive(!isActive)}
            className='btn btn-sm btn-light-secondary  mr-8 p-2'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
            <BooleanCell value={isActive} />
          </button>
        </div>
      </div>
      {/* end::Header */}

      <div className='py-3 row'>
        {/* begin::Filters */}
        <div className='mb-5 d-flex align-items-center overflow-x-auto px-4'>
          {/* <div className="col-auto d-flex align-items-center mx-3">
            <label className='form-label mb-0 me-2'>Tipo</label>
            <div style={{ display: 'inline-block', width: 'auto', minWidth: '100px', maxWidth: '200px' }}>
              <select
                name={'typeClassroom'}
                value={courtesy}
                onChange={handleChangeCourtesy}
                className="form-select form-select-sm"
                aria-label="Default select example"
              >
                <option value={undefined}></option>
                <option value={'PLACEMENT_TEST'}>Placement Test</option>
                <option value={'COURTESY'}>Explorers</option>
              </select>
            </div>
          </div> */}
          <div className="col-auto d-flex align-items-center mx-3">
            <label className='form-label mb-0 me-2'>Status</label>
            <div style={{ display: 'inline-block', width: 'auto', minWidth: '100px', maxWidth: '200px' }}>
              <select
                name={'typeClassroom'}
                value={status}
                onChange={handleChangeStatus}
                className="form-select form-select-sm"
                aria-label="Default select example"
              >
                <option value={undefined}></option>
                <option value={'FREE'}>Libre</option>
                <option value={'IN_USE'}>En uso</option>
                <option value={'IN_EVALUATION'}>En evaluación</option>
                <option value={'ARCHIVED'}>Archivado</option>
                <option value={'TRANSFERRED'}>Tranferido</option>
                <option value={'UNDER_NEGOTIATION'}>Bajo negociación</option>

              </select>
            </div>
          </div>
        </div>
      </div>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {!isLoading && data && <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>País</th>
                <th className='min-w-120px'>Student</th>
                <th className='min-w-120px'>Active</th>
                <th className='min-w-120px'>Is used</th>
                <th className='min-w-100px'>Serial</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {isLoading && <tr className='text-center'>
                <img
                  className="rounded mx-auto d-block"
                  width={'70%'}
                  src={toAbsoluteUrl('/media/svg/icons/loading.svg')} alt='' />
              </tr>}
              {data.result.map((x: CourtesyCode) => (
                <tr key={x.id} className='p-0'>
                  <td className='p-0'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        <img src={toAbsoluteUrl('/media/svg/icons/earth.svg')} alt='' />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {x.code}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {x.url ?? ''}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className='p-0'>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {x.name ?? 'vacio'}
                    </span>
                  </td>
                  <td className='text-start'>
                    <BooleanCell value={x.isActive} />
                  </td>
                  <td className='text-start'>
                    <BooleanCell value={x.isUsed} />
                  </td>
                  <td>
                    {x.serial??''}
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          <GenericPaginator
            limit={limit}
            currentPage={paginator.current_page}
            items={paginator.result.length}
            total={paginator.total}
            totalPage={paginator.total_page}
            changePage={changePage}
            changeLimit={changeLimit}
          />
          {/* end::Table */}
        </div>}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    <CreateModal show={modal} handleClose={() => alert()} />
  </>
  )
}

export { CourtesyCodeTableList }
