import React from "react";
import { UseBookingWindows } from "../store";
import CustomButton from "../../../components/common/custom-button";
import { useThemeMode } from "../../../../_metronic/partials";
import { UseMyRoadmapModule } from "../../profile/components/my-road-step/store";
import { SettingModalityType } from "../../admin/user/interfaces";
import { ClassroomType } from "../../admin/classroom/interfaces";
interface Props {

}

const ModalityBookingButton: React.FC<Props> = ({ }) => {
    const {mode} = useThemeMode()
    const {settings} = UseMyRoadmapModule();
    let {setClassroomType} = UseBookingWindows()

    return ( 
        <div className="d-flex justify-content-center align-items-center" style={{height: '280px', maxHeight:'280px'}}>
            {settings && settings.modality === SettingModalityType.FACE ?
                <CustomButton
                    title='Presencial'
                    type='xl'
                    color={mode==='dark'?'SECONDARY':'PRIMARY'}
                    handleClick={() => setClassroomType(ClassroomType.FACE)}
                />
            : settings?.modality === SettingModalityType.VIRTUAL ?
                <CustomButton
                    title='Virtual'
                    type='xl'
                    color={mode==='dark'?'SECONDARY':'PRIMARY'}
                    handleClick={() => setClassroomType(ClassroomType.VIRTUAL)}
                />
            : settings?.modality === SettingModalityType.ALTERNATION &&
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <CustomButton
                        title='Presencial'
                        type='xl'
                        color={mode==='dark'?'SECONDARY':'PRIMARY'}
                        handleClick={() => setClassroomType(ClassroomType.FACE)}
                    />
                    <CustomButton
                        title='Virtual'
                        type='xl'
                        color={mode==='dark'?'SECONDARY':'PRIMARY'}
                        handleClick={() => setClassroomType(ClassroomType.VIRTUAL)}
                    />
                </div>
            }
        </div>    
    );
};

export default ModalityBookingButton;