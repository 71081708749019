import {Route, Routes} from 'react-router-dom'
import { DocumentPortfolioDetails } from '../components/details'
import { DocumentPortfolioTableList } from '../components/list'


const DocumentPortfolioPage = () => (
  <Routes>
      <Route path='/details' element={<DocumentPortfolioDetails />} />
      <Route path='/:id' element={<DocumentPortfolioTableList />} />
  </Routes>
)

export {DocumentPortfolioPage}
