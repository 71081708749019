import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { UseParagraphDragDropModule } from './store';
import WordBoxDragAndDrop from './WordBoxDragDrop';


interface DraggableWordsListProps {
  colorSecondary?: string;
  titleContent?: string;
  descriptionContent?: string;
  renderType?: 'left' | 'right'
}

export const WordBoxContent: React.FC<DraggableWordsListProps> = ({
  colorSecondary,
  titleContent,
  descriptionContent,
  renderType
}) => {
  
  return (
    <DndProvider backend={HTML5Backend}>
      <WordBoxDragAndDrop
        colorSecondary={colorSecondary??'blue'}
        titleContent={titleContent??'Lionel Pessi'}
        descriptionContent={descriptionContent??'El genio mundial'}
        renderType={renderType}
      />
    </DndProvider>
  );
};