import React, { useRef, useState } from 'react';
import { DayPicker, SelectSingleEventHandler } from 'react-day-picker';
import moment from 'moment';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useThemeMode } from '../../../../_metronic/partials';

interface CustomDatePickerProps {
  onSelectDate: (date: Date) => void;
  calendarIconSrc: string;
  initialValues?: Date;
  buttonStyle?: React.CSSProperties;
  symbolWidth?: string;
  calendarPosition?: 'left' | 'right';
  valueCalendarPosition?: number;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ initialValues, valueCalendarPosition, onSelectDate, calendarIconSrc, buttonStyle, symbolWidth, calendarPosition }) => {
  const {mode} = useThemeMode();

  const [selected, setSelected] = useState<Date>(initialValues ?? moment().toDate());
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef<HTMLDivElement>(null);

  const closePopper = () => {
    setIsPopperOpen(false);
  };

  const handleButtonClick = () => {
    setIsPopperOpen(!isPopperOpen);
  };

  const handleDaySelect: SelectSingleEventHandler = (date) => {
    setSelected(date ? date : moment().toDate());
    closePopper();
    onSelectDate(date ? date : moment().toDate());
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <button
        className={`btn symbol symbol-${symbolWidth} me-5`}
        type="button"
        aria-label="Pick a date"
        onClick={handleButtonClick}
        style={{ ...buttonStyle }}
      >
        <img src={toAbsoluteUrl(calendarIconSrc)} alt="Calendar" className="calendar-icon" />
      </button>
      {isPopperOpen && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1000,
            top: '100%', // Ajusta según sea necesario
            [calendarPosition || 'right']: valueCalendarPosition ?? 0,
            display: 'block',
            width: '300px',
            backgroundColor:mode==='light'?'#fff':'#000000',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          }}
          ref={popperRef}
        >
          <DayPicker
            className='champ-bold'
            style={{color:mode==='light'?'#2d30e1':'white'}}
            mode="single"
            defaultMonth={selected}
            selected={selected}
            onSelect={handleDaySelect}
            showOutsideDays={true}
          />
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;