import { create } from 'zustand';
import { UserSettings } from './interfaces';
import { persist } from 'zustand/middleware';
import { UseGetMyRoadMap } from './services';
import Swal from 'sweetalert2';
import { LevelUpgradeUseCase } from '../../../admin/appointment/services';


interface MyRoadmapModule {
    isLoading: boolean
    settings?: UserSettings;
    courtesy?: any//tipar cuando mezcle con Leonardo
    setSettings: (mode: UserSettings) => void;
    settingChecked: () => Promise<UserSettings>;
    resetSetting: () => void;
    setIsLoading: (value: boolean)=>void;
    setCourtesy:(value: any)=>void;
}




export const UseMyRoadmapModule = create(persist<MyRoadmapModule>(
    (set) => ({
        isLoading: false,
        settings: undefined,
        setIsLoading: (value: boolean) => {
            set({isLoading: value})
        },
        setSettings: (settings: UserSettings) => {
            set({ settings: settings })
        },
        setCourtesy:(value) => {
            set({courtesy: value});
        },
        settingChecked: async () => {
            try {
                set({isLoading: true})
                let response = await UseGetMyRoadMap()
                if (response.typeStep === 'RANDOM' && response.finishedStep) {
                    Swal.fire({
                        title: '¡Ya has completado el proceso minimo de este nivel!',
                        html: "<p class='poppins-light'>¿Deseas subir de nivel?</p>",
                        icon: 'warning',
                        iconColor: '#2d30e1',
                        showCancelButton: true,
                        cancelButtonColor: '#d33',
                        confirmButtonText: "<span class='poppins-light fs-4'>Sí!</span>",
                        cancelButtonText: "<span class='poppins-light fs-4'>No</span>",
                        customClass: {
                            title: 'champ-light',
                            confirmButton: 'confirm-button-sweet-alert',
                            cancelButton: 'cancel-button-sweet-alert',
                        }
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            await LevelUpgradeUseCase({
                                student: response.id??'',
                            });
                            // window.location.reload();
                        }
                    })
                }
                set({
                    settings: response
                })
                return response;
            } catch {
                set({
                    settings: undefined
                })
            } finally{
                set({isLoading:false})
            }
        },
        resetSetting: () => {
            set({ settings: undefined })
        },
    }),
    {
        name: "setting-student",

    }
));