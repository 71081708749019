import React, { useState, useEffect } from 'react';
import BoosterHeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/BoosterHeaderCard';
import './index.css';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { AddBoosterReponse } from '../services';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { UseBoosterExercise } from '../main/main';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseBookContainerModule } from '../../../../book/store/main';

interface TextCompletionProps {
  userMode?: ModeTypeExercise
  texts: string[];
  answers: { [key: string]: string };
  studentRes?: { [key: string]: string };
}

const divStyle: React.CSSProperties = {
  backgroundColor: '#3f8bff',
  maxWidth: '500px',
  width:'500px',
  maxHeight: '500px',
  borderRadius: '30px',
  borderColor: 'rgb(255, 255, 255)',
  borderStyle: 'solid',
  padding: '27px 25px 20px 25px',
};

const TextCompletion: React.FC<TextCompletionProps> = ({ userMode, texts, answers, studentRes }) => {
  const [inputs, setInputs] = useState<{ [key: string]: string }>({});
  const [completed, setCompleted] = useState(false);
  const [accuracy, setAccuracy] = useState<number | null>(null);

  const {
    lessonData,
    stepBookItem,
    currentTaskId,
    setStepBookItem,
    addCompletedExerciseId,
    setIsLoadingSubmit,
    onChangeTaskByAnswer,
  } = UseBookStudentModule();

  const { exerciseId, instruction } = UseBoosterExercise();
  const { modeViewAnswer } = UseModeCourseModule();

  useEffect(() => {
    checkCompletion();
  }, [inputs]);

  useEffect(() => {
    if (modeViewAnswer) {
      setInputs(answers);
    };
  }, [modeViewAnswer, answers]);

  useEffect(() => {
    if (studentRes) {
      setInputs(studentRes);
    }
  }, [studentRes]);

  const handleChange = (key: string, value: string) => {
    setInputs(prev => ({ ...prev, [key]: value }));
  };

  const resetExercise = () => {
    setInputs({});
    setCompleted(false);
    setAccuracy(null);
  };

  const checkCompletion = () => {
    const allInputsFilled = texts.every(text => {
      const placeholders = text.match(/\{[^\}]+\}/g) || [];
      return placeholders.every(p => {
        const key = p.slice(1, -1).trim();
        return inputs[key] && inputs[key].trim();
      });
    });

    if (allInputsFilled) {
      calculateAccuracy();
      setCompleted(true);
    }
  };

  const calculateAccuracy = () => {
    let correct = 0;
    let total = 0;
    Object.keys(answers).forEach(key => {
      total++;
      const trimmedInput = inputs[key]?.trim() || '';
      const trimmedAnswer = answers[key]?.trim() || '';
      if (trimmedInput === trimmedAnswer) {
        correct++;
      }
    });
    setAccuracy((correct / total) * 100);
  };

  const renderText = (text: string) => {
    const parts = text.split(/(\{[^\}]+\})/);
    return parts.map((part, index) => {
      if (part.startsWith('{') && part.endsWith('}')) {
        const key = part.slice(1, -1);
        const { setOffDraggable } = UseBookContainerModule()
        return (
          /* //champ-bold    champ-light  poppins-light poppins-bold  */
          <input
            className='poppins-bold fs-6 fw-semibold'
            key={`${key}-${index}`}
            placeholder={''}
            value={inputs[key] || ''}
            onChange={(e) => handleChange(key, e.target.value)}
            onMouseEnter={() => setOffDraggable(true)}
            onMouseLeave={() => setOffDraggable(false)}
            style={{
              width: `${key.length + 3}ch`,
              backgroundColor: '#d0ff00ee',
              color: 'rgb(0, 11, 159)',
              outline: 'none',
              border: '2px solid',
              borderColor: 'rgb(0, 11, 159)',
              borderRadius: '50px',
              padding: '0px 7px 0px 7px',
            }}
          />
        );
      }
      return part;
    });
  };
  
  const handleSubmit = async () => {
    if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
      try {
        setIsLoadingSubmit(true)
        const response = await AddBoosterReponse({
          exercises: exerciseId,
          task: currentTaskId ?? '',
          percentage: accuracy ? accuracy : 0,
          isActive: true,
          answer: inputs,
        });
        onChangeTaskByAnswer(response, 'booster');
      } catch (e) {
        showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' });
      } finally {
        setIsLoadingSubmit(false)
      }
    }
    if (lessonData && lessonData.evaluation && lessonData.evaluation.evaluation_item) {
      addCompletedExerciseId(exerciseId ?? "")
      if (lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
        setStepBookItem(exerciseId ?? '')
      } else {
        setStepBookItem('FINISH_LESSON')
      }
    }
  }
  
  return (
    <>
      <div className="container" style={{ display: 'flex' }}>
        <div style={divStyle}>
          <BoosterHeaderCard />
          <div className="scroll-y overflow-y-auto mb-3 " style={{ maxHeight: '75px' }}>
            <span
              className="text-white cuestion mb-0 poppins-light"
              style={{
                marginLeft: '0px',
                fontSize: 14
              }}
            >
              {instruction}
            </span>
          </div>
          <div className='scroll-y overflow-y-auto mb-5 custom-scroll' style={{ maxHeight: '275px', scrollbarWidth: 'thin', 
            }}>
            {texts.map((text, index) => (
              <>
                <p className='champ-bold'
                  style={{
                    color: 'white',
                    fontSize: 15,
                    marginBottom: 0,
                    paddingBottom: 0
                  }}
                >{index + 1}.</p>
                <p
                  className='poppins-light'
                  style={{
                    fontSize: 16,
                    color: 'white'
                  }}
                  key={index}>{renderText(text)}</p>
              </>
            ))}
          </div>
          
          {completed && <div className='d-flex justify-content-between align-items-center'>
              <div>
                <ButtonTryAgain onAction={resetExercise} />
              </div>
              <ButtonSaveExercise
                isChecked={completed}
                onNext={() => { }}
                onSubmit={handleSubmit}
                exerciseId={exerciseId}
              />
            </div>}
        </div>
      </div>
    </>
  );
};

export default TextCompletion;