import moment from "moment"
import { AppointmentTeacherDashboard } from "../../interfaces"
import CountdownTimer from "../../count-down-timer"
import { t } from "i18next"

type Props = {
    appointment: AppointmentTeacherDashboard
    isCurrent?: boolean
    index?:number
}
const ClassListItem: React.FC<Props> = ({ appointment, isCurrent, index }: Props) => {
    // const currentDay = moment().format('DD')
    return (<>
        <tr className={`${appointment.status_appointment === 'TO_BE_CLOSED' && 'breathing-blue-effect' }`}>
            <td>
                <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark champ-light fw-bold bold text-gray-900 text-hover-primary mb-1 fs-6'>
                            {appointment.title}
                        </a>
                        <div className='rating'>
                            <div className='rating-label me-2 checked text-muted'>
                                {appointment.student?.length}
                            </div>
                            <div className='rating-label me-2 '>
                                <i className="bi bi-person-circle"></i>
                            </div>
                            {/* <div className='rating-label me-2 checked'>
                                <i className='bi bi-star-fill fs-5'></i>
                            </div>
                            <div className='rating-label me-2 checked'>
                                <i className='bi bi-star-fill fs-5'></i>
                            </div>
                            <div className='rating-label me-2 checked'>
                                                                <i className='bi bi-star-fill fs-5'></i>
                            </div>
                            <div className='rating-label me-2 checked'>
                                <i className='bi bi-star-fill fs-5'></i>
                            </div> */}
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <a href='#' className='text-dark champ-light fw-bold text-hover-primary d-block mb-1 fs-6'>
                    {moment(appointment.time.start).format('ddd, MMM DD')}
                </a>
                <span className='text-muted champ-light fw-semibold fw-bold text-muted d-block fs-7'>
                    {moment(appointment.time.start).format('HH:mm')}
                </span>
            </td>
            <td>
                <a href='#' className={`text-dark champ-light fw-bold text-hover-primary d-block mb-1 fs-6`}>
                    {t(appointment.status_appointment)}
                </a>
                <div className='rating'>
                    <div className='rating-label me-2 checked'>
                        <i className='bi bi-star-fill fs-5'></i>
                    </div>
                    <div className='rating-label me-2 checked'>
                        <i className='bi bi-star-fill fs-5'></i>
                    </div>
                    <div className='rating-label me-2 checked'>
                        <i className='bi bi-star-fill fs-5'></i>
                    </div>
                    <div className='rating-label me-2 checked'>
                        <i className='bi bi-star-fill fs-5'></i>
                    </div>
                </div>
            </td>
     
            {<CountdownTimer 
                targetDate={moment(appointment.time.start).toString()}
                showButtonThreshold={2}
                appointment={appointment}
                isToday={moment().isSame(appointment.time.start, 'day')}
                // isCurrent={isCurrent}
                isCurrent
            />}  
            {/* {moment().isSame(appointment.time.start, 'day') ? "hola" : "no"} */}
            {/* {currentDay === moment(appointment.time.start).format('DD') ? <>hola</>: <>no</>} */}
             {/* <CountdownTimer targetDate={moment(appointment.time.start).toString()} showButtonThreshold={2} appointment={appointment}  />  */}
        </tr>
    </>)
}
export { ClassListItem }