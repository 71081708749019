import React, { useEffect, useState } from 'react';
import './index.css';
import HeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import { CorrectSentenceInterface, CorrectSentenceItemQuestionsAnswer } from '../interfaces';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { AddCorrectSentence } from '../services';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseCorrectSentenceItemModule } from '../store';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { UseBookContainerModule } from '../../../../book/store/main';

type Props = {
    userMode: ModeTypeExercise
    title: string
    instruction: string
    question: CorrectSentenceInterface[];
    responses?: CorrectSentenceItemQuestionsAnswer[];
    handleSendAnswer: (x: CorrectSentenceItemQuestionsAnswer[]) => void
};

const divStyle = {
    backgroundColor: '#3f8bff',
    maxWidth: '525px',
    minHeight: '425px',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    padding: '27px 20px 40px 20px'
};
const input = {
    display: 'flex',
    width: '350px',
    borderRadius: '40px',
    border: '0px',
    backgroundColor: '#D0FF00',
    outline: 'none',
    padding: '2px'
}

const CorrectSentenceItem: React.FC<Props> = ({
    userMode,
    question,
    responses,
    instruction,
}: Props) => {

    const {
        lessonData,
        stepBookItem,
        currentTaskId,
        setStepBookItem,
        addCompletedExerciseId,
        onChangeTaskByAnswer,
        setIsLoadingSubmit
    } = UseBookStudentModule();

    const { exerciseId } = UseCorrectSentenceItemModule();
    const { modeViewAnswer } = UseModeCourseModule();
    const [results, setResults] = useState<string[]>([]);
    const [accuracyPercentage, setAccuracyPercentage] = useState<number | null>(null);
    const [answers, setAnswers] = useState<CorrectSentenceItemQuestionsAnswer[]>([]);
    const [checked, setChecked] = useState<boolean>(false)

    useEffect(() => {
        if (responses && responses.length > 0) {
            setAnswers(responses);
        } else {
            setAnswers(question.map((x) => ({
                // wrongSentence: x.wrongSentence,
                trueSentence: x.trueSentence,
                text: modeViewAnswer ? x.trueSentence : '',
                order: x.order,
                isTrue: false
            })));
        }
    }, [question, responses, modeViewAnswer]);

    useEffect(() => {
        if (answers.length > 0) {
            const allAnsweredCheck = answers.every(x => x.text !== '');
            if (allAnsweredCheck) {
                setChecked(allAnsweredCheck)
                calculateAccuracy();
            }
        }
    }, [answers]);

    const resetExercise = () => {
        setChecked(false);
        setAnswers(question.map((x) => ({
            // wrongSentence: x.wrongSentence,
            trueSentence: x.trueSentence,
            text: '',
            order: x.order,
            isTrue: false
        })));
        setResults([]);
    };

    const handleSubmit = async () => {
        if (userMode === 'STUDENT') {
            try {
                setIsLoadingSubmit(true)
                const response = await AddCorrectSentence({
                    isActive: true,
                    answer: answers,
                    percentage: accuracyPercentage ?? 0,
                    exercises: exerciseId ?? '',
                    task: currentTaskId ?? ""
                });
                onChangeTaskByAnswer(response, 'correct_sentence');
            } finally {
                setIsLoadingSubmit(false)
            }
        }
        if (lessonData && lessonData.evaluation &&
            lessonData.evaluation.evaluation_item) {
            addCompletedExerciseId(exerciseId ?? "")
            if (lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
                setStepBookItem(exerciseId ?? '')
            } else {
                setStepBookItem('FINISH_LESSON')
            }
        }
    };

    const handleInputChange = (index: number, value: string) => {
        const newAnswers = [...answers];
        newAnswers[index] = { ...newAnswers[index], text: value };
        setAnswers(newAnswers);
        // const newAnswers = [...userAnswers];
        // newAnswers[index] = value;

        // setUserAnswers(newAnswers);
    };

    const calculateAccuracy = () => {
        const newAnswers = question.map((question, index) => {
            const userAnswerNormalized = answers[index]?.text?.toLowerCase().trim().replace(/\s+/g, ' ');
            const trueSentenceNormalized = question.trueSentence.toLowerCase().trim().replace(/\s+/g, ' ');
            const isTrue = userAnswerNormalized === trueSentenceNormalized;
            return {
                trueSentence: question.trueSentence,
                // wrongSentence: question.wrongSentence,
                order: question.order,
                text: answers[index].text,
                isTrue: isTrue
            };
        });
        // setAnswers(newAnswers);
        const correctAnswersCount = newAnswers.filter(answer => answer.isTrue).length;
        const accuracy = (correctAnswersCount / question.length) * 100;
        setAccuracyPercentage(accuracy);
    };

    const handleChecked = () => {
        const newResults = question.map((question, index) =>
            answers[index].text === question.trueSentence ? "Correcto" : "Incorrecto"
        );
        setResults(newResults);
        setChecked(true)
    }

    const { setOffDraggable } = UseBookContainerModule()

    return (
        <>
            <div className="container-fluid " style={{ display: 'flex' }}>
                <div style={divStyle}>
                    <HeaderCard />
                    <div className="scroll-y overflow-y-auto mb-3" style={{maxHeight:'75px'}}>
                    <p className="text-white p-3 fs-5 mb-0 poppins-light" style={{ marginLeft: '10px' }}>{instruction}</p>
                    </div>
                    <div className='scroll-y overflow-y-auto mb-6' style={{ maxHeight: '250px' }}>
                        {question.map((x, y) => (
                            <div className=" row d-flex px-9 fs-8 text-white container-fluid">
                                <div className="col-10 py-1 poppins-light fs-8">
                                    <li className="mb-0">{x.wrongSentence}</li>
                                    <div className='d-flex flex-row'>
                                        <div className="col-3"><span className="mb-0 fw-bold">Right version:</span></div>
                                        <div className="col-9">
                                            <div key={y}>
                                                <input
                                                    type="text"
                                                    value={answers[y]?.text || ''}
                                                    onChange={(e) => {
                                                        handleInputChange(y, e.target.value)
                                                        // calculateAccuracy();
                                                    }
                                                    }
                                                    onMouseEnter={() => setOffDraggable(true)}
                                                    onMouseLeave={() => setOffDraggable(false)}
                                                    placeholder={`Respuesta para la pregunta ${y + 1}`}
                                                    style={input}
                                                />
                                                {results[y] && <p>{results[y]}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 d-flex ">
                                    {/*validacion*/}
                                </div>
                            </div>
                        ))}
                    </div>
                    {checked &&
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                {checked &&
                                    <ButtonTryAgain onAction={resetExercise} />
                                }
                            </div>
                            <div>
                                <h3 className='text-center' style={{ fontSize: 10 }}>
                                    {checked &&
                                        <span className=" text-white  mb-0 poppins-light">Porcentaje correcto fghfgh: {accuracyPercentage && accuracyPercentage.toFixed(2)}%</span>
                                    }
                                </h3>
                            </div>
                            <ButtonSaveExercise
                                isChecked={checked}
                                onNext={handleChecked}
                                onSubmit={handleSubmit}
                                exerciseId={exerciseId}
                            />
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export { CorrectSentenceItem };