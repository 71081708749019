
import { persist } from 'zustand/middleware';
import {create} from 'zustand';
export type ResumeNextLesson ={
    lessons:NextLesson[]
    totalTaskInReview: number
    totalTrailClass: number
} 

export interface NextLesson {
  status: string
  trailClass: TrailClass
  lesson: Lesson
}

export interface TrailClass {
  id: string
  date: string
}

export interface Lesson {
  id: string
  name: string
}
  interface TrailTaskState {
    modal:boolean,
    data?: ResumeNextLesson
    currentLesson?: NextLesson;
    setNextLessonData: (data: ResumeNextLesson) => void;
    setModal:(mode:boolean)=>void;    setCurrentLesson: (value: NextLesson | undefined) => void
}

const useTrailTask = create(persist<TrailTaskState>(
    (set, get) =>  ({
        modal:false,
        data:undefined,
        setNextLessonData: (data: ResumeNextLesson) =>{
            set({data:data})
        },
        setCurrentLesson: (value) => {
          set({currentLesson: value});
        },
        setModal:(mode)=>{
            set({modal:mode})
        },
      }),
      {
        name:"trail_stack_state",
        // getStorage: () => localStorage
      }
));

export default useTrailTask;