


import { useStore } from 'zustand'
import { UseLeadResumeModule } from '../store/main'

import moment from 'moment'
import useAuth from '../../../api/main'
import { api } from '../../auth/services/auth.services'

const { token, id, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}

export interface BookingFind {
  after: string
  before: string
  level: String
}


export const UseResumeLeadsAllPaginate = async (): Promise<any> => {
  try {
    // await authChecked()
    const { setPaginator, limit, currentPage, where, isActive, after, before,country,teacher,source,level} = await UseLeadResumeModule.getState()
    const response: any = await api.get(
      `/leads/paginated?after=${after}&before=${before}&country=${country}&teacher=${teacher}&source=${source}&level=${level}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
      e.response.data.message.map((x) => {
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseResumeLeadsTeacher = async ( ): Promise<any> => {
  try {
    // await authChecked()
    const { setPaginator, limit, currentPage, where, isActive, after, before,country,teacher,source,level} = await UseLeadResumeModule.getState()
    const response: any = await api.get(
      `/leads/stats?after=${after}&before=${before}&teacher=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
      e.response.data.message.map((x) => {
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseResumeLeadsExecutive = async (): Promise<any> => {
  try {
    // await authChecked()
    const { setPaginator, limit, currentPage, where, isActive, after, before,country,teacher,source,level} = await UseLeadResumeModule.getState()
    const response: any = await api.get(
      `/leads/stats?after=${after}&before=${before}&user=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
      e.response.data.message.map((x) => {
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

