export interface DataPaginateDocumentPortfolioResponse {
    status:  string;
    message: string;
    data:    DataDocumentPortfolio;
}

export interface DataAllDocumentPortfolioResponse {
    status:  string;
    message: string;
    data:    DocumentPortfolio[];
}

export interface DataDocumentPortfolio {
    id?: string;
    description:string;
    name:string;
    code:string;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface DocumentPortfolio  {
    id?: string;
    description?:string;
    name:string;
    code:string;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export enum DocumentPortfolioType{ 
    PLACEMENT_TEST = 'PLACEMENT_TEST',
    WELCOME_DAY = 'WELCOME_DAY',
    CONTRACT = 'CONTRACT',
 };  