/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import { CourtesyRegisterDataForm } from '../form'
type Props = {
  show: boolean
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CourtesyRegisterDataModal = ({show}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [hasError, setHasError] = useState(false)
  const submit = () => {
    window.location.reload()
  }



  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
     // onHide={handleClose}
      //   onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        {/* begin::Close */}
        {/* <div className='btn btn-sm btn-icon btn-active-color-primary'>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div> */}
        {/* end::Close */}
      </div>
      <div className='py-9'>
        <CourtesyRegisterDataForm />
      </div>
    </Modal>,
    modalsRoot
  )
}

export {CourtesyRegisterDataModal}