import React from "react";
import { FrontPageCourses } from "..";
import { useParams } from "react-router-dom";
import { showSimpleAlert } from "../../../../../commonHooks/alert";
import { UseLearningPathOne } from "../../../../abroad/learning-path/services";
import { LearningPath, LearningPathType } from "../../../../abroad/learning-path/interfaces";
import { TopicsDrawer, TopicsEbookDrawer } from "../../../../../../_metronic/partials";
import useAuth from "../../../../../api/main";

type Props = {};

const FrontPage: React.FC<Props> = () => {
    const {id} = useParams();
    const {typeUser} = useAuth();
    const [dataCourse, setDataCourse] = React.useState<LearningPath>();

    React.useEffect(() => {
        if (id) {
            const findLearningPath = async () => {
                try {
                    const response = await UseLearningPathOne(id);
                    setDataCourse(response);
                } catch (e) {
                    showSimpleAlert({ message: 'Error al encontrar curso!', icon: 'error' })
                    throw 'Error'
                }
            }
            findLearningPath();
        }
    }, [id]);

    return(<>
        {dataCourse && 
        <>
            <FrontPageCourses 
                description={dataCourse.description??''} 
                logoCourse={dataCourse?.logo?.location} 
                bannerPrincipal={dataCourse?.bannerPrincipal?.location} 
                colorCourse={dataCourse.colorBase??'#FFFFFF'} 
                typeCourse={dataCourse?.type}
                mode={typeUser === 'COURTESY' ? 'COURTESY' : 'STUDENT'}
                courseId={dataCourse.id??''}
            />

            {/* {dataCourse.type === LearningPathType.ABROAD ?
                <TopicsEbookDrawer
                    courseId={dataCourse.id}
                    mode={'STUDENT'}
                />
            :  (dataCourse.type === LearningPathType.ENGLISH_EXPLORER ||
                dataCourse.type === LearningPathType.PRISMATIC) &&
                <TopicsDrawer
                    courseId={dataCourse.id}
                    mode={'STUDENT'}
                />
            } */}
        </>}
    </>)
}

export {FrontPage};