import { create } from 'zustand';
import { CheckWritingAnswerAndThemesIE, CheckWritingQuestionAnswerIE, CheckWritingQuestionIE, CheckWritingTheme, quiz,themes } from '../interfaces';

interface CheckWritingInterface {
  questions: CheckWritingQuestionIE[]
  themes:CheckWritingTheme[]
  title: string;
  instructions?: string;
  percentage: number
  answer?: CheckWritingAnswerAndThemesIE;
  exerciseId?: string;
  resetData: () => void;
  setQuestions:(
    exerciseId: string, 
    instruction: string,
    value: CheckWritingQuestionIE[], themes: CheckWritingTheme[])=>void;
  setAnswer:(value: CheckWritingAnswerAndThemesIE)=>void;
}

export const UseCheckWritingModule = create<CheckWritingInterface>((set) => ({
  status: 'ANSWER',
  title: "Este es un titulo de quiz",
  instructions: "",
  questions: [],
  themes:[],
  percentage: 0,
  resetData: () => {},
  setQuestions:(exerciseId, instructions, value, themes)=>{
    set({questions: value, themes, exerciseId, instructions, answer: undefined});
  },
  setAnswer:(value)=>{
    set({answer: value});
  }
}));