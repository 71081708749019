import { create } from "zustand";
import { AnswerCoordinates } from "../components/coordiantes-item";

export interface ColumnItemCoordinates {
    title: string;
    value: string;
}
export interface RowItemCoordinates {
    idWord: string;
    title: string;
    value: string;

}
export interface ArrayQuestion {
    column: ColumnItemCoordinates[];
    row: RowItemCoordinates[];
}
interface InterfaceCoordinates {
    answer?: AnswerCoordinates[];
    checked: boolean;
    questions: ArrayQuestion;
    instruction: string;
    inputValues: any[];
    exerciseId?: string;
    setChecked: (value: boolean) => void;
    setInputValuesMo: (value: any[]) => void;
    setQuestions: (
        exerciseId: string, 
        instruction: string,
        value: ArrayQuestion) => void;
    setAnswer:(value: AnswerCoordinates[])=>void;
}

export const UseCoordinatesEleven = create<InterfaceCoordinates>((set) => ({
    checked: false,
    instruction: '',
    questions: {
        column: [
            // { title: "To set up", value: "a" },
            // { title: "To build up", value: "b" },
            // { title: "To buy and roast", value: "c" },
            // { title: "To to make", value: "d" },
            // { title: "To go", value: "e" },
            // { title: "To adsfsasdf", value: "f" },
        ],
        row: [
            // { idWord: '1', title: "row-A", value: "b" },
            // { idWord: '2', title: "row-B", value: "c" },
            // { idWord: '3', title: "row-C", value: "e" },
            // { idWord: '4', title: "row-D", value: "d" },
            // { idWord: '5', title: "row-E", value: "a" },
        ],
    },

    inputValues: [],
    setInputValuesMo(value) {
        set({ inputValues: value })
    },

    setChecked: (value) => {
        set({ checked: value })
    },
    setQuestions: (exerciseId, instruction, value) => {
        set({ questions: value, exerciseId, instruction, answer: undefined });
    },
    setAnswer:(value)=>{
        set({answer: value});
    }
}))    