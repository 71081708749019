 import {create} from 'zustand';
import { CorrectSentenceItemQuestionsAnswer,CorrectSentenceInterface,  quiz  } from '../interfaces';

interface CorrectSentenceItemInterface {
  questions:CorrectSentenceInterface[]
  title:string;
  instructions:string;
  percentage:number
  answer?:CorrectSentenceItemQuestionsAnswer[]
  exerciseId?: string
  resetData:()=>void;
  setQuestions:(
    exerciseId: string,
    instruction: string,
    value: CorrectSentenceInterface[])=>void,
  setAnswer:(value: CorrectSentenceItemQuestionsAnswer[])=>void;
}

export const UseCorrectSentenceItemModule = create<CorrectSentenceItemInterface>( (set) => ({
    status:'ANSWER',
    title:"Este es un titulo de quiz",
    instructions:"",
    questions:[]/*quiz.questions*/,
    percentage:0,
    setTitle:(value)=>{},
    resetData:()=>{},
    setQuestions:(exerciseId, instructions, value)=>{
      set({questions: value, exerciseId, instructions, answer: undefined});
    },
    setAnswer:(value)=>{
      set({answer: value});
    }
}));