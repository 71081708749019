import moment from "moment";
import UseTranslateTimeZone from "../../../hooks/useTranslateTimeZone";

export const translateEventTimes = (events: any[], getDate?: string): any[] => {
    return events
        .map(event => {
            // Traducir start y end a la zona horaria del cliente
            const translatedStart = UseTranslateTimeZone(event.time.start);
            const translatedEnd = UseTranslateTimeZone(event.time.end);
        
            // Actualizar el campo start con el valor traducido
            const updatedStart = translatedStart.format('YYYY-MM-DD HH:mm');
            const updatedEnd = translatedEnd.format('YYYY-MM-DD HH:mm');
        
            // Crear una copia del evento con los valores actualizados
            const updatedEvent = {
                ...event,
                time: {
                    start: updatedStart,
                    end: updatedEnd
                },
                start: updatedStart
            };
        
            // Filtrar por la fecha proporcionada si se pasa un parámetro getDate
            if (getDate) {
                const eventDate = translatedStart.format('YYYY-MM-DD');
                return eventDate === getDate ? updatedEvent : null;
            }
        
            return updatedEvent;
        })
        .filter(event => event !== null); // Filtrar los eventos nulos
};
