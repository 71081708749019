import React, { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useMeBookingActiveState } from '../../hooks/useMeReportRepo'
import { UseMeBookingActiveModule } from '../../store/main'
import * as Swal from 'sweetalert2'
import { MeReportDelete } from '../../services'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import GenericPaginator from '../../../../components/common/Paginator'
import moment from 'moment'
import { CardHeaderModule } from '../../../../components/common/widgets/statistics/cardHeaderModule'
import { CreateModalTrialSuspense } from '../../../admin/trail-suspense/components/modal'
import { UseTrailSuspenseMe } from '../../../admin/trail-suspense/services'
import { UseBookingModule } from '../../../me-booking/store'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { FindOneSettingByType } from '../../../abroad/settings/services'
import { SettingEnumType } from '../../../abroad/settings/interfaces'
import { UseTrailSuspenseModule } from '../../../admin/trail-suspense/store/main'
import { useThemeMode } from '../../../../../_metronic/partials'
import './index.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { UseMyRoadmapModule } from '../../../profile/components/my-road-step/store'
import { ClassroomType } from '../../../admin/classroom/interfaces'
import UseTranslateToBogotaTimeZone from '../../../../hooks/useBogotaTranslateTz'
import UseTranslateTimeZone from '../../../../hooks/useTranslateTimeZone'

type PaginatorSetter = {
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
}
type Props = {

}
const MeBookingActiveTableList: React.FC<Props> = ({ }) => {
  const { t } = useTranslation();
  const { mode } = useThemeMode();
  const queryClient = useQueryClient()
  const navigate = useNavigate();
  const { settings } = UseMyRoadmapModule();
  const {
    after,
    before,
    paginator,
    limit,
    currentPage,
    isActive,
    where,
    setCurrentPage,
    setLimit,
  } = UseMeBookingActiveModule();

  const {
    modal,
    setModal,
    trail_class,
    setTrailClass,

  } = UseTrailSuspenseModule()

  const {data, isLoading} = useMeBookingActiveState({
    before: UseTranslateToBogotaTimeZone().yearMonthDay,
    after: UseTranslateToBogotaTimeZone().endOfWeek,
    blockHour: moment().hour(),
    id: settings ? settings.id : undefined
  });

  const { level, setMeSuspense, setMyAgenda, myAgenda, setStrikes, strikes } = UseBookingModule();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [bookingAvailable, setBookingAvailable] = React.useState<any[]>([]);
  const [dataSettings, setDataSettings] = React.useState<any>();
  const [tabletWidth, setTabletWidth] = React.useState<number>(0);
  const [isMovil, setisMovil] = React.useState(false);
  const [isTablet, setisTablet] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        setisMovil(true);
        setisTablet(false);
        setTabletWidth(200);
      } else if (window.innerWidth < 768) {
        setisMovil(false);
        setisTablet(true);
        setTabletWidth(300);/**MOVIL RESPONSIVE DE PEQUEÑO A GRANDE */
      } else if (window.innerWidth < 1024) {
        setisMovil(false);
        setisTablet(true);
        setTabletWidth(800);/**TABLET*/
      } else {
        setisMovil(false);
        setisTablet(false);
        setTabletWidth(window.innerWidth - 200);/**PC */
      }
    };
    handleResize();/** Inicia con el ancho actual */
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const changePage = (number: number) => {
    setCurrentPage(number)
  };
  const changeLimit = (number: number) => {
    setLimit(number)
  }

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['me-booking-active'] })
  }, [isActive, where, currentPage, limit]);

  const findTrailSuspense = async () => {
    try {
      setLoading(true)
      let response = await UseTrailSuspenseMe()
      setMeSuspense(response)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  };

  const fetchSettings = async () => {
    try {
      setLoading(true)
      const responseSettings = await FindOneSettingByType(SettingEnumType.NUMBER_HOURS_BEFORE_CANCELING_RESERVATION);
      setDataSettings(responseSettings);

    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Settings!', icon: 'error' });
      throw 'Error'
    } finally {
      setLoading(false)
    }
  };

  React.useEffect(() => {
    fetchSettings();
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (dataSettings && data && data?.result) {
        const now = moment(UseTranslateToBogotaTimeZone().yearMonthDayHour);
        const updatedBookings = data?.result?.map((x) => {
          const futureDate = moment(x.time.start);
          const duration = moment.duration(futureDate.diff(now));
          return {
            ...x,
            activeAnimation: duration.minutes() <= 10,
            disableButton: duration.asHours() <= dataSettings.value,
            // disableLobby: duration.asMinutes() <= 10
          };
        });
        setBookingAvailable(updatedBookings);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [dataSettings, data]);

  return (<>

    <CardHeaderModule
      title={t('BOOKING_ACTIVE')}
      leftIcon={`/media/svg/michigan-icons/header/${mode === 'light' ? 'blue/back_blue' : 'white/back_white'}.svg`}
      svgIcon={`/media/svg/michigan-icons/header/${mode === 'light' ? 'blue/my_booking_eye' : 'white/my_booking_eye'}.svg`}
      background={'#E6E6FA'}
      colorTitle={'#2d30e1'}
    />

    <div className={`card mt-3 content-booking`} style={{ border: `1px solid ${mode === 'light' ? '#2d30e1' : '#FFFFFF'}` }}>

      {/* begin::Body */}
      <div className='card-body pt-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold poppins-bold fs-5' style={{ color: mode === 'light' ? '#2d30e1' : 'white', borderBottom: `1px solid ${mode === 'light' ? '#2d30e1' : '#FFFFFF'}` }}>
                {(isMovil || isTablet) ? (
                  <>
                    <th className='text-center'>Cancelar</th>
                    <th className='text-center'>Ingresar</th>
                    <th className='text-center'>Fecha</th>
                    <th className='text-center'>Inicio</th>
                    <th className='text-center'>Final</th>
                    {/* <th className='text-center'>Tipo</th> */}
                    <th className='text-center'>Sesión</th>
                    <th className='text-center'>Salon</th>
                    <th className='text-center'>Modalidad</th>
                  </>
                ) : (
                  <>
                    <th className='text-center'>Fecha</th>
                    <th className='text-center'>Inicio</th>
                    <th className='text-center'>Final</th>
                    {/* <th className='text-center'>Tipo</th> */}
                    <th className='text-center'>Sesión</th>
                    <th className='text-center'>Salon</th>
                    <th className='text-center'>Modalidad</th>
                    <th className='text-center'>Ingresar</th>
                    <th className='text-center'>Cancelar</th>
                  </>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='mt-2'>
              {loading &&
                <tr>
                  <img
                    className="rounded mx-auto d-block"
                    width={'70%'}
                    src={toAbsoluteUrl('/media/svg/icons/loading.svg')} alt='' />
                </tr>
              }
              {data && bookingAvailable?.map((x) => (
                <tr key={x.id} style={{ color: mode === 'light' ? '#2d30e1' : 'white' }}>
                  {isMovil ? (
                    <>
                    <td className='text-center'>
                      <button className='btn' onClick={() => setTrailClass(x.id)} disabled={x.disableButton ? true : false}>
                        <i className="fa-solid fa-ban fs-2 text-danger cursor-pointer" />
                      </button>
                    </td>
                    <td className='text-center'>
                      {x.typeClassroom === ClassroomType.VIRTUAL ?
                        <button
                          // disabled={
                          //   (x.assistantStudent || x.assistantTeacher) 
                          //   ? false : x.activeAnimation }
                          className={`btn ${x.activeAnimation && 'breathing-blue-effect'}`}
                          onClick={() => navigate(`/lobby-class/${x.id}`)}>
                          <img src={toAbsoluteUrl('/media/svg/michigan-icons/elements/next_active_blue.svg')} style={{ width: '20px' }} alt="" />
                        </button>
                        : <>
                          <i className='fa-solid fa-school-flag' style={{ color: 'blue', fontSize: 15 }} />
                        </>}
                    </td>
                    <td className='text-center'>
                      <span className='d-block fs-3 poppins-light'>
                        {UseTranslateTimeZone(x.start).format('DD/MM/YYYY')}
                      </span>
                    </td>
                    <td className='text-center'>
                      <span className='d-block fs-3 poppins-bold'>
                        {UseTranslateTimeZone(x.time.start).format('HH:mm')}
                      </span>
                    </td>
                    <td className='text-center'>
                      <span className='d-block fs-3 poppins-light'>
                        {UseTranslateTimeZone(x.time.end).format('HH:mm')}
                      </span>
                    </td>
                    {/* <td className='text-center'>
                  <span className='d-block fs-3 poppins-light'>
                  {x.title}
                  </span>
                  </td> */}
                    <td className='text-center'>
                      <span className='d-block fs-3 poppins-light'>
                        {x.title}
                      </span>
                    </td>
                    <td className='text-center'>
                      <span className='d-block fs-3 poppins-light'>
                        {x.room}
                      </span>
                    </td>
                    <td className='text-center'>
                      <span className='d-block fs-3 poppins-light'>
                        {x.typeClassroom === ClassroomType.VIRTUAL ? 'Online' : `Presencial - ${x.campusClassroom}`}
                      </span>
                    </td>
                  </>
                  ) : isTablet ? (
                    <>
                      <td className='text-center'>
                        <button className='btn' onClick={() => setTrailClass(x.id)} disabled={x.disableButton ? true : false}>
                          <i className="fa-solid fa-ban fs-2 text-danger cursor-pointer" />
                        </button>
                      </td>
                      <td className='text-center'>
                        {x.typeClassroom === ClassroomType.VIRTUAL ?
                          <button
                            // disabled={
                            //   (x.assistantStudent || x.assistantTeacher) 
                            //   ? false : x.activeAnimation }
                            className={`btn ${x.activeAnimation && 'breathing-blue-effect'}`}
                            onClick={() => navigate(`/lobby-class/${x.id}`)}>
                            <img src={toAbsoluteUrl('/media/svg/michigan-icons/elements/next_active_blue.svg')} style={{ width: '20px' }} alt="" />
                          </button>
                          : <>
                            <i className='fa-solid fa-school-flag' style={{ color: 'blue', fontSize: 15 }} />
                          </>}
                      </td>
                      <td className='text-center'>
                        <span className='d-block fs-3 poppins-light'>
                          {moment(x.start.split(' ')[0]).format('DD/MM/YYYY')}
                        </span>
                      </td>
                      <td className='text-center'>
                        <span className='d-block fs-3 poppins-bold'>
                          {x.time.start.split(' ')[1]}
                        </span>
                      </td>
                      <td className='text-center'>
                        <span className='d-block fs-3 poppins-light'>
                          {x.time.end.split(' ')[1]}
                        </span>
                      </td>
                      {/* <td className='text-center'>
                    <span className='d-block fs-3 poppins-light'>
                    {x.title}
                    </span>
                    </td> */}
                      <td className='text-center'>
                        <span className='d-block fs-3 poppins-light'>
                          {x.title}
                        </span>
                      </td>
                      <td className='text-center'>
                        <span className='d-block fs-3 poppins-light'>
                          {x.room}
                        </span>
                      </td>
                      <td className='text-center'>
                        <span className='d-block fs-3 poppins-light'>
                          {x.typeClassroom === ClassroomType.VIRTUAL ? 'Online' : `Presencial - ${x.campusClassroom}`}
                        </span>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className='text-center'>
                        <span className='d-block fs-3 poppins-light'>
                          {moment(x.start.split(' ')[0]).format('DD/MM/YYYY')}
                        </span>
                      </td>
                      <td className='text-center'>
                        <span className='d-block fs-3 poppins-bold'>
                          {x.time.start.split(' ')[1]}
                        </span>
                      </td>
                      <td className='text-center'>
                        <span className='d-block fs-3 poppins-light'>
                          {x.time.end.split(' ')[1]}
                        </span>
                      </td>
                      {/* <td className='text-center'>
                    <span className='d-block fs-3 poppins-light'>
                    {x.title}
                    </span>
                    </td> */}
                      <td className='text-center'>
                        <span className='d-block fs-3 poppins-light'>
                          {x.title}
                        </span>
                      </td>
                      <td className='text-center'>
                        <span className='d-block fs-3 poppins-light'>
                          {x.room}
                        </span>
                      </td>
                      <td className='text-center'>
                        <span className='d-block fs-3 poppins-light'>
                          {x.typeClassroom === ClassroomType.VIRTUAL ? 'Online' : `Presencial - ${x.campusClassroom}`}
                        </span>
                      </td>
                      <td className='text-center'>
                        {x.typeClassroom === ClassroomType.VIRTUAL ?
                          <button
                            // disabled={
                            //   (x.assistantStudent || x.assistantTeacher) 
                            //   ? false : x.activeAnimation }
                            className={`btn ${x.activeAnimation && 'breathing-blue-effect'}`}
                            onClick={() => navigate(`/lobby-class/${x.id}`)}>
                            <img src={toAbsoluteUrl('/media/svg/michigan-icons/elements/next_active_blue.svg')} style={{ width: '20px' }} alt="" />
                          </button>
                          : <>
                            <i className='fa-solid fa-school-flag' style={{ color: 'blue', fontSize: 15 }} />
                          </>}
                      </td>
                      <td className='text-center'>
                        <button className='btn' onClick={() => setTrailClass(x.id)} disabled={x.disableButton ? true : false}>
                          <i className="fa-solid fa-ban fs-2 text-danger cursor-pointer" />
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          <GenericPaginator
            limit={limit}
            currentPage={paginator.current_page}
            items={paginator.result.length}
            total={paginator.total}
            totalPage={paginator.total_page}
            changePage={changePage}
            changeLimit={changeLimit}
          />
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    <CreateModalTrialSuspense show={modal} handleClose={() => setModal(false)} onSubmit={() => {
      findTrailSuspense();
    }} />
  </>
  )
}

export { MeBookingActiveTableList }
