import { useEffect, useRef } from 'react';

const useAuthCheck = (authChecked: () => void, intervalTime: number = 60000) => {
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    const startAuthCheck = () => {
      // Limpia cualquier intervalo previo
      if (intervalRef.current !== null) {
        console.log('Limpia cualquier intervalo previo')
        clearInterval(intervalRef.current);
      }

      // Establece un nuevo intervalo para ejecutar authChecked
      intervalRef.current = window.setInterval(() => {
        console.log('Establece un nuevo intervalo para ejecutar authChecked')
        authChecked();
      }, intervalTime);
    };

    const stopAuthCheck = () => {
      // Limpia el intervalo cuando la pestaña no está visible
      if (intervalRef.current !== null) {
        console.log('Limpia el intervalo cuando la pestaña no está visible')
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log('visible')
        startAuthCheck();
      } else {
        console.log('No visible')
        stopAuthCheck();
      }
    };

    // Configura el evento visibilitychange
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Inicia el intervalo cuando la pantalla está activa por primera vez
    if (document.visibilityState === 'visible') {
      console.log('Inicia el intervalo cuando la pantalla está activa por primera vez')
      startAuthCheck();
    }

    // Limpia el intervalo y el listener cuando se desmonta el componente
    return () => {
        console.log('Limpia el intervalo y el listener cuando se desmonta el componente')
      stopAuthCheck();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [authChecked, intervalTime]);
};

export default useAuthCheck;