import {useState, useEffect} from 'react'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {ExercisesType} from '../../../../exercises/interfaces'
import {showSimpleAlert} from '../../../../../../commonHooks/alert'
import {AddTeacherWritingCheckListResponse} from '../../../../../courses/exercises/WrintingCheckList/services'
import {AddTeacherCheckWritingResponse} from '../../../../../courses/exercises/check-writing/services'
import {AddTeacherSpeakOutResponse} from '../../../../../courses/exercises/speak-out/services'
import {MovieWritingResponseUpdate} from '../../../../movie-writing-response/services'
import {UseQualifyPlacementModule} from '../../placement-test/store/main'
import useAuth from '../../../../../../api/main'
import { UseTasksModule } from '../../../../tasks/store/main'
import { TaskType } from '../../../../tasks/interfaces'

type Props = {
  setShow: (v: boolean) => void
  exercise: {type: ExercisesType; id: string}
  feedback: any
}

const QualifyModal = ({exercise, setShow, feedback}: Props) => {
  const [comments, setComments] = useState('')
  const [percentage, setPercentage] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {reload, setReload} = UseQualifyPlacementModule()
  const { typeUser } = useAuth();

  useEffect(() => {
    setComments(feedback.teacherObservation)
    setPercentage(feedback.percentage)
  }, [feedback])

  const handlePercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value)
    setPercentage(value)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      if (exercise.type === ExercisesType.CHECK_WRITING) {
        await AddTeacherCheckWritingResponse(exercise.id, {
          teacherObservation: comments,
          percentage,
        })
      } else if (exercise.type === ExercisesType.SPEAK_OUT) {
        await AddTeacherSpeakOutResponse(exercise.id, {
          teacherObservation: comments,
          percentage,
        })
      } else if (exercise.type === ExercisesType.WRITING_CHECK_LIST) {
        await AddTeacherWritingCheckListResponse(exercise.id, {
          teacherObservation: comments,
          percentage,
        })
      } else if (exercise.type === ExercisesType.MOVIE_WRITING) {
        await MovieWritingResponseUpdate(
          {teacherObservation: comments, percentage: percentage},
          exercise.id
        )
      }
      setShow(false)
      setReload(!reload)
    } catch {
      showSimpleAlert({message: 'Error al calificar la tarea', icon: 'error'})
    }
    setIsLoading(false)
  }

  return (
    <>
      <div className='modal-body'>
        <div>
          <button
            onClick={() => setShow(false)}
            type='button'
            className='btn btn-icon btn-light btn-sm border-0 me-1'
          >
            <KTIcon iconName='arrow-left' className='fs-2 text-primary' />
          </button>
        </div>
        <div>
          <div className={`my-5 w-100`}>
            {exercise.type === ExercisesType.MOVIE_WRITING ? (
              <>
                <label className='row form-label poppins-bold fs-3 ms-2'> Movie Description</label>
                <div className='row'>
                  <div
                    className='col-4 mx-5'
                    style={{
                      minHeight: 'fit-content',
                      paddingLeft: 10,
                      border: '1px solid gray',
                      borderRadius: '10px',
                    }}
                  >
                    <div className='row align-items-center p-0 m-0 '>
                      <div className='col p-0 '>
                        <label className='col-form-label poppins-light p-0'>
                          Title of the movie:
                        </label>
                      </div>
                      <div className='col p-0 m-0'>
                        <input
                          type='text'
                          disabled
                          className='form-control poppins-light'
                          aria-describedby='passwordHelpInline'
                          value={feedback.title}
                          style={{
                            marginTop: 0,
                            background: 'transparent',
                            borderBottom: '1px solid white',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            color: '#2d30e1',
                            height: 10,
                            boxShadow: 'none',
                          }}
                        />
                      </div>
                    </div>
                    <div className='row align-items-center p-0' style={{margin: 0}}>
                      <div className='col p-0 '>
                        <label className='col-form-label poppins-light p-0'>Genre:</label>
                      </div>
                      <div className='col p-0 m-0'>
                        <input
                          type='text'
                          disabled
                          className='form-control poppins-light '
                          aria-describedby='passwordHelpInline'
                          value={feedback.genre}
                          style={{
                            marginTop: 0,
                            background: 'transparent',
                            borderBottom: '1px solid white',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            color: '#2d30e1',
                            height: 10,
                            boxShadow: 'none', // Asegura que no haya sombra en el input
                          }}
                        />
                      </div>
                    </div>
                    <div className='row align-items-center p-0' style={{margin: 0}}>
                      <div className='col p-0 '>
                        <label className='col-form-label poppins-light p-0'> Duration:</label>
                      </div>
                      <div className='col p-0 m-0'>
                        <input
                          type='text'
                          disabled
                          className='form-control poppins-light '
                          aria-describedby='passwordHelpInline'
                          value={feedback.duration}
                          style={{
                            marginTop: 0,
                            background: 'transparent',
                            borderBottom: '1px solid white',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            color: '#2d30e1',
                            height: 10,
                            boxShadow: 'none', // Asegura que no haya sombra en el input
                          }}
                        />
                      </div>
                    </div>
                    <div className='row align-items-center p-0' style={{margin: 0}}>
                      <div className='col p-0 '>
                        <label className='col-form-label poppins-light p-0'>Main characters:</label>
                      </div>
                      <div className='col p-0 m-0'>
                        <input
                          type='text'
                          className='form-control poppins-light '
                          aria-describedby='passwordHelpInline'
                          disabled
                          value={feedback.mainCharter}
                          style={{
                            marginTop: 0,
                            background: 'transparent',
                            borderBottom: '1px solid white',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            color: '#2d30e1',
                            height: 10,
                            boxShadow: 'none', // Asegura que no haya sombra en el input
                          }}
                        />
                      </div>
                    </div>
                    <div className='row align-items-center p-0' style={{margin: 0}}>
                      <div className='col p-0 '>
                        <label className='col-form-label poppins-light p-0'>Rating:</label>
                      </div>
                      <div className='col p-0 m-0'>
                        <input
                          type='text'
                          disabled
                          className='form-control poppins-light '
                          aria-describedby='passwordHelpInline'
                          value={feedback.rating}
                          style={{
                            marginTop: 0,
                            width: '100%',
                            background: 'transparent',
                            borderBottom: '1px solid white',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            color: '#2d30e1',
                            height: 10,
                            boxShadow: 'none', // Asegura que no haya sombra en el input
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-7 me-0 pe-0'>
                    <textarea
                      disabled
                      value={feedback.description}
                      className='w-100 h-100 px-2 pt-3 border-1 border-opacity-25 rounded poppins-light row  '
                      placeholder='No hay respuesta...'
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <label className='form-label poppins-bold fs-3'>Description</label>
                <textarea
                  disabled
                  value={feedback.description}
                  className='w-100 h-80 px-5 pt-3 border-1 border-opacity-25 rounded poppins-light'
                  placeholder='No hay respuesta...'
                />
              </>
            )}
          </div>
        </div>
        <form onSubmit={handleSubmit} noValidate>
          <div className='my-5 w-100'>
            <label className='form-label poppins-bold fs-3'>Comments: </label>
            <textarea
              disabled={typeUser === 'STUDENT'}
              value={comments}
              className='w-100 h-80 px-5 pt-3 border-1 border-opacity-25 rounded poppins-light'
              onChange={(e) => setComments(e.target.value)}
              placeholder='Leave some comments...'
            />
          </div>
          <div className='mb-3 d-flex justify-content-between'>
            <div>
              <label className='form-label poppins-bold fs-3 me-5'>Score: </label>
              <input
                disabled={typeUser === 'STUDENT'}
                min={0}
                max={100}
                type='number'
                value={percentage}
                onChange={handlePercentageChange}
                className='poppins-light pe-0  w-25 text-end  border-1 border-opacity-25 rounded'
                id='exampleInputPassword1'
              />
              %
            </div>
            {typeUser !== 'STUDENT' &&
              <button type='submit' className='btn btn-primary py-2'>
                {!isLoading && 'Submit'}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            }
          </div>
        </form>
      </div>
    </>
  )
}

export {QualifyModal}
