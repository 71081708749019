import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {CourtesyCode} from '../../interfaces'

import { CourtesyCodeAdd, CourtesyCodeUpdate } from '../../services'
import { UseCourtesyCodeModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'

export type CourtesyCodeFormFields = {
  days:number,
  number:number
}

const entitySchema = Yup.object().shape({
  days: Yup.number().required('days is required'),
  number: Yup.number().required('days is required')
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const CourtesyCodeForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseCourtesyCodeModule();
  const [data, setData] = useState<CourtesyCode>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<CourtesyCodeFormFields>({
      initialValues: {
        days: 0,
        number: 0,
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await CourtesyCodeAdd({
              ...formData,
              courtesy:'COURTESY'
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['courtesy-code'] })

            }catch(e){
              setLoading(false);
            }

          }
      },
    })
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

 
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Días</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'days'}
              value={values.days}
              onChange={handleChange}
            />
            {touched.days && errors.days && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.days}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Cantidad</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'number'}
              value={values.number}
              onChange={handleChange}
            />
            {touched.days && errors.number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.number}</span>
                </div>
              </div>
            )}
          </div>

 
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {CourtesyCodeForm}
