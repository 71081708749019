import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import { LoginPlatform } from './components/LoginPlatform'
import { ForgotPasswordPlatform } from './components/ForgotPasswordPlatform'
import { RecoveryPasswordPlatform } from './components/RecoveryPasswordPlatform'
import { SignInPlatform } from './components/SignInPlatform'

const mode = process.env.REACT_APP_THEME_DEMO;

const AuthPage = () => (
  <Routes>
    <Route /*element={<AuthLayout />}*/>
      {/* <Route path='login' element={<Login />} /> */}
      <Route path='login' element={<LoginPlatform />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPasswordPlatform />} /> 
      <Route path='forgot-password' element={<ForgotPasswordPlatform />} /> 
      <Route path='recovery-password' element={<RecoveryPasswordPlatform />} /> 
      {mode === 'SALES' && 
        <Route path='sign-in' element={<SignInPlatform />} /> 
      }
      <Route index element={<LoginPlatform />} />
      {/* <Route path='forgot-password' element={<ForgotPassword />} /> */}
      {/* <Route index element={<Login />} /> */}
    </Route>
  </Routes>
)

export {AuthPage}
