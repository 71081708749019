import useAuth from "../../../../api/main";
import { api } from "../../../auth/services/auth.services";

const { token, logout, authChecked } = useAuth.getState()

export const FindTaskMeByLesson = async (idSetting: string, idLesson: string): Promise<any> => {
    try {
      // await authChecked()
  
      const response: any = await api.get(
        `/tasks/me-by-lesson/${idSetting}/${idLesson}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      if (e.response.status === 404) {
        window.location.replace('/dashboard')
      }
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
      //@ts-ignore
      showSimpleAlert({ message: 'Error!', icon: 'error' })
      throw 'Error'
    }
    
  }
  

  export const FindOneEvaluationByTask = async (paramId: string, settingId: string, type: 'evaluation' | 'lesson' ): Promise<any> => {
    try {
  
      const response: any = await api.get(
        `/tasks/evaluation?param=${paramId}&student_setting=${settingId}&filterType=${type}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
      //@ts-ignore
      showSimpleAlert({ message: 'Error!', icon: 'error' })
      throw 'Error'
    }
  }
  