import {Route, Routes} from 'react-router-dom'
import { StudentScheduleBlockDetails } from '../components/details'
import { StudentScheduleBlockTableList } from '../components/list'


const StudentScheduleBlockPage = () => (
  <Routes>
      <Route path='/details' element={<StudentScheduleBlockDetails />} />
      <Route path='/' element={<StudentScheduleBlockTableList />} />
  </Routes>
)

export {StudentScheduleBlockPage}
