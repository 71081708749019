import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { TypeDragDrop, WordDragDropIE } from '../../interfaces'

import { DragDropQuestionsAdd, DragDropQuestionsUpdate } from '../../services'
import { UseDragDropQuestionsModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export type FormFields = {
  name: string
  isActive: boolean
  description?: string;
  words: WordDragDropIE[];
  sentences: string[];
  type: TypeDragDrop;
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  // type_category: Yup.string(),
})

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
}
const DragDropQuestionsForm: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const queryClient = useQueryClient()
  const { itemSelected, mode, resetData } = UseDragDropQuestionsModule();
  const [sentenceData, setSentenceData] = React.useState<string>();
  const [wordsData, setWordsData] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false)
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        words: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.words.map(x => JSON.parse(x)) ?? [] : [],
        sentences: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.sentences ?? [] : [],
        type: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.type ?? TypeDragDrop.WORD_BOX_V1 : TypeDragDrop.WORD_BOX_V1

      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (props.mode === 'ADD') {
          try {
            setLoading(true);
            await DragDropQuestionsAdd({
              ...formData,
              exercises: id ?? 'xx'
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['drag-drop-questions'] })

          } catch (e) {
            setLoading(false);
          }

        }
        else {
          try {
            setLoading(true);
            await DragDropQuestionsUpdate({
              ...formData,
              exercises: id ?? 'xx'
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['drag-drop-questions'] })

          } catch (e) {
            setLoading(false);
          }
        }
      },
    });

  const addSentence = () => {
    setFieldValue('sentences', [...values.sentences, sentenceData]);
    setSentenceData('');
  };

  const addWord = () => {
    setFieldValue('words', [...values.words, { id: `word_${values.words.length + 1}`, text: wordsData }]);
    setWordsData('');
  }

  const deleteSentence = (sentences: string) => {
    setFieldValue('sentences', values.sentences.filter(x => sentences !== x))
  }

  const deleteWord = (id: string) => {
    setFieldValue('words', values.words.filter(x => id !== x.id))
  }

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive ? 1 : 0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>level</label>
            <select
              name='type'
              className='form-select'
              aria-label='Default select example'
              value={values.type}
              onChange={handleChange}
              disabled={mode === 'VIEW'}
            >
              <option value=''></option>
              {Object.keys(TypeDragDrop).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-2'>Sentence</label>
            <input
              type="text"
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              value={sentenceData}
              onChange={(e) => setSentenceData(e.target.value)}
            />
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' className='btn btn-primary' onClick={addSentence} disabled={loading || mode === 'VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Word</label>
            <input
              type="text"
              className='form-control form-control-lg form-control-solid'
              disabled={mode === 'VIEW'}
              value={wordsData}
              onChange={(e) => setWordsData(e.target.value)}
            />
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' className='btn btn-primary' onClick={addWord} disabled={loading || mode === 'VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
          <div className='col-6'>
            <table className=" table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr className="fw-bold text-muted">
                  <th className='text-dark text-center champ-bold border border-4 border-secondary fs-4'>Sentences</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {values.sentences && values.sentences.map((x, i) => (
                  <tr key={i}>
                    <td className='border border-4 border-secondary'>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-end flex-column scroll-y overflow-y" style={{ maxHeight: '50px', maxWidth: '370px' }}>
                          <a href="" className='text-dark fw-bold text-hover-primary px-2' style={{ maxHeight: '50px', maxWidth: '370px' }}>
                            {x}
                          </a>
                          <span className='text-muted fw-semibold d-block fs-7'>
                            {/* {x.id} */}
                          </span>
                        </div>
                        <button
                          disabled={loading || mode === 'VIEW'}
                          type='button'
                          onClick={() => deleteSentence(x)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 px-8'
                          style={{marginLeft:'30px'}}
                        >
                          <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15}}></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='col-6'>
            <table className=" table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr className="fw-bold text-muted">
                  <th className='text-dark text-center champ-bold border border-4 border-secondary fs-4'>Words</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {values.words.map((x) => (
                  <tr>
                    <td className='border border-4 border-secondary'>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start  flex-column scroll-y overflow-y" style={{ maxHeight: '50px', maxWidth: '370px' }}>
                          <a href="" className='text-dark fw-bold text-hover-primary px-7 my-auto' style={{ maxHeight: '50px', maxWidth: '370px' }}>
                            {x.text}
                          </a>
                        </div>
                        <button
                          disabled={loading || mode === 'VIEW'}
                          type='button'
                          onClick={() => deleteWord(x.id)}
                          className='btn btn-icon btn-bg-light btn-active-color-primarybtn-sm me-1 px-8'
                          style={{marginLeft:'30px'}}
                        >
                          <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { DragDropQuestionsForm }
