import {FormEvent, useCallback, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {Lessons} from '../../../../../abroad/learning-path/modules/lessons/interfaces'
import {showSimpleAlert} from '../../../../../../commonHooks/alert'
import {UseAllLessonByChapter} from '../../../../../abroad/learning-path/modules/lessons/services'
import {FormFields, RoadMapStep} from './index'
import {SequenceType} from '../../../../../abroad/road-map-step/interfaces'
import {UseManualUpgradeStudent} from '../../services'
import {useQueryClient} from '@tanstack/react-query'

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
  submitPromotion: (e?: FormEvent<HTMLFormElement> | undefined) => void
  roadMapStep: RoadMapStep
  loading: boolean
  setLoading: (value: boolean) => void
  formData: FormFields
  setShow: (value: boolean) => void
  studentSetting: string
}
const SelectLessonsForm = ({
  submitPromotion,
  roadMapStep,
  mode,
  formData,
  setShow,
  studentSetting,
  loading,
  setLoading,
}: Props) => {
  const [lessons, setLessons] = useState<Lessons[]>()
  const [selectedLessons, setSelectedLessons] = useState<string[]>([])
  const [hoursToSee, setHoursToSee] = useState<number>(roadMapStep.hoursToSee)
  const [additionalHours, setAdditionalHours] = useState<number>(0)
  const [previousAdditionalHours, setPreviousAdditionalHours] = useState(additionalHours);

  const isStandardSequence = roadMapStep.sequence === SequenceType.STANDARD
  const queryClient = useQueryClient()

  const maxHours = roadMapStep.hours

  const {handleSubmit, setFieldValue} = useFormik<FormFields>({
    initialValues: {
      ...formData,
      studentSetting: studentSetting,
      additionalHours: additionalHours
    },
    onSubmit: async () => {
      setFieldValue('hasLessons', true)
      const approvedLessons = selectedLessons
      if (selectedLessons?.length > 0) {
        try {
          submitPromotion()
          await UseManualUpgradeStudent({
            studentSetting,
            newCurrentStep: formData.newCurrentStep,
            approvedLessons,
            date: formData.date,
            additionalHours: additionalHours
          })
          setLoading(false)
          queryClient.invalidateQueries({queryKey: ['promotion-history']})
        } catch (e) {
          showSimpleAlert({message: `${e}`, icon: 'error'})
          setLoading(false)
        }
      }
    },
  })

  const getLessonsData = useCallback(async () => {
    if (roadMapStep.chapter.id) {
      try {
        const lessons = await UseAllLessonByChapter(roadMapStep.chapter.id)
        setLessons(lessons)
      } catch (e) {
        showSimpleAlert({message: `${e}`, icon: 'error'})
      }
    }
  }, [roadMapStep.chapter])

  useEffect(() => {
    getLessonsData()
  }, [roadMapStep.chapter])

  const handleLessonChange = useCallback( (lessonId: string) => {
    if(lessons){
      setSelectedLessons((prevSelected) => {
        let newSelected: string[] = []
        if (isStandardSequence) {
          const selectedIndex = lessons.findIndex((lesson) => lesson.id === lessonId)

          if (selectedIndex >= 0) {
            if (prevSelected.includes(lessonId)) {
              // Si la lección ya está seleccionada, eliminar todas las lecciones desde esa hasta la última
              const indexInSelected = prevSelected.indexOf(lessonId)
              newSelected = prevSelected.slice(0, indexInSelected)
            } else {
              // Incluir todas las lecciones anteriores a la seleccionada
              const previousLessonIds = lessons.slice(0, selectedIndex + 1).map((lesson) => lesson.id)
              newSelected = Array.from(new Set([...prevSelected, ...previousLessonIds]))
            }
          }
        } else {
          newSelected = prevSelected.includes(lessonId)
            ? prevSelected.filter((id) => id !== lessonId)
            : [...prevSelected, lessonId]
        }
  
        // Calcular el total de horas basado en las lecciones seleccionadas
        const totalHours = roadMapStep.hoursToSee
        const hoursToDiscount = newSelected.length
        setHoursToSee(totalHours - hoursToDiscount-additionalHours)
        return newSelected
      })
    }
  },[lessons])

  useEffect(() => {
    setHoursToSee((prevHours) => {
      const difference = additionalHours - previousAdditionalHours;
      return prevHours - difference;
    });
    
    setPreviousAdditionalHours(additionalHours);
  }, [additionalHours]);
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className='card-body border-top p-9 row'>
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>New Level</th>
                <th>Road Map Name</th>
                <th>Hours to see in this new level</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{roadMapStep.level}</td>
                <td>{roadMapStep.roadMap.name}</td>
                <td>{hoursToSee}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className='row'>
          <h1 className='fs-2'>Seleccione las lecciones que YA aprobó el estudiante</h1>
          <div className='d-flex flex-wrap justify-content-between'>
            {lessons &&
              lessons
                .sort((a, b) => a.order - b.order)
                .map((lesson, i) => {
                  // const isDisabled =
                    // isStandardSequence && i > 0 && !selectedLessons.includes(lessons[i - 1].id)
                  return (
                    <a
                      href='#'
                      key={i}
                      className={`col-12 mb-3 ${
                        selectedLessons.includes(lesson.id ?? '')
                          ? 'bg-primary text-white'
                          : 'bg-secondary text-dark'
                      }`}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '32%',
                        justifyContent: 'flex-start',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                      onClick={() => {
                        handleLessonChange(lesson.id ?? '')
                      }}
                    >
                      {selectedLessons.includes(lesson.id ?? '') ? (
                        <i className='fa-solid fa-check text-white me-2'></i>
                      ) : (
                        <i className='bi bi-dash-square-fill me-2'></i>
                      )}
                      {lesson.name}
                    </a>
                  )
                })}
          </div>
        </div>
        { lessons &&
          <div className='row justify-content-between'>
            <div className='col-6 d-flex align-items-center'>
              <h1 className='fs-3 m-0'>Otras horas por sumar: </h1>
              <input 
                type='number' 
                min={0}
                max={maxHours - lessons?.length}
                className='text-start poppins-light px-0 m-0 text-end border-1 border-opacity-25 rounded' 
                style={{ width: "10%", marginLeft: "10px" }}
                value={additionalHours}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  if (value <= 0 || e.target.value === "") {
                    setAdditionalHours(0);
                    showSimpleAlert({ message: 'El mínimo es 0', icon: 'info' });
                  } else if (value > maxHours - lessons?.length) {
                    setAdditionalHours(maxHours - lessons?.length);
                    showSimpleAlert({ message: `El máximo es ${maxHours - lessons.length}`, icon: 'info' });
                  } else {
                    setAdditionalHours(value);
                  }
                }}
              />  
            </div>
            {/* <button type='button' className='col-4 btn btn-primary' onClick={() => {
              if (selectedLessons.length === lessons.length) {
                setSelectedLessons([]);
              } else {
                lessons.map((x) => {
                  if(!selectedLessons.includes(x.id)){
                    handleLessonChange(x.id);
                  }
                });
              }
            }}>
              Seleccionar todo
            </button> */}
          </div>
        }
        
      </div>
      <div className='card-footer d-flex justify-content-between py-6 px-9'>
        <button type='button' className='btn border-none' onClick={() => setShow(false)}>
          <span>Go Back</span>
        </button>

        <button
          type='submit'
          className='btn btn-primary'
          disabled={loading || mode === 'VIEW' || selectedLessons.length < 1}
        >
          {!loading && 'Save'}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}

export {SelectLessonsForm}
