import { useState, useEffect } from 'react';

const useImageLoader = (imageUrls: string[]) => {
  const [loaded, setLoaded] = useState(false);
  const [images, setImages] = useState<HTMLImageElement[]>([]);

  useEffect(() => {
    const loadImages = async () => {
      if (!imageUrls || imageUrls.length === 0) return;
      const promises = imageUrls.map(url => {
        return new Promise<HTMLImageElement>((resolve, reject) => {
          const img = new Image();
          img.src = url;
          img.onload = () => resolve(img);
          img.onerror = reject;
        });
      });

      try {
        const loadedImages = await Promise.all(promises);
        setImages(loadedImages);
        setLoaded(true);
      } catch (error) {
        console.error('Error loading images', error);
      }
    };

    loadImages();
  }, [imageUrls]);

  return { loaded, images };
};

export default useImageLoader;