import React, {useEffect, useState} from 'react'
import {UseTasksOne} from '../services'
import {ExercisesType} from '../../exercises/interfaces'
import {QualifyModal} from '../../qualify/modules/common/modal/qualify'
import {StudentSetting} from '../../history-writing-response/interfaces'
import {UseEvaluationCourseModule} from '../../../courses/screen/store/main'
import {getEvaluationResponses} from '../../qualify/modules/placement-test/components/utils/utils'
import {UseQualifyPlacementModule} from '../../qualify/modules/placement-test/store/main'
import {useTranslation} from 'react-i18next'
import {QualifyAudioModal} from '../../qualify/modules/common/modal/qualifyAudio'
import {showSimpleAlert} from '../../../../commonHooks/alert'
import { LevelsByCategory, TaskStatus } from '../interfaces'
import { QualifyHistoryWritingModal } from '../../qualify/modules/common/modal/qualifyHistory'
import { TraditionalLevelEnum } from '../../../abroad/road-map-step/interfaces'
import useAuth from '../../../../api/main'

type Props = {
  item: any
  isLoading: boolean
  setStudent?: (value: StudentSetting) => void
  setIsLoading: (value: boolean) => void
}

const TasksDetails = ({item, isLoading, setIsLoading, setStudent}: Props) => {
  const {t} = useTranslation()
  const [items, setItems] = React.useState<any[]>([])
  const [show, setShow] = React.useState<boolean>(false)
  const [selectExercise, setSelectExercise] = React.useState<{type: ExercisesType; id: string}>()
  const [writingFeedback, setWritingFeedback] = useState<any>()
  const [levelsByCategory, setLevelsByCategory] = useState<LevelsByCategory>()
  const {itemSelected, reload, levelRecommended ,setLevelRecommended} = UseQualifyPlacementModule()
  const [showOverallLevel, setShowOverallLevel] = useState<boolean>(false)
  const {resetEvaluationResponses} = UseEvaluationCourseModule()
  const {typeUser} = useAuth();
  
  const getDetails = async () => {
    if (!item) return
    setIsLoading(true)
    try{
      let response = await UseTasksOne(item.id)
      if (response.resume) {
        setItems(response.resume)
      }
      if (response?.studentSetting && setStudent) {
        setStudent(response.studentSetting)
      }
      if (response?.overallLevel) {
        setLevelRecommended(response.overallLevel as TraditionalLevelEnum)
        setShowOverallLevel(true)
      }
      if (response?.levelsByCategory) {
        setLevelsByCategory(response.levelsByCategory)
      }
    } finally {
      setIsLoading(false)
    }
    
  }

  useEffect(() => {
    getDetails()
  }, [item, reload])

  const setTrueAnswers = async () => {
    if (items.length > 0) {
      getEvaluationResponses(items)
    }
  }

  useEffect(() => {
    const resetAndSetEvaluationResponses = async () => {
      resetEvaluationResponses()
      await setTrueAnswers()
    }
    resetAndSetEvaluationResponses()
  }, [items])

  // Agrupar los elementos por categoría
  const groupedItems = items.reduce((groups, item) => {
    const category = item.result?.category || 'Sin Categoría'
    if (!groups[category]) {
      groups[category] = []
    }
    groups[category].push(item)
    return groups
  }, {} as Record<string, any[]>)

  return (
    <>
      {isLoading ? (
        <span className='indicator-progress d-flex justify-content-center my-5'>
          <span className='spinner-border spinner-border-lg align-middle'></span>
        </span>
      ) : (
        <>
          {!selectExercise ? (
            <>
              <div className='accordion' id='tasksAccordion'>
                {Object.keys(groupedItems).map((category, index) => (
                  <div
                    key={index}
                    className={`accordion-item text-dark
                    ${(itemSelected?.status === TaskStatus.IN_REVIEW 
                      && groupedItems[category].some(x=>
                        x.exercise.type ===  ExercisesType.CHECK_WRITING
                        || x.exercise.type === ExercisesType.HISTORY_WRITING
                        || x.exercise.type === ExercisesType.SPEAK_OUT
                        || x.exercise.type === ExercisesType.WRITING_CHECK_LIST
                        || x.exercise.type === ExercisesType.MOVIE_WRITING
                        || x.exercise.type === ExercisesType.AUDIO_TEST)
                    ) && "breathing-blue-effect my-1"}
                  `}>
                    <h2 className='accordion-header' id={`heading-${index}`}>
                      <button
                        className='accordion-button'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#collapse-${index}`}
                        data-bs-parent={`#tasksAccordion`}
                        aria-expanded='false'
                        aria-controls={`collapse-${index}`}
                      >
                        <div className='fs-4 text-start' style={{ flex: 1 }}>{t(category)}  </div>
                        <div className='fs-4 text-end me-5' style={{ whiteSpace: 'nowrap' }}>{levelsByCategory ? `${levelsByCategory[category]?.value.toFixed(1)}% | ${t(levelsByCategory[category]?.level)}` : ""}</div>
                      </button>
                    </h2>
                    <div
                      id={`collapse-${index}`}
                      className='accordion-collapse collapse'
                      aria-labelledby={`heading-${index}`}
                      data-bs-parent='#tasksAccordion'
                    >
                      <div className='accordion-body p-1'>
                        <table className='table p-5'>
                          <thead className=''>
                            <tr className={`fw-bold bg-light poppins-bold`}>
                              <th className='text-center align-middle'>Ejercicio</th>
                              <th className='text-center align-middle'>Respondido</th>
                              <th className='text-center align-middle'>Correcto (%)</th>
                              <th className='text-center align-middle'>Valor del ejercicio</th>
                              <th className='text-center align-middle'>Valor sobre el Global</th>
                              <th className='text-center align-middle'>Profesor</th>
                              <th className='text-center align-middle'>
                                {typeUser !== 'STUDENT' ?
                                "Calificar" : "Ver calificación" }
                              </th>
                              
                            </tr>
                          </thead>
                          <tbody className=''>
                            {groupedItems[category] &&
                              groupedItems[category].map((x, i) => (
                                <tr key={i} className={`mt-3 ms-2 fs-5`}>
                                  <td className='text-center align-middle'>{x.exercise?.name}</td>
                                  <td className='text-center align-middle'>
                                    <span className='poppins-light fw-bold'>
                                      {x.result?.isCompleted ? 'Si' : 'No'}
                                    </span>
                                  </td>
                                  <td className='text-center align-middle'>
                                    <span className='poppins-light fw-bold'>
                                      {x.result?.percentageUnity?.toFixed(1)} %
                                    </span>
                                  </td>
                                  <td className='text-center align-middle'>
                                    <span className='poppins-light fw-bold'>
                                      {x.result?.percentageExercise?.toFixed(1)} %
                                    </span>
                                  </td>
                                  <td className='text-center align-middle'>
                                    <span className='poppins-light fw-bold'>
                                      {x.result?.percentageGlobal?.toFixed(1)} %
                                    </span>
                                  </td>
                                  {[
                                    ExercisesType.CHECK_WRITING,
                                    ExercisesType.HISTORY_WRITING,
                                    ExercisesType.SPEAK_OUT,
                                    ExercisesType.WRITING_CHECK_LIST,
                                    ExercisesType.MOVIE_WRITING,
                                    ExercisesType.AUDIO_TEST,
                                  ].includes(x.exercise?.type as ExercisesType) ? (
                                    <>
                                      <td className='text-center align-middle'>
                                        {x.feedback?.isReviewed ? x.feedback.teacher : 'Sin nota'}
                                      </td>
                                      <td className='text-center align-middle m-auto'>
                                        <button
                                          className='btn btn-icon btn-bg-light btn-sm'
                                          onClick={() => {
                                            if (x.answerId === '') {
                                              showSimpleAlert({
                                                message: 'El estudiante no subió respuesta',
                                                icon: 'info',
                                              })
                                            } else {
                                              setShow(true)
                                              setSelectExercise({
                                                type: x.exercise?.type as ExercisesType,
                                                id: x.answerId ?? '',
                                              })
                                              setWritingFeedback(x.feedback)
                                            }
                                          }}
                                        >
                                          <i className='fa-solid fa-list-check text-success-emphasis' />
                                        </button>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td className='text-center align-middle'>Auto</td>
                                      <td className='text-center align-middle'></td>
                                    </>
                                  )}
                                </tr>
                              ))}
                            {groupedItems[category].length > 0 && (
                              <>
                                <tr>
                                  <td
                                    colSpan={8}
                                    className='border-bottom border-2'
                                  ></td>
                                </tr>
                                <tr className={`mt-0 ms-2 fs-5`}>
                                  <td className='text-center align-middle'>
                                    <span className='poppins-light fw-bold fs-5'>
                                      Total {t(category).toLocaleLowerCase()}
                                    </span>
                                  </td>
                                  <td className='text-center align-middle'>
                                    {/* Dejar vacío*/}
                                  </td>
                                  <td className='text-center align-middle'>
                                    <span className='poppins-light fw-bold'>
                                      {levelsByCategory && levelsByCategory[category]?.value.toFixed(1)}
                                      {''}%
                                    </span>
                                  </td>
                                  <td className='text-center align-middle'>
                                    <span className='poppins-light fw-bold'>
                                      {groupedItems[category]
                                        .reduce((sum, x) => sum + x.result.percentageExercise, 0)
                                        .toFixed(1)}
                                      {''}%
                                    </span>
                                  </td>
                                  <td className='text-center align-middle'>
                                    <span className='poppins-light fw-bold'>
                                      {groupedItems[category]
                                        .reduce((sum, x) => sum + x.result.percentageGlobal, 0)
                                        .toFixed(1)}
                                      {''}%
                                    </span>
                                  </td>
                                  { levelsByCategory &&
                                    <td colSpan={2} className='text-center align-middle'>
                                      <span className='poppins-light fw-bold text-primary'>
                                        {t(levelsByCategory[category]?.level) ?? 'Not Found Error'}
                                      </span>
                                    </td>
                                  }
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <table className='table p-5'>
                <tbody className=''>
                  {items.length > 0 && (
                    <>
                      <tr>
                        <td colSpan={showOverallLevel? 4 : 2} className='border-bottom-2 border-success'></td>
                      </tr>
                      <tr className={`mt-3 ms-2 fs-5`}>
                        <td className={`text-end ${showOverallLevel? "" : "w-100"}`}>
                          <span className='poppins-light fw-bold'>Total:</span>
                        </td>
                        <td className='text-start'>
                          <span className='poppins-light fw-bold me-5'>
                            {items
                              .reduce((sum, x) => sum + x.result.percentageGlobal, 0)
                              .toFixed(1)}
                            {''}%
                          </span>
                        </td>
                        { showOverallLevel &&
                          <>
                            <td className='text-end'>
                              <span className='poppins-light fw-bold'>Nivel recomendado:</span>
                            </td>
                            <td className='text-start'>
                              <span className='poppins-light fw-bold text-primary'>{t(levelRecommended)}</span>
                            </td>
                          </> 
                        }
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </>
          ) : selectExercise.type === ExercisesType.AUDIO_TEST ? (
            <QualifyAudioModal
              setShow={(e) => !e && setSelectExercise(undefined)}
              exercise={selectExercise}
              feedback={writingFeedback}
            />
          ) : selectExercise.type === ExercisesType.HISTORY_WRITING ? (
            <QualifyHistoryWritingModal
              setShow={(e) => !e && setSelectExercise(undefined)}
              exercise={selectExercise}
              feedback={writingFeedback}
            />
          ) : (
            <QualifyModal
              setShow={(e) => !e && setSelectExercise(undefined)}
              exercise={selectExercise}
              feedback={writingFeedback}
            />
          )}
        </>
      )}
    </>
  )
}

export {TasksDetails}
