import useAuth from "../../../../../api/main";
import { showSimpleAlert } from "../../../../../commonHooks/alert";
import { TeacherResponseExercise } from "../../../../admin/exercises/interfaces";
import { api } from "../../../../auth/services/auth.services";

const { token, logout, authChecked } = useAuth.getState();
interface ResponseSpeakOutResponse {
  name: string;
  description?: string;
  isActive: boolean;
  isReviewed: boolean;
  isPending: boolean;
  exercises: string;
  task: string;
  percentage: number;
}
export const AddSpeakOutResponse = async (data: ResponseSpeakOutResponse): Promise<any> => {
    try {
      const response: any = await api.post(
        '/speak-out-response',
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
      
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
  
      throw 'Error'
    }
  }

  export const AddTeacherSpeakOutResponse = async (id: string, data: TeacherResponseExercise): Promise<any> => {
    try {
      const response: any = await api.patch(
        `speak-out-response/${id}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
      
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
  
      throw 'Error'
    }
  }