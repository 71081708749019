import React, {useEffect} from 'react'
import './index.css'
import {useThemeMode} from '../../../../../../_metronic/partials'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Answer, QuestionTrueFalse} from '../interfaces'
import {UseModeCourseModule} from '../../../components/front-page/store/main'
import {UseEvaluationCourseModule} from '../../../screen/store/main'
import {AddTrueOrFalseResponse} from '../services'
import {showSimpleAlert} from '../../../../../commonHooks/alert'
import {UseTrueFalseModule} from '../store'
import useAuth from '../../../../../api/main'
import {validationPercentageObtained} from '../../../utils/validatePercentage'
import {ButtonNextFullScreen} from '../../../utils/buttonNextFullScreen'
import useIsImportantEvaluation from '../../../hooks/useIsImportantEvaluation'
interface ResponseAnswer {
  answer: Answer[]
  percentage: number
}
type Props = {
  questions: QuestionTrueFalse[]
  handleNext: (x: number) => void
  handleSendAnswer: (x: ResponseAnswer) => void
  modeQuiz: 'RESUME' | 'ANSWER' | 'THROW_ERROR' | 'TITLE'
  setStatus: (string) => void
  setStep: (number) => void
  totalQuestions: number
}

const BasicVocabularyExerciseItem: React.FC<Props> = ({
  setStatus,
  questions,
  modeQuiz,
  handleSendAnswer,
}: Props) => {
  const {mode} = useThemeMode()
  const {typeUser} = useAuth()
  const {isPercentageMin} = UseModeCourseModule()
  const {answer, percentage, resetData} = UseTrueFalseModule()

  const {evaluationLesson, questionsEvaluation, currentTaskId, setEvaluationResponses} =
    UseEvaluationCourseModule()

  const [answers, setAnswers] = React.useState<Answer[]>([])
  const [allAnswered, setAllAnswered] = React.useState(false)

  const resetExercise = () => {
    setAnswers([])
    setAllAnswered(false)
    resetData()
    setStatus('ANSWER')
  }

  const handleAnswer = (order: number, answers_response: 'TRUE' | 'FALSE' | 'NOT_GIVEN') => {
    if (modeQuiz != 'ANSWER') return
    const question = questions.find((q) => q.order === order)
    if (!question) return // Si no se encuentra la pregunta, no hacer nada

    const existingAnswerIndex = answers.findIndex((ans) => ans.order === order)
    const newAnswer: Answer = {
      title: question.title,
      order: question.order,
      answer: question.answer, // Suponiendo que quieras mantener el valor original aquí
      answers_response, // Esta es la respuesta seleccionada
    }

    if (existingAnswerIndex >= 0) {
      // Si ya existe una respuesta para esta pregunta, actualizarla
      const updatedAnswers = [...answers]
      updatedAnswers[existingAnswerIndex] = newAnswer
      setAnswers(updatedAnswers)
    } else {
      // Si es una nueva respuesta, agregarla
      setAnswers([...answers, newAnswer])
    }
  }

  const isImportantQuiz = useIsImportantEvaluation()
  useEffect(() => {
    // Verificar si el número de respuestas es igual al número de preguntas y que todas las preguntas tienen una respuesta
    const isAllAnswered = questions.length > 0 && answers.length === questions.length
    if (isAllAnswered) {
      calculateAccuracy()
    }
    setAllAnswered(isAllAnswered)
  }, [answers, questions]) // Dependencias: se ejecuta cada vez que cambian las respuestas o las preguntas

  const finderSelected = (
    order: number,
    answers_response: 'TRUE' | 'FALSE' | 'NOT_GIVEN'
  ): boolean => {
    return answers.some((x) => x.order === order && x.answers_response === answers_response)
  }
  const finderSelectedIsTrue = (order: number): boolean => {
    return answers.some((x) => x.order === order && x.answers_response === x.answer)
  }

  const calculateAccuracy = () => {
    const correctAnswersCount = answers.reduce((acc, answer) => {
      const question = questions.find((q) => q.order === answer.order)
      return acc + (question && answer.answers_response === question.answer ? 1 : 0)
    }, 0)

    const accuracyPercentage = (correctAnswersCount / questions.length) * 100
    if (!isImportantQuiz) {
      validationPercentageObtained(questionsEvaluation?.minPercentage ?? 50, accuracyPercentage)
    }
    if (answers) {
      handleSendAnswer({
        percentage: accuracyPercentage,
        answer: answers,
      })
    }
  }

  const handleSubmit = async () => {
    if (!isImportantQuiz) {
      if (!isPercentageMin) return
    }
    // ResetStepByExercises();

    const feedbackAnswers = answer.map((x) => ({
      question: x.title,
      value: x.answers_response ?? '',
      isCorrect: x.answer === x.answers_response,
      feedback: questionsEvaluation?.exercise?.true_or_false[0]?.description ?? 'NA',
    }))
    setEvaluationResponses({
      percentage,
      exercise_title: questionsEvaluation?.exercise.name ?? 'Titulo ejercicio',
      responses: feedbackAnswers,
    })
    if (
      (typeUser === 'STUDENT' || typeUser === 'COURTESY' || typeUser === 'PLACEMENT_TEST') &&
      currentTaskId &&
      questionsEvaluation?.exercise
    ) {
      try {
        await AddTrueOrFalseResponse({
          percentage: percentage,
          isActive: true,
          answer: answer,
          exercises: questionsEvaluation?.exercise?.id ?? 'xx',
          task: currentTaskId ?? 'xx',
        })
      } catch (e) {
        showSimpleAlert({message: 'Error al cargar respuesta', icon: 'error'})
      }
    }
  }
  return (
    <div
      className='card-true-false'
      style={
        mode !== 'dark'  ? 
          {
            height: 420,
            background: 'white',
          }
        : {
            height: 420,
            background: '#1e1e2d',
            border: `1px solid`,
            borderColor: '#3e94ff',
          }
      }
    >
      <h3
        className='champ-light title-true-false '
        style={{
          color: evaluationLesson?.colorPrimary ?? '#2d30e1',
        }}
      >
        True or false
      </h3>
      <h3
        className='poppins-light subtitle-true-false text'
        style={{
          color: evaluationLesson?.colorPrimary ?? '#2d30e1',
        }}
      >
        Instruction: Read the following statements and choose if the are True (T) , False(F), or Not
        Given (NG).
      </h3>
      <div className='row w-100' style={{maxHeight:'245px', overflowY: 'auto'}}>
        {questions.map((x, index) => (
          <div className='d-flex' key={index}>
            <div
              className='col-9 champ-light'
              style={{fontSize: 16, color: evaluationLesson?.colorPrimary ?? '#2d30e1'}}
            >
              {modeQuiz === 'THROW_ERROR' &&
                !isImportantQuiz &&
                (finderSelectedIsTrue(x.order) ? (
                  <i className='bi bi-check icon-option-true-false' style={{color: 'green'}}></i>
                ) : (
                  <i className='bi bi-x icon-option-true-false' style={{color: 'red'}}></i>
                ))}
              {index + 1}. {x.title}
            </div>
            <div className='col-3 d-flex'>
              <button
                type='button'
                onClick={() => handleAnswer(x.order, 'TRUE')}
                className=' btn px-0 py-0 ml-3 rounded rounded-circle    '
                style={
                  finderSelected(x.order, 'TRUE')
                    ? {
                        fontSize: '11px',
                        marginBottom: '3px',
                        marginRight: 5,
                        backgroundColor: '#d0ff00',
                        border: '1px solid #d0ff00',
                        minHeight: '25px',
                        minWidth: '25px',
                        maxWidth: '25px',
                        maxHeight: '25px',
                        background: 'white',
                        color: evaluationLesson?.colorPrimary ?? '#2d30e1',
                      }
                    : {
                        fontSize: '11px',
                        marginBottom: '3px',
                        marginRight: 5,
                        border: '1px solid #3e94ff',
                        minHeight: '25px',
                        minWidth: '25px',
                        maxWidth: '25px',
                        maxHeight: '25px',
                        background: 'white',
                        color: evaluationLesson?.colorPrimary ?? '#2d30e1',
                      }
                }
              >
                T
              </button>
              <button
                type='button'
                onClick={() => handleAnswer(x.order, 'FALSE')}
                className='   btn px-0 py-0 ml-3 rounded rounded-circle   '
                style={
                  finderSelected(x.order, 'FALSE')
                    ? {
                        fontSize: '11px',
                        marginBottom: '3px',
                        marginRight: 5,
                        backgroundColor: '#d0ff00',
                        border: '1px solid #d0ff00',
                        minHeight: '25px',
                        minWidth: '25px',
                        maxWidth: '25px',
                        maxHeight: '25px',
                        background: 'white',
                        color: evaluationLesson?.colorPrimary ?? '#2d30e1',
                      }
                    : {
                        fontSize: '11px',
                        marginBottom: '3px',
                        marginRight: 5,
                        border: '1px solid #3e94ff',
                        minHeight: '25px',
                        minWidth: '25px',
                        maxWidth: '25px',
                        maxHeight: '25px',
                        background: 'white',
                        color: evaluationLesson?.colorPrimary ?? '#2d30e1',
                      }
                }
              >
                F
              </button>
              <button
                type='button'
                onClick={() => handleAnswer(x.order, 'NOT_GIVEN')}
                className=' btn px-0 py-0 ml-3 rounded rounded-circle  text-center'
                style={
                  finderSelected(x.order, 'NOT_GIVEN')
                    ? {
                        fontSize: '11px',
                        marginBottom: '3px',
                        backgroundColor: '#d0ff00',
                        border: '1px solid #d0ff00',
                        minHeight: '25px',
                        minWidth: '25px',
                        maxWidth: '25px',
                        maxHeight: '25px',
                        background: 'white',
                        color: evaluationLesson?.colorPrimary ?? '#2d30e1',
                      }
                    : {
                        fontSize: '11px',
                        marginBottom: '3px',
                        border: '1px solid #3e94ff',
                        minHeight: '25px',
                        minWidth: '25px',
                        maxWidth: '25px',
                        maxHeight: '25px',
                        background: 'white',
                        color: evaluationLesson?.colorPrimary ?? '#2d30e1',
                      }
                }
              >
                NG
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className='d-flex justify-content-between mt-auto'>
        {modeQuiz === 'THROW_ERROR' && allAnswered && (
          <>
            <div className='text-start'>
              {isImportantQuiz && (
                <button
                type='button'
                className='cursor-pointer border border-0 bg-transparent'
                onClick={() => resetExercise()}
                >
                  <img height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/try_again.svg`)}/>
                </button>
              )}
            </div>
            <div className='text-end'>
              {(isPercentageMin || isImportantQuiz) && (
                <ButtonNextFullScreen onAction={handleSubmit} />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export {BasicVocabularyExerciseItem}
