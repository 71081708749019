import React from 'react'
import { Modal } from 'react-bootstrap'
import ExerciseInfo from './ExerciseInfo'
import { RenderBookPage } from './renderBookPage'
import clsx from 'clsx'
import { TraditionalLevelEnum } from '../../../abroad/road-map-step/interfaces'
import { Exercises, ExercisesType, VariantsExercisesType } from '../../../admin/exercises/interfaces'
import { useTranslation } from 'react-i18next'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { StudentEbook, UseBookTeacherModule } from '../../modules/class/store/main'
import { FindOneTaskByLessonAndStudent } from '../../modules/class/services'
import { useLocation } from 'react-router-dom'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { EvaluationItem } from '../../../admin/evaluation/interfaces'
import { setAnswersEbook } from '../../utils/setAnswersEBook'
import { setQuestionsEbook } from '../../utils/setQuestionsEBook'
import { BookPage } from '../../../admin/book/modules/book-page/interfaces'
import { Lessons } from '../../../abroad/learning-path/modules/lessons/interfaces'
import { OneTaskByFindOneTask } from '../../../courses/exercises/basic-vocabulary/services'

export type Student = {
  name: string
  id: string
  level: TraditionalLevelEnum
  photo?: any
  color?: string
}

type Props = {
  // activeStudents: Array<Student>
}

const LoggedStudentsModal: React.FC<Props> = ({  }) => {
  //Datos quemados para maquetación
  const exercises: Array<Exercises> = [
    {
      id: '1',
      description: 'esta es una descripción',
      name: 'Ejercicio 1',
      code: 'Eje1-PRE-A1',
      feedBackImmediate: true,
      fileContent: '',
      type: 'SIMPLE_SELECTION',
      level: TraditionalLevelEnum.A1,
      variant: VariantsExercisesType.BOOK,
      isActive: true,
    },
    {
      id: '2',
      description: 'esta es una descripción',
      name: 'Ejercicio 2',
      code: 'Eje1-PRE-A1',
      feedBackImmediate: true,
      fileContent: '',
      type: 'SIMPLE_SELECTION',
      level: TraditionalLevelEnum.A1,
      variant: VariantsExercisesType.BOOK,
      isActive: false,
    },
    {
      id: '3',
      description: 'esta es una descripción',
      name: 'Ejercicio 3',
      code: 'Eje1-PRE-A1',
      feedBackImmediate: true,
      fileContent: '',
      type: 'SIMPLE_SELECTION',
      level: TraditionalLevelEnum.A1,
      variant: VariantsExercisesType.BOOK,
      isActive: true,
    },
  ]

  const { t } = useTranslation();
  const location = useLocation();
  const {studentsList, currentPage, currentsItemsTest } = UseBookTeacherModule();

  const [selectedExercise, setSelectedExercise] = React.useState<Exercises>();
  const [evaluationItems, setEvaluationItems] = React.useState<EvaluationItem[]>([]);
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const [lesson, setLesson] = React.useState<Lessons>()
  const [student, setStudent] = React.useState<StudentEbook>()
  const [tab, setTab] = React.useState<'Exercise' | 'Book'>('Book');
  const [bookPages, setBookPages] = React.useState<BookPage[]>();
  const [currentTask, setCurrentTask] = React.useState<any>(); // tipar tarea

  const [isScrollActive, setIsScrollActive] = React.useState(false)
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const handleSelectItem = (item: EvaluationItem) => {
    if (currentTask) {
      setSelectedExercise(item.exercise);
      setQuestionsEbook(item);
      setAnswersEbook(currentTask, item.exercise.type, item.exercise.id);
    }
  };

  const handleOpenModal = (selectedStudent: StudentEbook) => {
    setShowModal(true)
    setStudent(selectedStudent);
    if (selectedStudent.lesson !== 'empty') {
      getTaskByLessonInStudent(selectedStudent.studentSetting, selectedStudent.lesson);
    }
  }

  const handleCloseModal = async () => {
    setShowModal(false);
    if (currentTask && currentTask?.id) {
      await OneTaskByFindOneTask(currentTask?.id);
    }
  };

  const getTaskByLessonInStudent = React.useCallback(async (setting: string, lesson: string) => {
    try {
      const response = await FindOneTaskByLessonAndStudent(setting, lesson);
      if (response && response.lesson && response.lesson.evaluation && 
        (response.lesson.evaluation.evaluation_item || response.lesson.evaluation.evaluation_item.length > 0)) {
          setLesson(response.lesson);
          setBookPages(response.lesson?.book_pages ? response.lesson.book_pages : []);
          setEvaluationItems(response.lesson.evaluation.evaluation_item);
          // setSelectedExercise(response.lesson.evaluation.evaluation_item[0].exercise);
          if (response?.task) {
            setCurrentTask(response.task);
          }
      }
    } catch (e) {
      showSimpleAlert({message: 'Error al encontrar tarea', icon: 'error'});
    }
  }, [studentsList]);


  React.useEffect(() => {
    const containerElement = containerRef.current
    if (containerElement) {
      // Verifica si el contenido ha desbordado el contenedor verticalmente
      setIsScrollActive(containerElement.scrollHeight > containerElement.clientHeight)
    }
  }, [studentsList]);

  return (
    <>
      <div
        ref={containerRef}
        style={styles.studentListContainer}
        className={`d-flex flex-column justify-content-${isScrollActive ? 'start' : 'center'
          } align-items-center overflow-auto flex-nowrap`}
      >
        {studentsList && studentsList.map((student, index) => (
          <div className='d-flex flex-column align-items-center' key={index}>
            <div
              className={`symbol symbol-${studentsList.length > 7 ? '40px' : 'symbol-75px'} symbol-circle mt-2 cursor-pointer`}
            >
              {student.avatar ? (<>
                <img
                  alt='Pic'
                  style={{ width: 40, height: 40 }}
                  src={student.avatar}
                  onClick={() => handleOpenModal(student)}
                />
                <div className="symbol-badge bg-danger start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2"></div>
              </>
              ) : (<>
                <span
                  className={`symbol-label bg-secondary text-${student.assistantStudent}  `}
                  onClick={() => handleOpenModal(student)}
                >
                  {student.studentName.charAt(0)}
                </span>
                <div className={`symbol-badge bg-${student.assistantStudent?'success':'danger'} start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2`}></div>
              </>
              )}
            </div>
            <>
              <a
                className=' poppins-light text-gray-700 text-hover-primary  mb-0 cursor-pointer'
                onClick={() => handleOpenModal(student)}
                style={{ fontSize: '10px' }}
              >
                {student.studentName}
              </a>
            </>
          </div>
        ))}
      </div>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName='modal-dialog modal-dialog-centered modal-xl'
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title className='poppins-bold'>Detalles del Estudiante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Contenido principal del modal */}
          <div className='d-flex flex-row justify-content-between align-items-stretch'>
            {/* Contenedor de la zona izquierda del modal*/}
            <div className='d-flex flex-column flex-grow-1 border border-1 rounded me-1'>
              {/* Contenedor con la info del estudiante */}
              <div className='d-flex flex-row justify-content-center align-items-center mx-10 pt-10'>
                <div className={`symbol 'symbol-55px' symbol-circle m-auto d-flex `}>
                  {student?.avatar ? (
                    <img alt='Pic' src={toAbsoluteUrl(student.avatar.url)} />
                  ) : (
                    <span
                      className={`symbol-label bg-grey text-grey  fs-5 fw-bolder`}
                    >
                      {student?.studentName.charAt(0)}
                    </span>
                  )}
                </div>

                <div className=' w-100 justify-content-between'>
                  <div className='flex-fill'>
                    <p className='list-unstyled text-gray-700 poppins-bold fs-6 ps-10 m-0'>
                      {student?.studentName}
                    </p>
                    <p className='list-unstyled text-gray-400 poppins-light fs-6 ps-10 m-0'>
                      {student?.studentName}
                    </p>
                  </div>
                </div>
                <p className='px-10 me-5 poppins-bold '>Topic: {lesson && lesson.name}</p>
                <p className='w-25 poppins-light text-end'>Page: 1</p>
              </div>
              {/* Tabs con el resto del contenido */}
              <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-hidden'>
                  <ul
                    className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
                  >
                    <li className='nav-item'>
                      <a
                        className={clsx(`nav-link poppins-bold text-${tab === 'Exercise' ? "primary" : ""} cursor-pointer`, {
                          active: tab === 'Exercise',
                        })}
                        onClick={() => setTab('Exercise')}
                      >
                        Exercise
                      </a>
                    </li>

                    <li className='nav-item'>
                      <a
                        className={clsx(`nav-link poppins-bold text-${tab === 'Book' ? "primary" : ""} cursor-pointer`, {
                          active: tab === 'Book',
                        })}
                        onClick={() => setTab('Book')}
                        role='tab'
                      >
                        Book Page
                      </a>
                    </li>
                  </ul>
                </div>
                {tab === 'Exercise' && selectedExercise && currentTask &&  
                  <ExerciseInfo exercise={selectedExercise} task={currentTask} />}
                {tab === 'Book' && (
                  <RenderBookPage
                    numberPage={currentPage}
                    pagesBook={bookPages??[]}
                  />
                )}
              </div>
            </div>
            {/* Contenedor de la lista de ejercicios */}
            <div className='col rounded' style={{
              maxHeight: '700px',
              overflowY: 'auto'
            }}>
              <div className='list-group' style={{
              }}>
                {
                  evaluationItems && 
                  evaluationItems.filter(x => x.exercise.type !== ExercisesType.AUDIO_INTERACTION)
                  .sort((a, b) => a.order - b.order).map((item) => (
                  <a
                    href='#'
                    className={`list-group-item list-group-item-action ${selectedExercise === item.exercise ? 'active' : ''
                      } `}
                    aria-current='true'
                    onClick={() => handleSelectItem(item)}
                  >
                    <div className='d-flex w-100 justify-content-between'>
                      <h5 className='mb-1 poppins-bold'>{item.name}</h5>
                      <small className='poppins-light'>{item.exercise.level}</small>
                    </div>
                    <p className={`mb-1 poppins-light text-${selectedExercise === item.exercise ? "white" : "secondary-700"}`}>{t(`common:${item.exercise.type}`)}.</p>
                    <div className='d-flex w-100 justify-content-between'>
                      <small className={`poppins-light text-${selectedExercise === item.exercise ? "white" : "secondary-700"}`}>{item.description}</small>
                      <small>
                        {item.exercise.isActive ? (
                          <i className='bi bi-check-circle-fill text-success' />
                        ) : (
                          <i className='bi bi-x-circle-fill text-danger' />
                        )}
                      </small>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default LoggedStudentsModal

const styles: Record<string, React.CSSProperties> = {
  studentListContainer: {
    position: 'absolute',
    top: 120,
    left: 0,
    right: `calc(100vw - 120px)`,
    bottom: 20,
    height: `calc(90vh - 120px)`,
  },
}
