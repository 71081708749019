
import { persist } from 'zustand/middleware';
import {create} from 'zustand';
import { LoginResponse } from '../modules/auth/interfaces';
import { authChecked, loginAuth } from '../modules/auth/services/auth.services';
import { ProfileDocumentType } from '../modules/profile/components/details';
import { UserSettings } from '../modules/profile/components/my-road-step/interfaces';

export interface Profile {
  aboutMe?:string
  address?:string
  birthDay?:string
  btcWallet?:string
  city?:string
  country?:string
  documentNumber?:string
  firstName?:string
  gender?:string
  lastName?:string
  phoneNumber?:string
  photo?:any
  avatar?:string
  tronWallet?:string
  zipCode?:string
  culturalInformation?: string;
  documentType?: ProfileDocumentType;
  state?: string;
  municipality?: string;
  eps?: string;
  neighborhood?: string;
  socialStratum?: string;
  civilStatus?: string;
  sisben?: string;
  educationLevel?: string;
  occupation?: string;
  hourlyDay?: string;
  disability?: string;
}

export type UserType = 'TEACHER'|'EXECUTIVE'|'ADMIN'|'ROOT'|'CLIENT'|'ASSISTANT'|'STUDENT'|'COURTESY'|'PLACEMENT_TEST'|'ASSISTANT_WELCOME';

  interface AuthState {
    id?:    string
    email?: string;
    loading: boolean;
    roles?: string[]
    fullName?: string;
    code?:string
    token?: string
    studentSettings?:UserSettings;
    profile?:Profile
    notifications:any[]
    typeUser?:UserType,
    isAuthenticated: boolean
    username?: string
    userAuth?:LoginResponse
    login: (username: string,password:string) => Promise<void>;
    setProfile: (value:Profile) => void;
    setPhotoProfile: (value:Profile) => void;
    logout: () => void;
    resetNotification: () => void;
    authChecked: () => Promise<void>;
    setNotification:(value:any)=>void;
    setSettings:(mode:UserSettings)=>void;
    setAuthChecked:(value: any)=>void;
    setLoading:(value: boolean)=>void;
}

const useAuth = create(persist<AuthState>(
    (set, get) =>  ({
        notifications:[],
        loading: false,
        isAuthenticated: false,
        login: async (username: string,password:string) =>{ 
          try{
            let data = await loginAuth({
              email: username,
              password: password
            })
            // console.log(data)
            set({token: data.token});
            
            setTimeout(() => { 
            set({
              loading: false,
              id:data.id,
              email:data.email??'',
              roles:data.roles,
              fullName: data.fullName??'',
              token: data.token,
              profile:data.profile,
              isAuthenticated:true,
              typeUser:data.type,
              code:data.code??undefined,
            });
              window.location.replace('/dashboard')
            }, 4000)
            
            // setTimeout(() => {
            //   window.location.replace('/')
            // }, 1000)
          }catch(e){
            set({
              email:'',
              roles:[],
              token:"",
              profile:{},
              isAuthenticated:false
            })
          }
        },
        logout: () =>{
          localStorage.clear();
          const cookies = document.cookie.split(";");

          cookies.forEach(cookie => {
            // Obtenemos el nombre de la cookie
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.slice(0, eqPos).trim() : cookie.trim();
      
            // Eliminar la cookie estableciendo una fecha pasada
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            // Si las cookies están en subdominios, agrega el dominio
            // document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.example.com`;
          });
          window.location.replace('/auth')
        },
        authChecked:async ()=>{
          // if (!get().token) {
          //   return;
          // }
          // try{
          //   let response = // await authChecked()
          //   set({
          //       profile:{...response.profile},
          //       token:response.token,
          //       typeUser:response.type
          //     })
          // }catch{
          //   set({
          //     isAuthenticated: false,
          //     username: undefined,
          //     userAuth:undefined,
          //     token:"",
          //     profile:{}
          //   })
          //   localStorage.clear();
          //   window.location.replace('/auth')
          // }
        },
        setProfile(value:Profile){
          set({
            profile:{
              ...value,
              avatar:useAuth.getState().profile?.avatar
            }
          })
        },
        setPhotoProfile(value:Profile){
          set({profile:{
            ...useAuth.getState().profile,
            avatar:value.avatar
          }})
        },
        setNotification(value:any){
          let currentArray:any[]=useAuth.getState().notifications;
          currentArray.push(value)
          set({notifications:currentArray})
        },
        resetNotification(){
          set({notifications:[]})
        },
        setSettings(value){
          set({studentSettings:value})
        },
        setAuthChecked:(value)=>{
          set({
            // profile: {...value.profile},
            token: value.token,
            // typeUser: value.typeUser,
          })
        },
        setLoading:(value) => {
          set({loading: value})
        }
      }),
      {
        name:"auth",
        // getStorage: () => localStorage
      }
));

export default useAuth;